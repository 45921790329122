import React, { useMemo } from 'react';
import { Typography, Box, Paper, Grid, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, BarChart, Bar, XAxis, YAxis } from 'recharts';
import NutritionStats from "../../nutrition/NutritionStats";
import ExpandableCard from "../../../../../components/list/ExpandableCard";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));

const StatBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
}));

function RecipeStats({ items }) {
    const theme = useTheme();
    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.error.main,
        theme.palette.warning.main,
        theme.palette.info.main,
        theme.palette.success.main,
    ];

    const stats = useMemo(() => {
        const categories = {};
        const tags = {};
        let totalCookingTime = 0;
        const cookingTimes = [];
        const processedIds = new Set();

        items.forEach(item => {
            if (item.id && !processedIds.has(item.id)) {
                processedIds.add(item.id);
                if (item.content && item.content.content) {
                    const { category, tags: recipeTags, time_estimate_minutes } = item.content.content;
                    if (category) {
                        categories[category] = (categories[category] || 0) + 1;
                    }
                    if (recipeTags && Array.isArray(recipeTags)) {
                        recipeTags.forEach(tag => {
                            tags[tag] = (tags[tag] || 0) + 1;
                        });
                    }
                    if (time_estimate_minutes) {
                        const time = parseInt(time_estimate_minutes);
                        totalCookingTime += time;
                        cookingTimes.push(time);
                    }
                }
            }
        });

        const totalItems = processedIds.size;
        const avgCookingTime = totalItems > 0 ? totalCookingTime / totalItems : 0;
        const cookingTimeDistribution = [
            { name: '0-15 min', value: cookingTimes.filter(time => time <= 15).length },
            { name: '16-30 min', value: cookingTimes.filter(time => time > 15 && time <= 30).length },
            { name: '31-60 min', value: cookingTimes.filter(time => time > 30 && time <= 60).length },
            { name: '60+ min', value: cookingTimes.filter(time => time > 60).length },
        ];

        return {
            totalItems,
            categories,
            tags,
            avgCookingTime,
            cookingTimeDistribution
        };
    }, [items]);

    const preparePieChartData = (data) => {
        return Object.entries(data)
            .map(([name, value]) => ({
                name: name.charAt(0).toUpperCase() + name.slice(1),
                value
            }))
            .sort((a, b) => b.value - a.value);
    };

    const categoryData = preparePieChartData(stats.categories);
    const tagData = preparePieChartData(stats.tags);

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>Recipe Book Stats</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <Typography variant="h4">{stats.totalItems}</Typography>
                        <Typography variant="body2">Total Recipes</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <Typography variant="h4">{Object.keys(stats.categories).length}</Typography>
                        <Typography variant="body2">Recipe Categories</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <Typography variant="h4">{stats.avgCookingTime.toFixed(0)} min</Typography>
                        <Typography variant="body2">Avg. Cooking Time</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>Recipe Categories Distribution</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={categoryData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                outerRadius={80}
                                fill={theme.palette.primary.main}
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {categoryData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>Cooking Time Distribution</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={stats.cookingTimeDistribution}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Bar dataKey="value" fill={theme.palette.primary.main}>
                                {stats.cookingTimeDistribution.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Top Recipe Tags</Typography>
                    <ResponsiveContainer width="100%" height={200}>
                        <BarChart data={tagData.slice(0, 5)}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Bar dataKey="value" fill={theme.palette.primary.main}>
                                {tagData.slice(0, 5).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        Your recipe book contains a total of {stats.totalItems} recipes, covering {Object.keys(stats.categories).length} different categories.
                    </Typography>
                    <Typography variant="body2">
                        The charts display the distribution of recipe categories, cooking times, and top tags in your recipe book, helping you visualize the variety and characteristics of your recipes.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        This overview can help you ensure you're maintaining a diverse collection of recipes across various categories and cooking times. Consider adding recipes to underrepresented categories or exploring new tags to expand your culinary repertoire.
                    </Typography>
                </Grid>
            </Grid>
            &nbsp;
            <ExpandableCard
                title={"Nutrition Estimates"}
                initiallyExpanded={false}
            >
                <NutritionStats items={items} nutritionPath="content.content.nutrition_estimate" />
            </ExpandableCard>
        </StyledPaper>
    );
}

export default RecipeStats;