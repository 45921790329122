import React, { useState, useCallback } from 'react';
import { IconButton, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useApi from "./ApiService";

const DeleteSource = ({ sourceId, onDeleteSuccess }) => {
    const { apiCall } = useApi();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleDelete = useCallback(async () => {
        try {
            await apiCall(`/source/${sourceId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (onDeleteSuccess) {
                onDeleteSuccess(sourceId);
            }
        } catch (error) {
            console.error('Error deleting source:', error);
            // You might want to add error handling here, such as showing an error message to the user
        }
    }, [sourceId, onDeleteSuccess, apiCall]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {showConfirmation ? (
                <Button
                    variant="contained"
                    color="error"
                    startIcon={<CheckCircleIcon />}
                    onClick={handleDelete}
                    sx={{ mr: 2 }}
                >
                    Confirm Delete
                </Button>
            ) : (
                <IconButton
                    color="error"
                    onClick={() => setShowConfirmation(true)}
                    aria-label="delete source"
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default DeleteSource;