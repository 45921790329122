import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import AppCardGrid from "../../../components/apps/AppCardGrid";
import subNav from "./subNavConfig";

function Library() {
    return (
        <Container maxWidth="lg">
            <Box textAlign="center" my={4}>
                <Typography variant="h2" component="h1" gutterBottom>
                    Welcome to the Library
                </Typography>
                <Typography variant="h5" component="p" gutterBottom>
                    Manage your sources and collections, and search for sources here.
                </Typography>
            </Box>
            <AppCardGrid subNav={subNav} />
        </Container>
    );
}

export default Library;