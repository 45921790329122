import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Typography, CircularProgress, Box, Checkbox, Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SourceItem from './SourceItem';
import useApi from "../ApiService";
import { useSource} from "../../context/SourceContext";

function SourceList({
                        query,
                        ItemDisplay = SourceItem,
                        onSourceLoad,
                        onSourceUpdate,
                        onContentLoad,
                        onSourceDelete,
                        eagerLoad = false,
                        autoLoadExpandedContent = false,
                        expanded,
                        filterFunction = () => true,
                        sortFunction = null,
                        onChecklistUpdate,
                        asChecklist = false,
                        checkedItems = [],
                        showCheckAllClear = false,
                        allowDelete = false
                    }) {
    const { apiCall } = useApi();
    const { updateSourceInfo } = useSource();
    const [sources, setSources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [checkedSources, setCheckedSources] = useState(checkedItems);
    const prevCheckedItemsRef = useRef(checkedItems);

    const handleSourceUpdate = (updatedSource) => {
        setSources(prevSources =>
            prevSources.map(source =>
                source.id === updatedSource.id ? updatedSource : source
            )
        );
        if (onSourceUpdate) {
            onSourceUpdate(updatedSource);
        }
    };

    const handleSourceDelete = useCallback((deletedSource) => {
        console.log("Handling delete from SourceList:", deletedSource);
        setSources(prevSources => prevSources.filter(source => source.id !== deletedSource.id));
        setCheckedSources(prevChecked => prevChecked.filter(id => id !== deletedSource.id));

        if (onSourceDelete) {
            onSourceDelete(deletedSource);
        }

        toast.success(`Source "${deletedSource.name || deletedSource.id}" deleted successfully`);
    }, [onSourceDelete]);

    useEffect(() => {
        const fetchSources = async () => {
            try {
                const response = await apiCall(`${query}`);
                if (response.status !== 200) {
                    throw new Error('Failed to fetch sources');
                }
                const data = await response.data;
                setSources(data.sources);
                setLoading(false);

                // Update source names in the context
                data.sources.forEach(source => {
                    updateSourceInfo(source.id, source.name, source.uri);
                });

                if (eagerLoad && onSourceLoad) {
                    data.sources.forEach(source => onSourceLoad(source));
                }
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchSources();
    }, [query, eagerLoad, onSourceLoad, apiCall, updateSourceInfo]);

    useEffect(() => {
        if (asChecklist && onChecklistUpdate) {
            onChecklistUpdate(checkedSources);
        }
    }, [asChecklist, checkedSources, onChecklistUpdate]);

    useEffect(() => {
        if (prevCheckedItemsRef.current !== checkedItems) {
            setCheckedSources(checkedItems);
            prevCheckedItemsRef.current = checkedItems;
        }
    }, [checkedItems]);

    const filteredAndSortedSources = useMemo(() => {
        let result = sources.filter(filterFunction);
        if (sortFunction) {
            result = result.sort(sortFunction);
        }
        return result;
    }, [sources, filterFunction, sortFunction]);

    const handleCheckboxChange = (sourceId) => {
        setCheckedSources(prev => {
            if (prev.includes(sourceId)) {
                return prev.filter(id => id !== sourceId);
            } else {
                return [...prev, sourceId];
            }
        });
    };

    const handleCheckAll = () => {
        setCheckedSources(filteredAndSortedSources.map(source => source.id));
    };

    const handleClear = () => {
        setCheckedSources([]);
    };

    const handleDeleteChecked = useCallback(async () => {
        let successCount = 0;
        let errorCount = 0;

        for (const sourceId of checkedSources) {
            try {
                await apiCall(`/source/${sourceId}`, {method: 'DELETE'});
                handleSourceDelete({ id: sourceId });
                successCount++;
            } catch (err) {
                console.error(`Failed to delete source ${sourceId}:`, err);
                errorCount++;
                toast.error(`Failed to delete source ${sourceId}`);
            }
        }

        if (successCount > 0) {
            toast.success(`Successfully deleted ${successCount} source${successCount > 1 ? 's' : ''}`);
        }
        if (errorCount > 0) {
            toast.error(`Failed to delete ${errorCount} source${errorCount > 1 ? 's' : ''}`);
        }

        setCheckedSources([]);
    }, [checkedSources, apiCall, handleSourceDelete]);

    return (
        <Box width="100%">
            {asChecklist && showCheckAllClear && (
                <Box display="flex" justifyContent="flex-start" mb={1} ml={1}>
                    <Typography
                        component="span"
                        sx={{
                            cursor: 'pointer',
                            mr: 2,
                            color: 'orange.main',
                            '&:hover': { textDecoration: 'underline' }
                        }}
                        onClick={handleCheckAll}
                    >
                        Check All
                    </Typography>
                    <Typography
                        component="span"
                        sx={{
                            cursor: 'pointer',
                            color: 'orange.main',
                            '&:hover': { textDecoration: 'underline' }
                        }}
                        onClick={handleClear}
                    >
                        Clear
                    </Typography>
                </Box>
            )}
            {asChecklist && allowDelete && checkedSources.length > 0 && (
                <Box mb={2}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteChecked}
                    >
                        Delete Checked
                    </Button>
                </Box>
            )}
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Typography color="error" align="center">
                    Error: {error}
                </Typography>
            ) : filteredAndSortedSources.length === 0 ? (
                <Typography>No sources found.</Typography>
            ) : (
                filteredAndSortedSources.map((source) => (
                    <Box key={source.id} sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        {asChecklist && (
                            <Checkbox
                                checked={checkedSources.includes(source.id)}
                                onChange={() => handleCheckboxChange(source.id)}
                                sx={{ mt: 1, mr: 1 }}
                            />
                        )}
                        <Box flexGrow={1} width="100%">
                            <ItemDisplay
                                key={source.id}
                                id={source.id}
                                item={source}
                                sourceRecord={source}
                                eagerlyLoadContent={eagerLoad}
                                showRagGenerations={true}
                                onSourceLoad={onSourceLoad}
                                onSourceUpdate={handleSourceUpdate}
                                onSourceDelete={handleSourceDelete}
                                onContentLoad={onContentLoad}
                                expanded={expanded}
                                autoLoadExpandedContent={autoLoadExpandedContent}
                            />
                        </Box>
                    </Box>
                ))
            )}
        </Box>
    );
}

export default SourceList;