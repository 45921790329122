// src/apps/subNavConfig.js
import React from 'react';
import Professor from "./professor/Professor";
import Nutritionist from "./nutritionist/Nutritionist";
import PersonalTrainer from "./personaltrainer/PersonalTrainer";
import LifeCoach from "./lifecoach/LifeCoach";
import { School, DinnerDining } from '@mui/icons-material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import MenuBook from "@mui/icons-material/MenuBook";
import Library from "./library/Library";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const subNav = {
    header: 'Apps',
    links: [
        {
            name: 'Nutritionist',
            path: '/apps/nutritionist',
            icon: DinnerDining,
            element: <Nutritionist />,
            title: 'Nutritionist',
            description: 'Easily manage your kitchen.',
            buttonText: 'Open Nutritionist'
        },
        // {
        //     name: 'Life Coach',
        //     path: '/apps/lifecoach',
        //     icon: CalendarMonthIcon,
        //     element: <LifeCoach />,
        //     title: 'Life Coach',
        //     description: 'Manage your life.',
        //     buttonText: 'Open Life Coach'
        // },
        {
            name: 'Personal Trainer',
            path: '/apps/personaltrainer',
            icon: RecordVoiceOverIcon,
            element: <PersonalTrainer />,
            title: 'Personal Trainer',
            description: 'Track and improve your fitness.',
            buttonText: 'Open Personal Trainer'
        },
        {
            name: 'Professor',
            path: '/apps/professor',
            icon: School,
            element: <Professor />,
            title: 'Professor',
            description: 'Access teaching tools and resources',
            buttonText: 'Open Professor Tools'
        },
        {
            name: 'Library',
            path: '/apps/library',
            icon: MenuBook,
            element: <Library />,
            title: 'Digital Library',
            description: 'Manage your sources and collections.',
            buttonText: 'Browse Library'
        },
    ],
    routes: [
        { path: '/apps/professor', element: <Professor /> },
        { path: '/apps/nutritionist', element: <Nutritionist /> },
        { path: '/apps/library', element: <Library /> },
        { path: '/apps/lifecoach', element: <LifeCoach /> },
        { path: '/apps/personaltrainer', element: <PersonalTrainer /> }
    ]
};

export default subNav;