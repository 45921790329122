import React from 'react';
import ManagedPage from "../../../../components/apps/ManagedPage";
import FoodItem from './FoodItem';
import FoodStats from './FoodStats';

function FoodInventory() {
    const customFilter = (item, filterText) => {
        const content = item.content?.content;
        if (!content) return false;

        const searchableText = `${content.name} ${content.food_group} ${content.type} ${content.expiration_date}`.toLowerCase();
        return searchableText.includes(filterText.toLowerCase());
    };

    const customSort = (a, b) => {
        const dateA = new Date(a.content?.content?.expiration_date || '');
        const dateB = new Date(b.content?.content?.expiration_date || '');
        return dateA - dateB;
    };

    return (
        <ManagedPage
            title="Food Inventory"
            addItemsButtonText={"Add Food Items"}
            systemCollectionName="Food Inventory"
            sourceType="Food Item"
            StatsComponent={({ items }) => <FoodStats items={items} />}
            ItemDisplay={FoodItem}
            extractorProps={{
                availableTypes: [{ id: "Food Item", label: "Food Item" }],
                fromQuery: '/source/search?',
                toQuery: '/source/search?',
                fromRootAssociation: "Nutritionist",
                fromPossibleTypes: ["Source"],
                showTo: false,
                showContext: false,
                showType: false,
            }}
            managedListProps={{
                filterFunction: customFilter,
                sortFunction: customSort,
                allowDelete: true,
            }}
        />
    );
}

export default FoodInventory;