import AppCardGrid from "../../../components/apps/AppCardGrid";
import subNav from "../personaltrainer/subNavConfig";
import React from "react";

const PersonalTrainerLanding = () => {
    return (
        <AppCardGrid title={`${subNav.header} Dashboard`} subNav={subNav} />
    );
};

export default PersonalTrainerLanding;
