import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import { Save, Cancel, Delete } from '@mui/icons-material';

const EditField = React.memo(({ field, value, onSubmit, onCancel, invertColors = false, showDelete=true }) => {
    const [editedValue, setEditedValue] = useState(value || '');

    const handleSubmit = () => {
        onSubmit(editedValue);
    };

    const handleDelete = () => {
        onSubmit('');
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
                label={field}
                value={editedValue}
                onChange={(e) => setEditedValue(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
            />
            <IconButton onClick={handleSubmit} color={invertColors ? "secondary" : "primary"}>
                <Save />
            </IconButton>
            <IconButton onClick={onCancel} color="default">
                <Cancel />
            </IconButton>
            {showDelete &&
                <IconButton onClick={handleDelete} color={invertColors ? "secondary" : "primary"}>
                    <Delete />
                </IconButton>
            }
        </Box>
    );
});

export default EditField;