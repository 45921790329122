import React, { useMemo } from 'react';
import { Typography, Box, Grid, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LineChart, Line, CartesianGrid } from 'recharts';

const NutritionStats = ({ items, nutritionPath = 'content.content.nutrition_estimate' }) => {
    const theme = useTheme();

    const nutritionStats = useMemo(() => {
        const stats = {
            averages: { calories: 0, fat: 0, protein: 0, carbs: 0, fiber: 0 },
            categories: {
                carb: { low: 0, medium: 0, high: 0 },
                fat: { low: 0, medium: 0, high: 0 },
                calorie: { low: 0, medium: 0, high: 0 },
            },
            healthCategories: {},
            calorieDistribution: [],
        };

        let validItems = 0;

        items.forEach(item => {
            const nutrition = nutritionPath.split('.').reduce((obj, key) => obj && obj[key], item);
            if (nutrition) {
                validItems++;
                stats.averages.calories += nutrition.calories || 0;
                stats.averages.fat += nutrition.fat || 0;
                stats.averages.protein += nutrition.protein || 0;
                stats.averages.carbs += nutrition.carbs || 0;
                stats.averages.fiber += nutrition.fiber || 0;

                if (nutrition.carb_category) stats.categories.carb[nutrition.carb_category.toLowerCase()]++;
                if (nutrition.fat_category) stats.categories.fat[nutrition.fat_category.toLowerCase()]++;
                if (nutrition.calorie_category) stats.categories.calorie[nutrition.calorie_category.toLowerCase()]++;
                if (nutrition.health_category) {
                    stats.healthCategories[nutrition.health_category] = (stats.healthCategories[nutrition.health_category] || 0) + 1;
                }

                stats.calorieDistribution.push({
                    name: item.content?.content?.name || `Recipe ${validItems}`,
                    calories: nutrition.calories || 0
                });
            }
        });

        if (validItems > 0) {
            Object.keys(stats.averages).forEach(key => {
                stats.averages[key] = Math.round(stats.averages[key] / validItems);
            });
        }

        stats.calorieDistribution.sort((a, b) => a.calories - b.calories);

        return stats;
    }, [items, nutritionPath]);

    const macroData = [
        { name: 'Fat', value: nutritionStats.averages.fat },
        { name: 'Protein', value: nutritionStats.averages.protein },
        { name: 'Carbs', value: nutritionStats.averages.carbs },
        { name: 'Fiber', value: nutritionStats.averages.fiber },
    ];

    const categoryData = [
        { name: 'Carbs', low: nutritionStats.categories.carb.low, medium: nutritionStats.categories.carb.medium, high: nutritionStats.categories.carb.high },
        { name: 'Fat', low: nutritionStats.categories.fat.low, medium: nutritionStats.categories.fat.medium, high: nutritionStats.categories.fat.high },
        { name: 'Calories', low: nutritionStats.categories.calorie.low, medium: nutritionStats.categories.calorie.medium, high: nutritionStats.categories.calorie.high },
    ];

    const healthCategoryData = Object.entries(nutritionStats.healthCategories).map(([name, value]) => ({ name, value }));

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>Nutrition Statistics</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>Average Calorie Content (per serving)</Typography>
                    <Typography variant="h4" align="center">{nutritionStats.averages.calories} kcal</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>Average Macronutrients (grams per serving)</Typography>
                    <ResponsiveContainer width="100%" height={200}>
                        <BarChart data={macroData}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Bar dataKey="value" fill={theme.palette.primary.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>Calorie Distribution</Typography>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart data={nutritionStats.calorieDistribution}>
                            <XAxis dataKey="name" tick={false} />
                            <YAxis />
                            <CartesianGrid stroke="#f5f5f5" />
                            <Line type="monotone" dataKey="calories" stroke={theme.palette.secondary.main} />
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>Nutrition Categories Distribution</Typography>
                    <ResponsiveContainer width="100%" height={200}>
                        <BarChart data={categoryData}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Bar dataKey="low" stackId="a" fill={theme.palette.success.main} />
                            <Bar dataKey="medium" stackId="a" fill={theme.palette.warning.main} />
                            <Bar dataKey="high" stackId="a" fill={theme.palette.error.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>Health Categories Distribution</Typography>
                    <ResponsiveContainer width="100%" height={200}>
                        <BarChart data={healthCategoryData}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Bar dataKey="value" fill={theme.palette.info.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NutritionStats;