import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Chip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSourceName } from "../../context/useSourceName";

const ExtractionFlowDisplay = ({ fromSelections, toSelections, inputMethod, manualText, file, type }) => {
    const { getSourceNameById } = useSourceName();
    const [sourceNames, setSourceNames] = useState([]);
    const [destinationNames, setDestinationNames] = useState([]);

    useEffect(() => {
        const fetchNames = async (selections, setNames) => {
            if (selections && selections.length > 0) {
                const names = await Promise.all(
                    selections.map(async (id) => {
                        try {
                            return await getSourceNameById(id);
                        } catch (error) {
                            console.error('Error fetching source name:', error);
                            return 'Unknown';
                        }
                    })
                );
                setNames(names);
            } else {
                setNames([]);
            }
        };

        if (inputMethod === 'select') {
            fetchNames(fromSelections, setSourceNames);
        } else {
            setSourceNames([]);
        }
        fetchNames(toSelections, setDestinationNames);
    }, [fromSelections, toSelections, getSourceNameById, inputMethod]);

    const renderChips = (names, color) => (
        <Box display="flex" flexWrap="wrap" justifyContent="center">
            {names.map((name, index) => (
                <Chip
                    key={index}
                    label={name}
                    color={color}
                    sx={{ m: 0.5 }}
                />
            ))}
        </Box>
    );

    const renderSourceContent = () => {
        switch (inputMethod) {
            case 'select':
                return sourceNames.length > 0
                    ? renderChips(sourceNames, 'primary')
                    : <Chip label="No sources selected" color="warning" sx={{ m: 0.5 }} />;
            case 'text':
                return <Chip label={`Your Details (${manualText.length} characters)`} color="primary" sx={{ m: 0.5 }} />;
            case 'file':
                return <Chip label={file ? `File: ${file.name}` : 'No file selected'} color="primary" sx={{ m: 0.5 }} />;
            default:
                return <Chip label="Please select input" color="warning" sx={{ m: 0.5 }} />;
        }
    };

    const renderDestinationContent = () => {
        return destinationNames.length > 0
            ? renderChips(destinationNames, 'primary')
            : <Chip label="No destinations selected" color="warning" sx={{ m: 0.5 }} />;
    };

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: 'background.paper' }}>
            <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
                {renderSourceContent()}
                <ArrowForwardIcon sx={{ mx: 2 }} />
                <Chip label={type || 'No type specified'} color="info" sx={{ m: 0.5 }} />
                <ArrowForwardIcon sx={{ mx: 2 }} />
                {renderDestinationContent()}
            </Box>
        </Paper>
    );
};

export default React.memo(ExtractionFlowDisplay);