// ExpandableCard.js
import React, { useState } from 'react';
import { Card, CardContent, Typography, CardHeader, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ExpandableCard({ title, initiallyExpanded = false, children, onExpand }) {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

    const handleExpandClick = () => {
        const newExpandedState = !isExpanded;
        setIsExpanded(newExpandedState);
        if (onExpand) {
            onExpand(newExpandedState);
        }
    };

    const renderTitle = () => {
        if (typeof title === 'string') {
            return (
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
            );
        }
        return title;
    };

    return (
        <Card sx={{ mb: 1, width: '100%' }}>
            <CardHeader
                sx={{
                    py: 1,
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'action.hover',
                    },
                }}
                onClick={handleExpandClick}
                title={renderTitle()}
                action={
                    <IconButton
                        aria-expanded={isExpanded}
                        aria-label="show more"
                        size="small"
                        sx={{
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                        }}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ py: 1 }}>
                    {children}
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default ExpandableCard;