import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { TextField, Box, InputAdornment, Button, CircularProgress, Grid, Checkbox, FormControlLabel, Snackbar, Alert, Typography } from '@mui/material';
import SourceList from './SourceList';
import AssociationsDropdown from "../AssociationsDropdown";
import SourceForm from "../form/SourceForm";
import TypeSelector from "../TypeSelector";
import TooltipWrapper from "../StyledTooltip";

function ManagedList({
                         query,
                         rootAssociation,
                         ItemDisplay,
                         onSourceLoad,
                         onSourceUpdate,
                         onSourceDelete,
                         onContentLoad,
                         customFilterFunction,
                         sortFunction = null,
                         showTypeSelection = false,
                         possibleTypes,
                         defaultSelectedTypes = [],
                         onChecklistUpdate,
                         onAssociationUpdate,
                         checkedItems = [],
                         eagerLoad = false,
                         expanded = false,
                         asChecklist = false,
                         showCheckAllClear = false,
                         showAssociationsDropdown = true,
                         showFilter = true,
                         showOrphanOptions = false,
                         showOrphansByDefault = false,
                         autoLoadExpandedContent = false,
                         allowNew = false,
                         allowDelete = false,
                         loadOnDefault = false,
                         selectedAssociation = null,
                         NewItemForm,
                         includeRoot = false,
                     }) {
    const [filterText, setFilterText] = useState('');
    const [selectedAssociations, setSelectedAssociations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [includeOrphans, setIncludeOrphans] = useState(showOrphansByDefault);
    const [orphansOnly, setOrphansOnly] = useState(false);
    const [checkedSources, setCheckedSources] = useState(checkedItems);
    const [showNewSourceForm, setShowNewSourceForm] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState(defaultSelectedTypes);
    const [refreshKey, setRefreshKey] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [shouldLoadSources, setShouldLoadSources] = useState(loadOnDefault);
    const [selectedAssociationId, setSelectedAssociationId] = useState(selectedAssociation);
    const [isDeleteMode, setIsDeleteMode] = useState(false);

    const defaultSortFunction = useCallback((a, b) => {
        return a.name.localeCompare(b.name);
    }, []);

    const effectiveSortFunction = sortFunction || defaultSortFunction;

    const handleChecklistUpdate = useCallback((newCheckedSources) => {
        setCheckedSources(newCheckedSources);
        if (onChecklistUpdate) {
            onChecklistUpdate(newCheckedSources);
        }
    }, [onChecklistUpdate]);

    const handleSourceDelete = useCallback((deletedSourceId) => {
        if (onSourceDelete) {
            onSourceDelete(deletedSourceId);
        }
    }, [onSourceDelete]);

    const handleSourceLoad = useCallback((loadedSources) => {
        if (onSourceLoad) {
            onSourceLoad(loadedSources);
        }
    }, [onSourceLoad]);

    const defaultFilterFunction = useCallback((source) => {
        const lowercaseFilter = filterText.toLowerCase();
        return (
            source.name.toLowerCase().includes(lowercaseFilter) ||
            source.description.toLowerCase().includes(lowercaseFilter) ||
            source.id.toLowerCase().includes(lowercaseFilter) ||
            (source.uri && source.uri.toLowerCase().includes(lowercaseFilter))
        );
    }, [filterText]);

    const filterFunction = customFilterFunction
        ? (source) => customFilterFunction(source, filterText)
        : defaultFilterFunction;

    const handleClearFilter = () => {
        setFilterText('');
    };

    const handleCollectionSelect = useCallback((selected) => {
        console.log("handleCollectionSelect: ", selected);
        if (onAssociationUpdate) {
            onAssociationUpdate(selected);
        }
        setSelectedAssociations(selected.allDescendants);
        setShouldLoadSources(true);
        setSelectedAssociationId(selected.id);
    }, [onAssociationUpdate]);

    const handleCollectionsUpdate = useCallback((newCollections) => {
        console.log("handleCollectionsUpdate: ", newCollections);
        if (newCollections.length > 0) {
            const rootCollection = newCollections.find(c => c.label === rootAssociation);
            if (rootCollection) {
                setSelectedAssociations(rootCollection.allDescendants);
            }
        }
        setIsLoading(false);
    }, [rootAssociation]);

    const handleIncludeOrphansChange = (event) => {
        setIncludeOrphans(event.target.checked);
    };

    const handleOrphansOnlyChange = (event) => {
        const newOrphansOnly = event.target.checked;
        setOrphansOnly(newOrphansOnly);
        setShouldLoadSources(true);
        if (newOrphansOnly) {
            setSelectedAssociations([]);
            setSelectedAssociationId(null);
        }
    };

    const handleSourceUpdate = useCallback((updatedSource) => {
        if (onSourceUpdate) {
            onSourceUpdate(updatedSource);
        }
    }, [onSourceUpdate]);

    const modifiedQuery = useMemo(() => {
        const params = new URLSearchParams();

        // Add base query parameters
        const baseParams = new URLSearchParams(query.split('?')[1]);
        baseParams.forEach((value, key) => params.append(key, value));

        // Add type filtering
        if (selectedTypes.length > 0) {
            selectedTypes.forEach(type => params.append('types', type));
        }

        if (orphansOnly) {
            params.set('orphansOnly', 'true');
        }

        if (showOrphanOptions && orphansOnly) {
            params.set('includeOrphans', 'true');
        } else {
            if (selectedAssociations.length > 0) {
                selectedAssociations.forEach(association => params.append('associations', association));
            }
            if (showOrphanOptions && includeOrphans) {
                params.set('includeOrphans', 'true');
            }
        }

        // Always include the root when includeRoot is true
        if (includeRoot && selectedAssociationId) {
            params.append('includeIds', selectedAssociationId);
        }

        return `${query.split('?')[0]}?${params.toString()}`;
    }, [query, selectedTypes, selectedAssociations, includeOrphans, orphansOnly, showOrphanOptions, includeRoot, selectedAssociationId]);

    const handleAddNewSource = () => {
        setShowNewSourceForm(true);
    };

    const handleNewSourceSubmit = (newSource) => {
        setShowNewSourceForm(false);
        setSuccessMessage('New source added successfully!');
        setRefreshKey(prevKey => prevKey + 1);

        // Clear the success message after 3 seconds
        setTimeout(() => {
            setSuccessMessage('');
        }, 3000);
    };

    const handleTypeChange = useCallback((newSelectedTypes) => {
        setSelectedTypes(newSelectedTypes);
        setRefreshKey(prevKey => prevKey + 1);
    }, []);

    const handleDeleteModeToggle = () => {
        setIsDeleteMode(!isDeleteMode);
    };

    // Determine if we should show the delete functionality
    const showDeleteFunctionality = allowDelete && !asChecklist;

    useEffect(() => {
        setSelectedAssociationId(selectedAssociation);
    }, [selectedAssociation]);

    return (
        <Box maxWidth="100%" width="100vw" px={2}>
            <Grid container spacing={2} alignItems="center">
                {(!showOrphanOptions || !orphansOnly) && (
                    <Grid item xs={12} md={showOrphanOptions ? 5 : 8}>
                        <AssociationsDropdown
                            rootAssociation={rootAssociation}
                            onCollectionSelect={handleCollectionSelect}
                            onCollectionsUpdate={handleCollectionsUpdate}
                            hide={!showAssociationsDropdown}
                            selected={selectedAssociationId}
                        />
                    </Grid>
                )}
                {showTypeSelection && (
                    <Grid item xs={12}>
                        <TypeSelector
                            possibleTypes={possibleTypes}
                            defaultSelectedTypes={defaultSelectedTypes}
                            onChange={handleTypeChange}
                        />
                    </Grid>
                )}
                {showOrphanOptions && (
                    <Grid item xs={12} md={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeOrphans}
                                    onChange={handleIncludeOrphansChange}
                                    disabled={orphansOnly}
                                />
                            }
                            label="Include Orphans"
                        />
                        <TooltipWrapper title={"An orphan is a Source that is not associated with any collection."}/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={orphansOnly}
                                    onChange={handleOrphansOnlyChange}
                                />
                            }
                            label="Orphans Only"
                        />
                    </Grid>
                )}
                {showFilter && (
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Filter sources"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {filterText && (
                                            <Button onClick={handleClearFilter}>Clear</Button>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                )}
                {allowNew && (
                    <Grid item xs={12} md={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddNewSource}
                        >
                            Add New
                        </Button>
                    </Grid>
                )}
            </Grid>

            <Box display="flex" justifyContent="flex-end" mt={2}>
                {showDeleteFunctionality && (
                    <Typography
                        component="span"
                        sx={{
                            cursor: 'pointer',
                            color: 'error.main',
                            '&:hover': { textDecoration: 'underline' }
                        }}
                        onClick={handleDeleteModeToggle}
                    >
                        {isDeleteMode ? 'Done Deleting' : 'Delete Items'}
                    </Typography>
                )}
            </Box>

            {!NewItemForm && showNewSourceForm && (
                <Box mt={2} mb={2}>
                    <SourceForm
                        mode="create"
                        onSubmit={handleNewSourceSubmit}
                        onCancel={() => setShowNewSourceForm(false)}
                    />
                </Box>
            )}

            {NewItemForm && showNewSourceForm && (
                <Box mt={2} mb={2}>
                    <NewItemForm
                        onSubmit={handleNewSourceSubmit}
                        onCancel={() => setShowNewSourceForm(false)}
                    />
                </Box>
            )}

            {isLoading ? (
                <CircularProgress />
            ) : shouldLoadSources ? (
                <SourceList
                    key={refreshKey}
                    query={modifiedQuery}
                    onSourceLoad={handleSourceLoad}
                    onSourceUpdate={handleSourceUpdate}
                    onSourceDelete={handleSourceDelete}
                    onContentLoad={onContentLoad}
                    eagerLoad={eagerLoad}
                    expanded={expanded}
                    filterFunction={filterFunction}
                    sortFunction={effectiveSortFunction}
                    asChecklist={asChecklist || isDeleteMode}
                    showCheckAllClear={showCheckAllClear || isDeleteMode}
                    checkedItems={checkedSources}
                    onChecklistUpdate={handleChecklistUpdate}
                    ItemDisplay={ItemDisplay}
                    autoLoadExpandedContent={autoLoadExpandedContent}
                    allowDelete={allowDelete}
                />
            ) : (
                <Box mt={2}>
                    <Alert severity="info">Select the collection that contains the source you wish to use, and then select the source.</Alert>
                </Box>
            )}

            <Snackbar open={Boolean(successMessage)} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ManagedList;