// src/apps/nutritionist/subNavConfig.js
import React from 'react';
import CookBook from "./cookbook/Cookbook";
import Chef from "./chef/Chef";

import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import MenuBook from '@mui/icons-material/MenuBook';

const subNav = {
    header: 'Chef',
    links: [
        {
            name: 'Chef',
            path: '/apps/nutritionist/recipes/chef',
            icon: RestaurantMenuIcon,
            element: <Chef />,
            title: 'Chef',
            description: 'Design delicious, custom recipes.',
            buttonText: 'Create Recipes'
        },
        {
            name: 'Recipe Book',
            path: '/apps/nutritionist/recipes/cookbook',
            icon: MenuBook,
            element: <CookBook />,
            title: 'Recipe Book',
            description: 'Browse and manage your recipes',
            buttonText: 'Explore Recipes'
        }
    ],
    routes: [
        { path: '/apps/nutritionist/recipes/chef', element: <Chef /> },
        { path: '/apps/nutritionist/recipes/cookbook', element: <CookBook /> },
    ]
};

export default subNav;