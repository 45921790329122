import React from 'react';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import StraightenIcon from '@mui/icons-material/Straighten';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';

const subNav = {
    header: 'Personal Trainer',
    links: [
        // {
        //     name: 'Exercise',
        //     path: '/apps/personaltrainer/exercise',
        //     icon: DirectionsRunIcon,
        //     element: <Exercise />,
        //     title: 'Exercise',
        //     description: 'Plan and track your exercise routines.',
        //     buttonText: 'Start Exercising'
        // },
    ],
    routes: [
        // { path: '/apps/personaltrainer/exercise', element: <Exercise /> },
    ]
};

export default subNav;