import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import useApi from "./ApiService";
import { useAuth } from "../AuthContext";

const PasswordUpdateForm = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { apiCall } = useApi();
    const { user } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert("New passwords don't match");
            return;
        }

        try {
            const response = await apiCall('/auth/update-password', {
                method: 'PUT',
                data: {
                    username: user.username,
                    currentPassword,
                    newPassword
                }
            });

            if (response.status === 200) {
                alert('Password updated successfully');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                alert('Failed to update password: ' + response.data);
            }
        } catch (error) {
            alert('Failed to update password: ' + (error.response?.data || error.message));
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                name="currentPassword"
                label="Current Password"
                type="password"
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="new-password"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Update Password
            </Button>
        </Box>
    );
};

export default PasswordUpdateForm;