import React, { useState, useRef, useCallback } from 'react';
import {
    Box,
    IconButton,
    Tooltip,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';
import {
    VolumeUp as VolumeUpIcon,
    VolumeOff as VolumeOffIcon
} from '@mui/icons-material';
import useApi from "./ApiService";

const TextToSpeech = ({ text }) => {
    const { apiCall } = useApi();
    const [isAudioEnabled, setIsAudioEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const audioRef = useRef(null);
    const isMounted = useRef(true);
    const lastTextRef = useRef('');

    const debugLog = (message, data = null) => {
        console.log(`[TTS Debug] ${message}`, data || '');
    };

    // Convert base64 to blob with additional error handling
    const base64ToBlob = useCallback((base64, mimeType) => {
        try {
            debugLog('Converting base64 to blob');
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });
            debugLog('Blob created successfully:', { size: blob.size, type: blob.type });
            return blob;
        } catch (error) {
            debugLog('Error in base64ToBlob:', error);
            throw new Error(`Failed to convert base64 to blob: ${error.message}`);
        }
    }, []);

    React.useEffect(() => {
        debugLog('Component mounted');
        isMounted.current = true;
        return () => {
            debugLog('Component unmounting');
            isMounted.current = false;
            // Clean up any playing audio
            if (audioRef.current) {
                audioRef.current.pause();
                if (audioRef.current.dataset.blobUrl) {
                    URL.revokeObjectURL(audioRef.current.dataset.blobUrl);
                }
            }
        };
    }, []);

    const handleTextToSpeech = useCallback(async (textToConvert) => {
        debugLog('handleTextToSpeech called with text:', textToConvert);
        debugLog('Current audio enabled state:', isAudioEnabled);

        // Prevent duplicate processing of the same text
        if (lastTextRef.current === textToConvert) {
            debugLog('Skipping duplicate text');
            return;
        }
        lastTextRef.current = textToConvert;

        if (!textToConvert || !isAudioEnabled) {
            debugLog('Returning early - no text or audio disabled');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            debugLog('Making API call to synthesize speech');
            const response = await apiCall('/api/tts/synthesize', {
                method: 'POST',
                data: {
                    text: textToConvert,
                    voice: 'onyx',
                    speed: 1.0
                }
            });
            debugLog('API response received:', response);

            if (!isMounted.current) {
                debugLog('Component unmounted during API call, cleaning up');
                return;
            }

            const data = response.data;
            debugLog('Processing audio data');

            if (!data.audioData) {
                throw new Error('No audio data received from API');
            }

            // Stop any currently playing audio
            if (audioRef.current) {
                audioRef.current.pause();
                if (audioRef.current.dataset.blobUrl) {
                    URL.revokeObjectURL(audioRef.current.dataset.blobUrl);
                }
            }

            const blob = base64ToBlob(data.audioData, 'audio/mp3');
            debugLog('Created blob:', { size: blob.size, type: blob.type });
            const url = URL.createObjectURL(blob);
            debugLog('Created blob URL:', url);

            if (audioRef.current && isMounted.current) {
                audioRef.current.src = url;
                audioRef.current.dataset.blobUrl = url;

                try {
                    const playPromise = audioRef.current.play();
                    if (playPromise !== undefined) {
                        playPromise
                            .then(() => debugLog('Audio playback started successfully'))
                            .catch(playError => {
                                debugLog('Error playing audio:', playError);
                                if (isMounted.current) {
                                    setError(`Failed to play audio: ${playError.message}`);
                                }
                            });
                    }
                } catch (playError) {
                    debugLog('Error playing audio:', playError);
                    if (isMounted.current) {
                        setError(`Failed to play audio: ${playError.message}`);
                    }
                }
            }
        } catch (err) {
            debugLog('Error in handleTextToSpeech:', err);
            if (isMounted.current) {
                const errorMessage = err.response?.data?.message || err.message || 'Failed to convert text to speech';
                setError(errorMessage);
            }
        } finally {
            if (isMounted.current) {
                setIsLoading(false);
            }
        }
    }, [apiCall, isAudioEnabled, base64ToBlob]);

    // Watch for text changes with debounce
    React.useEffect(() => {
        debugLog('Text or audio enabled state changed:', { text, isAudioEnabled });

        if (isAudioEnabled && text) {
            // Add small delay to ensure component is fully mounted
            const timeoutId = setTimeout(() => {
                if (isMounted.current) {
                    handleTextToSpeech(text);
                }
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [text, isAudioEnabled, handleTextToSpeech]);

    return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Tooltip title={isAudioEnabled ? "Turn off audio" : "Turn on audio"}>
                <IconButton
                    onClick={() => {
                        debugLog('Toggle button clicked, current state:', isAudioEnabled);
                        setIsAudioEnabled(!isAudioEnabled);
                    }}
                    color={isAudioEnabled ? "primary" : "default"}
                    disabled={isLoading}
                    size="large"
                    sx={{
                        '&.Mui-disabled': {
                            color: 'action.disabled'
                        }
                    }}
                >
                    {isLoading ? (
                        <CircularProgress size={24} />
                    ) : isAudioEnabled ? (
                        <VolumeUpIcon />
                    ) : (
                        <VolumeOffIcon />
                    )}
                </IconButton>
            </Tooltip>

            <audio
                ref={audioRef}
                style={{ display: 'none' }}
                onEnded={() => {
                    debugLog('Audio playback ended');
                    if (audioRef.current?.dataset.blobUrl) {
                        URL.revokeObjectURL(audioRef.current.dataset.blobUrl);
                        delete audioRef.current.dataset.blobUrl;
                    }
                }}
                onError={(e) => {
                    debugLog('Audio element error:', e.currentTarget.error);
                }}
            />

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => {
                    debugLog('Clearing error message');
                    setError(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setError(null)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default TextToSpeech;