import React from 'react';
import Sources from './sources/Sources';
import Directory from "./directory/Directory";
import Graph from "./graph/Graph";
import Extract from "./extractor/Extract";
import Associations from "./associations/Associations";
import Article from '@mui/icons-material/Article';
import PermMedia from '@mui/icons-material/PermMedia';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import LinkIcon from '@mui/icons-material/Link';
import {Storage} from "@mui/icons-material";
import {BrowserUpdated} from "@mui/icons-material";
import Database from "./database/Database";
import WebScraper from "./webscraper/WebScraper";

const subNav = {
    header: 'Advanced',
    links: [
        {
            name: 'Sources',
            path: '/advanced/sources',
            icon: Article,
            element: <Sources />,
            title: 'Sources',
            description: 'Manage and view your information sources',
            buttonText: 'View Sources'
        },
        {
            name: 'Directory',
            path: '/advanced/directory',
            icon: PermMedia,
            element: <Directory />,
            title: 'Directory',
            description: 'Visualize connections in your data',
            buttonText: 'Open Directory'
        },
        {
            name: 'Graph',
            path: '/advanced/graph',
            icon: AccountTreeIcon,
            element: <Graph />,
            title: 'Graph',
            description: 'Visualize connections in your data',
            buttonText: 'Open Graph'
        },
        {
            name: 'Extract',
            path: '/advanced/extractor',
            icon: AutoFixHigh,
            element: <Extract />,
            title: 'Extract',
            description: 'Extract information and objects from your sources',
            buttonText: 'Start Extraction'
        },
        {
            name: 'Associations',
            path: '/advanced/associations',
            icon: LinkIcon,
            element: <Associations />,
            title: 'Associations',
            description: 'Manage associations and organize your sources',
            buttonText: 'View Associations'
        },
        {
            name: 'Web Scraper',
            path: '/advanced/webscraper',
            icon: BrowserUpdated,
            element: <WebScraper />,
            title: 'Web Scraper',
            description: 'A Tamper Monkey script to extract text from the current webpage to create a source.',
            buttonText: 'View Script'
        },
        // {
        //     name: 'Database',
        //     path: '/advanced/database',
        //     icon: Storage,
        //     element: <Database />,
        //     title: 'Database',
        //     description: 'The raw data, be careful!',
        //     buttonText: 'Database'
        // }
    ],
    routes: [
        { path: '/advanced/sources', element: <Sources /> },
        { path: '/advanced/directory', element: <Directory /> },
        { path: '/advanced/graph', element: <Graph /> },
        { path: '/advanced/extractor', element: <Extract /> },
        { path: '/advanced/associations', element: <Associations /> },
        { path: '/advanced/database', element: <Database /> },
        { path: '/advanced/webscraper', element: <WebScraper /> },
    ],
};

export default subNav;