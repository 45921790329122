import React, { useState, useCallback, useEffect } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography,
    Grid,
    Paper,
    Snackbar,
    Alert,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import EntreeForm from './EntreeForm';
import useApi from "../../../../../components/ApiService";
import { useNavigate } from 'react-router-dom';
import AssociationsDropdown from "../../../../../components/AssociationsDropdown";
import { parseISO, format } from 'date-fns';

const MealDesigner = ({ sourceRecord,
                        onSourceUpdate,
                        showAddNew= false,
                        showMealLogNav = false,
                        allowCopy = false,
                        associationDropdownConfig = {}
}) => {
    const [mealType, setMealType] = useState('');
    const [mealDate, setMealDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [entrees, setEntrees] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [totalNutrition, setTotalNutrition] = useState({});
    const [totalHealthCategory, setTotalHealthCategory] = useState('');
    const { apiCall } = useApi();
    const isNewMeal = !sourceRecord;
    const isTrackMealButtonEnabled = isNewMeal && entrees.length > 0 && entrees.every(entree => entree.submitted);
    const navigate = useNavigate();
    const [selectedAssociation, setSelectedAssociation] = useState('');
    const [associationsRefreshTrigger, setAssociationsRefreshTrigger] = useState(0);

    const updateExistingMeal = useCallback(async (updatedEntrees, newDate = mealDate, newMealType = mealType) => {
        if (!sourceRecord || !sourceRecord.id) {
            console.error("Cannot update: no source record or ID");
            return;
        }

        const updatedMealData = {
            id: sourceRecord.id,
            content: {
                mealType: newMealType,
                mealDate: newDate,
                entrees: updatedEntrees.map(entree => entree.data || {}),
                totalNutrition
            },
            contentType: "application/json"
        };

        try {
            const response = await apiCall(`/content/${sourceRecord.content.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(updatedMealData),
            });

            if (response.status === 200) {
                setSnackbar({ open: true, message: 'Meal updated successfully!', severity: 'success' });
            } else {
                throw new Error('Failed to update meal');
            }
        } catch (error) {
            console.error('Error updating meal:', error);
            setSnackbar({ open: true, message: `Failed to update meal: ${error.response?.data?.message || error.message}`, severity: 'error' });
        }
    }, [sourceRecord, apiCall, onSourceUpdate, totalNutrition, mealDate, mealType]);

    useEffect(() => {
        if (sourceRecord && sourceRecord.associations && sourceRecord.associations.length > 0) {
            setSelectedAssociation(sourceRecord.associations[0]);
        } else {
            setSelectedAssociation('Meal Log');
        }
    }, [sourceRecord, associationsRefreshTrigger]);

    const calculateHealthCategory = (healthCategory, calories) => {
        const categories = ['Unhealthy', 'Moderately Unhealthy', 'Generally Healthy', 'Very Healthy'];
        return categories.indexOf(healthCategory) * calories;
    };

    const updateSourceAssociation = useCallback(async (newAssociation) => {
        if (!sourceRecord || !sourceRecord.id) {
            console.error("Cannot update: no source record or ID");
            return;
        }

        try {
            const response = await apiCall(`/source/${sourceRecord.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    ...sourceRecord,
                    associations: [newAssociation]
                }),
            });

            if (response.status === 200) {
                setSnackbar({ open: true, message: 'Meal association updated successfully!', severity: 'success' });
                if (onSourceUpdate) {
                    onSourceUpdate(response.data);
                }
            } else {
                throw new Error('Failed to update meal association');
            }
        } catch (error) {
            console.error('Error updating meal association:', error);
            setSnackbar({ open: true, message: `Failed to update meal association: ${error.response?.data?.message || error.message}`, severity: 'error' });
        }
    }, [sourceRecord, apiCall, onSourceUpdate]);

    const handleAssociationSelect = useCallback(async (selectedCollection) => {
        if (selectedCollection.id !== selectedAssociation) {
            setSelectedAssociation(selectedCollection.id);
            if (!isNewMeal) {
                await updateSourceAssociation(selectedCollection.id);
            }
        }
    }, [isNewMeal, selectedAssociation]);

    useEffect(() => {
        if (sourceRecord && sourceRecord.content && sourceRecord.content.content) {
            const { mealType, mealDate, entrees } = sourceRecord.content.content;
            setMealType(mealType || '');
            setMealDate(mealDate || format(new Date(), 'yyyy-MM-dd'));
            setEntrees(entrees.map(entree => ({
                id: entree.id || null,
                dish: { contentType: '', content: '' },
                ingredients: entree.ingredients || [],
                desiredServings: entree.nutrition_estimate?.quantity || 1,
                submitted: true,
                isSubmitting: false,
                data: entree
            })));
        } else if (isNewMeal) {
            // Add a default entree in edit mode for new meals
            setEntrees([{
                id: null,
                dish: { contentType: '', content: '' },
                ingredients: [],
                desiredServings: 1,
                submitted: false,
                isSubmitting: false,
                data: null
            }]);
        }
    }, [sourceRecord, isNewMeal]);

    useEffect(() => {
        const newTotalNutrition = {};
        let totalCalories = 0;
        let weightedHealthCategory = 0;

        entrees.forEach(entree => {
            if (entree.submitted && entree.data && entree.data.nutrition_estimate) {
                const nutritionEstimate = entree.data.nutrition_estimate;
                Object.entries(nutritionEstimate).forEach(([key, value]) => {
                    if (typeof value === 'number') {
                        newTotalNutrition[key] = (newTotalNutrition[key] || 0) + value;
                    }
                });

                totalCalories += nutritionEstimate.calories || 0;
                weightedHealthCategory += calculateHealthCategory(nutritionEstimate.health_category, nutritionEstimate.calories || 0);
            }
        });

        setTotalNutrition(newTotalNutrition);

        if (totalCalories > 0) {
            const avgHealthCategoryIndex = Math.round(weightedHealthCategory / totalCalories);
            const healthCategories = ['Unhealthy', 'Moderately Unhealthy', 'Generally Healthy', 'Very Healthy'];
            setTotalHealthCategory(healthCategories[avgHealthCategoryIndex] || 'Unknown');
        } else {
            setTotalHealthCategory('Unknown');
        }
    }, [entrees]);

    const handleTrackMeal = async () => {
        const getSafeMealName = () => {
            if (entrees.length > 0 && entrees[0].data && entrees[0].data.dish && entrees[0].data.dish.name) {
                return entrees[0].data.dish.name.slice(0, 20);
            }
            return "";
        };

        const formattedDate = formatDateToYYYYMMDD(mealDate);

        const mealData = {
            associations: [selectedAssociation],
            types: ["Meal"],
            name: `${mealDate} ${mealType} ${getSafeMealName()}`,
            description: `Tracked ${mealType} meal for ${mealDate}.`,
            attributes: [
                {
                    name: "meal_date",
                    value: mealDate,
                    type: "date"
                }
            ],
            content: {
                mealType,
                mealDate,
                entrees: entrees.map(entree => entree.data || {}),
                totalNutrition
            },
            contentType: "application/json",
            runDataPreperation: false
        };

        try {
            const response = await apiCall('/aissistant/ingest/content', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(mealData),
            });

            if (response.status === 200 || response.status === 201) {
                navigate(`/apps/nutritionist/mealtracker/meal#${response.data.id}`);
            } else {
                throw new Error('Failed to track meal');
            }
        } catch (error) {
            console.error('Error tracking meal:', error);
            setSnackbar({ open: true, message: `Failed to track meal: ${error.response?.data?.message || error.message}`, severity: 'error' });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    const handleEntreeSubmit = useCallback(async (index, entreeData) => {
        const newEntrees = [...entrees];
        newEntrees[index] = {
            ...newEntrees[index],
            data: entreeData,
            ingredients: entreeData.ingredients,
            submitted: true,
            isSubmitting: false
        };

        setEntrees(newEntrees);

        if (!isNewMeal) {
            await updateExistingMeal(newEntrees, mealDate, mealType);
        }

        setSnackbar({ open: true, message: `Entree submission successful!`, severity: 'success' });
    }, [isNewMeal, entrees, mealDate, mealType, updateExistingMeal]);

    const addEntree = () => {
        setEntrees(prevEntrees => [...prevEntrees, {
            id: null,
            dish: { contentType: '', content: '' },
            ingredients: [],
            desiredServings: 1,
            submitted: false,
            isSubmitting: false,
            data: null
        }]);
    };

    const removeEntree = async (index) => {
        const updatedEntrees = entrees.filter((_, i) => i !== index);
        setEntrees(updatedEntrees);
        if (!isNewMeal) {
            await updateExistingMeal(updatedEntrees, mealDate);
        }
    };

    const formatDateToYYYYMMDD = (date) => {
        return typeof date === 'string' ? date : format(date, 'yyyy-MM-dd');
    };

    const handleDateChange = async (newDate) => {
        const formattedDate = format(newDate, 'yyyy-MM-dd');
        setMealDate(formattedDate);
        if (!isNewMeal) {
            await updateExistingMeal(entrees, formattedDate, mealType);
        }
    };

    const handleMealTypeChange = async (e) => {
        const newMealType = e.target.value;
        setMealType(newMealType);
        if (!isNewMeal) {
            await updateExistingMeal(entrees, mealDate, newMealType);
        }
    };

    const handleCopyToToday = async () => {
        if (!sourceRecord || !sourceRecord.content || !sourceRecord.content.content) {
            console.error("Cannot copy: no source record or content");
            setSnackbar({ open: true, message: "Cannot copy: meal data is incomplete", severity: 'error' });
            return;
        }

        const today = format(new Date(), 'yyyy-MM-dd');
        const copyMealData = {
            associations: sourceRecord.associations || [],  // Use the original meal's associations
            types: ["Meal"],
            name: `${today} ${mealType} (Copied)`,
            description: `Copied ${mealType} meal from ${mealDate} to ${today}.`,
            attributes: [
                {
                    name: "meal_date",
                    value: today,
                    type: "date"
                }
            ],
            content: {
                ...sourceRecord.content.content,
                mealDate: today,
            },
            contentType: "application/json",
            runDataPreperation: false
        };

        try {
            const response = await apiCall('/aissistant/ingest/content', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(copyMealData),
            });

            if (response.status === 200 || response.status === 201) {
                setSnackbar({ open: true, message: 'Meal copied successfully!', severity: 'success' });
                navigate(`/apps/nutritionist/mealtracker/meal#${response.data.id}`);
            } else {
                throw new Error('Failed to copy meal');
            }
        } catch (error) {
            console.error('Error copying meal:', error);
            setSnackbar({ open: true, message: `Failed to copy meal: ${error.response?.data?.message || error.message}`, severity: 'error' });
        }
    };

    const defaultAssociationDropdownConfig = {
        rootAssociation: "Meal Log",
        selectionLabel: "Select Collection to track the meal under.",
    };

    const mergedAssociationDropdownConfig = {
        ...defaultAssociationDropdownConfig,
        ...associationDropdownConfig
    };

    return (
        <>
            <Grid item xs={12} md={9}>
                <AssociationsDropdown
                    rootAssociation={mergedAssociationDropdownConfig.rootAssociation}
                    selected={selectedAssociation}
                    onCollectionSelect={handleAssociationSelect}
                    selectionLabel={mergedAssociationDropdownConfig.selectionLabel}
                    key={associationsRefreshTrigger}
                    {...mergedAssociationDropdownConfig}
                />
            </Grid>
            <Paper elevation={3} sx={{ p: 3, position: 'relative' }}>
                {isNewMeal && (
                    <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleTrackMeal}
                            disabled={!isTrackMealButtonEnabled}
                        >
                            Track Meal
                        </Button>
                        <Typography variant="caption" sx={{ mt: 1 }}>
                            (You must track to save the meal.)
                        </Typography>
                    </Box>
                )}
                <Typography variant="h4" gutterBottom>
                    {isNewMeal ? 'Track Your Meal' : ''}
                </Typography>

                <Grid container spacing={2} alignItems="center">
                    {/* Navigation Buttons */}
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} order={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} justifyContent="flex-end">
                            {showMealLogNav && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate('/apps/nutritionist/mealtracker/meallog')}
                                    fullWidth
                                >
                                    Meal Log
                                </Button>
                            )}
                            {showAddNew && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/apps/nutritionist/mealtracker/mealtracker')}
                                    fullWidth
                                >
                                    Track Another
                                </Button>
                            )}
                        </Box>
                    </Grid>

                    {/* Meal Type Dropdown */}
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} order={{ xs: 2, sm: 2, md: 1, lg: 1, xl: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="meal-type-label">Meal Type</InputLabel>
                            <Select
                                labelId="meal-type-label"
                                value={mealType}
                                onChange={handleMealTypeChange}
                                required
                            >
                                <MenuItem value="Breakfast">Breakfast</MenuItem>
                                <MenuItem value="Lunch">Lunch</MenuItem>
                                <MenuItem value="Dinner">Dinner</MenuItem>
                                <MenuItem value="Snack">Snack</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Date Picker */}
                    <Grid item xs={12} sm={6} md={5} lg={5} xl={5} order={{ xs: 3, sm: 3, md: 2, lg: 2, xl: 2 }}>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" gap={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Meal Date"
                                    value={parseISO(mealDate)}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    inputFormat="yyyy-MM-dd"
                                />
                            </LocalizationProvider>
                            {!isNewMeal && allowCopy && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleCopyToToday}
                                    sx={{ height: '56px' }}
                                >
                                    Copy to Today
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {entrees.map((entree, index) => (
                        <Box key={index} mb={3}>
                            <EntreeForm
                                entree={entree}
                                setEntree={(updatedEntree) => {
                                    setEntrees(prevEntrees => {
                                        const newEntrees = [...prevEntrees];
                                        newEntrees[index] = updatedEntree;
                                        return newEntrees;
                                    });
                                }}
                                mealType={mealType}
                                setSnackbar={setSnackbar}
                                isSubmitting={entree.isSubmitting}
                                onUpdate={handleEntreeSubmit}
                                index={index}
                                onRemove={removeEntree}
                            />
                        </Box>
                    ))}
                    <Button startIcon={<AddIcon />} onClick={addEntree} variant="contained" color="primary" sx={{ mt: 2 }}>
                        Add Entree
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h5" gutterBottom>
                        Total Meal Nutrition Estimates
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nutrient</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(totalNutrition).map(([key, value]) => (
                                    <TableRow key={key}>
                                        <TableCell>
                                            {key.split('_')
                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                .join(' ')}
                                        </TableCell>
                                        <TableCell align="right">{value.toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>Health Category</TableCell>
                                    <TableCell align="right">{totalHealthCategory}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Paper>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default MealDesigner;