import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useTheme,
    TextField,
    Snackbar,
    Alert,
    Paper,
    Collapse,
    CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import ManagedList from "../list/ManagedList";
import useApi from "../ApiService";
import FeedbackArea from "./FeedbackArea";
import InputMethodSelector from "./InputMethodSelector";

const MemoizedManagedList = React.memo(ManagedList);

function Extractor({
                       fromQuery,
                       toQuery,
                       availableTypes = [],
                       fromPossibleTypes,
                       toPossibleTypes,
                       fromRootAssociation,
                       toRootAssociation,
                       includeFromRoot= false,
                       includeToRoot= true,
                       extractFrom,
                       extractTo,
                       onSubmit,
                       showType = true,
                       showFrom = true,
                       showTo = true,
                       showContext = true,
                       showSpecialInstructions = true,
                       returnAsItems = false,
                       showSummary = true,
                       useInputMethod,
                       fromManagedListProps = {},
                       toManagedListProps = {},
                   }) {
    const { apiCall } = useApi();
    const theme = useTheme();
    const [selectedType, setSelectedType] = useState('');
    const [fromSelections, setFromSelections] = useState(extractFrom || []);
    const [toSelections, setToSelections] = useState(extractTo || []);
    const [context, setContext] = useState('');
    const [specialInstructions, setSpecialInstructions] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [extractedIds, setExtractedIds] = useState([]);
    const [inputMethod, setInputMethod] = useState(useInputMethod || 'text');
    const [manualText, setManualText] = useState('');
    const [file, setFile] = useState(null);
    const [isContextVisible, setIsContextVisible] = useState(false);
    const [isSpecialInstructionsVisible, setIsSpecialInstructionsVisible] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);

    const toggleContext = useCallback(() => {
        setIsContextVisible(prev => !prev);
    }, []);
    const toggleSpecialInstructions = useCallback(() => {
        setIsSpecialInstructionsVisible(prev => !prev);
    }, []);

    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

    useEffect(() => {
        if (availableTypes.length === 1) {
            setSelectedType(availableTypes[0].id);
        }
    }, [availableTypes]);

    useEffect(() => {
        if (extractFrom) {
            setFromSelections(extractFrom);
            setInputMethod('select');
        }
    }, [extractFrom]);

    useEffect(() => {
        if (extractTo) {
            setToSelections(extractTo);
        }
    }, [extractTo]);

    const handleTypeSelection = useCallback((event) => {
        setSelectedType(event.target.value);
    }, []);

    const updateFromSelected = useCallback((selectedItems) => {
        if (!extractFrom) {
            setFromSelections(selectedItems);
        }
    }, [extractFrom]);

    const updateToSelected = useCallback((selectedItems) => {
        if (!extractTo) {
            setToSelections(selectedItems);
        }
    }, [extractTo]);

    const handleContextChange = useCallback((event) => {
        setContext(event.target.value);
    }, []);

    const handleSpecialInstructionsChange = useCallback((event) => {
        setSpecialInstructions(event.target.value);
    }, []);

    const handleInputMethodChange = useCallback((event) => {
        setInputMethod(event.target.value);
        setFile(null);
        setAudioBlob(null);
        setManualText('');
    }, []);

    const handleManualTextChange = useCallback((event) => {
        setManualText(event.target.value);
    }, []);

    const startRecording = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new MediaRecorder(stream);
            audioChunks.current = [];

            mediaRecorder.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorder.current.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                setAudioBlob(audioBlob);
                setIsRecording(false);
            };

            mediaRecorder.current.start();
            setIsRecording(true);
        } catch (err) {
            console.error('Error accessing microphone:', err);
            setSnackbar({ open: true, message: `Error accessing microphone: ${err.message}`, severity: 'error' });
        }
    }, []);

    const stopRecording = useCallback(() => {
        if (mediaRecorder.current && isRecording) {
            mediaRecorder.current.stop();
            setIsRecording(false);
        }
    }, [isRecording]);

    const handleMouseDown = useCallback(() => {
        startRecording();
    }, [startRecording]);

    const handleMouseUp = useCallback(() => {
        stopRecording();
    }, [stopRecording]);

    const handleTouchStart = useCallback((e) => {
        e.preventDefault();
        startRecording();
    }, [startRecording]);

    const handleTouchEnd = useCallback((e) => {
        e.preventDefault();
        stopRecording();
    }, [stopRecording]);

    const defaultFromManagedListProps = {
        query: fromQuery,
        asChecklist: true,
        onChecklistUpdate: updateFromSelected,
        showAssociations: false,
        showTypeSelection: true,
        defaultSelectedTypes: ["Source"],
        showCheckAllClear: true,
        possibleTypes: fromPossibleTypes,
        rootAssociation: fromRootAssociation,
        includeRoot: includeFromRoot,
        selectedAssociation: fromRootAssociation,
    };

    const finalFromManagedListProps = {
        ...defaultFromManagedListProps,
        ...fromManagedListProps,
    };

    const defaultToManagedListProps = {
        query: toQuery,
        asChecklist: true,
        onChecklistUpdate: updateToSelected,
        showAssociations: false,
        showTypeSelection: true,
        defaultSelectedTypes: ["Collection"],
        showCheckAllClear: true,
        allowNew: false,
        possibleTypes: toPossibleTypes,
        rootAssociation: toRootAssociation,
        selectedAssociation: toRootAssociation,
        includeRoot: includeToRoot,
    };

    const finalToManagedListProps = {
        ...defaultToManagedListProps,
        ...toManagedListProps,
    };

    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        const baseApiEndpoint = process.env.REACT_APP_API_URL;
        let url = `${baseApiEndpoint}/ai/extractor/extract`;

        let body;
        let headers = {};

        if ((inputMethod === 'file' && file) || (inputMethod === 'speech' && audioBlob)) {
            url = `${baseApiEndpoint}/ai/extractor/extract-file`;
            const formData = new FormData();
            formData.append('file', inputMethod === 'file' ? file : audioBlob);
            const metadata = {
                extractTo: toSelections,
                type: selectedType,
                context: context,
                specialInstructions: specialInstructions
            };
            formData.append('metadata', new Blob([JSON.stringify(metadata)], {
                type: 'application/json'
            }));
            body = formData;
        } else {
            const fromContentInfo = {};

            if (inputMethod === 'source' || inputMethod === 'select') {
                fromSelections.forEach((selection, index) => {
                    fromContentInfo[`source_${index}`] = {
                        name: `Source ${index + 1}`,
                        contentType: "application/source",
                        content: selection
                    };
                });
            } else if (inputMethod === 'text') {
                fromContentInfo.manualText = {
                    name: "Manual Text Input",
                    contentType: "text/plain",
                    content: manualText
                };
            }

            body = JSON.stringify({
                fromContentInfo,
                toAssociations: toSelections,
                type: selectedType,
                additionalContext: context,
                specialInstructions
            });
            headers['Content-Type'] = 'application/json';
        }

        try {
            const response = await apiCall(url, {
                method: 'POST',
                headers: headers,
                data: body,
            });

            if (response.status === 200 || response.status === 201) {
                console.log('Extraction successful:', response.data);
                const extractedIdsArray = Object.values(response.data)[0];
                setExtractedIds(extractedIdsArray);
                setSnackbar({ open: true, message: 'Extraction successful!', severity: 'success' });

                if (returnAsItems && onSubmit) {
                    console.error("AI broke this, better go run to AI to fix it.")
                    // ... existing code for returnAsItems ...
                } else if (onSubmit) {
                    onSubmit(response.data);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbar({ open: true, message: `Extraction failed: ${error.message}`, severity: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    }, [apiCall, fromSelections, toSelections, selectedType, context, specialInstructions, inputMethod, manualText, file, audioBlob, returnAsItems, onSubmit]);

    const handleCloseSnackbar = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar((prev) => ({ ...prev, open: false }));
    }, []);

    const isOnlyOneType = useMemo(() => availableTypes.length === 1, [availableTypes]);

    const headerStyle = useMemo(() => ({
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '2rem',
        width: '100%',
        textAlign: 'center',
    }), [theme.palette.primary.main]);

    const isSubmitDisabled = !selectedType || isSubmitting ||
        (inputMethod === 'select' && fromSelections.length === 0) ||
        (inputMethod === 'text' && !manualText) ||
        (inputMethod === 'file' && !file) ||
        (inputMethod === 'speech' && !audioBlob) ||
        toSelections.length === 0;

    const extractedIdsQuery = useMemo(() => {
        return `/source/search?${extractedIds.map(id => `ids=${id}`).join('&')}`;
    }, [extractedIds]);

    const SubmitButton = () => (
        <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            size="large"
            sx={{
                fontSize: '1.1rem',
                py: 1.5,
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    opacity: 0.7,
                }
            }}
            disabled={isSubmitDisabled || isSubmitting}
        >
            {!selectedType ? 'Select Type' : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {isSubmitting && (
                        <CircularProgress
                            size={20}
                            sx={{
                                color: theme.palette.primary.contrastText,
                                mr: 1,
                            }}
                        />
                    )}
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </Box>
            )}
        </Button>
    );

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px', margin: 'auto', padding: 1 }}>
            {showType &&
                <Typography sx={headerStyle}>
                    <FormControl
                        fullWidth
                        sx={{
                            mb: 3,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.divider,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                            },
                        }}
                    >
                        <InputLabel id="type-select-label">{isOnlyOneType ? "Type" : "Select Type"}</InputLabel>
                        <Select
                            labelId="type-select-label"
                            value={selectedType}
                            label={isOnlyOneType ? "Type" : "Select Type"}
                            onChange={handleTypeSelection}
                            IconComponent={isOnlyOneType ? () => null : undefined}
                            readOnly={isOnlyOneType}
                            displayEmpty={!isOnlyOneType}
                        >
                            {!isOnlyOneType && (
                                <MenuItem value="" disabled>
                                    -Select Type-
                                </MenuItem>
                            )}
                            {availableTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Typography>
            }

            {showFrom &&
                <>
                    <InputMethodSelector
                        inputMethod={inputMethod}
                        handleInputMethodChange={handleInputMethodChange}
                    />
                    {(inputMethod === 'source' || inputMethod === 'select') && !extractFrom && (
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem' }} />}
                                sx={{ minHeight: 70 }}
                            >
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="h3" sx={headerStyle}>
                                        {inputMethod === 'source' ? 'Source' : 'Select From'}
                                    </Typography>
                                    <Typography variant="caption">
                                        ({fromSelections.length} selected)
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MemoizedManagedList {...finalFromManagedListProps} />
                            </AccordionDetails>
                        </Accordion>
                    )}

                    {inputMethod === 'text' && !extractFrom && (
                        <TextField
                            fullWidth
                            multiline
                            rows={6}
                            variant="outlined"
                            label="Enter details"
                            value={manualText}
                            onChange={handleManualTextChange}
                            sx={{ mb: 2 }}
                        />
                    )}

                    {inputMethod === 'file' && !extractFrom && (
                        <Box sx={{ mb: 2 }}>
                            <div {...getRootProps()} style={{
                                border: '2px dashed #cccccc',
                                borderRadius: '4px',
                                padding: '20px',
                                textAlign: 'center',
                                cursor: 'pointer'
                            }}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>Drop the file here ...</p> :
                                        <p>Drag 'n' drop a file here, or click to select a file</p>
                                }
                            </div>
                            {file && <Typography sx={{ mt: 1 }}>{file.name}</Typography>}
                        </Box>
                    )}

                    {inputMethod === 'speech' && !extractFrom && (
                        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color={isRecording ? "secondary" : "primary"}
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                                sx={{
                                    mb: 2,
                                    userSelect: 'none',
                                    touchAction: 'none',
                                    width: '220px',  // Set a fixed width
                                    height: '50px',  // Set a fixed height
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                startIcon={<MicIcon />}
                            >
                                <Typography noWrap>
                                    {isRecording ? "Recording..." : "Press and hold"}
                                </Typography>
                            </Button>
                            {audioBlob && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography>Audio recorded successfully</Typography>
                                    <audio controls src={URL.createObjectURL(audioBlob)} />
                                </Box>
                            )}
                        </Box>
                    )}
                </>
            }
            {showTo && (
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem' }} />}
                        sx={{ minHeight: 70 }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h3" sx={headerStyle}>
                                Extract To
                            </Typography>
                            <Typography variant="caption">
                                ({toSelections.length} selected)
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MemoizedManagedList {...finalToManagedListProps} />
                    </AccordionDetails>
                </Accordion>
            )}

            <Box sx={{ mt: 3 }}>
                {showContext && (
                    <>
                        <Button
                            variant="outlined"
                            onClick={toggleContext}
                            sx={{ mb: 2 }}
                        >
                            {isContextVisible ? "Hide Context" : "Add Context"}
                        </Button>
                        <Collapse in={isContextVisible}>
                            <Paper elevation={0}>
                                <Typography variant="body1" component="div">
                                    (Include important context. Such as if extracting dates for a course, provide the start and end date if relevant.)
                                </Typography>
                            </Paper>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                label="Context"
                                value={context}
                                onChange={handleContextChange}
                                sx={{ mb: 2 }}
                            />
                        </Collapse>
                    </>
                )}

                {showSpecialInstructions && inputMethod && (
                    <>
                        <Button
                            variant="outlined"
                            onClick={toggleSpecialInstructions}
                            sx={{ mb: 2 }}
                        >
                            {isSpecialInstructionsVisible ? "Hide Special Instructions" : "Add Special Instructions"}
                        </Button>
                        <Collapse in={isSpecialInstructionsVisible}>
                            <Paper elevation={0}>
                                <Typography variant="body1" component="div">
                                    (Include any specific instructions, such as to ignore specific things from the source.)
                                </Typography>
                            </Paper>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                label="Special Instructions"
                                value={specialInstructions}
                                onChange={handleSpecialInstructionsChange}
                                sx={{ mb: 2 }}
                            />
                        </Collapse>
                    </>
                )}
                {showSummary &&
                    <FeedbackArea
                        fromSelections={fromSelections}
                        toSelections={toSelections}
                        inputMethod={inputMethod}
                        manualText={manualText}
                        file={file}
                        audioBlob={audioBlob}
                        type={selectedType}
                    />
                }
                <SubmitButton />
            </Box>

            {extractedIds.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h3" sx={headerStyle}>
                        Extracted Items
                    </Typography>
                    <MemoizedManagedList
                        query={extractedIdsQuery}
                        asChecklist={true}
                        allowDelete={true}
                        showAssociationsDropdown={false}
                        showTypeSelection={false}
                        loadOnDefault={true}
                        showCheckAllClear={true}
                    />
                </Box>
            )}

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Box sx={{ height: '10vh' }} />
        </Box>
    );
}

export default React.memo(Extractor);