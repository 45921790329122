import React, { useState } from 'react';
import { TextField, Button, Box, Slider, Typography } from '@mui/material';
import ManagedList from "../../../../components/list/ManagedList";
import TypeSelector from "../../../../components/TypeSelector";
import TooltipWrapper from "../../../../components/StyledTooltip";

function Librarian() {
    const [query, setQuery] = useState('');
    const [rootAssociation, setRootAssociation] = useState('AIssistant');
    const [selectedTypes, setSelectedTypes] = useState(['Source']);
    const [refreshKey, setRefreshKey] = useState(0);
    const [userInput, setUserInput] = useState('');
    const [similarityScore, setSimilarityScore] = useState(0.65);
    const [maxResults, setMaxResults] = useState(10);

    const handleTypeChange = (newSelectedTypes) => {
        setSelectedTypes(newSelectedTypes);
    };

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newQuery = `/source/search-vector?query=${encodeURIComponent(userInput)}&types=${selectedTypes.join(',')}&similarityScore=${similarityScore}&numberOfResults=${maxResults}`;
        setQuery(newQuery);
        setRefreshKey(prevKey => prevKey + 1);
    };

    const sortByScore = (a, b) => {
        return (b.score || 0) - (a.score || 0);
    };

    return (
        <>
            <h1>Librarian</h1>
            <TypeSelector
                defaultSelectedTypes={['Source']}
                onChange={handleTypeChange}
            />
            Write a descriptive query on what resources you would like to find.
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={userInput}
                    onChange={handleInputChange}
                    placeholder="Enter your search query"
                    sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Search
                    </Button>
                    <Box sx={{ width: '60%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ mr: 1 }}>
                            Similarity: {similarityScore.toFixed(2)}
                        </Typography>
                        <TooltipWrapper title={
                            <React.Fragment>
                                Similarity score determines how closely related the sources should be to your query. For best results, keep it within the <strong>65-75</strong> range.
                            </React.Fragment>
                        }
                        />
                        <Slider
                            value={similarityScore}
                            onChange={(_, newValue) => setSimilarityScore(newValue)}
                            min={0}
                            max={1}
                            step={0.01}
                            sx={{ width: 100, mr: 2 }}
                            marks={[{ value: 0.65 }]}
                        />
                        <Typography variant="body2" sx={{ mr: 1 }}>
                            Max Results: {maxResults}
                        </Typography>
                        <Slider
                            value={maxResults}
                            onChange={(_, newValue) => setMaxResults(newValue)}
                            min={1}
                            max={50}
                            step={1}
                            sx={{ width: 100 }}
                            marks={[{ value: 10 }]}
                        />
                    </Box>
                </Box>
            </Box>
            {query && (
                <div>
                    <ManagedList
                        key={refreshKey}
                        query={query}
                        rootAssociation={rootAssociation}
                        autoLoadExpandedContent={true}
                        showAssociationsDropdown={true}
                        sortFunction={sortByScore}
                        loadOnDefault={true}
                    />
                </div>
            )}
        </>
    );
}

export default Librarian;
