import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Grid, Modal, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { DragDropContext } from 'react-beautiful-dnd';
import useApi from "../../../components/ApiService";
import AssociationsDropdown from "../../../components/AssociationsDropdown";
import TypeSelector from "../../../components/TypeSelector";
import FolderNavigation from './FolderNavigation';
import AddCollectionForm from "../../apps/library/createcollection/CreateCollectionForm";
import FilterBar from './FilterBar';

const Directory = () => {
    const { apiCall } = useApi();
    const [allNodes, setAllNodes] = useState([]);
    const [allEdges, setAllEdges] = useState([]);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [includeOrphans, setIncludeOrphans] = useState(true);
    const [selectedTypes, setSelectedTypes] = useState(["Application", "Collection", "Source"]);
    const [selectedCollectionId, setSelectedCollectionId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentFolderPath, setCurrentFolderPath] = useState([]);
    const [activeComponent, setActiveComponent] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, itemId: null });
    const leftNavigateToRef = useRef([]);
    const rightNavigateToRef = useRef([]);

    const handleOnCollectionSelect = useCallback((selected) => {
        const associationsQueryFilter = selected.allDescendants.map(id => `&associations=${id}`).join('');
        setFilter(associationsQueryFilter);
        setSelectedCollectionId(selected.id);
    }, []);

    const handleOnCollectionsUpdate = useCallback(() => {
        console.log("Collections updated");
    }, []);

    const handleNameFilterChange = useCallback((event) => {
        setNameFilter(event.target.value);
    }, []);

    const handleTypeChange = useCallback((newSelectedTypes) => {
        setSelectedTypes(newSelectedTypes);
    }, []);

    const handleIncludeOrphansChange = useCallback((event) => {
        setIncludeOrphans(event.target.checked);
    }, []);

    const fetchGraphData = useCallback(async () => {
        try {
            const orphansParam = includeOrphans ? '&includeOrphans=true' : '';
            const filterParam = filter ? `&${filter.slice(1)}` : '';
            const url = `/graph/subgraph?a=a${filterParam}${orphansParam}`;
            // console.log('Fetching graph data with URL:', url);
            const response = await apiCall(url);
            const result = await response.data;

            if (!result.data || !Array.isArray(result.data.nodes) || !Array.isArray(result.data.edges)) {
                throw new Error('Invalid data structure received from API');
            }

            setAllNodes(result.data.nodes);
            setAllEdges(result.data.edges);
        } catch (error) {
            console.error('Error fetching graph data:', error);
            setError(error.message);
        }
    }, [filter, includeOrphans, apiCall]);

    const handleDeleteConfirmation = useCallback(async () => {
        try {
            await apiCall(`/source/${deleteConfirmation.itemId}`, {
                method: 'DELETE',
            });
            console.log('Item deleted successfully');
            await fetchGraphData();
            setRefreshKey(prevKey => prevKey + 1);
            setSnackbar({ open: true, message: 'Item deleted successfully', severity: 'success' });
        } catch (error) {
            console.error('Error deleting item:', error);
            setSnackbar({ open: true, message: 'Failed to delete item. Please try again.', severity: 'error' });
        } finally {
            setDeleteConfirmation({ open: false, itemId: null });
        }
    }, [apiCall, deleteConfirmation.itemId, fetchGraphData]);

    const handleDragEnd = useCallback(async (result) => {
        if (!result.destination) return;

        const sourceId = result.draggableId.split('-')[1];
        const sourceComponentId = result.source.droppableId.split('-')[0];
        const destinationComponentId = result.destination.droppableId.split('-')[0];
        const action = result.destination.droppableId.split('-')[1];

        // Check if the item was dropped back to its original location
        if (result.source.droppableId === result.destination.droppableId &&
            result.source.index === result.destination.index) {
            console.log('Item dropped back to its original location. No action needed.');
            return;
        }

        // Prevent moving the Orphans folder itself
        if (sourceId === 'orphans') {
            console.log('Cannot move the Orphans folder.');
            setSnackbar({ open: true, message: 'The Orphans folder cannot be moved.', severity: 'warning' });
            return;
        }

        let operationPerformed = false;

        try {
            if (action === 'copyTo') {
                const destinationPath = destinationComponentId === 'left' ? leftNavigateToRef.current : rightNavigateToRef.current;
                const destinationId = destinationPath.length > 0 ? destinationPath[destinationPath.length - 1] : 'root';
                await apiCall('/source/associations/add', {
                    method: 'POST',
                    data: {
                        sourcesToSet: [sourceId],
                        sourcesToAdd: [destinationId]
                    }
                });
                console.log('Copy successful');
                operationPerformed = true;
            } else if (action === 'remove' || action === 'delete') {
                const currentPath = sourceComponentId === 'left' ? leftNavigateToRef.current : rightNavigateToRef.current;
                const currentFolderId = currentPath.length > 0 ? currentPath[currentPath.length - 1] : 'root';

                if (currentFolderId === 'orphans' && action === 'delete') {
                    // Open delete confirmation dialog
                    setDeleteConfirmation({ open: true, itemId: sourceId });
                    return;
                }

                if (currentFolderId === 'orphans' && action === 'remove') {
                    console.log('Cannot remove items from Orphans folder.');
                    setSnackbar({ open: true, message: 'Items cannot be removed from the Orphans folder.', severity: 'warning' });
                    return;
                }

                await apiCall(`/source/${sourceId}/associations/${currentFolderId}`, {
                    method: 'DELETE',
                });
                console.log('Association removed successfully');

                // Update navigation paths if necessary
                if (sourceComponentId === 'left') {
                    leftNavigateToRef.current = leftNavigateToRef.current.filter(id => id !== sourceId);
                } else {
                    rightNavigateToRef.current = rightNavigateToRef.current.filter(id => id !== sourceId);
                }
                operationPerformed = true;
            } else if (action === 'moveTo') {
                // Handle drop into the "Move To" box
                const oppositeComponentPath = sourceComponentId === 'left' ? rightNavigateToRef.current : leftNavigateToRef.current;
                const destinationId = oppositeComponentPath.length > 0 ? oppositeComponentPath[oppositeComponentPath.length - 1] : 'root';

                const currentPath = sourceComponentId === 'left' ? leftNavigateToRef.current : rightNavigateToRef.current;
                const currentFolderId = currentPath.length > 0 ? currentPath[currentPath.length - 1] : 'root';

                // Special handling for moving to Orphans
                if (destinationId === 'orphans') {
                    // Remove all associations
                    await apiCall(`/source/${sourceId}/associations`, {
                        method: 'DELETE',
                    });
                    console.log('All associations removed, item moved to Orphans');
                } else {
                    // Normal move operation
                    await apiCall(`/source/${sourceId}/associations/${currentFolderId}`, {
                        method: 'DELETE',
                    });
                    await apiCall('/source/associations', {
                        method: 'POST',
                        data: {
                            sourcesToSet: [sourceId],
                            sourcesToAdd: [destinationId]
                        }
                    });
                    console.log('Move successful');
                }

                // Update navigation paths
                if (sourceComponentId === 'left') {
                    leftNavigateToRef.current = leftNavigateToRef.current.filter(id => id !== sourceId);
                } else {
                    rightNavigateToRef.current = rightNavigateToRef.current.filter(id => id !== sourceId);
                }
                operationPerformed = true;
            } else {
                // Handle drop into a regular folder
                const destinationId = result.destination.droppableId.split('-')[1];
                const currentPath = sourceComponentId === 'left' ? leftNavigateToRef.current : rightNavigateToRef.current;
                const currentFolderId = currentPath.length > 0 ? currentPath[currentPath.length - 1] : 'root';

                // Check if the destination is different from the current folder
                if (destinationId !== currentFolderId) {
                    if (destinationId === 'orphans') {
                        // Remove all associations when moving to Orphans
                        await apiCall(`/source/${sourceId}/associations`, {
                            method: 'DELETE',
                        });
                        console.log('All associations removed, item moved to Orphans');
                    } else {
                        // Normal move operation
                        await apiCall(`/source/${sourceId}/associations/${currentFolderId}`, {
                            method: 'DELETE',
                        });
                        await apiCall('/source/associations', {
                            method: 'POST',
                            data: {
                                sourcesToSet: [sourceId],
                                sourcesToAdd: [destinationId]
                            }
                        });
                        console.log('Move successful');
                    }

                    // Update navigation paths if necessary
                    if (sourceComponentId === 'left') {
                        leftNavigateToRef.current = leftNavigateToRef.current.filter(id => id !== sourceId);
                    } else {
                        rightNavigateToRef.current = rightNavigateToRef.current.filter(id => id !== sourceId);
                    }
                    operationPerformed = true;
                } else {
                    console.log('Item dropped within the same folder. No action needed.');
                }
            }

            if (operationPerformed) {
                await fetchGraphData();
                setRefreshKey(prevKey => prevKey + 1);
                setSnackbar({ open: true, message: 'Operation completed successfully', severity: 'success' });
            }
        } catch (error) {
            console.error('Error during drag and drop operation:', error);
            setSnackbar({ open: true, message: 'Operation failed. Please try again.', severity: 'error' });
        }
    }, [apiCall, fetchGraphData, leftNavigateToRef, rightNavigateToRef]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleLeftNavigate = useCallback((newPath) => {
        leftNavigateToRef.current = newPath;
    }, []);

    const handleRightNavigate = useCallback((newPath) => {
        rightNavigateToRef.current = newPath;
    }, []);

    const handleAddFolder = useCallback((path, componentId) => {
        setCurrentFolderPath(path);
        setActiveComponent(componentId);
        setIsModalOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const handleCollectionAdded = useCallback(async (newCollectionId) => {
        setIsModalOpen(false);
        await fetchGraphData();

        const newPath = [...currentFolderPath, newCollectionId];

        if (activeComponent === 'left') {
            leftNavigateToRef.current = newPath;
        } else {
            rightNavigateToRef.current = newPath;
        }

        setRefreshKey(prevKey => prevKey + 1);
    }, [fetchGraphData, currentFolderPath, activeComponent]);

    useEffect(() => {
        fetchGraphData();
    }, [fetchGraphData]);

    const filteredNodes = allNodes.filter(node => {
        const nodeTypes = Array.isArray(node.type) ? node.type : [node.type];

        const nameMatch = nodeTypes.some(type =>
            type.includes("System") ||
            type.includes("Collection") ||
            type.includes("Application")
        ) || node.label.toLowerCase().includes(nameFilter.toLowerCase());

        const typeMatch = nodeTypes.some(type =>
            selectedTypes.includes(type) ||
            (type.includes("System") && selectedTypes.includes("Application")) ||
            (type.includes("Collection") && selectedTypes.includes("Collection")) ||
            (type.includes("Application") && selectedTypes.includes("Application"))
        );

        return nameMatch && typeMatch;
    });

    const filteredEdges = allEdges.filter(edge =>
        filteredNodes.some(node => node.id === edge.source) &&
        filteredNodes.some(node => node.id === edge.target)
    );

    if (error) {
        return (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error:</strong>
                <span className="block sm:inline"> {error}</span>
            </div>
        );
    }

    return (
        <Box sx={{ p: 4, bgcolor: 'background.default', color: 'text.primary' }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Folder-Based View of Data
            </Typography>

            <AssociationsDropdown
                rootAssociation={"AIssistant"}
                selected={"AIssistant"}
                onCollectionsUpdate={handleOnCollectionsUpdate}
                onCollectionSelect={handleOnCollectionSelect}
                allowAddCollection={false}
            />

            <FilterBar
                nameFilter={nameFilter}
                onNameFilterChange={handleNameFilterChange}
                includeOrphans={includeOrphans}
                onIncludeOrphansChange={handleIncludeOrphansChange}
            />

            <TypeSelector
                defaultSelectedTypes={["Application", "Collection", "Source"]}
                onChange={handleTypeChange}
                lockedTypes={["Collection"]}
            />

            <DragDropContext onDragEnd={handleDragEnd}>
                <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={6}>
                        <FolderNavigation
                            key={`left-${refreshKey}`}
                            initialNodes={filteredNodes}
                            initialEdges={filteredEdges}
                            selectedCollectionId={selectedCollectionId}
                            componentId="left"
                            onNavigate={handleLeftNavigate}
                            navigateTo={leftNavigateToRef.current}
                            onAddFolder={handleAddFolder}
                            refreshData={fetchGraphData}
                            otherNavigationPath={rightNavigateToRef.current}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FolderNavigation
                            key={`right-${refreshKey}`}
                            initialNodes={filteredNodes}
                            initialEdges={filteredEdges}
                            selectedCollectionId={selectedCollectionId}
                            componentId="right"
                            onNavigate={handleRightNavigate}
                            navigateTo={rightNavigateToRef.current}
                            onAddFolder={handleAddFolder}
                            refreshData={fetchGraphData}
                            otherNavigationPath={leftNavigateToRef.current}
                        />
                    </Grid>
                </Grid>
            </DragDropContext>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="add-collection-modal"
                aria-describedby="modal-to-add-new-collection"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <AddCollectionForm
                        initialAssociations={currentFolderPath.length > 0 ? [currentFolderPath[currentFolderPath.length - 1]] : []}
                        onUpdate={handleCollectionAdded}
                    />
                </Box>
            </Modal>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={deleteConfirmation.open}
                onClose={() => setDeleteConfirmation({ open: false, itemId: null })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will completely delete the item. Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmation({ open: false, itemId: null })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Directory;