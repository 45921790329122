import React, { useMemo } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { parseISO, isWithinInterval } from 'date-fns';

const HealthCategory = ({ items, startDate, endDate }) => {
    const filteredItems = useMemo(() => {
        return items.filter(item => {
            const itemDate = parseISO(item.content.content.mealDate);
            return isWithinInterval(itemDate, { start: parseISO(startDate), end: parseISO(endDate) });
        });
    }, [items, startDate, endDate]);

    const calculateMealHealthCategory = (entrees) => {
        const categories = ['Unhealthy', 'Moderately Unhealthy', 'Generally Healthy', 'Very Healthy'];
        let totalWeightedCategory = 0;
        let totalCalories = 0;

        entrees.forEach(entree => {
            const calories = entree.nutrition_estimate.calories || 0;
            const categoryIndex = categories.indexOf(entree.nutrition_estimate.health_category);
            totalWeightedCategory += categoryIndex * calories;
            totalCalories += calories;
        });

        if (totalCalories === 0) return 'Unknown';
        const averageCategoryIndex = Math.round(totalWeightedCategory / totalCalories);
        return categories[averageCategoryIndex] || 'Unknown';
    };

    const healthCategories = useMemo(() => {
        const categories = {
            'Unhealthy': { count: 0, calories: 0 },
            'Moderately Unhealthy': { count: 0, calories: 0 },
            'Generally Healthy': { count: 0, calories: 0 },
            'Very Healthy': { count: 0, calories: 0 },
            'Unknown': { count: 0, calories: 0 }
        };

        filteredItems.forEach(item => {
            const { entrees, totalNutrition } = item.content.content;
            const calories = totalNutrition.calories || 0;
            const mealHealthCategory = calculateMealHealthCategory(entrees);

            categories[mealHealthCategory].count++;
            categories[mealHealthCategory].calories += calories;
        });

        const totalCount = Object.values(categories).reduce((sum, value) => sum + value.count, 0);
        const totalCalories = Object.values(categories).reduce((sum, value) => sum + value.calories, 0);

        return Object.entries(categories)
            .filter(([category, data]) => category !== 'Unknown' || data.count > 0)
            .map(([category, data]) => ({
                category,
                countPercentage: totalCount > 0 ? (data.count / totalCount) * 100 : 0,
                caloriePercentage: totalCalories > 0 ? (data.calories / totalCalories) * 100 : 0
            }));
    }, [filteredItems]);

    const colors = {
        'Unhealthy': '#FF6B6B',
        'Moderately Unhealthy': '#FFD93D',
        'Generally Healthy': '#6BCB77',
        'Very Healthy': '#4D96FF',
        'Unknown': '#CCCCCC'
    };

    const renderBar = (data, isCalorieBased) => (
        <Box sx={{ display: 'flex', height: 30, mb: 2 }}>
            {data.map(({ category, countPercentage, caloriePercentage }) => (
                <Box
                    key={category}
                    sx={{
                        width: `${isCalorieBased ? caloriePercentage : countPercentage}%`,
                        bgcolor: colors[category],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: category === 'Moderately Unhealthy' ? 'black' : 'white',
                        fontSize: '0.75rem',
                        fontWeight: 'bold'
                    }}
                >
                    {(isCalorieBased ? caloriePercentage : countPercentage) > 10 &&
                        `${(isCalorieBased ? caloriePercentage : countPercentage).toFixed(1)}%`}
                </Box>
            ))}
        </Box>
    );

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
                Health Category Distribution
            </Typography>
            {filteredItems.length === 0 ? (
                <Typography variant="body1">No data available for the selected date range.</Typography>
            ) : (
                <>
                    <Typography variant="subtitle2" gutterBottom>
                        By Calories
                    </Typography>
                    {renderBar(healthCategories, true)}

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {healthCategories.map(({ category, countPercentage, caloriePercentage }) => (
                            <Box key={category} sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Box
                                    sx={{
                                        width: 16,
                                        height: 16,
                                        bgcolor: colors[category],
                                        mr: 1
                                    }}
                                />
                                <Typography variant="body2">
                                    {category}: {countPercentage.toFixed(1)}% (meals), {caloriePercentage.toFixed(1)}% (calories)
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </Paper>
    );
};

export default HealthCategory;