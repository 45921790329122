import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactMarkdown from "react-markdown";
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import useApi from "../ApiService";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';

const useContent = (contentUri, contentType, onContentLoaded) => {
    const { apiCall } = useApi();
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);

    const memoizedApiCall = useCallback(apiCall, []);

    const fetchContent = useCallback(async () => {
        if (!contentUri) {
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            let response;
            if (contentType.startsWith('image/') || contentType === 'application/pdf') {
                response = await memoizedApiCall(`/content/uri`, {
                    method: 'POST',
                    data: { uri: contentUri, contentType },
                    responseType: 'arraybuffer'
                });
                const blob = new Blob([response.data], { type: contentType });
                const url = URL.createObjectURL(blob);
                if (contentType.startsWith('image/')) {
                    setImageUrl(url);
                    setContent({ content: url });
                } else if (contentType === 'application/pdf') {
                    setPdfUrl(url);
                    setContent({ content: url });
                }
            } else {
                response = await memoizedApiCall(`/content/uri`, { method: 'POST', data: { uri: contentUri } });
                setContent(response.data);
            }
            if (onContentLoaded) {
                onContentLoaded(response.data);
            }
        } catch (err) {
            setError(err.message || 'Error fetching content');
            console.error('Error fetching content:', err);
        } finally {
            setIsLoading(false);
        }
    }, [contentUri, contentType, memoizedApiCall, onContentLoaded]);

    useEffect(() => {
        fetchContent();
        return () => {
            if (imageUrl) URL.revokeObjectURL(imageUrl);
            if (pdfUrl) URL.revokeObjectURL(pdfUrl);
        };
    }, [fetchContent]);

    return { content, error, isLoading, imageUrl, pdfUrl };
};

const ContentDisplay = ({ contentUri, contentType, renderContent, onContentLoaded }) => {
    const { content, error, isLoading, imageUrl, pdfUrl } = useContent(contentUri, contentType, onContentLoaded);

    const SafeMarkdown = ({ children }) => {
        if (typeof children !== 'string') {
            console.warn('Invalid type passed to Markdown component:', typeof children);
            return <div>Unable to render content</div>;
        }

        return <ReactMarkdown>{children}</ReactMarkdown>;
    };

    const renderContentBasedOnType = useMemo(() => {
        if (!content) return null;

        if (renderContent) {
            return renderContent(content);
        }

        switch (contentType) {
            case 'text/plain':
                return <SafeMarkdown>{content.content || ''}</SafeMarkdown>;
            case 'application/json':
                return <JsonView data={content.content || {}} />;
            case 'image/jpeg':
            case 'image/png':
            case 'image/gif':
                return imageUrl ? <img src={imageUrl} alt="Content" style={{ maxWidth: '100%' }} /> : null;
            case 'application/pdf':
                return (
                    <Box mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            onClick={() => window.open(pdfUrl, '_blank')}
                        >
                            View PDF
                        </Button>
                    </Box>
                );
            default:
                return <div>Unsupported content type: {contentType}</div>;
        }
    }, [content, contentType, renderContent, imageUrl, pdfUrl]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="content-display">
            {renderContentBasedOnType}
        </div>
    );
};

export default ContentDisplay;