// src/root/advanced/Advanced.js
import React, { useState, useEffect } from 'react';
import Extractor from "../../../components/extract/Extractor";
import useApi from "../../../components/ApiService";

function Extract() {
    const { apiCall } = useApi();
    const [availableTypes, setAvailableTypes] = useState([]);

    useEffect(() => {
        const fetchAvailableTypes = async () => {
            try {
                const response = await apiCall(`/ai/extractor/types`);
                const types = response.data;

                // Transform the array of strings into the required format
                const formattedTypes = types.map(type => ({
                    label: type.replace(/([A-Z])/g, ' $1').trim(), // Add spaces before capital letters
                    id: type
                }));

                setAvailableTypes(formattedTypes);
            } catch (error) {
                console.error('Error fetching available types:', error);
                // You might want to set some default types or show an error message
            }
        };

        fetchAvailableTypes();
    }, []);

    return (
        <>
            <h1>
                Extract and create from your sources.
            </h1>
            <div>
                <Extractor
                    availableTypes={availableTypes}
                    fromQuery={'/source/search?'}
                    toQuery={'/source/search?'}
                />
            </div>
        </>
    );
}

export default Extract;
