import React, { useState, useCallback } from 'react';
import { Typography, Box, Chip, Divider, Paper, Card, CardContent, CardHeader, Collapse, Tooltip, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { CheckCircle, Warning, ExpandMore } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import ExpandableCard from "../../../../components/list/ExpandableCard";
import EditField from "../../../../components/form/EditField";
import RAGGeneration from "../../../../components/rag/RAGGeneration";
import useApi from "../../../../components/ApiService";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    marginBottom: theme.spacing(1),
}));

const ContentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const PointsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

const InstructionsBox = styled(Box)(({ theme }) => ({
    minHeight: '200px',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
}));

const ButtonBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
}));

function AssignmentItem({ sourceRecord, onSourceUpdate }) {
    const { apiCall } = useApi();
    const [assignment, setAssignment] = useState(sourceRecord.content.content);
    const [isExpanded, setIsExpanded] = useState(false);
    const [editingField, setEditingField] = useState(null);
    const [tempEditValue, setTempEditValue] = useState('');

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleDoubleClick = (field) => {
        if (field === 'submitted') {
            handleSubmitEdit('submitted', !assignment.submitted);
        } else {
            setEditingField(field);
            setTempEditValue(assignment[field]);
        }
    };

    const handleCancelEdit = () => {
        setEditingField(null);
        setTempEditValue('');
    };

    const handleSubmitEdit = useCallback(async (field, value) => {
        try {
            const updatedAssignment = { ...assignment, [field]: value };
            const requestBody = { id: sourceRecord.content.id, content: updatedAssignment };
            const response = await apiCall(`/content/${sourceRecord.content.id}`, {method: 'PUT', data: requestBody});

            if (response.data && response.data.content[0].content) {
                setAssignment(response.data.content[0].content);
                if (onSourceUpdate) {
                    const updatedSource = {
                        ...sourceRecord,
                        content: {
                            ...sourceRecord.content,
                            content: response.data.content[0].content
                        }
                    };
                    onSourceUpdate(updatedSource);
                }
            } else {
                setAssignment(updatedAssignment);
            }

            setEditingField(null);
            setTempEditValue('');
        } catch (error) {
            console.error('Error updating assignment:', error);
        }
    }, [assignment, sourceRecord.content.id]);

    const renderEditableField = (field, value, label) => {
        if (editingField === field) {
            if (field === 'instructions') {
                return (
                    <>
                        <InstructionsBox>
                            <TextField
                                fullWidth
                                multiline
                                variant="outlined"
                                value={tempEditValue}
                                onChange={(e) => setTempEditValue(e.target.value)}
                                autoFocus
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: 0,
                                    },
                                }}
                            />
                        </InstructionsBox>
                        <ButtonBox>
                            <Button variant="outlined" onClick={handleCancelEdit}>Cancel</Button>
                            <Button variant="contained" onClick={() => handleSubmitEdit(field, tempEditValue)}>Submit</Button>
                        </ButtonBox>
                    </>
                );
            }
            return (
                <EditField
                    field={label}
                    value={field === 'points_earned' && value === '<None>' ? '' : value}
                    onSubmit={(newValue) => handleSubmitEdit(field, newValue)}
                    onCancel={handleCancelEdit}
                />
            );
        }

        if (field === 'instructions' || field === 'context') {
            return (
                <Tooltip title="Double-click to edit">
                    <InstructionsBox
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                        onDoubleClick={() => handleDoubleClick(field)}
                    >
                        <ReactMarkdown>{value}</ReactMarkdown>
                    </InstructionsBox>
                </Tooltip>
            );
        }

        return (
            <Tooltip title="Double-click to edit">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        },
                    }}
                    onDoubleClick={() => handleDoubleClick(field)}
                >
                    <Typography variant="body1">
                        <strong>{label}:</strong> {value}
                    </Typography>
                </Box>
            </Tooltip>
        );
    };

    return (
        <ExpandableCard title={assignment.title}>
            <StyledPaper>
                <PointsBox>
                    {renderEditableField('points_possible', assignment.points_possible, 'Points Possible')}
                    {renderEditableField('points_earned', assignment.points_earned || '<None>', 'Points Awarded')}
                    <Tooltip title="Click to toggle submission status">
                        <Chip
                            icon={assignment.submitted ? <CheckCircle /> : <Warning />}
                            label={assignment.submitted ? "Submitted" : "Not Submitted"}
                            color={assignment.submitted ? "success" : "warning"}
                            size="small"
                            onClick={() => handleDoubleClick('submitted')}
                            sx={{ cursor: 'pointer' }}
                        />
                    </Tooltip>
                </PointsBox>

                <SectionTitle variant="h6">Instructions</SectionTitle>
                <ContentBox>
                    {renderEditableField('instructions', assignment.instructions, 'Instructions')}
                </ContentBox>

                <Divider sx={{ my: 3 }} />
                <Card sx={{ mb: 2 }}>
                    <RAGGeneration
                        contextSupplier={()=>{return assignment.context}}
                        specialInstructionsSupplier={()=>{return ""}}
                        querySupplier={()=>{return "You are a student's tutor talking to the student. You are assessing their actual submission in <context>...</context> that it follows the instructions. Assess the submission in <context>...</> against the Additional context provided by the system and provide them with feedback on their actual submission. Keep the focus on the submission how it meets requirements, not the requirements. Do NOT provide actual work for the student."}}
                        buttonText={"Pre-Check Submission"}
                    />
                </Card>
                <Card sx={{ mb: 2 }}>
                    <CardHeader
                        sx={{
                            py: 1,
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                        onClick={handleExpandClick}
                        title={
                            <Typography variant="h6" component="div">
                                Full Context
                            </Typography>
                        }
                        action={
                            <ExpandMore
                                sx={{
                                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s',
                                }}
                            />
                        }
                    />
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            {renderEditableField('context', assignment.context, 'Context')}
                        </CardContent>
                    </Collapse>
                </Card>
            </StyledPaper>
        </ExpandableCard>
    );
}

export default AssignmentItem;