import React, { useMemo, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    useTheme,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { differenceInDays, parseISO, format, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay } from 'date-fns';

const WorkoutFrequency = ({ workouts }) => {
    const theme = useTheme();
    const [timeFrame, setTimeFrame] = useState('all');

    const stats = useMemo(() => {
        if (!workouts || workouts.length === 0) return null;

        const dates = workouts
            .map(w => w.content?.content?.date)
            .filter(Boolean)
            .map(d => parseISO(d))
            .sort((a, b) => a - b);

        const firstDate = dates[0];
        const lastDate = dates[dates.length - 1];
        const totalDays = differenceInDays(lastDate, firstDate) + 1;
        const totalWeeks = Math.ceil(totalDays / 7);

        let longestStreak = 0;
        let currentStreak = 0;
        let previousDate = null;

        dates.forEach(date => {
            if (previousDate && differenceInDays(date, previousDate) === 1) {
                currentStreak++;
            } else {
                currentStreak = 1;
            }
            if (currentStreak > longestStreak) {
                longestStreak = currentStreak;
            }
            previousDate = date;
        });

        const weekMap = new Map();
        dates.forEach(date => {
            const weekStart = format(startOfWeek(date), 'yyyy-MM-dd');
            weekMap.set(weekStart, (weekMap.get(weekStart) || 0) + 1);
        });

        const mostWorkoutsInWeek = Math.max(...weekMap.values());
        const averageWorkoutsPerWeek = (dates.length / totalWeeks).toFixed(1);

        return {
            totalWorkouts: dates.length,
            averageWorkoutsPerWeek,
            longestStreak,
            mostWorkoutsInWeek,
            daysTracked: totalDays,
            workoutFrequency: ((dates.length / totalDays) * 100).toFixed(1) + '%',
            firstWorkout: format(firstDate, 'MMM d, yyyy'),
            lastWorkout: format(lastDate, 'MMM d, yyyy'),
            weeklyData: Array.from(weekMap, ([week, count]) => ({ week, count })),
        };
    }, [workouts]);

    const filteredData = useMemo(() => {
        if (!stats) return [];
        const currentDate = new Date();
        let startDate;
        switch (timeFrame) {
            case '1m':
                startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
                break;
            case '3m':
                startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
                break;
            case '6m':
                startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
                break;
            default:
                return stats.weeklyData;
        }
        return stats.weeklyData.filter(item => parseISO(item.week) >= startDate);
    }, [stats, timeFrame]);

    if (!stats) {
        return (
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom color="primary">
                    Workout Frequency
                </Typography>
                <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography color="error">No workout data available.</Typography>
                </Paper>
            </Box>
        );
    }

    const StatItem = ({ title, value }) => (
        <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h6" color="success.main" gutterBottom>
                    {value}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {title}
                </Typography>
            </Paper>
        </Grid>
    );

    return (
        <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom color="success.main">
                Workout Frequency
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom color="success.main">
                            Weekly Workout Frequency
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <ToggleButtonGroup
                                value={timeFrame}
                                exclusive
                                onChange={(e, newTimeFrame) => setTimeFrame(newTimeFrame)}
                                aria-label="time frame"
                                sx={{
                                    '& .MuiToggleButton-root.Mui-selected': {
                                        color: 'success.main',
                                        borderColor: 'success.main',
                                    }
                                }}
                            >
                                <ToggleButton value="all" aria-label="all time">
                                    All Time
                                </ToggleButton>
                                <ToggleButton value="6m" aria-label="6 months">
                                    6 Months
                                </ToggleButton>
                                <ToggleButton value="3m" aria-label="3 months">
                                    3 Months
                                </ToggleButton>
                                <ToggleButton value="1m" aria-label="1 month">
                                    1 Month
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={filteredData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="week" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke={theme.palette.success.main} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <StatItem title="Total Workouts" value={stats.totalWorkouts} />
                <StatItem title="Workout Frequency" value={stats.workoutFrequency} />
                <StatItem title="Avg Workouts/Week" value={stats.averageWorkoutsPerWeek} />
                <StatItem title="Longest Streak" value={`${stats.longestStreak} days`} />
                <StatItem title="Most in a Week" value={stats.mostWorkoutsInWeek} />
                <StatItem title="Days Tracked" value={stats.daysTracked} />
                <StatItem title="First Workout" value={stats.firstWorkout} />
                <StatItem title="Last Workout" value={stats.lastWorkout} />
            </Grid>
        </Box>
    );
};

export default WorkoutFrequency;