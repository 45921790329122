import React, {useState, useEffect, useCallback} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import AssociationManager from "../form/AssociationManager";
import StringList from "../form/StringList";
import useApi from "../ApiService";
import { useSourceName } from "../../context/useSourceName";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        overflow: 'hidden',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
}));
const MemoizedStringList = React.memo(StringList);
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
}));

const formatDate = (dateString) => {
    if (!dateString) return '';

    // Check if the date is already in YYYY-MM-DD format
    if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return dateString;
    }

    // If it's in MM/DD/YYYY format
    const [month, day, year] = dateString.split('/');
    if (month && day && year) {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    // If it's in another format or invalid, return as is
    console.warn('Invalid date format:', dateString);
    return dateString;
};

const AddDateModal = ({ isOpen, onClose, selectedDate, onDateAdded, selectedAssociation }) => {
    const { apiCall } = useApi();
    const { getSourceNameById } = useSourceName();
    const [dateInfo, setDateInfo] = useState({
        date: '',
        title: '',
        description: '',
    });
    const [associations, setAssociations] = useState(selectedAssociation ? [selectedAssociation] : []);

    useEffect(() => {
        if (selectedDate) {
            setDateInfo(prevState => ({
                ...prevState,
                date: selectedDate
            }));
        }
    }, [selectedDate]);

    const sourceDisplayPresenter = useCallback((sourceId) => {
        return getSourceNameById(sourceId);
    }, [getSourceNameById]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDateInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAssociationsUpdate = (newAssociations) => {
        setAssociations(newAssociations);
    };

    const handleSubmit = async () => {
        try {
            const formattedDate = formatDate(dateInfo.date);
            const response = await apiCall(`/aissistant/ingest/content`, {
                method: 'POST',
                data: {
                    types: ["Date"],
                    name: dateInfo.title,
                    description: "User Entered Date",
                    content: {
                        ...dateInfo,
                        date: formattedDate
                    },
                    attributes: [],
                    associations: associations,
                    contentType: "application/json"
            }});

            if (response.status === 200 || response.status === 201) {
                const newSourceId = response.data.id;
                console.log('newSourceId', newSourceId);
                const sourceResponse = await apiCall(`/source/search`, {
                    params: {
                        ids: newSourceId,
                        includeContent: true
                    }
                });

                if (sourceResponse.data.sources && sourceResponse.data.sources.length > 0) {
                    onDateAdded(sourceResponse.data.sources[0]);
                    onClose();
                }
            }
        } catch (error) {
            console.error('Error adding date:', error);
        }
    };

    return (
        <StyledDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add New Date</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                    <TextField
                        name="date"
                        label="Date"
                        type="text"
                        value={dateInfo.date}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                    <TextField
                        name="title"
                        label="Title"
                        value={dateInfo.title}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="description"
                        label="Description"
                        value={dateInfo.description}
                        onChange={handleInputChange}
                        multiline
                        rows={4}
                        fullWidth
                    />
                    <Box>
                        <Typography variant="subtitle1">Associations</Typography>
                        <MemoizedStringList
                            label="Associations"
                            values={associations}
                            onChange={handleAssociationsUpdate}
                            editable={false}
                            isNewItem={true}
                            displayPresenter={sourceDisplayPresenter}
                        />
                        <AssociationManager
                            associations={associations}
                            onUpdate={handleAssociationsUpdate}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <StyledDialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Add Date
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default AddDateModal;