import React, { useState, useCallback, useRef, useEffect } from 'react';
import { TextField, Button, Box, Modal, Typography, CircularProgress, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDropzone } from 'react-dropzone';
import ManagedList from "./list/ManagedList";
import { useSystemCollection } from "../context/systemColections";
import { useSourceName } from "../context/useSourceName";
import { styled } from '@mui/material/styles';

const ResponsiveBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
}));

const InputBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const ButtonBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: 'auto',
    },
}));

const SecondaryButtonBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
    },
}));

const TextOrSourceInput = ({
                               name = 'content',
                               rootAssociation,
                               textPlaceholder = "Enter text, select source, or drop a file",
                               sourceButtonPlaceholder = "Select Recipe",
                               fileButtonPlaceholder = "From File",
                               textButtonPlaceholder = "Submit Description",
                               onUpdate,
                               modal = true,
                               initialTextValue = '',
                               allowText = true,
                               allowFile = true,
                               allowSource = true,
                               types = ["Source"]
                           }) => {
    const [inputText, setInputText] = useState(initialTextValue);
    const [isSourceSelectionOpen, setIsSourceSelectionOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const { getSystemCollectionIdByName } = useSystemCollection();
    const [collectionId, setCollectionId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { getSourceNameById } = useSourceName();
    const sourceNamesRef = useRef({});
    const sourceNamePromisesRef = useRef({});

    useEffect(() => {
        return () => {
            // Clean up any pending promises or timeouts if needed
        };
    }, []);

    const createResponse = (contentType, content) => ({
        name,
        contentType,
        content
    });

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputText(newValue);
    };

    const handleTextSubmit = () => {
        onUpdate(createResponse('text/plain', { text: inputText }));
    };

    const handleOpenSourceSelection = async () => {
        if (!allowSource) return;
        setIsLoading(true);
        setError(null);
        try {
            const id = await getSystemCollectionIdByName(rootAssociation);
            setCollectionId(id);
            setIsSourceSelectionOpen(true);
        } catch (error) {
            console.error(`Error fetching ${rootAssociation} collection:`, error);
            setError(`Failed to load ${rootAssociation} collection. Please try again.`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSourceSelection = () => {
        setIsSourceSelectionOpen(false);
    };

    const handleSourceSelect = useCallback((checkedSources) => {
        if (checkedSources.length > 0) {
            const sourceId = checkedSources[0];
            setSelectedSource(sourceId);
            setSelectedFile(null);
            setIsSourceSelectionOpen(false);
            onUpdate(createResponse('application/source', { source: sourceId }));

            if (sourceNamesRef.current[sourceId]) {
                setInputText(sourceNamesRef.current[sourceId]);
            } else if (!sourceNamePromisesRef.current[sourceId]) {
                sourceNamePromisesRef.current[sourceId] = getSourceNameById(sourceId)
                    .then(name => {
                        sourceNamesRef.current[sourceId] = name;
                        setInputText(name);
                        delete sourceNamePromisesRef.current[sourceId];
                    })
                    .catch(error => {
                        console.error('Error fetching source name:', error);
                        sourceNamesRef.current[sourceId] = 'Unknown Source';
                        setInputText('Unknown Source');
                        delete sourceNamePromisesRef.current[sourceId];
                    });
            }
        }
    }, [getSourceNameById, onUpdate, name]);

    const handleClear = () => {
        setSelectedSource(null);
        setSelectedFile(null);
        setIsSourceSelectionOpen(false);
        setInputText('');
        onUpdate(null);
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSelectedFile(file);
            setSelectedSource(null);
            setInputText(file.name);
            onUpdate(createResponse('multipart/form-data', { file: file }));
        }
    }, [onUpdate, name]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const renderSourceSelection = () => (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                Select Source
            </Typography>
            {collectionId && (
                <ManagedList
                    query={`/source/search?associations=${collectionId}`}
                    rootAssociation={collectionId}
                    showAssociationsDropdown={true}
                    selectedAssociation={collectionId}
                    showFilter={true}
                    eagerLoad={false}
                    expanded={false}
                    onChecklistUpdate={handleSourceSelect}
                    loadOnDefault={true}
                    asChecklist={true}
                    showCheckAllClear={false}
                    checkedItems={selectedSource ? [selectedSource] : []}
                    defaultSelectedTypes={types}
                />
            )}
        </Box>
    );

    const isSelectionMade = selectedSource !== null || selectedFile !== null;

    return (
        <ResponsiveBox>
            <InputBox>
                {isSelectionMade ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Chip
                            label={inputText || (selectedFile ? "Selected File" : "Selected Source")}
                            color="primary"
                            onDelete={handleClear}
                            deleteIcon={<CloseIcon />}
                        />
                        <Typography variant="body2">
                            {selectedFile ? "(File selected)" : "(Source selected)"}
                        </Typography>
                    </Box>
                ) : allowText ? (
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={inputText}
                        onChange={handleInputChange}
                        placeholder={textPlaceholder}
                    />
                ) : (
                    <Typography variant="body1" color="text.secondary">
                        Please make a selection
                    </Typography>
                )}
            </InputBox>
            <ButtonBox>
                {!isSelectionMade && allowText && (
                    <Button
                        variant="contained"
                        onClick={handleTextSubmit}
                        fullWidth
                    >
                        {textButtonPlaceholder}
                    </Button>
                )}
                <SecondaryButtonBox>
                    {!isSelectionMade && allowSource && (
                        <Button
                            variant="contained"
                            onClick={handleOpenSourceSelection}
                            disabled={isLoading}
                            fullWidth
                        >
                            {isLoading ? <CircularProgress size={24} /> : sourceButtonPlaceholder}
                        </Button>
                    )}
                    {!isSelectionMade && allowFile && (
                        <Button
                            variant="contained"
                            {...getRootProps()}
                            fullWidth
                            sx={{
                                borderStyle: 'dashed',
                                borderColor: 'white',
                                borderWidth: 3,
                            }}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? 'DROP FILE HERE' : fileButtonPlaceholder}
                        </Button>
                    )}
                </SecondaryButtonBox>
            </ButtonBox>
            {error && (
                <Typography color="error">{error}</Typography>
            )}

            {modal && (
                <Modal
                    open={isSourceSelectionOpen}
                    onClose={handleCloseSourceSelection}
                    aria-labelledby="selection-modal"
                    aria-describedby="modal-to-select-source"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '90vh',
                        overflowY: 'auto',
                    }}>
                        {renderSourceSelection()}
                    </Box>
                </Modal>
            )}
            {!modal && isSourceSelectionOpen && renderSourceSelection()}
        </ResponsiveBox>
    );
};

export default TextOrSourceInput;