import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ManagedList from "../list/ManagedList";
import TypeSelector from "../TypeSelector";

const AssociationManager = ({ associations , onUpdate, allowedTypes = ["Collection"], defaultedTypes, includeRoot }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState(defaultedTypes ? defaultedTypes : ["Application", "Collection"]);
    const [query, setQuery] = useState("/source");
    const [key, setKey] = useState(0);
    console.log("ASSOCIATIONS:", associations);

    useEffect(() => {
        const typeParams = selectedTypes.map(type => `types=${encodeURIComponent(type)}`).join('&');
        setQuery(`/source/search${typeParams ? '?' + typeParams : ''}`);
        setKey(prevKey => prevKey + 1);
    }, [selectedTypes]);

    const handleAssociationsUpdate = (newAssociations) => {
        onUpdate(newAssociations);
    };

    const handleTypeChange = (newSelectedTypes) => {
        setSelectedTypes(newSelectedTypes);
    };

    return (
        <>
            <Button onClick={() => setIsModalOpen(true)}>Select Collections</Button>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="associations-modal"
                aria-describedby="associations-checklist"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    borderRadius: '8px',
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography id="associations-modal" variant="h6" component="h2">
                            Select Collections
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => setIsModalOpen(false)}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TypeSelector
                        defaultSelectedTypes={selectedTypes}
                        onChange={handleTypeChange}
                        possibleTypes={allowedTypes}
                    />
                    <ManagedList
                        key={key}
                        query={query}
                        rootAssociation="Collections"
                        onChecklistUpdate={handleAssociationsUpdate}
                        checkedItems={associations}
                        asChecklist={true}
                        showAssociationsDropdown={true}
                        showFilter={true}
                        loadOnDefault={true}
                        possibleTypes={["Collection"]}
                        includeRoot={includeRoot}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default AssociationManager;