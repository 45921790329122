import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MealDesigner from "./MealDesigner";
import useApi from "../../../../../components/ApiService";
import { CircularProgress, Typography } from '@mui/material';

const MealDisplay = ({ mealId: propMealId }) => {
    const [mealSourceRecord, setMealSourceRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { apiCall } = useApi();
    const location = useLocation();

    useEffect(() => {
        const fetchMealData = async () => {
            setIsLoading(true);
            setError(null);

            // Determine the meal ID to use
            const idToUse = propMealId || location.hash.slice(1);

            if (!idToUse) {
                setError("No meal ID provided");
                setIsLoading(false);
                return;
            }

            try {
                const response = await apiCall(`/source/search?ids=${idToUse}&includeContent=true`, {
                    method: 'GET',
                });

                if (response.status === 200 && response.data.sources && response.data.sources.length > 0) {
                    setMealSourceRecord(response.data.sources[0]);
                } else {
                    throw new Error('Failed to fetch meal data');
                }
            } catch (err) {
                console.error('Error fetching meal data:', err);
                setError(`Failed to fetch meal data: ${err.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMealData();
    }, [propMealId, location.hash, apiCall]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!mealSourceRecord) {
        return <Typography>No meal data available</Typography>;
    }

    return (
        <MealDesigner
            sourceRecord={mealSourceRecord}
            showAddNew={true}
            showMealLogNav={true}
            associationDropdownConfig={{ allowAddCollection: false, hide: true }}
        />
    );
};

export default MealDisplay;