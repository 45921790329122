import React, { useState, useMemo } from 'react';
import { Box, Select, MenuItem, Grid, Alert } from '@mui/material';
import { parseISO, startOfDay, endOfDay, subDays, subMonths } from 'date-fns';
import NutritionSummary from './NutritionSummary';
import MacronutrientDistribution from './MacronutrientDistribution';
import CalorieTrends from './CalorieTrends';
import MacronutrientTrends from './MacronutrientTrends';
import HealthCategory from "./HealthCategory";
import MacronutrientTrendsWithDietComparison from "./MacronutrientTrendsWithDietComparison";
import ExpandableCard from "../../../../../components/list/ExpandableCard";
import DietAnalysisRAG from "./DietAnalysisRAG";
import DietQueryRAGChat from "./DietQueryRAGChat";

const MealStats = ({ items }) => {
    const [timeFilter, setTimeFilter] = useState('last-week');
    const [movingAverageDays, setMovingAverageDays] = useState(7);

    const filteredItemsAndRange = useMemo(() => {
        const today = startOfDay(new Date());
        let startDate = today;

        switch (timeFilter) {
            case 'three-months':
                startDate = startOfDay(subMonths(today, 3));
                break;
            case 'last-month':
                startDate = startOfDay(subMonths(today, 1));
                break;
            case 'last-week':
                startDate = startOfDay(subDays(today, 6));
                break;
            case 'today':
                // startDate is already set to today
                break;
            default:
                startDate = startOfDay(subMonths(today, 3)); // Default to 3 months
        }

        const filtered = (items || []).filter(item => {
            if (!item || !item.content || !item.content.content || !item.content.content.mealDate) {
                return false;
            }
            const itemDate = startOfDay(parseISO(item.content.content.mealDate));
            return itemDate >= startDate && itemDate <= endOfDay(today);
        });

        return {
            filtered,
            startDate: startDate.toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0]
        };
    }, [items, timeFilter]);

    const getChartTimeframe = () => {
        switch (timeFilter) {
            case 'three-months':
                return 'Last 3 Months';
            case 'last-month':
                return 'Last 30 Days';
            case 'last-week':
                return 'Last 7 Days';
            case 'today':
                return 'Today';
            default:
                return 'Last 3 Months';
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Select
                    value={timeFilter}
                    onChange={(e) => setTimeFilter(e.target.value)}
                    sx={{ minWidth: 120 }}
                >
                    <MenuItem value="three-months">Last 3 Months</MenuItem>
                    <MenuItem value="last-month">Last Month</MenuItem>
                    <MenuItem value="last-week">Last Week</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                </Select>
            </Box>

            {filteredItemsAndRange.filtered.length === 0 ? (
                <Alert severity="info" sx={{ mb: 3 }}>
                    No data available for the selected time period. Try selecting a different time range.
                </Alert>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <DietQueryRAGChat
                            items={filteredItemsAndRange.filtered}
                            startDate={filteredItemsAndRange.startDate}
                            endDate={filteredItemsAndRange.endDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DietAnalysisRAG
                            items={filteredItemsAndRange.filtered}
                            startDate={filteredItemsAndRange.startDate}
                            endDate={filteredItemsAndRange.endDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} sx={{ height: '100%' }}>
                            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                                <NutritionSummary
                                    items={filteredItemsAndRange.filtered}
                                    startDate={filteredItemsAndRange.startDate}
                                    endDate={filteredItemsAndRange.endDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{
                                display: 'flex',
                                minHeight: { xs: '300px', sm: '400px' },
                                width: { xs: '100%', sm: 'auto' }
                            }}>
                                <MacronutrientDistribution
                                    items={filteredItemsAndRange.filtered}
                                    startDate={filteredItemsAndRange.startDate}
                                    endDate={filteredItemsAndRange.endDate}
                                    getChartTimeframe={getChartTimeframe}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HealthCategory
                            items={filteredItemsAndRange.filtered}
                            startDate={filteredItemsAndRange.startDate}
                            endDate={filteredItemsAndRange.endDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CalorieTrends
                            items={filteredItemsAndRange.filtered}
                            startDate={filteredItemsAndRange.startDate}
                            endDate={filteredItemsAndRange.endDate}
                            getChartTimeframe={getChartTimeframe}
                            movingAverageDays={movingAverageDays}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MacronutrientTrends
                            items={filteredItemsAndRange.filtered}
                            startDate={filteredItemsAndRange.startDate}
                            endDate={filteredItemsAndRange.endDate}
                            getChartTimeframe={getChartTimeframe}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ExpandableCard title={"Diet Comparison"}>
                            <MacronutrientTrendsWithDietComparison
                                items={filteredItemsAndRange.filtered}
                                startDate={filteredItemsAndRange.startDate}
                                endDate={filteredItemsAndRange.endDate}
                                getChartTimeframe={getChartTimeframe}
                            />
                        </ExpandableCard>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default MealStats;