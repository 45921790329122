import React, { useCallback, useMemo } from 'react';
import "./Terminology.css";
import ManagedPage from "../../../../components/apps/ManagedPage";
import SourceItem from "../../../../components/list/SourceItem";
import RAGGeneration from "../../../../components/rag/RAGGeneration";
import ContentDisplay from "../../../../components/list/ContentDisplay";
import TerminologyStats from "./TerminologyStats";

function Terminology() {
    const goDeeper = useMemo(() => "You are a professor writing a deep and detailed informative lesson. You are giving a student a full lesson explaining in full detail the following content. Expand and give a full lesson fully explaining the content.", []);

    const renderCustomContent = useCallback((sourceRecord) => (
        <div className="terminology-content">
            <ContentDisplay
                contentUri={sourceRecord.uri}
                contentType={sourceRecord.contentType}
                renderContent={(contentObject) => {
                    if (contentObject && contentObject.content) {
                        const { terminology, description } = contentObject.content;
                        const ragContent = `${terminology}: ${description}`;

                        return (
                            <div className="parsed-terminology">
                                <h4>{terminology}</h4>
                                <p>{description}</p>

                                <>
                                    <RAGGeneration
                                        contentSupplier={() => ragContent}
                                        specialInstructionsSupplier={() => ""}
                                        contextSupplier={() => ""}
                                        querySupplier={() => goDeeper}
                                        buttonText="Go Deeper"
                                    />
                                    <RAGGeneration
                                        contentSupplier={() => ragContent}
                                        specialInstructionsSupplier={() => ""}
                                        contextSupplier={() => ""}
                                        buttonText="Quick Question"
                                    />
                                </>
                            </div>
                        );
                    }
                    return <p>No content available</p>;
                }}
            />
        </div>
    ), [goDeeper]);

    const WrappedSourceItem = useCallback(({ item, ...props }) => (
        <SourceItem
            {...props}
            item={item}
            renderCustomContent={renderCustomContent}
            showRagGenerations={false}
        />
    ), [renderCustomContent]);

    const extractorProps = useMemo(() => ({
        availableTypes: [{ id: "Terminology", label: "Terminology" }],
        fromQuery: '/source/search?',
        toQuery: '/source/search?',
        toRootAssociation: "Terminology",
        fromRootAssociation: "Professor"
    }), []);

    const managedListProps = useMemo(() => ({
        loadOnDefault: true,
        eagerLoad: false
    }), []);

    return (
        <ManagedPage
            title="Terminology"
            systemCollectionName="Terminology"
            sourceType="Terminology"
            StatsComponent={TerminologyStats}
            ItemDisplay={WrappedSourceItem}
            extractorProps={extractorProps}
            managedListProps={managedListProps}
            addItemsButtonText={"Add Terms"}
        />
    );
}

export default Terminology;