import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Snackbar,
    Alert,
    Link,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StringList from "../../../../components/form/StringList";
import AssociationManager from "../../../../components/form/AssociationManager";
import useApi from "../../../../components/ApiService";
import { useSourceName } from "../../../../context/useSourceName";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
}));

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const MemoizedStringList = React.memo(StringList);
const MemoizedAssociationManager = React.memo(AssociationManager);

const AddSourceForm = ({ onUpdate, setAssociations = [] }) => {
    const { apiCall } = useApi();
    const initialSourceData = useMemo(() => ({
        name: '',
        description: '',
        types: ['Source'],
        content: '',
        contentType: 'file',
        associations: setAssociations,
    }), [setAssociations]);
    console.log("Adding collection with set association:", setAssociations)

    const [sourceData, setSourceData] = useState(initialSourceData);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [newSourceId, setNewSourceId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [file, setFile] = useState(null);
    const initialAssociationsRef = useRef(setAssociations);
    const { getSourceNameById } = useSourceName();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        setSourceData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    useEffect(() => {
        initialAssociationsRef.current = sourceData.associations;
    }, []);

    const handleAssociationsChange = useCallback((newAssociations) => {
        setSourceData(prevState => ({
            ...prevState,
            associations: newAssociations
        }));
    }, []);

    const handleAssociationsUpdate = useCallback((newAssociations) => {
        const initialAssociations = initialAssociationsRef.current;
        if (JSON.stringify(newAssociations.sort()) !== JSON.stringify(initialAssociations.sort())) {
            setSourceData(prevState => ({
                ...prevState,
                associations: newAssociations
            }));
            initialAssociationsRef.current = newAssociations;
        }
    }, []);

    const validateForm = useCallback(() => {
        if (!sourceData.contentType) {
            setErrorMessage('Content Type is required.');
            return false;
        }
        if (!sourceData.content && sourceData.contentType !== 'file') {
            setErrorMessage('Content is required.');
            return false;
        }
        if (sourceData.contentType === 'file' && !file) {
            setErrorMessage('File is required.');
            return false;
        }
        setErrorMessage('');
        return true;
    }, [sourceData.contentType, sourceData.content, file]);

    const handleContentTypeChange = useCallback((event) => {
        const newContentType = event.target.value;
        setSourceData(prevState => ({
            ...prevState,
            contentType: newContentType,
            content: '',
            name: newContentType === 'file' ? prevState.name : '',
        }));
        if (newContentType !== 'file') {
            setFile(null);
        }
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0]);
            setSourceData(prevState => ({
                ...prevState,
                name: acceptedFiles[0].name,
            }));
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: sourceData.contentType === 'file' ? undefined : { 'application/json': ['.json'], 'text/plain': ['.txt'] }
    });

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        setErrorMessage('');

        try {
            let endpoint = '/aissistant/ingest/content';
            let body;
            let headers = {};

            if (sourceData.contentType === 'file') {
                endpoint = '/aissistant/ingest/file';
                body = new FormData();
                body.append('file', file);
                const metadata = {
                    associations: sourceData.associations,
                    types: sourceData.types,
                    name: sourceData.name,
                    description: sourceData.description,
                    contentType: file.type
                };
                body.append('metadata', new Blob([JSON.stringify(metadata)], {
                    type: 'application/json'
                }));
            } else {
                body = JSON.stringify({
                    ...sourceData,
                    content: sourceData.contentType === 'application/json'
                        ? JSON.parse(sourceData.content)
                        : sourceData.content,
                    runDataPreperation: false
                });
                headers['Content-Type'] = 'application/json';
            }

            const response = await apiCall(endpoint, {
                method: 'POST',
                headers: headers,
                data: body,
            });

            if (response.status === 200 || response.status === 201) {
                console.log('Source added successfully, ID:', response.data);
                setNewSourceId(response.data);
                setSnackbarOpen(true);
                setSourceData(prevState => ({
                    ...initialSourceData,
                    associations: prevState.associations
                }));
                setFile(null);

                // Call the onUpdate callback with the response data
                if (onUpdate) {
                    onUpdate(response.data);
                }
            }
        } catch (error) {
            console.error('Error adding source:', error);
            setErrorMessage(`Error adding source: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    }, [sourceData, file, validateForm, apiCall, initialSourceData, onUpdate]);

    const sourceDisplayPresenter = useCallback((sourceId) => {
        return getSourceNameById(sourceId);
    }, [getSourceNameById]);

    const handleCloseSnackbar = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }, []);

    const renderContentInput = useCallback(() => {
        switch (sourceData.contentType) {
            case 'file':
                return (
                    <>
                        {file && (
                            <TextField
                                name="name"
                                label="Name"
                                value={sourceData.name}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        )}
                        <Box
                            {...getRootProps()}
                            sx={{
                                border: '2px dashed #ccc',
                                borderRadius: '4px',
                                padding: '20px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    borderColor: '#999',
                                },
                            }}
                        >
                            <input {...getInputProps()} />
                            <CloudUploadIcon sx={{ fontSize: 48, color: '#999' }} />
                            <Typography>
                                {isDragActive
                                    ? "Drop the file here"
                                    : file
                                        ? `File selected: ${file.name}`
                                        : "Drag and drop a file here, or click to select a file. Alternatively, select a different Content Type above to manually enter in your source."}
                            </Typography>
                        </Box>
                    </>
                );
            case 'text/plain':
                return (
                    <TextField
                        name="content"
                        label="Content"
                        value={sourceData.content}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={5}
                    />
                );
            case 'application/json':
                return (
                    <TextField
                        name="content"
                        label="JSON Content"
                        value={sourceData.content}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={5}
                    />
                );
            case 'url':
                return (
                    <TextField
                        name="content"
                        label="URL"
                        value={sourceData.content}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                );
            default:
                return null;
        }
    }, [sourceData.contentType, sourceData.content, sourceData.name, file, getRootProps, getInputProps, isDragActive, handleInputChange]);

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h5" gutterBottom>Add New Source</Typography>
            <StyledForm onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <InputLabel id="content-type-label">Content Type</InputLabel>
                    <Select
                        labelId="content-type-label"
                        name="contentType"
                        value={sourceData.contentType}
                        onChange={handleContentTypeChange}
                        required
                    >
                        <MenuItem value="file">File</MenuItem>
                        <MenuItem value="text/plain">Text</MenuItem>
                        <MenuItem value="application/json">JSON</MenuItem>
                        <MenuItem value="url">URL</MenuItem>
                    </Select>
                </FormControl>
                {sourceData.contentType !== 'file' && (
                    <TextField
                        name="name"
                        label="Name"
                        value={sourceData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                )}
                {renderContentInput()}
                <TextField
                    name="description"
                    label="Description"
                    value={sourceData.description}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={3}
                />
                <Box>
                    <MemoizedStringList
                        label="Associations"
                        values={sourceData.associations}
                        onChange={handleAssociationsChange}
                        editable={false}
                        isNewItem={true}
                        isRequired={true}
                        displayPresenter={sourceDisplayPresenter}
                    />
                    <MemoizedAssociationManager
                        associations={sourceData.associations}
                        onUpdate={handleAssociationsUpdate}
                    />
                </Box>
                {errorMessage && (
                    <Typography color="error">{errorMessage}</Typography>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                >
                    {isSubmitting ? 'Processing...' : 'Add Source'}
                </Button>
            </StyledForm>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Source added successfully!
                    <Box mt={1}>
                        <Link href={`/apps/library/catalog#${newSourceId}`} target="_blank" rel="noopener">
                            View Source
                        </Link>
                    </Box>
                </Alert>
            </Snackbar>
        </StyledPaper>
    );
};

export default React.memo(AddSourceForm);