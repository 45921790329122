// src/themeDefinitions.js
import { createTheme } from '@mui/material/styles';

const themeDefinitions = [
    {
        name: 'Global Harmony',
        theme: createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: '#2196f3', // A vibrant blue, universally associated with trust and stability
                    light: '#64b5f6',
                    dark: '#1976d2',
                },
                secondary: {
                    main: '#4caf50', // A fresh green, symbolizing growth and harmony
                    light: '#81c784',
                    dark: '#388e3c',
                },
                background: {
                    default: '#f5f5f5',
                    paper: '#ffffff',
                },
                text: {
                    primary: '#212121',
                    secondary: '#757575',
                },
                error: {
                    main: '#f44336',
                },
                warning: {
                    main: '#ffeb3b',
                },
                info: {
                    main: '#03a9f4',
                },
                success: {
                    main: '#4caf50',
                },
                specialButton: {
                    text: '#ffffff',
                },
            },
            typography: {
                fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Inter", sans-serif',
                    fontSize: '2.5rem',
                    fontWeight: 700,
                    letterSpacing: '-0.01562em',
                },
                h2: {
                    fontFamily: '"Inter", sans-serif',
                    fontSize: '2rem',
                    fontWeight: 600,
                    letterSpacing: '-0.00833em',
                },
                h3: {
                    fontFamily: '"Inter", sans-serif',
                    fontSize: '1.75rem',
                    fontWeight: 600,
                    letterSpacing: '0em',
                },
                body1: {
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em',
                },
                button: {
                    textTransform: 'none',
                    fontWeight: 600,
                },
            },
            shape: {
                borderRadius: 8,
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
                    
                    body {
                        transition: all 0.3s linear;
                    }
                    
                    :root {
                        --toastify-color-light: #fff;
                        --toastify-color-dark: #121212;
                        --toastify-color-info: #3498db;
                        --toastify-color-success: #07bc0c;
                        --toastify-color-warning: #f1c40f;
                        --toastify-color-error: #e74c3c;
                        --toastify-color-transparent: rgba(255, 255, 255, 0.7);
                    }
                `,
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 50,
                            padding: '10px 24px',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
                            },
                        },
                        containedPrimary: {
                            background: 'linear-gradient(45deg, #2196f3 30%, #21CBF3 90%)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #21CBF3 30%, #2196f3 90%)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                            },
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            background: 'rgba(255,255,255,0.8)',
                            backdropFilter: 'blur(20px)',
                            boxShadow: 'none',
                            borderBottom: '1px solid rgba(0,0,0,0.1)',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(0,0,0,0.1)',
                                    transition: 'all 0.3s ease-in-out',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(33,150,243,0.5)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#2196f3',
                                },
                            },
                        },
                    },
                },
                MuiSwitch: {
                    styleOverrides: {
                        root: {
                            width: 42,
                            height: 26,
                            padding: 0,
                            margin: 8,
                        },
                        switchBase: {
                            padding: 1,
                            '&.Mui-checked': {
                                transform: 'translateX(16px)',
                                color: '#fff',
                                '& + .MuiSwitch-track': {
                                    backgroundColor: '#2196f3',
                                    opacity: 1,
                                    border: 'none',
                                },
                            },
                            '&.Mui-focusVisible .MuiSwitch-thumb': {
                                color: '#2196f3',
                                border: '6px solid #fff',
                            },
                        },
                        thumb: {
                            width: 24,
                            height: 24,
                        },
                        track: {
                            borderRadius: 26 / 2,
                            border: '1px solid #bdbdbd',
                            backgroundColor: '#fafafa',
                            opacity: 1,
                            transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#ffffff',
            backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21CBF3 90%)',
            borderRadius: '12px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
        },
    },
    {
        name: 'Liquid Crystal',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#00E5FF',
                    light: '#6EFFFF',
                    dark: '#00B2CC',
                },
                secondary: {
                    main: '#FF00FF',
                    light: '#FF6EFF',
                    dark: '#CC00CC',
                },
                background: {
                    default: '#001620',
                    paper: '#002137',
                },
                text: {
                    primary: '#FFFFFF',
                    secondary: '#B2EBF2',
                },
                error: {
                    main: '#FF5252',
                },
                warning: {
                    main: '#FFD740',
                },
                info: {
                    main: '#40C4FF',
                },
                success: {
                    main: '#69F0AE',
                },
                specialButton: {
                    text: '#FF00FF',
                },
            },
            typography: {
                fontFamily: '"Exo 2", "Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Exo 2", sans-serif',
                    fontSize: '3rem',
                    fontWeight: 300,
                    letterSpacing: '0.1em',
                    background: 'linear-gradient(45deg, #00E5FF, #FF00FF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                },
                h2: {
                    fontFamily: '"Exo 2", sans-serif',
                    fontSize: '2.5rem',
                    fontWeight: 300,
                    letterSpacing: '0.05em',
                    background: 'linear-gradient(45deg, #00E5FF, #FF00FF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                },
                h3: {
                    fontFamily: '"Exo 2", sans-serif',
                    fontSize: '2rem',
                    fontWeight: 400,
                    color: '#00E5FF',
                },
                body1: {
                    fontSize: '1rem',
                    lineHeight: 1.6,
                },
                button: {
                    fontFamily: '"Exo 2", sans-serif',
                    fontWeight: 500,
                    letterSpacing: '0.05em',
                },
            },
            shape: {
                borderRadius: 12,
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    @keyframes liquidShift {
                        0% { background-position: 0% 50%; }
                        50% { background-position: 100% 50%; }
                        100% { background-position: 0% 50%; }
                    }
                    body {
                        background: linear-gradient(-45deg, #001620, #002137, #003A5C, #00527F);
                        background-size: 400% 400%;
                        animation: liquidShift 15s ease infinite;
                    }
                `,
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 25,
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-3px)',
                                boxShadow: '0 7px 14px rgba(0, 229, 255, 0.3), 0 3px 6px rgba(0, 229, 255, 0.2)',
                            },
                        },
                        contained: {
                            background: 'linear-gradient(45deg, #00E5FF, #40C4FF)',
                            color: '#001620',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #40C4FF, #00E5FF)',
                            },
                        },
                        outlined: {
                            borderColor: '#00E5FF',
                            color: '#00E5FF',
                            '&:hover': {
                                borderColor: '#40C4FF',
                                backgroundColor: 'rgba(0, 229, 255, 0.1)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            background: 'rgba(0, 33, 55, 0.7)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(0, 229, 255, 0.3)',
                            boxShadow: '0 8px 32px 0 rgba(0, 229, 255, 0.1)',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 12px 48px 0 rgba(0, 229, 255, 0.2)',
                            },
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            background: 'rgba(0, 22, 32, 0.8)',
                            backdropFilter: 'blur(10px)',
                            borderBottom: '1px solid rgba(0, 229, 255, 0.3)',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(0, 229, 255, 0.5)',
                                    transition: 'all 0.3s ease-in-out',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(0, 229, 255, 0.7)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#00E5FF',
                                    boxShadow: '0 0 0 2px rgba(0, 229, 255, 0.2)',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#B2EBF2',
                            },
                            '& .MuiInputBase-input': {
                                color: '#FFFFFF',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: 'rgba(0, 229, 255, 0.2)',
                        },
                        head: {
                            backgroundColor: 'rgba(0, 33, 55, 0.7)',
                            color: '#00E5FF',
                            fontWeight: 600,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#001620',
            backgroundImage: 'linear-gradient(45deg, #00E5FF, #FF00FF)',
            borderRadius: '50%',
            boxShadow: '0 0 20px rgba(0, 229, 255, 0.5)',
        },
    },
    // {
    //     name: 'Chaotic Retro Glitch',
    //     theme: createTheme({
    //         palette: {
    //             mode: 'light',
    //             primary: {
    //                 main: '#00FF00',
    //                 light: '#ADFF2F',
    //                 dark: '#006400',
    //             },
    //             secondary: {
    //                 main: '#FF00FF',
    //                 light: '#FF69B4',
    //                 dark: '#8B008B',
    //             },
    //             background: {
    //                 default: '#000080',
    //                 paper: '#008080',
    //             },
    //             text: {
    //                 primary: '#FFFF00',
    //                 secondary: '#FF4500',
    //             },
    //             error: {
    //                 main: '#FF0000',
    //             },
    //             warning: {
    //                 main: '#FFA500',
    //             },
    //             info: {
    //                 main: '#00FFFF',
    //             },
    //             success: {
    //                 main: '#32CD32',
    //             },
    //             specialButton: {
    //                 text: '#FF1493',
    //             },
    //         },
    //         typography: {
    //             fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
    //             fontSize: 16,
    //             h1: {
    //                 fontFamily: '"Courier New", Courier, monospace',
    //                 fontSize: '3rem',
    //                 fontWeight: 700,
    //                 textTransform: 'uppercase',
    //                 letterSpacing: '0.2em',
    //                 color: '#FF00FF',
    //                 textShadow: '3px 3px 0 #00FF00, -3px -3px 0 #0000FF',
    //             },
    //             h2: {
    //                 fontFamily: '"Times New Roman", Times, serif',
    //                 fontSize: '2.5rem',
    //                 fontWeight: 700,
    //                 fontStyle: 'italic',
    //                 color: '#00FFFF',
    //                 textDecoration: 'underline wavy #FF0000',
    //             },
    //             h3: {
    //                 fontFamily: 'Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif',
    //                 fontSize: '2rem',
    //                 fontWeight: 400,
    //                 color: '#FFFF00',
    //                 transform: 'skew(-10deg, 5deg)',
    //                 display: 'inline-block',
    //             },
    //             body1: {
    //                 fontSize: '1rem',
    //                 lineHeight: 1.8,
    //                 fontFamily: '"Papyrus", fantasy',
    //             },
    //             button: {
    //                 fontFamily: '"Brush Script MT", cursive',
    //                 fontWeight: 700,
    //                 fontSize: '1.2rem',
    //             },
    //         },
    //         shape: {
    //             borderRadius: 0,
    //         },
    //         components: {
    //             MuiCssBaseline: {
    //                 styleOverrides: `
    //                 @keyframes glitchEffect {
    //                     0% { transform: translate(0) }
    //                     20% { transform: translate(-5px, 5px) }
    //                     40% { transform: translate(-5px, -5px) }
    //                     60% { transform: translate(5px, 5px) }
    //                     80% { transform: translate(5px, -5px) }
    //                     100% { transform: translate(0) }
    //                 }
    //                 body {
    //                     background-image: repeating-linear-gradient(
    //                         45deg,
    //                         #000080,
    //                         #000080 10px,
    //                         #0000FF 10px,
    //                         #0000FF 20px
    //                     );
    //                     cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABFklEQVRYR9WXURLDIAhE6/0PbSdOtUpcd1Gnpv1KGpTHBpCE1/cXq+vrMph7dGvXZTtpfW10DCA5jrH1H0Jhs5E0hnZdCR+vb5S8Nn8mQCeS9BdSalYJqMBjAGzq59xAESN7VFVUgV8AZB/dZBR7QTFDCqGquvUBVVoEtgIwpQRzmANSFHgWQKExHdIrVnGrimdsEcA4mp3WAE1BRQkrQOXKcma3B4ziCHeNFXNPbgHIW1MP2QcFkB9YE18lAYQAyn4LQErg+eXMD3Xgec7xdONjHOc/QkExEQ8wjeNc2edqHKfrpZPTpAm+4nw8fhHXPABNMKUEVoEOIAAmBk0dIdIYYDX1tPn/1Qi1HrNzroHHH7kBMyRp1pxUzzcAAAAASUVORK5CYII=') 16 16, auto;
    //                 }
    //                 * {
    //                     animation: glitchEffect 0.3s infinite;
    //                 }
    //             `,
    //             },
    //             MuiButton: {
    //                 styleOverrides: {
    //                     root: {
    //                         borderWidth: 3,
    //                         borderStyle: 'dashed',
    //                         transform: 'rotate(2deg)',
    //                         transition: 'all 0.1s linear',
    //                         '&:hover': {
    //                             transform: 'rotate(-2deg) scale(1.05)',
    //                             animation: 'glitchEffect 0.2s infinite',
    //                         },
    //                     },
    //                     contained: {
    //                         backgroundColor: '#FF00FF',
    //                         color: '#00FF00',
    //                         '&:hover': {
    //                             backgroundColor: '#00FFFF',
    //                         },
    //                     },
    //                     outlined: {
    //                         borderColor: '#FF0000',
    //                         color: '#FFFF00',
    //                         '&:hover': {
    //                             borderColor: '#00FF00',
    //                             backgroundColor: 'rgba(255, 0, 255, 0.2)',
    //                         },
    //                     },
    //                 },
    //             },
    //             MuiCard: {
    //                 styleOverrides: {
    //                     root: {
    //                         backgroundColor: '#008080',
    //                         border: '5px solid #FF00FF',
    //                         boxShadow: '10px 10px 0 #FF0000, -10px -10px 0 #00FF00',
    //                         transform: 'rotate(-1deg)',
    //                     },
    //                 },
    //             },
    //             MuiAppBar: {
    //                 styleOverrides: {
    //                     root: {
    //                         backgroundColor: '#FF00FF',
    //                         backgroundImage: 'repeating-radial-gradient(circle, #FF00FF, #FF00FF 10px, #00FFFF 10px, #00FFFF 20px)',
    //                     },
    //                 },
    //             },
    //             MuiTextField: {
    //                 styleOverrides: {
    //                     root: {
    //                         '& .MuiOutlinedInput-root': {
    //                             '& fieldset': {
    //                                 borderColor: '#FF00FF',
    //                                 borderWidth: 3,
    //                                 borderStyle: 'dotted',
    //                             },
    //                             '&:hover fieldset': {
    //                                 borderColor: '#00FF00',
    //                             },
    //                             '&.Mui-focused fieldset': {
    //                                 borderColor: '#FFFF00',
    //                             },
    //                         },
    //                         '& .MuiInputLabel-root': {
    //                             color: '#FF4500',
    //                             fontFamily: '"Comic Sans MS", cursive',
    //                             fontSize: '1.2rem',
    //                         },
    //                         '& .MuiInputBase-input': {
    //                             color: '#FFFF00',
    //                             fontFamily: '"Courier New", monospace',
    //                             fontSize: '1.1rem',
    //                         },
    //                     },
    //                 },
    //             },
    //             MuiTableCell: {
    //                 styleOverrides: {
    //                     root: {
    //                         borderColor: '#FF00FF',
    //                         borderWidth: 2,
    //                         borderStyle: 'dashed',
    //                     },
    //                     head: {
    //                         backgroundColor: '#008080',
    //                         color: '#FFFF00',
    //                         fontWeight: 700,
    //                         fontFamily: '"Papyrus", fantasy',
    //                         fontSize: '1.2rem',
    //                     },
    //                 },
    //             },
    //         },
    //     }),
    //     icon: {
    //         bgcolor: '#FF00FF',
    //         backgroundImage: 'linear-gradient(45deg, #FF00FF, #00FFFF, #FFFF00, #FF0000)',
    //         animation: 'glitchEffect 0.3s infinite',
    //         border: '3px dashed #00FF00',
    //     },
    // },
    {
        name: 'Pastel Sunshine',
        theme: createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: '#FFD166',
                    light: '#FFE085',
                    dark: '#E6B94D',
                },
                secondary: {
                    main: '#F9C74F',
                    light: '#FAD479',
                    dark: '#E0B046',
                },
                background: {
                    default: '#FFF9E6',
                    paper: '#FFFFFF',
                },
                text: {
                    primary: '#5C4D3C',
                    secondary: '#8C7A6B',
                },
                error: {
                    main: '#F08080',
                },
                warning: {
                    main: '#FFB347',
                },
                info: {
                    main: '#87CEEB',
                },
                success: {
                    main: '#98FB98',
                },
                specialButton: {
                    text: '#F9C74F',
                },
            },
            typography: {
                fontFamily: '"Quicksand", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Comfortaa", cursive',
                    fontSize: '2.5rem',
                    fontWeight: 700,
                    color: '#E6B94D',
                },
                h2: {
                    fontFamily: '"Comfortaa", cursive',
                    fontSize: '2rem',
                    fontWeight: 700,
                    color: '#E6B94D',
                },
                h3: {
                    fontFamily: '"Comfortaa", cursive',
                    fontSize: '1.75rem',
                    fontWeight: 600,
                    color: '#E6B94D',
                },
                body1: {
                    fontSize: '1rem',
                    lineHeight: 1.6,
                },
                button: {
                    fontFamily: '"Quicksand", sans-serif',
                    fontWeight: 600,
                    textTransform: 'none',
                },
            },
            shape: {
                borderRadius: 12,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 20,
                            padding: '8px 16px',
                            '&:hover': {
                                boxShadow: '0 2px 8px 0 rgba(255, 209, 102, 0.5)',
                            },
                        },
                        contained: {
                            backgroundColor: '#FFD166',
                            color: '#5C4D3C',
                            '&:hover': {
                                backgroundColor: '#FFE085',
                            },
                        },
                        outlined: {
                            borderColor: '#FFD166',
                            color: '#5C4D3C',
                            '&:hover': {
                                borderColor: '#FFE085',
                                backgroundColor: 'rgba(255, 209, 102, 0.1)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#FFFFFF',
                            borderRadius: 16,
                            boxShadow: '0 4px 12px 0 rgba(255, 209, 102, 0.2)',
                            border: '1px solid rgba(255, 209, 102, 0.1)',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#FFF9E6',
                            color: '#5C4D3C',
                            boxShadow: '0 2px 8px 0 rgba(255, 209, 102, 0.2)',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#FFD166',
                                    borderRadius: 12,
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FFE085',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#E6B94D',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#8C7A6B',
                            },
                            '& .MuiInputBase-input': {
                                color: '#5C4D3C',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: 'rgba(255, 209, 102, 0.2)',
                            color: '#5C4D3C',
                        },
                        head: {
                            backgroundColor: '#FFF9E6',
                            color: '#E6B94D',
                            fontWeight: 700,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#FFD166',
            backgroundImage: 'linear-gradient(45deg, #FFD166 25%, #FFE085 25%, #FFE085 50%, #FFD166 50%, #FFD166 75%, #FFE085 75%, #FFE085 100%)',
            backgroundSize: '20px 20px',
        },
    },
    {
        name: 'The O.G.',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#e74c3c',
                },
                secondary: {
                    main: '#34495e',
                },
                background: {
                    default: '#343a40',
                    paper: '#34495e',
                },
                text: {
                    primary: '#ffffff',
                    secondary: '#cccccc',
                },
                specialButton: {
                    text: '#ffffff',
                },
            },
            typography: {
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: 16,
                h1: {
                    fontSize: '2.5rem',
                    fontWeight: 500,
                },
                h2: {
                    fontSize: '2rem',
                    fontWeight: 500,
                },
                h3: {
                    fontSize: '1.75rem',
                    fontWeight: 500,
                },
                h4: {
                    fontSize: '1.5rem',
                    fontWeight: 500,
                },
                h5: {
                    fontSize: '1.25rem',
                    fontWeight: 500,
                },
                h6: {
                    fontSize: '1rem',
                    fontWeight: 500,
                },
                button: {
                    textTransform: 'none',
                },
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: '0.25rem',
                            padding: '0.375rem 0.75rem',
                            fontSize: '1rem',
                            lineHeight: 1.5,
                        },
                        contained: {
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        },
                    },
                },
                MuiAppBar: {
                    defaultProps: {
                        color: 'primary',
                    },
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: '0.25rem',
                            boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
                            border: '1px solid rgba(0, 0, 0, 0.125)',
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        root: {
                            backgroundColor: 'rgba(0, 0, 0, 0.03)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0.25rem',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: '#dee2e6',
                        },
                        head: {
                            fontWeight: 700,
                            borderTop: '1px solid #dee2e6',
                        },
                    },
                },
            },
        }),
        icon: { bgcolor: '#2196f3', border: '2px solid #e74c3c' },
    },
    {
        name: 'Cyberpunk',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#00ffff',
                    light: '#66ffff',
                    dark: '#00cccc',
                },
                secondary: {
                    main: '#ff00ff',
                    light: '#ff66ff',
                    dark: '#cc00cc',
                },
                background: {
                    default: '#0f0f2d',
                    paper: '#1a1a3a',
                },
                text: {
                    primary: '#ffffff',
                    secondary: '#00ffff',
                },
                error: {
                    main: '#ff3333',
                },
                warning: {
                    main: '#ffaa00',
                },
                info: {
                    main: '#00ffff',
                },
                success: {
                    main: '#33ff33',
                },
                specialButton: {
                    text: '#ff00ff',
                },
            },
            typography: {
                fontFamily: '"Blender Pro", "Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Cyberpunk", "Blender Pro", sans-serif',
                    fontSize: '3rem',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '0.1em',
                    color: '#00ffff',
                },
                h2: {
                    fontFamily: '"Cyberpunk", "Blender Pro", sans-serif',
                    fontSize: '2.5rem',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '0.05em',
                    color: '#00ffff',
                },
                h3: {
                    fontFamily: '"Cyberpunk", "Blender Pro", sans-serif',
                    fontSize: '2rem',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    color: '#00ffff',
                },
                body1: {
                    fontSize: '1rem',
                    lineHeight: 1.6,
                },
                button: {
                    fontFamily: '"Blender Pro", sans-serif',
                    fontWeight: 600,
                    letterSpacing: '0.05em',
                    textTransform: 'uppercase',
                },
            },
            shape: {
                borderRadius: 0,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderWidth: 2,
                            '&:hover': {
                                boxShadow: '0 0 10px 0 #00ffff, 0 0 20px 0 #00ffff',
                            },
                        },
                        contained: {
                            backgroundColor: '#00ffff',
                            color: '#0f0f2d',
                            '&:hover': {
                                backgroundColor: '#66ffff',
                            },
                        },
                        outlined: {
                            borderColor: '#00ffff',
                            color: '#00ffff',
                            '&:hover': {
                                borderColor: '#66ffff',
                                backgroundColor: 'rgba(0, 255, 255, 0.1)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#1a1a3a',
                            borderRadius: 0,
                            border: '2px solid #00ffff',
                            boxShadow: '0 0 20px 0 rgba(0, 255, 255, 0.3)',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#0f0f2d',
                            backgroundImage: 'linear-gradient(90deg, #0f0f2d, #1a1a3a)',
                            borderBottom: '2px solid #00ffff',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#00ffff',
                                    borderWidth: 2,
                                },
                                '&:hover fieldset': {
                                    borderColor: '#66ffff',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#ff00ff',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#00ffff',
                            },
                            '& .MuiInputBase-input': {
                                color: '#ffffff',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: '#00ffff',
                        },
                        head: {
                            backgroundColor: '#1a1a3a',
                            color: '#00ffff',
                            fontWeight: 700,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#00ffff',
            backgroundImage: 'linear-gradient(45deg, #00ffff 25%, #ff00ff 25%, #ff00ff 50%, #00ffff 50%, #00ffff 75%, #ff00ff 75%, #ff00ff 100%)',
            backgroundSize: '20px 20px',
            boxShadow: '0 0 10px #00ffff, 0 0 20px #ff00ff',
        },
    },
    {
        name: 'Skull and Bones',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#b0a091', // Bone color
                    light: '#d8cec3',
                    dark: '#8c7b6b',
                },
                secondary: {
                    main: '#540804', // Dark blood red
                    light: '#801208',
                    dark: '#3c0602',
                },
                background: {
                    default: '#1c1c1c', // Very dark gray
                    paper: '#2c2c2c',
                },
                text: {
                    primary: '#e0e0e0',
                    secondary: '#b0b0b0',
                },
                error: {
                    main: '#ff3d00',
                },
                warning: {
                    main: '#ffa000',
                },
                info: {
                    main: '#64b5f6',
                },
                success: {
                    main: '#4caf50',
                },
                specialButton: {
                    text: '#ffffff',
                },
            },
            typography: {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Playfair Display", serif',
                    fontSize: '2.5rem',
                    fontWeight: 700,
                    color: '#b0a091',
                    letterSpacing: '0.02em',
                },
                h2: {
                    fontFamily: '"Playfair Display", serif',
                    fontSize: '2rem',
                    fontWeight: 700,
                    color: '#b0a091',
                    letterSpacing: '0.02em',
                },
                h3: {
                    fontFamily: '"Playfair Display", serif',
                    fontSize: '1.75rem',
                    fontWeight: 600,
                    color: '#b0a091',
                },
                body1: {
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: '1rem',
                    lineHeight: 1.6,
                    color: '#e0e0e0',
                },
                button: {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    fontWeight: 700,
                    fontSize: '0.875rem',
                    textTransform: 'uppercase',
                    letterSpacing: '0.05em',
                },
            },
            shape: {
                borderRadius: 2,
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&family=Roboto+Condensed:wght@700&display=swap');
                    body {
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60"><path d="M30 5 L55 50 L5 50 Z" fill="%23333333" fill-opacity="0.1"/></svg>');
                        background-size: 60px 60px;
                    }
                `,
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 2,
                            border: '1px solid #b0a091',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 4px 8px rgba(176, 160, 145, 0.5)',
                            },
                        },
                        contained: {
                            backgroundColor: '#b0a091',
                            color: '#1c1c1c',
                            '&:hover': {
                                backgroundColor: '#8c7b6b',
                            },
                        },
                        outlined: {
                            borderColor: '#b0a091',
                            color: '#b0a091',
                            '&:hover': {
                                backgroundColor: 'rgba(176, 160, 145, 0.1)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#2c2c2c',
                            border: '1px solid #b0a091',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                            '&:hover': {
                                boxShadow: '0 8px 16px rgba(176, 160, 145, 0.2)',
                            },
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#1c1c1c',
                            backgroundImage: 'linear-gradient(rgba(176, 160, 145, 0.05) 1px, transparent 1px), linear-gradient(90deg, rgba(176, 160, 145, 0.05) 1px, transparent 1px)',
                            backgroundSize: '20px 20px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#b0a091',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#d8cec3',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#8c7b6b',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#b0a091',
                            },
                            '& .MuiInputBase-input': {
                                color: '#e0e0e0',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: 'rgba(176, 160, 145, 0.2)',
                        },
                        head: {
                            backgroundColor: 'rgba(176, 160, 145, 0.1)',
                            color: '#b0a091',
                            fontWeight: 700,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#1c1c1c',
            backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'40\' height=\'40\' viewBox=\'0 0 40 40\'><path d=\'M20,5 L35,35 L20,30 L5,35 Z\' fill=\'%23b0a091\'/></svg>")',
            backgroundSize: 'cover',
            borderRadius: '2px',
            border: '1px solid #b0a091',
        },
    },
    {
        name: 'Covert Ops',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#8B0000',
                    light: '#A52A2A',
                    dark: '#660000',
                },
                secondary: {
                    main: '#4A0000',
                    light: '#660000',
                    dark: '#2E0000',
                },
                background: {
                    default: '#0A0000',
                    paper: '#1A0000',
                },
                text: {
                    primary: '#FF3333',
                    secondary: '#B22222',
                },
                error: {
                    main: '#FF0000',
                },
                warning: {
                    main: '#FF4500',
                },
                info: {
                    main: '#8B0000',
                },
                success: {
                    main: '#B22222',
                },
                specialButton: {
                    text: '#FF3333',
                },
            },
            typography: {
                fontFamily: '"Roboto", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    fontSize: '2.5rem',
                    fontWeight: 700,
                    letterSpacing: '0.05em',
                    color: '#FF3333',
                },
                h2: {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    fontSize: '2rem',
                    fontWeight: 700,
                    letterSpacing: '0.03em',
                    color: '#FF3333',
                },
                h3: {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    fontSize: '1.75rem',
                    fontWeight: 600,
                    color: '#FF3333',
                },
                body1: {
                    fontSize: '1rem',
                    lineHeight: 1.6,
                    color: '#FF3333',
                },
                button: {
                    fontFamily: '"Roboto Condensed", sans-serif',
                    fontWeight: 500,
                    letterSpacing: '0.05em',
                    textTransform: 'uppercase',
                },
            },
            shape: {
                borderRadius: 4,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderWidth: 1,
                            '&:hover': {
                                boxShadow: '0 0 8px 0 rgba(255, 51, 51, 0.5)',
                            },
                        },
                        contained: {
                            backgroundColor: '#4A0000',
                            '&:hover': {
                                backgroundColor: '#660000',
                            },
                        },
                        outlined: {
                            borderColor: '#8B0000',
                            color: '#FF3333',
                            '&:hover': {
                                borderColor: '#FF3333',
                                backgroundColor: 'rgba(139, 0, 0, 0.1)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#1A0000',
                            border: '1px solid #4A0000',
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#0A0000',
                            borderBottom: '1px solid #4A0000',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#4A0000',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#8B0000',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF3333',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#B22222',
                            },
                            '& .MuiInputBase-input': {
                                color: '#FF3333',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: '#4A0000',
                            color: '#FF3333',
                        },
                        head: {
                            backgroundColor: '#1A0000',
                            color: '#FF3333',
                            fontWeight: 700,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#8B0000',
            backgroundImage: 'radial-gradient(circle, #A52A2A 10%, #660000 90%)',
            boxShadow: 'inset 0 0 5px #FF3333',
        },
    },
    {
        name: 'Colorblind Friendly',
        theme: createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: '#0072B2',
                    light: '#56B4E9',
                    dark: '#004E8C',
                },
                secondary: {
                    main: '#E69F00',
                    light: '#F0B323',
                    dark: '#B07B00',
                },
                error: {
                    main: '#D55E00',
                },
                warning: {
                    main: '#F0E442',
                },
                info: {
                    main: '#56B4E9',
                },
                success: {
                    main: '#009E73',
                },
                background: {
                    default: '#F0F0F0',
                    paper: '#FFFFFF',
                },
                text: {
                    primary: '#000000',
                    secondary: '#56565A',
                },
                specialButton: {
                    text: '#D55E00',
                },
            },
            typography: {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: 16,
                h1: {
                    fontSize: '2.5rem',
                    fontWeight: 700,
                },
                h2: {
                    fontSize: '2rem',
                    fontWeight: 700,
                },
                h3: {
                    fontSize: '1.75rem',
                    fontWeight: 600,
                },
                body1: {
                    fontSize: '1rem',
                    lineHeight: 1.5,
                },
                button: {
                    fontWeight: 500,
                    textTransform: 'none',
                },
            },
            shape: {
                borderRadius: 4,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 4,
                            padding: '10px 20px',
                            fontWeight: 500,
                        },
                        contained: {
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            border: '1px solid #E0E0E0',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            borderBottom: '1px solid #E0E0E0',
                        },
                    },
                },
                MuiChip: {
                    styleOverrides: {
                        root: {
                            borderRadius: 4,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#0072B2',
            backgroundImage: 'linear-gradient(45deg, #0072B2 25%, #56B4E9 25%, #56B4E9 50%, #0072B2 50%, #0072B2 75%, #56B4E9 75%, #56B4E9 100%)',
            backgroundSize: '20px 20px',
        },
    },
    {
        name: 'Pink Pink',
        theme: createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: '#FF69B4',
                    light: '#FFB6C1',
                    dark: '#C71585',
                },
                secondary: {
                    main: '#DDA0DD',
                    light: '#E6E6FA',
                    dark: '#9932CC',
                },
                background: {
                    default: '#FFF0F5',
                    paper: '#FFFFFF',
                },
                text: {
                    primary: '#4B0082',
                    secondary: '#8E4585',
                },
                specialButton: {
                    text: '#9932CC',
                },
            },
            typography: {
                fontFamily: '"Playfair Display", serif',
                h1: {
                    fontFamily: '"Lobster", cursive',
                },
                h2: {
                    fontFamily: '"Lobster", cursive',
                },
                button: {
                    fontFamily: '"Roboto", sans-serif',
                    textTransform: 'none',
                },
            },
            shape: {
                borderRadius: 12,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 20,
                            padding: '10px 20px',
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: 16,
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        },
                    },
                },
            },
        }),
        icon: { bgcolor: '#FF69B4' },
    },
    {
        name: 'High Contrast',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#FFFF00',
                },
                secondary: {
                    main: '#00FFFF',
                },
                background: {
                    default: '#000000',
                    paper: '#0D0D0D',
                },
                text: {
                    primary: '#FFFFFF',
                    secondary: '#FFFF00',
                },
                error: {
                    main: '#FF0000',
                },
                warning: {
                    main: '#FFA500',
                },
                info: {
                    main: '#00FFFF',
                },
                success: {
                    main: '#00FF00',
                },
                specialButton: {
                    text: '#FF00FF',
                },
            },
            typography: {
                fontFamily: '"Roboto Mono", monospace',
                fontSize: 16,
                h1: {
                    fontSize: '2.5rem',
                    fontWeight: 700,
                },
                h2: {
                    fontSize: '2rem',
                    fontWeight: 700,
                },
                button: {
                    fontWeight: 700,
                },
            },
            shape: {
                borderRadius: 0,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderWidth: 2,
                            '&:hover': {
                                borderWidth: 2,
                            },
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            borderWidth: 2,
                        },
                    },
                },
            },
        }),
        icon: { bgcolor: '#FFFF00', border: '1px solid #000000' },
    },
    {
        name: 'Neon Night',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#00ff00',
                    light: '#66ff66',
                    dark: '#00cc00',
                },
                secondary: {
                    main: '#ff00ff',
                    light: '#ff66ff',
                    dark: '#cc00cc',
                },
                background: {
                    default: '#0a0a0a',
                    paper: '#1a1a1a',
                },
                text: {
                    primary: '#ffffff',
                    secondary: '#00ffff',
                },
                error: {
                    main: '#ff3333',
                },
                warning: {
                    main: '#ffaa00',
                },
                info: {
                    main: '#00ffff',
                },
                success: {
                    main: '#00ff00',
                },
                specialButton: {
                    text: '#ff00ff',
                },
            },
            typography: {
                fontFamily: '"Orbitron", "Roboto", "Helvetica", "Arial", sans-serif',
                h1: {
                    fontWeight: 700,
                    letterSpacing: '0.1em',
                    textTransform: 'uppercase',
                },
                h2: {
                    fontWeight: 700,
                    letterSpacing: '0.05em',
                },
                button: {
                    fontWeight: 700,
                    letterSpacing: '0.1em',
                    textTransform: 'uppercase',
                },
            },
            shape: {
                borderRadius: 0,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderWidth: 2,
                            '&:hover': {
                                boxShadow: '0 0 10px 0 #00ff00',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            backgroundImage: 'linear-gradient(45deg, #1a1a1a 25%, #2a2a2a 25%, #2a2a2a 50%, #1a1a1a 50%, #1a1a1a 75%, #2a2a2a 75%, #2a2a2a 100%)',
                            backgroundSize: '10px 10px',
                            border: '1px solid #00ff00',
                            boxShadow: '0 0 10px 0 rgba(0, 255, 0, 0.5)',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundImage: 'linear-gradient(to right, #0a0a0a, #1a1a1a)',
                            borderBottom: '1px solid #00ff00',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#00ff00',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#00ffff',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#ff00ff',
                                },
                            },
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#00ff00',
            border: '1px solid #000000',
            boxShadow: 'inset 0 0 5px #ffffff',
        },
    },
    {
        name: 'Wooden',
        theme: createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: '#5D4037',
                    light: '#8D6E63',
                    dark: '#3E2723',
                },
                secondary: {
                    main: '#795548',
                    light: '#A1887F',
                    dark: '#4E342E',
                },
                background: {
                    default: '#EFEBE9',
                    paper: '#D7CCC8',
                },
                text: {
                    primary: '#3E2723',
                    secondary: '#5D4037',
                },
                error: {
                    main: '#B71C1C',
                },
                warning: {
                    main: '#FF6F00',
                },
                info: {
                    main: '#1565C0',
                },
                success: {
                    main: '#2E7D32',
                },
                specialButton: {
                    text: '#FF6F00',
                },
            },
            typography: {
                fontFamily: '"Roboto Slab", serif',
                h1: {
                    fontFamily: '"Merriweather", serif',
                    fontWeight: 700,
                },
                h2: {
                    fontFamily: '"Merriweather", serif',
                    fontWeight: 700,
                },
                h3: {
                    fontFamily: '"Merriweather", serif',
                    fontWeight: 600,
                },
                button: {
                    fontFamily: '"Roboto Slab", serif',
                    fontWeight: 500,
                },
            },
            shape: {
                borderRadius: 4,
            },
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundImage: 'url("/wood-texture.png")',
                            backgroundBlendMode: 'overlay',
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            textTransform: 'none',
                            borderRadius: 4,
                            padding: '8px 16px',
                        },
                        contained: {
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                            border: '1px solid #A1887F',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundImage: 'none',
                        },
                    },
                },
            },
        }),
        icon: { bgcolor: '#5D4037' },
    },
    {
        name: 'Wealth',
        theme: createTheme({
            palette: {
                mode: 'light',
                primary: {
                    main: '#C9B037',
                    light: '#D4AF37',
                    dark: '#996515',
                },
                secondary: {
                    main: '#215E7C',
                    light: '#3A7CA5',
                    dark: '#0D2C54',
                },
                background: {
                    default: '#F5F5F5',
                    paper: '#FFFFFF',
                },
                text: {
                    primary: '#2C3E50',
                    secondary: '#34495E',
                },
                success: {
                    main: '#27AE60',
                },
                error: {
                    main: '#E74C3C',
                },
                specialButton: {
                    text: '#E74C3C',
                },
            },
            typography: {
                fontFamily: '"Cormorant Garamond", "Times New Roman", serif',
                h1: {
                    fontFamily: '"Playfair Display", serif',
                    fontWeight: 700,
                },
                h2: {
                    fontFamily: '"Playfair Display", serif',
                    fontWeight: 700,
                },
                h3: {
                    fontFamily: '"Playfair Display", serif',
                    fontWeight: 600,
                },
                button: {
                    fontFamily: '"Montserrat", sans-serif',
                    fontWeight: 500,
                },
            },
            shape: {
                borderRadius: 4,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 0,
                            padding: '10px 24px',
                            textTransform: 'none',
                        },
                        contained: {
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                            border: '1px solid rgba(201, 176, 55, 0.1)',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            borderBottom: '1px solid rgba(201, 176, 55, 0.2)',
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#C9B037',
            backgroundImage: 'linear-gradient(45deg, #C9B037 25%, #D4AF37 25%, #D4AF37 50%, #C9B037 50%, #C9B037 75%, #D4AF37 75%, #D4AF37 100%)',
            backgroundSize: '20px 20px',
        },
    },
    {
        name: 'Cosmic Fusion',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#7B68EE',
                    light: '#9683F0',
                    dark: '#5A4ACB',
                },
                secondary: {
                    main: '#FF6B6B',
                    light: '#FF8E8E',
                    dark: '#E54B4B',
                },
                background: {
                    default: '#0F1624',
                    paper: '#1A2333',
                },
                text: {
                    primary: '#FFFFFF',
                    secondary: '#B0C4DE',
                },
                error: {
                    main: '#FF4757',
                },
                warning: {
                    main: '#FFA502',
                },
                info: {
                    main: '#00D2FF',
                },
                success: {
                    main: '#2ED573',
                },
                specialButton: {
                    text: '#FFD700',
                },
            },
            typography: {
                fontFamily: '"Exo 2", "Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Orbitron", sans-serif',
                    fontSize: '3rem',
                    fontWeight: 700,
                    letterSpacing: '0.05em',
                    background: 'linear-gradient(45deg, #7B68EE, #00D2FF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                },
                h2: {
                    fontFamily: '"Orbitron", sans-serif',
                    fontSize: '2.5rem',
                    fontWeight: 600,
                    letterSpacing: '0.04em',
                    color: '#7B68EE',
                },
                h3: {
                    fontFamily: '"Exo 2", sans-serif',
                    fontSize: '2rem',
                    fontWeight: 600,
                    color: '#B0C4DE',
                },
                body1: {
                    fontSize: '1rem',
                    lineHeight: 1.6,
                    color: '#FFFFFF',
                },
                button: {
                    fontFamily: '"Exo 2", sans-serif',
                    fontWeight: 600,
                    letterSpacing: '0.05em',
                    textTransform: 'uppercase',
                },
            },
            shape: {
                borderRadius: 8,
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;600;700&family=Orbitron:wght@600;700&display=swap');
                    
                    @keyframes cosmicShimmer {
                        0% { background-position: 0% 50%; }
                        50% { background-position: 100% 50%; }
                        100% { background-position: 0% 50%; }
                    }
                    
                    body {
                        background: linear-gradient(45deg, #0F1624, #1A2333, #2C3E50);
                        background-size: 200% 200%;
                        animation: cosmicShimmer 15s ease infinite;
                    }
                    
                    ::-webkit-scrollbar {
                        width: 10px;
                    }
                    ::-webkit-scrollbar-track {
                        background: #1A2333;
                    }
                    ::-webkit-scrollbar-thumb {
                        background: #7B68EE;
                        border-radius: 5px;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: #5A4ACB;
                    }
                `,
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 25,
                            padding: '10px 24px',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-3px)',
                                boxShadow: '0 7px 14px rgba(123, 104, 238, 0.4)',
                            },
                        },
                        contained: {
                            background: 'linear-gradient(45deg, #7B68EE, #9683F0)',
                            color: '#FFFFFF',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #9683F0, #7B68EE)',
                            },
                        },
                        outlined: {
                            borderColor: '#7B68EE',
                            color: '#7B68EE',
                            '&:hover': {
                                borderColor: '#9683F0',
                                backgroundColor: 'rgba(123, 104, 238, 0.1)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            background: 'rgba(26, 35, 51, 0.7)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(123, 104, 238, 0.3)',
                            boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 12px 48px 0 rgba(123, 104, 238, 0.3)',
                            },
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            background: 'rgba(15, 22, 36, 0.8)',
                            backdropFilter: 'blur(10px)',
                            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                            borderBottom: '1px solid rgba(123, 104, 238, 0.3)',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(123, 104, 238, 0.5)',
                                    transition: 'all 0.3s ease-in-out',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(123, 104, 238, 0.7)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#7B68EE',
                                    boxShadow: '0 0 0 3px rgba(123, 104, 238, 0.3)',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#B0C4DE',
                            },
                            '& .MuiInputBase-input': {
                                color: '#FFFFFF',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: 'rgba(123, 104, 238, 0.2)',
                        },
                        head: {
                            backgroundColor: 'rgba(123, 104, 238, 0.1)',
                            color: '#B0C4DE',
                            fontWeight: 600,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#0F1624',
            backgroundImage: 'radial-gradient(circle, #7B68EE 10%, #00D2FF 90%)',
            borderRadius: '50%',
            boxShadow: '0 0 20px #7B68EE, 0 0 60px #00D2FF',
        },
    },
    {
        name: 'LSD Trip',
        theme: createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#FF00FF',
                    light: '#FF69B4',
                    dark: '#8B008B',
                },
                secondary: {
                    main: '#00FFFF',
                    light: '#E0FFFF',
                    dark: '#008B8B',
                },
                background: {
                    default: '#000000',
                    paper: 'rgba(255, 255, 255, 0.1)',
                },
                text: {
                    primary: '#FFFFFF',
                    secondary: '#FFFF00',
                },
                error: {
                    main: '#FF4500',
                },
                warning: {
                    main: '#FFA500',
                },
                info: {
                    main: '#1E90FF',
                },
                success: {
                    main: '#00FF00',
                },
                specialButton: {
                    text: '#FF1493',
                },
            },
            typography: {
                fontFamily: '"Groovy", "Helvetica", "Arial", sans-serif',
                fontSize: 16,
                h1: {
                    fontFamily: '"Groovy", "Helvetica", "Arial", sans-serif',
                    fontSize: '3.5rem',
                    fontWeight: 700,
                    background: 'linear-gradient(45deg, #FF00FF, #00FFFF, #FFFF00, #FF00FF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '2px 2px 4px rgba(255, 255, 255, 0.5)',
                },
                h2: {
                    fontFamily: '"Groovy", "Helvetica", "Arial", sans-serif',
                    fontSize: '2.8rem',
                    fontWeight: 600,
                    color: '#FF00FF',
                    textShadow: '2px 2px 4px #00FFFF',
                },
                h3: {
                    fontFamily: '"Groovy", "Helvetica", "Arial", sans-serif',
                    fontSize: '2.3rem',
                    fontWeight: 500,
                    color: '#FFFF00',
                    textShadow: '2px 2px 4px #FF00FF',
                },
                body1: {
                    fontSize: '1.1rem',
                    lineHeight: 1.8,
                    color: '#FFFFFF',
                },
                button: {
                    fontFamily: '"Groovy", sans-serif',
                    fontWeight: 600,
                    fontSize: '1.2rem',
                },
            },
            shape: {
                borderRadius: 20,
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    @keyframes psychedelicBackground {
                        0% { background-position: 0% 50% }
                        50% { background-position: 100% 50% }
                        100% { background-position: 0% 50% }
                    }
                    @keyframes pulseGlow {
                        0% { filter: drop-shadow(0 0 5px #FF00FF) }
                        50% { filter: drop-shadow(0 0 20px #00FFFF) }
                        100% { filter: drop-shadow(0 0 5px #FF00FF) }
                    }
                    @keyframes wobble {
                        0% { transform: rotate(0deg) }
                        25% { transform: rotate(-5deg) }
                        75% { transform: rotate(5deg) }
                        100% { transform: rotate(0deg) }
                    }
                    body {
                        background: linear-gradient(-45deg, #FF00FF, #00FFFF, #FFFF00, #FF1493, #1E90FF);
                        background-size: 400% 400%;
                        animation: psychedelicBackground 15s ease infinite;
                        overflow-x: hidden;
                    }
                    * {
                        transition: all 0.3s ease-in-out;
                    }
                `,
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: 30,
                            animation: 'pulseGlow 2s infinite',
                            '&:hover': {
                                transform: 'scale(1.1) rotate(5deg)',
                            },
                        },
                        contained: {
                            background: 'linear-gradient(45deg, #FF00FF, #00FFFF)',
                            color: '#FFFFFF',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #00FFFF, #FF00FF)',
                            },
                        },
                        outlined: {
                            borderColor: '#FF00FF',
                            color: '#FFFF00',
                            '&:hover': {
                                borderColor: '#00FFFF',
                                backgroundColor: 'rgba(255, 0, 255, 0.2)',
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            background: 'rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(10px)',
                            border: '2px solid',
                            borderImageSlice: 1,
                            borderImageSource: 'linear-gradient(45deg, #FF00FF, #00FFFF)',
                            animation: 'wobble 3s ease-in-out infinite',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
                            animation: 'psychedelicBackground 10s ease infinite',
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#FF00FF',
                                    borderWidth: 2,
                                },
                                '&:hover fieldset': {
                                    borderColor: '#00FFFF',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FFFF00',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#FFFF00',
                            },
                            '& .MuiInputBase-input': {
                                color: '#FFFFFF',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        head: {
                            background: 'linear-gradient(45deg, #FF00FF, #00FFFF)',
                            color: '#FFFFFF',
                            fontWeight: 700,
                        },
                    },
                },
            },
        }),
        icon: {
            bgcolor: '#000000',
            backgroundImage: 'linear-gradient(45deg, #FF00FF, #00FFFF, #FFFF00, #FF1493)',
            animation: 'psychedelicBackground 5s ease infinite, pulseGlow 2s infinite',
            borderRadius: '50%',
        },
    },
];

export default themeDefinitions;