import React, { useState, useCallback } from 'react';
import { Typography, Box, Chip, Paper, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { CheckCircle, HelpOutline, Restaurant, Category } from '@mui/icons-material';
import ExpandableCard from "../../../../components/list/ExpandableCard";
import EditField from "../../../../components/form/EditField";
import useApi from "../../../../components/ApiService";
import NutritionEstimatesDisplay from "../nutrition/NutritionEstimatesDisplay";
import { startCase } from 'lodash';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const ContentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const foodGroups = ['Grain', 'Fruit', 'Vegetable', 'Protein', 'Dairy', 'Other'];

function FoodStapleItem({ sourceRecord, onSourceUpdate }) {
    const { apiCall } = useApi();
    const [staple, setStaple] = useState(sourceRecord.content.content);
    const [editingField, setEditingField] = useState(null);
    const [tempEditValue, setTempEditValue] = useState('');

    const handleDoubleClick = (field) => {
        setEditingField(field);
        setTempEditValue(staple[field] || '');
    };

    const handleCancelEdit = () => {
        setEditingField(null);
        setTempEditValue('');
    };

    const handleSubmitEdit = useCallback(async (field, value) => {
        try {
            let updatedStaple = { ...staple, [field]: value };

            const requestBody = { id: sourceRecord.content.id, content: updatedStaple };
            const response = await apiCall(`/content/${sourceRecord.content.id}`, {method: 'PUT', data: requestBody});

            if (response.data && response.data.content[0].content) {
                setStaple(response.data.content[0].content);
                if (onSourceUpdate) {
                    const updatedSource = {
                        ...sourceRecord,
                        content: {
                            ...sourceRecord.content,
                            content: response.data.content[0].content
                        }
                    };
                    onSourceUpdate(updatedSource);
                }
            } else {
                setStaple(updatedStaple);
            }

            setEditingField(null);
            setTempEditValue('');
        } catch (error) {
            console.error('Error updating food item:', error);
        }
    }, [staple, sourceRecord.content.id, apiCall, onSourceUpdate]);

    const handleNutritionUpdate = useCallback(async (updatedNutrition) => {
        try {
            const updatedStaple = { ...staple, nutrition_estimate: updatedNutrition };
            const requestBody = { id: sourceRecord.content.id, content: updatedStaple };
            const response = await apiCall(`/content/${sourceRecord.content.id}`, {method: 'PUT', data: requestBody});

            if (response.data && response.data.content[0].content) {
                setStaple(response.data.content[0].content);
                if (onSourceUpdate) {
                    const updatedSource = {
                        ...sourceRecord,
                        content: {
                            ...sourceRecord.content,
                            content: response.data.content[0].content
                        }
                    };
                    onSourceUpdate(updatedSource);
                }
            } else {
                setStaple(updatedStaple);
            }
        } catch (error) {
            console.error('Error updating nutrition estimate:', error);
        }
    }, [staple, sourceRecord.content.id, apiCall, onSourceUpdate]);

    const renderEditableField = (field, value, label, options = null) => {
        if (editingField === field) {
            if (options) {
                return (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id={`${field}-label`}>{label}</InputLabel>
                        <Select
                            labelId={`${field}-label`}
                            value={tempEditValue || ''}
                            onChange={(e) => setTempEditValue(e.target.value)}
                            onBlur={() => handleSubmitEdit(field, tempEditValue)}
                            label={label}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
            return (
                <EditField
                    field={label}
                    value={tempEditValue}
                    onSubmit={(newValue) => handleSubmitEdit(field, newValue)}
                    onCancel={handleCancelEdit}
                />
            );
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'action.hover',
                    },
                }}
                onDoubleClick={() => handleDoubleClick(field)}
            >
                <Typography variant="body1">
                    <strong>{label}:</strong> {value || 'N/A'}
                </Typography>
            </Box>
        );
    };

    return (
        <ExpandableCard title={startCase(staple.name)}>
            <StyledPaper>
                <ContentBox>
                    {renderEditableField('name', startCase(staple.name), 'Name')}
                    {renderEditableField('description', staple.description, 'Description')}
                    {renderEditableField('quantity', staple.quantity, 'Quantity')}
                    {renderEditableField('unit', staple.unit, 'Unit')}
                    {renderEditableField('food_group', staple.food_group, 'Food Group', foodGroups)}
                    {renderEditableField('type', staple.type, 'Type')}
                    {renderEditableField('context', staple.context, 'Context')}
                </ContentBox>

                {staple.nutrition_estimate && (
                    <NutritionEstimatesDisplay
                        nutritionEstimate={staple.nutrition_estimate}
                        onUpdate={handleNutritionUpdate}
                    />
                )}

                <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap' }}>
                    <Chip
                        icon={<CheckCircle />}
                        label="Staple Item"
                        color="primary"
                        size="small"
                    />
                    <Tooltip title="Recommended quantity to keep on hand">
                        <Chip
                            icon={<HelpOutline />}
                            label={`${staple.quantity || 0} ${staple.unit || 'N/A'}`}
                            color="secondary"
                            size="small"
                        />
                    </Tooltip>
                    <Chip
                        icon={<Restaurant />}
                        label={staple.food_group || 'No Group'}
                        color="info"
                        size="small"
                    />
                    <Chip
                        icon={<Category />}
                        label={staple.type || 'No Type'}
                        color="default"
                        size="small"
                    />
                </Box>
            </StyledPaper>
        </ExpandableCard>
    );
}

export default FoodStapleItem;