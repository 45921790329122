import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

const CustomNode = memo(({ data }) => {
    const types = new Set(data.type);

    const renderHandles = () => {
        if (types.has('Root')) {
            return <Handle type="source" position={Position.Bottom}/>;
        }
        if (types.has('Source')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('System') && types.has('Source')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('System') && types.has('Report')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Context')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Collection')) {
            return (
                <>
                    <Handle type="target" position={Position.Top}/>
                    <Handle type="source" position={Position.Bottom}/>
                </>
            );
        }
        if (types.has('System') && types.has('Collection')) {
            return (
                <>
                    <Handle type="target" position={Position.Top}/>
                    <Handle type="source" position={Position.Bottom}/>
                </>
            );
        }
        if (types.has('System') && types.has('Application')) {
            return (
                <>
                    <Handle type="target" position={Position.Top}/>
                    <Handle type="source" position={Position.Bottom}/>
                </>
            );
        }
        if (types.has('Date')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Recipe')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Meal')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Chat')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Food Item')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Food')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Assignment')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Terminology')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Question')) {
            return (
                <>
                    <Handle type="source" position={Position.Top}/>
                    <Handle type="target" position={Position.Top}/>
                </>
            );
        }
        if (types.has('Cluster')) {
            return <Handle type="target" position={Position.Top}/>;
        }
        return null;
    };

    return (
        <div className={`custom-node ${data.type.map(type => type.replace(/\s+/g, '_')).join(' ')}`}>
            {data.label}
            {renderHandles()}
        </div>
    )
});

export default CustomNode;
