import React, { useState, useCallback } from 'react';
import { Button, Card, CardContent, CircularProgress, Typography, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import useApi from "../ApiService";

const useAnalysis = () => {
    const { apiCall } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const performAnalysis = useCallback(async (sourceIds, query, context, specialInstructions) => {
        setIsLoading(true);
        setError(null);
        try {
            const endpoint = `/ai/rag/source?ids=${sourceIds.join(',')}`;
            const response = await apiCall(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    query,
                    context,
                    specialInstructions
                })
            });
            setIsLoading(false);
            return response.data.generation;
        } catch (error) {
            console.error('Error performing analysis:', error);
            setError('Failed to perform analysis. Please try again.');
            setIsLoading(false);
            return null;
        }
    }, [apiCall]);

    return { performAnalysis, isLoading, error };
};

const RAGSimpleSpecificSources = ({ sourceIds, query, context, specialInstructions }) => {
    const [analysis, setAnalysis] = useState(null);
    const [isAnalysisVisible, setIsAnalysisVisible] = useState(false);

    const { performAnalysis, isLoading, error } = useAnalysis();

    const handleAnalysis = useCallback(async () => {
        if (analysis) {
            setIsAnalysisVisible(!isAnalysisVisible);
            return;
        }

        const result = await performAnalysis(sourceIds, query, context, specialInstructions);
        if (result) {
            setAnalysis(result);
            setIsAnalysisVisible(true);
        }
    }, [sourceIds, query, context, specialInstructions, performAnalysis, analysis, isAnalysisVisible]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleAnalysis}
                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                disabled={isLoading}
                aria-label="Perform Analysis"
            >
                {isLoading ? 'Analyzing...' : 'Perform Analysis'}
            </Button>

            {error && (
                <Typography color="error" variant="body2">
                    Error: {error}
                </Typography>
            )}

            {isAnalysisVisible && analysis && (
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Analysis Results
                        </Typography>
                        <Typography component="div">
                            <ReactMarkdown>{analysis}</ReactMarkdown>
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default RAGSimpleSpecificSources;