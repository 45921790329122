import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, Card, CardContent, CircularProgress, Typography, Box, TextField } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import useApi from "../ApiService";

const useGenericQueryChat = () => {
    const { apiCall } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const queryApi = useCallback(async (ids, query, chatHistory, context, specialInstructions) => {
        setIsLoading(true);
        setError(null);
        try {
            const endpoint = `/ai/rag/source?ids=${ids.join(',')}`;
            const response = await apiCall(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    query,
                    context,
                    specialInstructions,
                    content: "",
                    chatHistory: chatHistory.map(entry => ({
                        prompt: entry.prompt,
                        response: entry.response,
                        uniqueReferences: entry.uniqueReferences || []
                    }))
                })
            });
            setIsLoading(false);
            return response.data.generation;
        } catch (error) {
            console.error('Error querying API:', error);
            setError('Failed to process your query. Please try again.');
            setIsLoading(false);
            return null;
        }
    }, [apiCall]);

    return { queryApi, isLoading, error };
};

const RAGSimpleSpecificSourcesChat = ({ ids, context, specialInstructions, title, subtitle }) => {
    const [query, setQuery] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const { queryApi, isLoading, error } = useGenericQueryChat();
    const chatBoxRef = useRef(null);

    const handleQuerySubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!query.trim()) return;

        const response = await queryApi(ids, query, chatHistory, context, specialInstructions);
        if (response) {
            const newEntry = {
                prompt: query,
                response: response,
                uniqueReferences: []
            };
            setChatHistory(prev => [newEntry, ...prev]);
            setQuery('');
        }
    }, [query, ids, queryApi, chatHistory, context, specialInstructions]);

    const handleClearChat = useCallback(() => {
        setChatHistory([]);
        setQuery('');
    }, []);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = 0;
        }
    }, [chatHistory]);

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    {title}{' '}
                    {subtitle && (
                        <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontWeight: 'normal' }}
                        >
                            ({subtitle})
                        </Typography>
                    )}
                </Typography>
                <Box ref={chatBoxRef} sx={{ maxHeight: 300, overflowY: 'auto', mb: 2 }}>
                    {chatHistory.map((entry, index) => (
                        <Box key={index} sx={{ mb: 2, animation: index === 0 ? 'fadeIn 0.5s ease-in' : 'none' }}>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                    fontSize: '1.1rem'
                                }}
                            >
                                You:
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                {entry.prompt}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                    fontSize: '1.1rem'
                                }}
                            >
                                Assistant:
                            </Typography>
                            <Typography component="div">
                                <ReactMarkdown>{entry.response}</ReactMarkdown>
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <form onSubmit={handleQuerySubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Enter your query"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        disabled={isLoading}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isLoading || !query.trim() || ids.length === 0}
                            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                        >
                            {isLoading ? 'Processing...' : 'Submit'}
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleClearChat}
                            disabled={isLoading || chatHistory.length === 0}
                        >
                            Clear Chat
                        </Button>
                    </Box>
                </form>
                {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                        Error: {error}
                    </Typography>
                )}
                {ids.length === 0 && (
                    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                        No items found. Please check your selection criteria.
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default RAGSimpleSpecificSourcesChat;