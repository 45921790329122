import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem } from '@mui/material';
import { Add, Delete, Edit, Save, Cancel } from '@mui/icons-material';

const typeOptions = ['None', 'text/plain', 'application/json'];

const AttributeList = React.memo(({ attributes, onChange, editable, isNewItem }) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [newAttribute, setNewAttribute] = useState({ name: '', value: '', type: 'None' });

    const handleEdit = (index) => {
        setEditingIndex(index);
    };

    const handleSave = (index, updatedAttribute) => {
        const newAttributes = [...attributes];
        newAttributes[index] = updatedAttribute;
        onChange(newAttributes);
        setEditingIndex(null);
    };

    const handleDelete = (index) => {
        const newAttributes = attributes.filter((_, i) => i !== index);
        onChange(newAttributes);
    };

    const handleAdd = () => {
        if (newAttribute.name.trim()) {
            const newAttributes = [...attributes, {
                ...newAttribute,
                type: newAttribute.type === 'None' ? '' : newAttribute.type
            }];
            onChange(newAttributes);
            setNewAttribute({ name: '', value: '', type: 'None' });
        }
    };

    const renderTypeField = (value, onChangeHandler) => (
        <Select
            value={value || 'None'}
            onChange={onChangeHandler}
            size="small"
            fullWidth
        >
            {typeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    );

    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                Attributes:
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Type</TableCell>
                        {editable && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {attributes.map((attr, index) => (
                        <TableRow key={index}>
                            {editingIndex === index ? (
                                <>
                                    <TableCell>
                                        <TextField
                                            value={attr.name}
                                            onChange={(e) => handleSave(index, { ...attr, name: e.target.value })}
                                            size="small"
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={attr.value}
                                            onChange={(e) => handleSave(index, { ...attr, value: e.target.value })}
                                            size="small"
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {renderTypeField(
                                            attr.type,
                                            (e) => handleSave(index, { ...attr, type: e.target.value === 'None' ? '' : e.target.value })
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setEditingIndex(null)} color="primary">
                                            <Save />
                                        </IconButton>
                                        <IconButton onClick={() => setEditingIndex(null)} color="default">
                                            <Cancel />
                                        </IconButton>
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell>{attr.name}</TableCell>
                                    <TableCell>{attr.value}</TableCell>
                                    <TableCell>{attr.type || 'None'}</TableCell>
                                    {editable && (
                                        <TableCell>
                                            <IconButton onClick={() => handleEdit(index)} color="primary">
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(index)} color="error">
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </>
                            )}
                        </TableRow>
                    ))}
                    {editable && (
                        <TableRow>
                            <TableCell>
                                <TextField
                                    value={newAttribute.name}
                                    onChange={(e) => setNewAttribute({ ...newAttribute, name: e.target.value })}
                                    size="small"
                                    placeholder="Name"
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={newAttribute.value}
                                    onChange={(e) => setNewAttribute({ ...newAttribute, value: e.target.value })}
                                    size="small"
                                    placeholder="Value"
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                {renderTypeField(
                                    newAttribute.type,
                                    (e) => setNewAttribute({ ...newAttribute, type: e.target.value })
                                )}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={handleAdd} color="primary">
                                    <Add />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
});

export default AttributeList;