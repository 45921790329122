// src/apps/professor/library/subNavConfig.js
import React from 'react';
import Catalog from "./catalog/Catalog";
import Librarian from "./librarian/Librarian";
import AddSource from "./addsource/AddSource";
import CreateCollection from "./createcollection/CreateCollection";

import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonSearch from '@mui/icons-material/PersonSearch';
import PostAdd from '@mui/icons-material/PostAdd';
import CreateNewFolder from '@mui/icons-material/CreateNewFolder';

const subNav = {
    header: 'Library',
    links: [
        {
            name: 'Catalog',
            path: '/apps/library/catalog',
            icon: MenuBookIcon,
            element: <Catalog />,
            title: 'Library Catalog',
            description: 'Browse and search through your resources',
            buttonText: 'Open Catalog'
        },
        {
            name: 'Librarian',
            path: '/apps/library/librarian',
            icon: PersonSearch,
            element: <Librarian />,
            title: 'AI Librarian',
            description: 'Search for sources on a topic or query',
            buttonText: 'Ask Librarian'
        },
        {
            name: 'Add Source',
            path: '/apps/library/addsource',
            icon: PostAdd,
            element: <AddSource />,
            title: 'Add New Source',
            description: 'Add new resources to your library',
            buttonText: 'Add Source'
        },
        {
            name: 'Create Collection',
            path: '/apps/library/createcollection',
            icon: CreateNewFolder,
            element: <CreateCollection />,
            title: 'Create a Collection',
            description: 'Organize your sources and items',
            buttonText: 'Create Collection'
        }
    ],
    routes: [
        { path: '/apps/library/catalog', element: <Catalog /> },
        { path: '/apps/library/librarian', element: <Librarian /> },
        { path: '/apps/library/addsource', element: <AddSource /> },
        { path: '/apps/library/createcollection', element: <CreateCollection /> }
    ]
};

export default subNav;