import React, { useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AttributeEditor from "../../../../../components/AttributeEditor";
import MuscleGroupAnalysis from './MuscleGroupAnalysis';
import WorkoutFrequency from './WorkoutFrequency';
import GymWorkoutRAGChat from "./GymQueryRAGChat";
import WorkoutStatsForExercise from "./WorkoutStatsForExercise";
import ExpandableCard from "../../../../../components/list/ExpandableCard";

const WorkoutStats = ({ items: workouts, collectionId }) => {
    console.log("Workouts: ", workouts);
    const [currentGoal, setCurrentGoal] = useState('');

    const handleGoalChange = useCallback((newGoal) => {
        setCurrentGoal(newGoal);
        console.log('Goal changed:', newGoal);
    }, []);

    if (!workouts || workouts.length === 0) {
        return (
            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Box sx={{ maxWidth: '500px', mx: 'auto' }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        No Workout Records Yet
                    </Typography>
                    <Typography color="text.secondary">
                        Add some workout records to see your statistics, track your progress, and analyze your performance.
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AttributeEditor
                        sourceId={collectionId}
                        attributeName="current_gym_goal"
                        title="Enter Your Current Fitness Goal"
                        placeholder="Enter your current fitness goal... (e.g., 'bulk up and focus on chest')"
                        onValueChange={handleGoalChange}
                        helperText="Describe your current fitness objective to help guide feedback and advice"
                        minRows={3}e
                        maxRows={6}
                        elevation={2}
                        variant="elevation"
                    />
                </Grid>
                <Grid item xs={12}>
                    <GymWorkoutRAGChat workouts={workouts} currentGoal={currentGoal} />
                </Grid>
            </Grid>

                <Grid container spacing={3} sx={{ mt: 2 }}>

                    <ExpandableCard title={"Statistics"} initiallyExpanded={true}>
                    <MuscleGroupAnalysis workouts={workouts} />
                    <WorkoutFrequency workouts={workouts} />
                    <WorkoutStatsForExercise workouts={workouts}/>

                    </ExpandableCard>
                </Grid>
        </Box>
    );
};

export default WorkoutStats;