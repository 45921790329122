import React, { useState, useEffect, useCallback } from 'react';
import ManagedPage from "../../../../components/apps/ManagedPage";
import CatalogStats from "./CatalogStats";

function Catalog() {
    const [pageConfig, setPageConfig] = useState({
        rootAssociation: 'AIssistant',
        expanded: false,
        loadOnDefault: false
    });

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash.startsWith('#')) {
                const id = hash.slice(1).trim();
                if (id) {
                    setPageConfig({
                        rootAssociation: 'X',
                        expanded: true,
                        loadOnDefault: true
                    });
                } else {
                    resetToDefault();
                }
            } else {
                resetToDefault();
            }
        };

        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    const resetToDefault = useCallback(() => {
        setPageConfig({
            rootAssociation: 'AIssistant',
            expanded: false,
            loadOnDefault: false
        });
    }, []);

    const customFilter = useCallback((item, filterText) => {
        if (!filterText) return true;
        const searchText = filterText.toLowerCase();

        return (
            (item.name?.toLowerCase().includes(searchText)) ||
            (item.description?.toLowerCase().includes(searchText)) ||
            (item.content?.content?.tags?.some(tag =>
                tag.toLowerCase().includes(searchText)
            ))
        );
    }, []);

    return (
        <ManagedPage
            title="Catalog"
            systemCollectionName={pageConfig.rootAssociation}
            sourceType="Source"
            showExtractor={false}
            StatsComponent={({ items }) => <CatalogStats items={items} />}
            eagerLoad={false}
            managedListProps={{
                showAssociationsDropdown: false,
                showFilter: true,
                autoLoadExpandedContent: true,
                expanded: pageConfig.expanded,
                loadOnDefault: pageConfig.loadOnDefault,
                showTypeSelection: true,
                defaultSelectedTypes: ["Source"],
                allowDelete: true,
                showCheckAllClear: true,
                asChecklist: true,
                customFilterFunction: customFilter
            }}
        />
    );
}

export default Catalog;