import React, { useMemo } from 'react';
import RAGSimpleSpecificSources from "../../../../components/rag/RAGSimpleSpecificSources";

const FoodInventoryAnalysisRAG = ({ items }) => {
    const sourceIds = useMemo(() => items.map(item => item.id), [items]);

    const query = "Review my food inventory. Highlight urgency of expiring items if any and overall nutritional advice.";
    const context = "Analyze the freshness (expiration dates) and nutritional content and balance of the food inventory provided.";
    const specialInstructions = "Provide a comprehensive analysis of the food inventory. Include insights on urgency of foods about to expire and then nutritional balance, potential areas for improvement, and general dietary recommendations. Summarize if too many entries. You are a nutritionist.";

    return (
        <RAGSimpleSpecificSources
            sourceIds={sourceIds}
            query={query}
            context={context}
            specialInstructions={specialInstructions}
            buttonText="Analyze My Food Inventory"
            analysisTitle="Food Inventory Analysis"
        />
    );
};

export default FoodInventoryAnalysisRAG;