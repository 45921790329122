import React, { useMemo } from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { parseISO, format, addDays, isBefore, isSameDay } from 'date-fns';

const CustomTooltip = ({ active, payload, label }) => {
    const theme = useTheme();

    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(1.5),
                    boxShadow: theme.shadows[4],
                }}
            >
                <Typography variant="subtitle2" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
                    {format(parseISO(label), 'MMMM d, yyyy')}
                </Typography>
                {payload.map((entry, index) => (
                    <Box key={`item-${index}`} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                        <Typography variant="body2" sx={{ color: entry.color, mr: 2, fontWeight: 'bold' }}>
                            {entry.name}:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {Math.round(entry.value)}
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    }
    return null;
};

const CalorieTrends = ({ items, startDate, endDate, getChartTimeframe, movingAverageDays }) => {
    const theme = useTheme();

    const chartData = useMemo(() => {
        const dateArray = [];
        for (let d = parseISO(startDate); isBefore(d, parseISO(endDate)) || isSameDay(d, parseISO(endDate)); d = addDays(d, 1)) {
            dateArray.push(format(d, 'yyyy-MM-dd'));
        }

        const dailyData = dateArray.map(date => {
            const dayItems = items.filter(item => item.content.content.mealDate === date);
            const calories = dayItems.reduce((sum, item) => sum + (item.content.content.totalNutrition.calories || 0), 0);

            return {
                date,
                calories,
                hasMeals: dayItems.length > 0,
            };
        });

        return dailyData.map((day, index, array) => {
            let movingAvgCalories = 0;
            let daysCount = 0;
            let i = index;

            while (daysCount < movingAverageDays && i >= 0) {
                if (array[i].hasMeals) {
                    movingAvgCalories += array[i].calories;
                    daysCount++;
                }
                i--;
            }

            movingAvgCalories = daysCount > 0 ? movingAvgCalories / daysCount : null;

            return {
                ...day,
                movingAvgCalories: movingAvgCalories
            };
        });
    }, [items, startDate, endDate, movingAverageDays]);

    const lineColor1 = '#ff6b6b';
    const lineColor2 = '#4ecdc4';

    return (
        <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary, mb: 2 }}>
                Calorie Trends - {getChartTimeframe()}
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
                <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(value) => format(parseISO(value), 'MM/dd')}
                        stroke={theme.palette.text.secondary}
                        tick={{ fill: theme.palette.text.secondary }}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        tick={{ fill: theme.palette.text.secondary }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        wrapperStyle={{ color: theme.palette.text.primary }}
                        formatter={(value, entry) => <span style={{ color: entry.color }}>{value}</span>}
                    />
                    <Line
                        type="monotone"
                        dataKey="calories"
                        stroke={lineColor1}
                        strokeWidth={2}
                        name="Daily Calories"
                        dot={{ fill: lineColor1, r: 4 }}
                        activeDot={{ r: 6 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="movingAvgCalories"
                        stroke={lineColor2}
                        strokeWidth={2}
                        name={`${movingAverageDays}-day Moving Average (Tracked Days)`}
                        dot={{ fill: lineColor2, r: 4 }}
                        activeDot={{ r: 6 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default CalorieTrends;