import React, { useState, useCallback, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import Chatbot from "../../../../../Chatbot";
import RecipeDisplay from "../RecipeDisplay";
import ManagedList from "../../../../../components/list/ManagedList";
import AssociationsDropdown from "../../../../../components/AssociationsDropdown";
import { useSystemCollection} from "../../../../../context/systemColections";
import { useSourceName} from "../../../../../context/useSourceName";

const Chef = () => {
    const [completionId, setCompletionId] = useState(null);
    const [selectedAssociation, setSelectedAssociation] = useState(null);
    const [chatConfig, setChatConfig] = useState(null);
    const [chatKey, setChatKey] = useState(0);
    const [listKey, setListKey] = useState(0);

    const { getSystemCollectionIdByName, isLoading, error } = useSystemCollection();
    const { getSourceNameById } = useSourceName();

    const handleAssociationSelect = useCallback((selectedCollection) => {
        setSelectedAssociation(selectedCollection);
        setChatKey(prevKey => prevKey + 1);
        setCompletionId(null);
    }, []);

    const handleSourceDelete = useCallback(() => {
        setListKey(prevKey => prevKey + 1);
    }, []);

    useEffect(() => {
        const setupChatConfig = async () => {
            if (selectedAssociation && !isLoading && !error) {
                try {
                    const foodInventoryReportId = await getSystemCollectionIdByName("Food Inventory Report");
                    const foodInventoryReportName = await getSourceNameById(foodInventoryReportId);

                    setChatConfig({
                        systemPromptPrefix: "You are an assistant for a nutritionist and a chef. You help collect preliminary information from clients and converse with them. Have a friendly conversation to gather the information needed for you to make a custom recipe tailored just for them and their current need. Only gather information, do not make an actual recipe. Don't ask for all information at once, make it a more natural conversational. Confirm with the user final details before completing. Converse with the user to get any additional information they wish to provide for a recipe. Record other additional information provided by the user in a list as \"additional_info\" Confirm all details and then and on a confirmation of all information.",
                        goal: "Your goal is to obtain the following required information at an absolute minimum, and attempt to obtain the optional information stated below.",
                        specialInstructions: [],
                        initialContext: [{"name": foodInventoryReportName, "contentType":"application/source", "content": foodInventoryReportId }],
                        requiredInformation: [
                            ["serving_size", "How many servings the recipe is for. Examples: \"4\", \"just myself (1)\", \"A family of 5\", \"a party of 20\""],
                            ["inventory_use", "If the user wants a recipe for their specific inventory. Options are \"Inventory Only\" to limit strictly to inventory, \"Inventory Biased\" to use items in inventory but allow additional ingredients, \"Any\" to not consider inventory restrictions."],
                            ["dietary_concerns", "Any dietary concerns, both allergy or diets. Examples: \"Peanut allergy\", \"I have hashimoto's disease\", \"I am on keto\", \"Low fat\", \"I need to lower my cholesterol\", \"none\", \"no\""]
                        ],
                        optionalInformation: [
                            ["taste_preference", "Preferences of taste to guide the recipe. Examples: \"Mexican\", \"Sweet and savory\", \"spicy\""],
                            ["time_preference", "Preferred cooking time or complexity. Examples: \"under 10 min\", \"quick and easy\", \"30 minutes or less\", \"1 hour max\", \"don't care\""]
                        ],
                        initialGreeting: "Have a natural conversation with me as a chef to collect the following information.  Start off with message: Hi! I'd be happy to make a custom recipe just for you!  Let's start with the basics.  How many servings should the recipe make?",
                        closingMessage: "Here is a recipe, made just for you!",
                        confirmResultsWithUser: true,
                        completionCallbackName: "recipeCompletionCallback",
                        completionCallbackConfig: {
                            "toAssociations": [selectedAssociation.id]
                        },
                        associations: [selectedAssociation.id]
                    });
                } catch (err) {
                    console.error('Error setting up chat config:', err);
                }
            }
        };

        setupChatConfig();
    }, [selectedAssociation, isLoading, error, getSystemCollectionIdByName, getSourceNameById]);

    const handleConversationComplete = (data) => {
        if (data.completionResult && data.completionResult.result) {
            setCompletionId(data.completionResult.result);
        }
    };

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography color="error">Error: {error.message}</Typography>;
    }

    return (
        <Container maxWidth="md">
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 4 }}>
                Your Personal Recipe Chef
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%', maxWidth: 600, mb: 4 }}>
                    <AssociationsDropdown
                        rootAssociation="Recipes"
                        selected={selectedAssociation?.label || 'Recipes'}
                        onCollectionSelect={handleAssociationSelect}
                        selectionLabel="Select a recipe collection"
                    />
                </Box>
                <Box sx={{ width: '100%', maxWidth: 600 }}>
                    {chatConfig && (
                        <Chatbot
                            key={chatKey}
                            agentName="Chef"
                            initialConfig={chatConfig}
                            onConversationComplete={handleConversationComplete}
                            sourceId={selectedAssociation.id}
                        />
                    )}
                </Box>
            </Box>
            {completionId && (
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>
                        Your Custom Recipe
                    </Typography>
                    <ManagedList
                        key={listKey}
                        query={`source/search?includeContent=true&ids=${completionId}`}
                        ItemDisplay={RecipeDisplay}
                        showAssociationsDropdown={false}
                        showFilter={false}
                        loadOnDefault={true}
                        onSourceDelete={handleSourceDelete}
                    />
                </Box>
            )}
        </Container>
    );
};

export default Chef;