import AppCardGrid from "../../../../components/apps/AppCardGrid";
import subNav from "./subNavConfig"
import React from "react";

const MealTracker = () => {

    return (
        <AppCardGrid title={`${subNav.header} Dashboard`} subNav={subNav} />
    );
};

export default MealTracker;
