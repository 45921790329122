import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventDetailsModal from "./EventDetailModal";
import AddDateModal from "./AddDateModal";
import "./Calendar.css"
import useApi from "../ApiService";

const Calendar = ({ sources, selectedAssociation }) => {
    const { apiCall } = useApi();
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [isAddDateModalOpen, setIsAddDateModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    console.log("SELECTED ASSOCIATIONS:", selectedAssociation);

    useEffect(() => {
        const transformedEvents = sources.map(source => ({
            id: source.id,
            title: source.content.content.title,
            date: formatDate(source.content.content.date),
            extendedProps: { source }
        }));
        setEvents(transformedEvents);
    }, [sources]);

    const formatDate = (dateString) => {
        if (!dateString) return '';

        // Check if the date is already in YYYY-MM-DD format
        if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
            return dateString;
        }

        // If it's in MM/DD/YYYY format
        const [month, day, year] = dateString.split('/');
        if (month && day && year) {
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }

        // If it's in another format or invalid, return as is
        console.warn('Invalid date format:', dateString);
        return dateString;
    };

    const handleDateSelect = (selectInfo) => {
        const selectedDate = selectInfo.dateStr;
        setSelectedDate(selectedDate);
        setIsAddDateModalOpen(true);
    };

    const handleEventClick = (clickInfo) => {
        setSelectedEvent(clickInfo.event.extendedProps.source);
        setIsEventModalOpen(true);
    };

    const handleEventDrop = async (info) => {
        const dateContentId = info.event.extendedProps.source.content.id;
        const sourceId = info.event.extendedProps.source.id;
        const date = info.event.startStr;
        const [year, month, day] = date.split('-');
        const newDate = `${month}/${day}/${year}`;

        try {
            const eventToUpdate = events.find(event => event.id === sourceId);
            const updatedContent = {
                ...eventToUpdate.extendedProps.source.content,
                content: {
                    ...eventToUpdate.extendedProps.source.content.content,
                    date: newDate
                }
            };

            const response = await apiCall(`/content/${dateContentId}`, {
                method: 'PUT',
                data: updatedContent
            });

            if (response.status === 200) {
                setEvents(prevEvents => prevEvents.map(event =>
                    event.id === sourceId
                        ? { ...event, date: date, extendedProps: { source: { ...event.extendedProps.source, content: updatedContent } } }
                        : event
                ));
            }
        } catch (error) {
            console.error('Error updating event date:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handleEventUpdate = useCallback((eventId, updatedContent) => {
        if (updatedContent === null) {
            // Event was deleted
            setEvents(prevEvents => prevEvents.filter(event => event.id !== eventId));
        } else {
            // Event was updated
            setEvents(prevEvents => prevEvents.map(event =>
                event.id === eventId
                    ? {
                        ...event,
                        title: updatedContent.title,
                        date: formatDate(updatedContent.date),
                        extendedProps: {
                            ...event.extendedProps,
                            source: {
                                ...event.extendedProps.source,
                                content: { content: updatedContent }
                            }
                        }
                    }
                    : event
            ));
        }
        setIsEventModalOpen(false);
    }, []);

    const handleDateAdded = async (newEvent) => {
        setEvents(prevEvents => [...prevEvents, {
            id: newEvent.id,
            title: newEvent.content.content.title,
            date: formatDate(newEvent.content.content.date),
            extendedProps: { source: newEvent }
        }]);
        setIsAddDateModalOpen(false);
    };

    const calendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        events: events,
        dateClick: handleDateSelect,
        eventClick: handleEventClick,
        eventDrop: handleEventDrop,
        editable: true,
        selectable: true,
        height: 'auto'
    };

    return (
        <div style={{ height: '600px' }}>
            <FullCalendar {...calendarOptions} />

            <EventDetailsModal
                isOpen={isEventModalOpen}
                onClose={() => setIsEventModalOpen(false)}
                selectedEvent={selectedEvent}
                onEventUpdate={handleEventUpdate}
            />

            <AddDateModal
                isOpen={isAddDateModalOpen}
                onClose={() => setIsAddDateModalOpen(false)}
                selectedDate={selectedDate}
                onDateAdded={handleDateAdded}
                selectedAssociation={selectedAssociation}
            />
        </div>
    );
};

export default Calendar;