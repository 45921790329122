// src/apps/nutritionist/subNavConfig.js
import React from 'react';
import MealLog from "./meallog/MealLog";
import MealTracker from "./mealtracker/MealTracker";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import MealDisplay from "./mealtracker/MealDisplay";

const subNav = {
    header: 'Meal Tracker',
    links: [
        {
            name: 'Meal Log',
            path: '/apps/nutritionist/mealtracker/meallog',
            icon: CalendarMonthIcon,
            element: <MealLog />,
            title: 'Meal Log',
            description: 'See your tracked meals',
            buttonText: 'View Meal History'
        },
        {
            name: 'Track Meal',
            path: '/apps/nutritionist/mealtracker/mealtracker',
            icon: EditCalendarIcon,
            element: <MealTracker />,
            title: 'Track Meal',
            description: 'Record your meals',
            buttonText: 'Log your meal entrees'
        }
    ],
    routes: [
        { path: '/apps/nutritionist/mealtracker/meallog', element: <MealLog /> },
        { path: '/apps/nutritionist/mealtracker/mealtracker', element: <MealTracker /> },
        { path: '/apps/nutritionist/mealtracker/meal', element: <MealDisplay /> }
    ]
};

export default subNav;