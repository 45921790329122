import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Snackbar,
    Alert,
    Link
} from '@mui/material';
import { styled } from '@mui/system';
import StringList from "../../../../components/form/StringList";
import AssociationManager from "../../../../components/form/AssociationManager";
import useApi from "../../../../components/ApiService";
import { useSourceName } from "../../../../context/useSourceName";
import TooltipWrapper from "../../../../components/StyledTooltip";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
}));

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const MemoizedStringList = React.memo(StringList);
const MemoizedAssociationManager = React.memo(AssociationManager);

const AddCollectionForm = ({ initialAssociations = [], onUpdate }) => {
    const { apiCall } = useApi();
    const initialCollectionData = useMemo(() => ({
        name: '',
        description: '',
        types: ['Collection'],
        contentType: 'String',
        uri: 'self://',
    }), []);

    const [collectionData, setCollectionData] = useState(initialCollectionData);
    const [associations, setAssociations] = useState(initialAssociations);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [newCollectionId, setNewCollectionId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const { getSourceNameById } = useSourceName();
    const sourceNamesRef = useRef({});
    const sourceNamePromisesRef = useRef({});

    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        setCollectionData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    const handleAssociationsChange = useCallback((newAssociations) => {
        setAssociations(newAssociations);
    }, []);

    const handleClearAssociations = useCallback(() => {
        setAssociations([]);
    }, []);

    const validateForm = useCallback(() => {
        if (!collectionData.name) {
            setErrorMessage('Name is required.');
            return false;
        }
        setErrorMessage('');
        return true;
    }, [collectionData.name]);

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        try {
            const response = await apiCall(`/source`, {
                method: 'POST',
                data: { ...collectionData, associations }
            });

            if (response.status === 200 || response.status === 201) {
                console.log('Collection added successfully, ID:', response.data);
                setNewCollectionId(response.data);
                setSnackbarOpen(true);
                setCollectionData(initialCollectionData);
                setAssociations([]);
                if (onUpdate) {
                    onUpdate(response.data);
                }
            }
        } catch (error) {
            console.error('Error adding collection:', error);
            setErrorMessage(`Error adding collection: ${error.response?.data?.message || error.message}`);
        }
    }, [collectionData, associations, validateForm, apiCall, initialCollectionData, onUpdate]);

    const sourceDisplayPresenter = useCallback((sourceId) => {
        if (sourceNamesRef.current[sourceId]) {
            return sourceNamesRef.current[sourceId];
        }

        if (!sourceNamePromisesRef.current[sourceId]) {
            sourceNamePromisesRef.current[sourceId] = getSourceNameById(sourceId)
                .then(name => {
                    sourceNamesRef.current[sourceId] = name;
                    delete sourceNamePromisesRef.current[sourceId];
                    return name;
                })
                .catch(error => {
                    console.error('Error fetching source name:', error);
                    sourceNamesRef.current[sourceId] = 'Unknown Source';
                    delete sourceNamePromisesRef.current[sourceId];
                    return 'Unknown Source';
                });
        }

        return sourceNamePromisesRef.current[sourceId].then(name => {
            // Force re-render only if the name has changed
            if (sourceNamesRef.current[sourceId] !== name) {
                setAssociations(prev => [...prev]);
            }
            return name;
        });
    }, [getSourceNameById]);

    const handleCloseSnackbar = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }, []);

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h5" gutterBottom>Add New Collection <TooltipWrapper title={"Collections are like folders to help you organize. You place your sources and collections in collections to group them."}/></Typography>
            <StyledForm onSubmit={handleSubmit}>
                <TextField
                    name="name"
                    label="Name"
                    value={collectionData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                />
                <TextField
                    name="description"
                    label="Description"
                    value={collectionData.description}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={3}
                />
                <Box>
                    <MemoizedStringList
                        label="Select the collections for this to be placed under"
                        values={associations}
                        onChange={handleAssociationsChange}
                        editable={false}
                        isNewItem={true}
                        isRequired={true}
                        displayPresenter={sourceDisplayPresenter}
                    />
                    <MemoizedAssociationManager
                        associations={associations}
                        onUpdate={handleAssociationsChange}
                        includeRoot={true}
                    />
                    {/*<Button*/}
                    {/*    onClick={handleClearAssociations}*/}
                    {/*    // variant="outlined"*/}
                    {/*    // color="secondary"*/}
                    {/*    // sx={{ mt: 1 }}*/}
                    {/*>*/}
                    {/*    Clear Associations*/}
                    {/*</Button>*/}
                </Box>
                {errorMessage && (
                    <Typography color="error">{errorMessage}</Typography>
                )}
                <Button type="submit" variant="contained" color="primary">
                    Add Collection
                </Button>
            </StyledForm>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Collection added successfully!
                    <Box mt={1}>
                        <Link href={`/apps/library/catalog#${newCollectionId}`} target="_blank" rel="noopener">
                            View Collection
                        </Link>
                    </Box>
                </Alert>
            </Snackbar>
        </StyledPaper>
    );
};

export default React.memo(AddCollectionForm);