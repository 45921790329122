import React, { useMemo } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const MacronutrientDistribution = ({ items, getChartTimeframe }) => {
    const pieChartData = useMemo(() => {
        const totalNutrition = items.reduce((acc, item) => {
            const nutrition = item.content.content.totalNutrition;
            acc.protein += nutrition.protein || 0;
            acc.carbs += nutrition.carbs || 0;
            acc.fat += nutrition.fat || 0;
            return acc;
        }, { protein: 0, carbs: 0, fat: 0 });

        return [
            { name: 'Protein', value: totalNutrition.protein },
            { name: 'Carbs', value: totalNutrition.carbs },
            { name: 'Fat', value: totalNutrition.fat },
        ];
    }, [items]);

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <ul style={{ listStyle: 'none', padding: 0, display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{ marginRight: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '10px', height: '10px', backgroundColor: entry.color, marginRight: '5px' }}></div>
                            <span>{entry.value}: {entry.payload.value.toFixed(1)}g ({(entry.payload.percent * 100).toFixed(0)}%)</span>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <Typography variant="h6" gutterBottom>Macronutrient Distribution - {getChartTimeframe()}</Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            outerRadius="80%"
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {pieChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend content={renderLegend} />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
        </Paper>
    );
};

export default MacronutrientDistribution;