import React, { useRef, useCallback, useEffect, useState } from 'react';
import {
    Typography, Box, Button, Paper, Grid, useTheme, alpha, CircularProgress
} from '@mui/material';
import {
    Print as PrintIcon,
    ShoppingCart as ShoppingCartIcon,
    Grain as GrainIcon,
    Apple as FruitIcon,
    EggAlt as ProteinIcon,
    LocalPizza as DairyIcon,
    Spa as VegetableIcon,
    Category as OtherIcon
} from '@mui/icons-material';
import useSourceAttributes from "../../../../context/useSourceAttributes";

const foodGroupIcons = {
    'Grain': GrainIcon,
    'Fruit': FruitIcon,
    'Vegetable': VegetableIcon,
    'Protein': ProteinIcon,
    'Dairy': DairyIcon,
    'Other': OtherIcon
};

const PrintableGroceryList = ({ items }) => {
    const printRef = useRef();
    const theme = useTheme();

    // Local state for attributes
    const [attributes, setAttributes] = useState({});

    // Import upsertAttribute from useSourceAttributes
    const { upsertAttribute } = useSourceAttributes();

    // Initialize attributes from items
    useEffect(() => {
        if (items && items.length > 0) {
            const initialAttributes = items.reduce((acc, item) => {
                const attribute = item.attributes.find(attr => attr.name === "grocery.list.checked");
                acc[item.id] = attribute ? attribute.value === "true" : false;
                return acc;
            }, {});
            setAttributes(initialAttributes);
        }
    }, [items]);

    const handleItemClick = async (sourceId) => {
        const currentValue = attributes[sourceId];
        const newValue = !currentValue;

        // Update local state
        setAttributes(prevAttributes => ({
            ...prevAttributes,
            [sourceId]: newValue,
        }));

        // Prepare attribute data
        const attribute = {
            name: 'grocery.list.checked',
            value: newValue.toString(),
        };

        try {
            // Update attribute on the backend
            await upsertAttribute(sourceId, attribute);
        } catch (err) {
            // Revert local state if API call fails
            setAttributes(prevAttributes => ({
                ...prevAttributes,
                [sourceId]: currentValue,
            }));
            console.error('Error updating attribute', err);
        }
    };

    const groupedItems = items.reduce((acc, item) => {
        const content = item.content.content;
        const group = content.food_group || 'Other';
        if (!acc[group]) {
            acc[group] = {};
        }
        if (!acc[group][content.name]) {
            acc[group][content.name] = { ...content, quantities: [], sourceId: item.id };
        }
        acc[group][content.name].quantities.push(`${content.quantity} ${content.unit}`);
        return acc;
    }, {});

    const handlePrint = useCallback(() => {
        const printContent = printRef.current;
        const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        winPrint.document.write(`
            <html>
                <head>
                    <title>Grocery Shopping List</title>
                    <style>
                        body { 
                            font-family: ${theme.typography.fontFamily};
                            padding: ${theme.spacing(2)};
                            max-width: 800px; 
                            margin: 0 auto; 
                            color: ${theme.palette.text.primary};
                        }
                        h1 { 
                            color: ${theme.palette.primary.main}; 
                            font-size: ${theme.typography.h4.fontSize};
                            margin-bottom: ${theme.spacing(2)};
                            text-align: center;
                        }
                        h2 {
                            font-size: ${theme.typography.h5.fontSize};
                            margin-top: ${theme.spacing(2)};
                            margin-bottom: ${theme.spacing(1)};
                            border-bottom: 1px solid ${theme.palette.divider};
                            padding-bottom: ${theme.spacing(0.5)};
                        }
                        .group {
                            margin-bottom: ${theme.spacing(2)};
                            break-inside: avoid;
                        }
                        .grid { 
                            display: grid; 
                            grid-template-columns: repeat(2, 1fr); 
                            gap: ${theme.spacing(1)}; 
                        }
                        .item { 
                            padding: ${theme.spacing(0.5)}; 
                            display: flex;
                            align-items: center;
                        }
                        .checkbox {
                            width: 16px;
                            height: 16px;
                            border: 2px solid ${theme.palette.action.active};
                            display: inline-block;
                            margin-right: ${theme.spacing(1)};
                        }
                        .checkbox.checked {
                            background-color: ${theme.palette.action.active};
                        }
                        .item-text {
                            display: flex;
                            flex-direction: column;
                        }
                        .item-name {
                            font-weight: bold;
                            font-size: 1.1em;
                        }
                        .item-quantity {
                            color: ${theme.palette.text.secondary};
                            font-size: 0.9em;
                        }
                        .food-group-icon {
                            display: none;
                        }
                        @media print {
                            body { -webkit-print-color-adjust: exact; }
                        }
                    </style>
                </head>
                <body>
                    <h1>Grocery Shopping List</h1>
                    ${printContent.innerHTML}
                </body>
            </html>
        `);
        winPrint.document.close();
        winPrint.focus();
        winPrint.print();
        winPrint.close();
    }, [theme]);

    return (
        <Paper
            elevation={3}
            sx={{
                p: theme.spacing(2),
                mb: theme.spacing(3),
                backgroundColor: alpha(theme.palette.background.paper, 0.8),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            }}
        >
            {/* Your existing component JSX */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={theme.spacing(2)}>
                <Typography variant="h6" color="primary">
                    <ShoppingCartIcon sx={{ mr: theme.spacing(1), verticalAlign: 'middle' }} />
                    Shopping List
                </Typography>
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={handlePrint}
                >
                    Print List
                </Button>
            </Box>
            <Box ref={printRef}>
                {Object.entries(groupedItems).map(([group, items]) => {
                    const Icon = foodGroupIcons[group] || OtherIcon;
                    return (
                        <Box key={group} className="group">
                            <Typography variant="h5" sx={{
                                mt: theme.spacing(2),
                                mb: theme.spacing(1),
                                borderBottom: `1px solid ${theme.palette.divider}`,
                                pb: theme.spacing(0.5),
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Icon sx={{ mr: theme.spacing(1), display: 'inline-block' }} className="food-group-icon" />
                                {group}
                            </Typography>
                            <Grid container spacing={1} className="grid">
                                {Object.values(items).map((item, index) => {
                                    const isChecked = attributes[item.sourceId];
                                    return (
                                        <Grid item xs={6} key={index}>
                                            <Box
                                                className="item"
                                                onClick={() => handleItemClick(item.sourceId)}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                    },
                                                    borderRadius: theme.shape.borderRadius,
                                                    transition: 'background-color 0.3s',
                                                    py: 0.5,
                                                }}
                                            >
                                                <Box
                                                    component="span"
                                                    className={`checkbox ${isChecked ? 'checked' : ''}`}
                                                    sx={{
                                                        width: 16,
                                                        height: 16,
                                                        border: `2px solid ${theme.palette.action.active}`,
                                                        display: 'inline-block',
                                                        marginRight: theme.spacing(1),
                                                        backgroundColor: isChecked ? theme.palette.action.active : 'transparent',
                                                        mt: 0.5,
                                                    }}
                                                />
                                                <Box className="item-text">
                                                    <Typography
                                                        variant="body1"
                                                        className="item-name"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            fontSize: '1.1em',
                                                            lineHeight: 1.2,
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className="item-quantity"
                                                        sx={{
                                                            color: theme.palette.text.secondary,
                                                            fontSize: '0.9em',
                                                        }}
                                                    >
                                                        {item.quantities.join(', ')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    );
                })}
            </Box>
        </Paper>
    );
};

export default PrintableGroceryList;