import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    Paper,
    TextField,
    IconButton,
    Button,
    CircularProgress,
    Grid,
    Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import TextOrSourceInput from "../../../../../components/TextOrSourceInput";
import useApi from "../../../../../components/ApiService";

const EntreeForm = ({
                        entree,
                        setEntree,
                        mealType,
                        setSnackbar,
                        isSubmitting,
                        onUpdate,
                        onRemove,
                        index
                    }) => {
    const { apiCall } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(!entree.submitted);
    const [localEntree, setLocalEntree] = useState(entree);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const updatedIngredients = entree.ingredients.map(ingredient => ({
            ...ingredient,
            amount: ingredient.quantity || ingredient.amount,
            originalAmount: ingredient.quantity || ingredient.amount,
            originalServings: entree.desiredServings || 1
        }));

        setLocalEntree({
            ...entree,
            ingredients: updatedIngredients,
            desiredServings: entree.desiredServings || 1
        });
        setIsEditing(!entree.submitted);
    }, [entree]);

    const handleEntreeDishChange = useCallback(async (value) => {
        console.log("VALUE: ", value);
        if (!value || value === "") {
            return;
        }

        setIsLoading(true);

        try {
            let recipeDetails;
            if (value.contentType === 'application/source') {
                recipeDetails = {
                    content: value.content.source,
                    contentType: "application/source",
                    name: "Recipe"
                };
            } else if (value.contentType === 'text/plain') {
                recipeDetails = {
                    content: value.content.text,
                    contentType: "text/plain",
                    name: "Recipe Details"
                };
            } else if (value.contentType === 'multipart/form-data') {
                const file = value.content.file;
                setSelectedFile(file);
                // File handling remains the same
                await handleFileExtraction(file);
                setIsLoading(false);
                return;
            }

            const url = `${process.env.REACT_APP_API_URL}/ai/extractor/extract`;
            const recipeInfo = {
                type: "Meal Ingredients",
                fromContentInfo: {
                    recipeDetails: recipeDetails
                },
                toAssociations: ["temp"],
                additionalContext: "",
                specialInstructions: ""
            };

            const response = await apiCall(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(recipeInfo),
            });

            if (response.status === 200 || response.status === 201) {
                const extractedRecipeId = response.data["Meal Ingredients"][0];
                await loadRecipeData(extractedRecipeId);
                setSnackbar({ open: true, message: 'Recipe processed successfully!', severity: 'success' });

                await deleteSource(extractedRecipeId);
            } else {
                throw new Error('Failed to process recipe');
            }

            setLocalEntree(prevEntree => ({ ...prevEntree, dish: value }));
        } catch (error) {
            console.error('Error processing entree dish:', error);
            setSnackbar({ open: true, message: `Failed to process entree: ${error.message}`, severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    }, [apiCall, setSnackbar]);

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleFileExtraction = async (file) => {
        const baseApiEndpoint = process.env.REACT_APP_API_URL;
        const url = `${baseApiEndpoint}/ai/extractor/extract-file`;

        const formData = new FormData();
        formData.append('file', file);
        const metadata = {
            extractTo: ["temp"],
            type: "Meal Ingredients",
            context: "",
            specialInstructions: ""
        };
        formData.append('metadata', new Blob([JSON.stringify(metadata)], {
            type: 'application/json'
        }));

        try {
            const response = await apiCall(url, {
                method: 'POST',
                data: formData,
            });

            if (response.status === 200 || response.status === 201) {
                const extractedRecipeId = response.data["Meal Ingredients"][0];
                await loadRecipeData(extractedRecipeId);
                setSnackbar({ open: true, message: 'Meal Ingredients extracted successfully!', severity: 'success' });

                await deleteSource(extractedRecipeId);
            } else {
                throw new Error('Failed to extract recipe from file');
            }
        } catch (error) {
            console.error('Error extracting recipe from file:', error);
            setSnackbar({ open: true, message: `Failed to extract recipe: ${error.message}`, severity: 'error' });
        }
    };

    const deleteSource = async (sourceId) => {
        try {
            const response = await apiCall(`/source/${sourceId}`, {
                method: 'DELETE',
            });

            if (response.status === 200) {
                console.log(`Source ${sourceId} deleted successfully`);
            } else {
                throw new Error('Failed to delete source');
            }
        } catch (error) {
            console.error('Error deleting source:', error);
            setSnackbar({ open: true, message: `Failed to delete source: ${error.message}`, severity: 'error' });
        }
    };

    const loadRecipeData = async (sourceId) => {
        try {
            const response = await apiCall(`/source/search?ids=${sourceId}&includeContent=true`, {
                method: 'GET',
            });

            if (response.status === 200 && response.data.sources && response.data.sources.length > 0) {
                const recipeData = response.data.sources[0].content.content;

                if (recipeData && recipeData.ingredients && recipeData.serving_size) {
                    const recipeServings = parseInt(recipeData.serving_size, 10);
                    const recipeIngredients = recipeData.ingredients.map(ingredient => ({
                        name: ingredient.name,
                        amount: ingredient.quantity || ingredient.amount,
                        unit: ingredient.unit,
                        originalAmount: ingredient.quantity || ingredient.amount,
                        originalServings: recipeServings
                    }));

                    const updatedEntree = {
                        ...localEntree,
                        ingredients: recipeIngredients,
                        servings: recipeServings,
                        desiredServings: 1,
                        data: recipeData
                    };

                    setLocalEntree(updatedEntree);
                    setEntree(updatedEntree);
                } else {
                    console.error('Invalid recipe data structure:', recipeData);
                    setSnackbar({ open: true, message: `Invalid recipe data structure`, severity: 'error' });
                }
            } else {
                console.error('Invalid API response:', response);
                setSnackbar({ open: true, message: `Failed to fetch recipe: Invalid response`, severity: 'error' });
            }
        } catch (error) {
            console.error('Error fetching recipe:', error);
            setSnackbar({ open: true, message: `Failed to fetch recipe: ${error.message}`, severity: 'error' });
        }
    };

    const handleServingsChange = (value) => {
        const newServings = parseFloat(value);

        if (isNaN(newServings) || newServings <= 0) {
            return;
        }

        const updatedIngredients = localEntree.ingredients.map(ingredient => {
            const originalAmount = parseFloat(ingredient.originalAmount);
            const originalServings = ingredient.originalServings || localEntree.servings || 1;
            const newAmount = (originalAmount * newServings / originalServings).toFixed(2);
            return {
                ...ingredient,
                amount: newAmount
            };
        });

        setLocalEntree(prevEntree => ({
            ...prevEntree,
            desiredServings: newServings,
            ingredients: updatedIngredients
        }));
    };

    const handleIngredientChange = (ingredientIndex, field, value) => {
        setLocalEntree(prevEntree => {
            const updatedIngredients = [...prevEntree.ingredients];
            updatedIngredients[ingredientIndex] = {
                ...updatedIngredients[ingredientIndex],
                [field]: value,
                originalAmount: field === 'amount' ? value : updatedIngredients[ingredientIndex].originalAmount
            };
            return { ...prevEntree, ingredients: updatedIngredients };
        });
    };

    const addIngredient = () => {
        setLocalEntree(prevEntree => ({
            ...prevEntree,
            ingredients: [...prevEntree.ingredients, { name: '', amount: '', unit: '', originalAmount: '', originalServings: prevEntree.desiredServings }]
        }));
    };

    const removeIngredient = (ingredientIndex) => {
        setLocalEntree(prevEntree => ({
            ...prevEntree,
            ingredients: prevEntree.ingredients.filter((_, i) => i !== ingredientIndex)
        }));
    };

    const handleSubmitEntree = useCallback(async () => {
        setIsLoading(true);
        const baseApiEndpoint = process.env.REACT_APP_API_URL;
        let url;
        let requestData;
        let headers = {};

        const submissionData = {
            mealType,
            name: localEntree.dish,
            ingredients: localEntree.ingredients.map(ingredient => ({
                name: ingredient.name,
                quantity: ingredient.amount,
                unit: ingredient.unit,
            })),
        };

        if (selectedFile) {
            url = `${baseApiEndpoint}/ai/extractor/extract-file`;
            const formData = new FormData();
            formData.append('file', selectedFile);

            const metadata = {
                extractTo: ["temp"],
                type: "Entree",
                context: JSON.stringify(submissionData),
                specialInstructions: ""
            };
            formData.append('metadata', new Blob([JSON.stringify(metadata)], {
                type: 'application/json'
            }));

            requestData = formData;
        } else {
            url = `${baseApiEndpoint}/ai/extractor/extract`;
            requestData = JSON.stringify({
                fromContentInfo: {
                    entreeDetails: {
                        name: "Entree Details",
                        contentType: "application/json",
                        content: JSON.stringify(submissionData)
                    }
                },
                toAssociations: ["temp"],
                type: "Entree",
                additionalContext: "",
                specialInstructions: ""
            });
            headers['Content-Type'] = 'application/json';
        }

        try {
            const response = await apiCall(url, {
                method: 'POST',
                headers: headers,
                data: requestData,
            });

            if (response.status === 200 || response.status === 201) {
                const entreeId = response.data.Entree[0];

                const entreeResponse = await apiCall(`/source/search?ids=${entreeId}&includeContent=true`, {
                    method: 'GET',
                });

                if (entreeResponse.status === 200 && entreeResponse.data.sources && entreeResponse.data.sources.length > 0) {
                    const entreeData = entreeResponse.data.sources[0].content.content;

                    setLocalEntree(prevEntree => ({
                        ...prevEntree,
                        data: entreeData
                    }));
                    onUpdate(index, entreeData);
                    setSnackbar({ open: true, message: 'Entree submitted successfully!', severity: 'success' });
                    setIsEditing(false);

                    await deleteSource(entreeId);
                } else {
                    throw new Error('Failed to fetch entree data');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbar({ open: true, message: `Entree submission failed: ${error.message}`, severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    }, [apiCall, localEntree, mealType, index, onUpdate, setSnackbar, selectedFile, setIsLoading, setIsEditing, deleteSource]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const EntreeDisplayMode = () => {
        if (!localEntree.data) return <Typography>No entree data available</Typography>;

        const nutritionKeys = ['calories', 'fat', 'protein', 'carbs', 'fiber', 'net_carbs'];
        const nutritionEstimate = localEntree.data.nutrition_estimate || {};

        return (
            <>
                <Typography variant="h3" gutterBottom>
                    {localEntree.data.name || 'Unnamed Entree'}
                </Typography>
                {localEntree.data.description && (
                    <Typography variant="body2" paragraph>
                        {localEntree.data.description}
                    </Typography>
                )}
                {localEntree.data.ingredients && localEntree.data.ingredients.length > 0 && (
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Ingredients
                        </Typography>
                        <Grid container spacing={2}>
                            {localEntree.data.ingredients.map((ingredient, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Paper elevation={1} sx={{ p: 2, height: '100%' }}>
                                        <Typography variant="body1">{ingredient.name}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {ingredient.quantity} {ingredient.unit}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
                {Object.keys(nutritionEstimate).length > 0 && (
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Nutrition Facts (per serving)
                        </Typography>
                        <Grid container spacing={2}>
                            {nutritionKeys.map((key) => (
                                <Grid item xs={6} sm={4} md={2} key={key}>
                                    <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="body2" color="text.secondary">
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </Typography>
                                        <Typography variant="h6">
                                            {typeof nutritionEstimate[key] === 'number'
                                                ? nutritionEstimate[key].toFixed(2)
                                                : nutritionEstimate[key] || 'N/A'}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </>
        );
    };

    return (
        <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
            <Box sx={{ display: isEditing ? 'block' : 'none' }}>
                <Typography variant="h6" gutterBottom>
                    Entree Details {isLoading && <CircularProgress size={20} />}
                </Typography>
                <TextOrSourceInput
                    rootAssociation="Recipes"
                    textPlaceholder="Describe the entree, or select a recipe."
                    onUpdate={handleEntreeDishChange}
                    sourceButtonPlaceholder="Select Recipe"
                    fileButtonPlaceholder="From File"
                    textButtonPlaceholder="Submit Description"
                    types={["Recipe"]}
                    disabled={isSubmitting || isLoading}
                    allowText={true}
                    allowFile={true}
                    allowSource={true}
                />
                <TextField
                    label="Desired Servings"
                    type="number"
                    value={localEntree.desiredServings || 1}
                    onChange={(e) => handleServingsChange(e.target.value)}
                    sx={{ mt: 2, mb: 1 }}
                    inputProps={{ min: ".25", step: "0.25" }}
                    disabled={isSubmitting || isLoading}
                />
                <Box sx={{ mt: 2, mb: 1, ml: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Ingredients
                    </Typography>
                    {localEntree.ingredients.map((ingredient, ingredientIndex) => (
                        <Stack
                            key={ingredientIndex}
                            direction={{ xs: 'column', md: 'row' }}
                            spacing={1}
                            alignItems="flex-start"
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                label="Ingredient"
                                value={ingredient.name}
                                onChange={(e) => handleIngredientChange(ingredientIndex, 'name', e.target.value)}
                                disabled={isSubmitting || isLoading}
                                sx={{
                                    flexGrow: 1,
                                    minWidth: { xs: '100%', md: '200px' },
                                    maxWidth: { md: '400px' }
                                }}
                            />
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    flexGrow: { md: 1 },
                                    justifyContent: { xs: 'space-between', md: 'flex-start' }
                                }}
                            >
                                <TextField
                                    label="Amount"
                                    value={ingredient.amount}
                                    onChange={(e) => handleIngredientChange(ingredientIndex, 'amount', e.target.value)}
                                    disabled={isSubmitting || isLoading}
                                    sx={{ width: { xs: 'calc(50% - 28px)', md: '100px' } }}
                                />
                                <TextField
                                    label="Unit"
                                    value={ingredient.unit}
                                    onChange={(e) => handleIngredientChange(ingredientIndex, 'unit', e.target.value)}
                                    disabled={isSubmitting || isLoading}
                                    sx={{ width: { xs: 'calc(50% - 28px)', md: '100px' } }}
                                />
                                <IconButton
                                    onClick={() => removeIngredient(ingredientIndex)}
                                    disabled={isSubmitting || isLoading}
                                    sx={{ alignSelf: 'center' }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    ))}
                    <Button
                        startIcon={<AddIcon />}
                        onClick={addIngredient}
                        sx={{ mt: 1 }}
                        disabled={isSubmitting || isLoading}
                    >
                        Add Ingredient
                    </Button>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        onClick={handleCancel}
                        color="primary"
                        disabled={isSubmitting || isLoading}
                    >
                        Cancel Editing
                    </Button>
                    <Button
                        onClick={handleSubmitEntree}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || isLoading}
                    >
                        Submit Entree
                    </Button>
                </Box>
            </Box>

            <Box sx={{ display: isEditing ? 'none' : 'block' }}>
                <EntreeDisplayMode />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        onClick={handleEdit}
                        startIcon={<EditIcon />}
                        color="primary"
                    >
                        Edit Entree
                    </Button>
                    <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => onRemove(index)}
                        color="primary"
                    >
                        Remove Entree
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default EntreeForm;