import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Container,
    Typography,
    Button,
    Modal,
    Box,
    Snackbar,
    Alert,
    Paper,
    useTheme,
    useMediaQuery
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RAGSourcesChat from "../../../../components/rag/RAGSourcesChat";
import AddSourceForm from "../../library/addsource/AddSourceForm";
import { useSystemCollection } from "../../../../context/systemColections";
import { useSourceName} from "../../../../context/useSourceName";

const Tutor = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState();
    const [tutorCollectionId, setTutorCollectionId] = useState(null);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const { getSystemCollectionIdByName, isLoading, error } = useSystemCollection();
    const { getSourceNameById } = useSourceName();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchTutorCollectionId = async () => {
            try {
                const id = await getSystemCollectionIdByName("Tutor");
                setTutorCollectionId(id);
            } catch (err) {
                console.error("Error fetching Tutor collection ID:", err);
            }
        };

        fetchTutorCollectionId();
    }, [getSystemCollectionIdByName]);

    const handleOpenModal = useCallback(() => setIsModalOpen(true), []);
    const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

    const handleCollectionChange = useCallback((collection) => {
        setSelectedCollection(collection);
    }, []);

    const handleFormUpdate = useCallback(async () => {
        handleCloseModal();
        const collectionId = selectedCollection || tutorCollectionId;
        try {
            const collectionName = await getSourceNameById(collectionId);
            setFeedbackMessage(`Source added to ${collectionName}, it will now be available to the tutor in the set collection(s).`);
            setFeedbackOpen(true);
        } catch (error) {
            console.error("Error fetching collection name:", error);
            setFeedbackMessage("Source added successfully, it will now be available to the tutor in the set collection(s).");
            setFeedbackOpen(true);
        }
    }, [handleCloseModal, selectedCollection, tutorCollectionId, getSourceNameById]);

    const handleCloseFeedback = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedbackOpen(false);
    }, []);

    const modalContent = useMemo(() => (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="add-source-modal"
            aria-describedby="modal-to-add-new-source"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : '80%',
                maxWidth: 800,
                maxHeight: '90vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: theme.shape.borderRadius,
            }}>
                <AddSourceForm
                    onUpdate={handleFormUpdate}
                    setAssociations={[selectedCollection || tutorCollectionId]}
                />
            </Box>
        </Modal>
    ), [isModalOpen, handleCloseModal, handleFormUpdate, selectedCollection, tutorCollectionId, isMobile, theme]);

    const addButton = useMemo(() => (
        <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
            disabled={!tutorCollectionId}
            sx={{ whiteSpace: 'nowrap' }}
        >
            Add Source
        </Button>
    ), [handleOpenModal, tutorCollectionId]);

    if (isLoading) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Typography variant="h6">Loading...</Typography>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Alert severity="error">Error: {error.message}</Alert>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems={isMobile ? 'flex-start' : 'center'} mb={2}>
                    <Typography variant="h4" component="h1" gutterBottom={isMobile} sx={{ mb: isMobile ? 2 : 0 }}>
                        Tutor
                    </Typography>
                    {addButton}
                </Box>

                {tutorCollectionId && (
                    <Box mt={4}>
                        <RAGSourcesChat
                            rootCollection={tutorCollectionId}
                            onCollectionChange={handleCollectionChange}
                        />
                    </Box>
                )}
            </Paper>

            {modalContent}

            <Snackbar
                open={feedbackOpen}
                autoHideDuration={6000}
                onClose={handleCloseFeedback}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseFeedback} severity="success" variant="filled" sx={{ width: '100%' }}>
                    {feedbackMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default React.memo(Tutor);