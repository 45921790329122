// src/apps/subNavConfig.js
import React from 'react';
import Apps from './apps/Apps';
import Advanced from './advanced/Advanced';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';

const subNav = {
    header: 'AIssistant',
    links: [
        {
            name: 'Apps',
            path: '/apps',
            icon: AppsIcon,
            element: <Apps />,
            title: 'AI Applications',
            description: 'Explore our suite of AI-powered applications',
            buttonText: 'Explore Apps'
        },
        {
            name: 'Advanced',
            path: '/advanced',
            icon: SettingsIcon,
            element: <Advanced />,
            title: 'Advanced Settings',
            description: 'Configure advanced AI settings and parameters',
            buttonText: 'Configure'
        },
    ],
    routes: [
        { path: '/apps', element: <Apps /> },
        { path: '/advanced', element: <Advanced /> },
    ],
};

export default subNav;