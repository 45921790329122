import React from 'react';
import CreateCollectionForm from './CreateCollectionForm';

function CreateCollection() {
    return (
        <div className="App">
            <CreateCollectionForm />
        </div>
    );
}

export default CreateCollection;