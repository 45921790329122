// src/apps/subNavConfig.js
import React from 'react';
import Terminology from "./terminology/Terminology";
import ProfessorCalendar from "./calendar/ProfessorCalendar";
import Tutor from "./tutor/Tutor";
import Assignments from "./assignments/Assignments";
import QuizMe from "./quizme/QuizMe";

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TranslateIcon from '@mui/icons-material/Translate';
import QuizIcon from '@mui/icons-material/Quiz';

const subNav = {
    header: 'Professor',
    links: [
        {
            name: 'Tutor',
            path: '/apps/professor/tutor',
            icon: SchoolIcon,
            element: <Tutor />,
            title: 'AI Tutor',
            description: 'Ask questions, the Tutor will search use your sources!',
            buttonText: 'Start Tutoring'
        },
        {
            name: 'Terminology',
            path: '/apps/professor/terminology',
            icon: TranslateIcon,
            element: <Terminology />,
            title: 'Terminology Guide',
            description: 'View terminology generated from your sources',
            buttonText: 'Explore Terminology'
        },
        {
            name: 'QuizMe',
            path: '/apps/professor/quizme',
            icon: QuizIcon,
            element: <QuizMe />,
            title: 'QuizMe',
            description: 'Create and take quizzes over your material',
            buttonText: 'Start Quiz'
        },
        {
            name: 'Calendar',
            path: '/apps/professor/calendar',
            icon: CalendarTodayIcon,
            element: <ProfessorCalendar />,
            title: 'Professor Calendar',
            description: 'View and manage important dates',
            buttonText: 'Open Calendar'
        },
        {
            name: 'Assignments',
            path: '/apps/professor/assignments',
            icon: AssignmentIcon,
            element: <Assignments />,
            title: 'Assignments Manager',
            description: 'Create and manage student assignments',
            buttonText: 'Manage and track your assignments'
        },
    ],
    routes: [
        { path: '/apps/professor/calendar', element: <ProfessorCalendar /> },
        { path: '/apps/professor/tutor', element: <Tutor /> },
        { path: '/apps/professor/assignments', element: <Assignments /> },
        { path: '/apps/professor/terminology', element: <Terminology /> },
        { path: '/apps/professor/quizme', element: <QuizMe /> },
    ]
};

export default subNav;