import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    Paper,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Alert,
    Snackbar
} from '@mui/material';
import useApi from "./ApiService";

const CreditManagement = ({ creditId }) => {
    const [credits, setCredits] = useState('');
    const [description, setDescription] = useState('');
    const [balance, setBalance] = useState(0);
    const [history, setHistory] = useState({ allocationHistory: [], usageHistory: [] });
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { apiCall } = useApi();

    const fetchBalance = async () => {
        try {
            const response = await apiCall(`/credits/${creditId}/balance`, {
                method: 'GET'
            });
            if (response.status === 200) {
                setBalance(response.data);
            }
        } catch (error) {
            setError('Failed to fetch balance');
            setBalance(0);
            console.error('Failed to fetch balance:', error);
        }
    };

    const fetchHistory = async () => {
        try {
            const response = await apiCall(`/credits/${creditId}/history`, {
                method: 'GET'
            });
            if (response.status === 200) {
                // If we get an empty array, initialize with empty allocation and usage arrays
                setHistory(Array.isArray(response.data) && response.data.length === 0
                    ? { allocationHistory: [], usageHistory: [] }
                    : response.data
                );
            }
        } catch (error) {
            setError('Failed to fetch history');
            // Always ensure we have the correct structure even on error
            setHistory({ allocationHistory: [], usageHistory: [] });
            console.error('Failed to fetch history:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBalance();
        fetchHistory();
    }, [creditId]);

    const handleAddCredits = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await apiCall(`/credits/${creditId}/add`, {
                method: 'POST',
                params: {
                    creditsToAdd: parseInt(credits),
                    description
                }
            });

            if (response.data.includes('Error')) {
                setError(response.data);
                return;
            }

            setSuccess('Credits added successfully');
            setCredits('');
            setDescription('');
            fetchBalance();
            fetchHistory();

        } catch (error) {
            setError(error.response?.data || error.message || 'Failed to add credits');
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Combine and sort allocation and usage history
    const combinedHistory = [...(history.allocationHistory || []).map(item => ({
        date: item.dateAdded,
        type: 'Allocation',
        amount: item.creditsAdded,
        description: item.description
    })), ...(history.usageHistory || []).map(item => ({
        date: item.dateUsed,
        type: 'Usage',
        amount: -item.creditsUsed,
        description: item.description
    }))].sort((a, b) => new Date(b.date) - new Date(a.date));

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError('')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error" onClose={() => setError('')}>{error}</Alert>
            </Snackbar>

            <Snackbar
                open={!!success}
                autoHideDuration={6000}
                onClose={() => setSuccess('')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>
            </Snackbar>

            <Typography variant="h6" gutterBottom>
                Credit Account Management
            </Typography>

            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Current Balance: {balance} credits
                </Typography>

                <Box component="form" onSubmit={handleAddCredits} sx={{ mt: 2 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="credits"
                        label="Credits to Add"
                        type="number"
                        value={credits}
                        onChange={(e) => setCredits(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="description"
                        label="Description (Optional)"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2 }}
                        disabled={!credits}
                    >
                        Add Credits
                    </Button>
                </Box>
            </Paper>

            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {combinedHistory
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((record, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{new Date(record.date).toLocaleDateString()}</TableCell>
                                        <TableCell>{record.type}</TableCell>
                                        <TableCell sx={{
                                            color: record.amount >= 0 ? 'success.main' : 'error.main'
                                        }}>
                                            {record.amount >= 0 ? '+' : ''}{record.amount}
                                        </TableCell>
                                        <TableCell>{record.description || '-'}</TableCell>
                                    </TableRow>
                                ))}
                            {combinedHistory.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        No credit history available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={combinedHistory.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default CreditManagement;