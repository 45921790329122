import React, { useMemo } from 'react';
import RAGSimpleSpecificSourcesChat from "../../../../components/rag/RAGSimpleSpecificSourcesChat";

const FoodInventoryRAGChat = ({ items }) => {
    const filteredItemIds = useMemo(() => {
        return items.map(item => item.id);
    }, [items]);

    const specialInstructions = "The user is chatting with you about their food inventory. Provide a detailed and helpful response. You are a nutritionist.";
    const dietTitle = "Chat with the Nutritionist about your food inventory";

    return (
        <RAGSimpleSpecificSourcesChat
            ids={filteredItemIds}
            context=""
            specialInstructions={specialInstructions}
            title={dietTitle}
        />
    );
};

export default FoodInventoryRAGChat;