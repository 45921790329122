import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Box, Tooltip, Link, IconButton, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentDisplay from "./ContentDisplay";
import RAGGeneration from "../rag/RAGGeneration";
import ExpandableCard from './ExpandableCard';
import useApi from "../ApiService";

function SourceItem({
                        sourceRecord,
                        renderCustomContent,
                        onSourceLoad,
                        onContentLoad,
                        onSourceDelete,
                        expanded = false,
                        showRagGenerations = true,
                        eagerlyLoadContent = false,
                        autoLoadExpandedContent = false,
                        showId = false,
                        allowDelete = true
                    }) {
    const { apiCall } = useApi();
    const [isContentVisible, setIsContentVisible] = useState(eagerlyLoadContent);
    const [loadedContent, setLoadedContent] = useState(null);
    const [copyTooltip, setCopyTooltip] = useState('Copy ID');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const goDeeper = "You are a professor writing a deep and detailed informative lesson. You are giving a student a full lesson explaining in full detail the following content. Expand and give a full lesson fully explaining the content."

    useEffect(() => {
        if (onSourceLoad) {
            onSourceLoad(sourceRecord);
        }
    }, [sourceRecord, onSourceLoad]);

    useEffect(() => {
        if (autoLoadExpandedContent && expanded) {
            setIsContentVisible(true);
        }
    }, [autoLoadExpandedContent, expanded]);

    const handleContentLoaded = useCallback((content) => {
        console.log('Content loaded:', content);
        setLoadedContent(content);
        if (onContentLoad) {
            onContentLoad(sourceRecord.id, content);
        }
    }, [onContentLoad, sourceRecord.id]);

    const handleToggleContent = useCallback(() => {
        setIsContentVisible(true);
    }, []);

    const ragContent = useCallback(() => {
        if (sourceRecord.contentType === 'image' || sourceRecord.contentType === 'application/pdf') {
            return [sourceRecord.id];
        }
        return loadedContent ? loadedContent.content : '';
    }, [loadedContent, sourceRecord.contentType, sourceRecord.id]);

    const isContentViaSourceIdList = useCallback(() => {
        return sourceRecord.contentType.startsWith('image/') || sourceRecord.contentType === 'application/pdf';
    }, [sourceRecord.contentType]);

    const handleCopyId = async () => {
        try {
            await navigator.clipboard.writeText(sourceRecord.id);
            setCopyTooltip('Copied!');
            setTimeout(() => setCopyTooltip('Copy ID'), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setCopyTooltip('Copy failed');
        }
    };

    const handleDelete = useCallback(async () => {
        try {
            const sourceUrl = `/source/${sourceRecord.id}`;
            const response = await apiCall(sourceUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Source deleted successfully');

            if (onSourceDelete) {
                onSourceDelete(sourceRecord);
            }

        } catch (error) {
            console.error('Error deleting source:', error);
        }
    }, [sourceRecord, onSourceDelete, apiCall]);

    const renderTextWithLinks = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = text.split(urlRegex);
        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <Link
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {part}
                    </Link>
                );
            }
            return part;
        });
    };

    const cardContent = (
        <>
            {renderCustomContent ? (
                renderCustomContent(sourceRecord)
            ) : (
                <>
                    <Typography variant="subtitle2" gutterBottom>Description:</Typography>
                    <Typography>{renderTextWithLinks(sourceRecord.description)}</Typography>
                    {!isContentVisible && !eagerlyLoadContent && (
                        <Typography
                            color="primary"
                            sx={{ mt: 1, mb: 1, cursor: 'pointer' }}
                            onClick={handleToggleContent}
                        >
                            Show Content
                        </Typography>
                    )}
                    {(isContentVisible || eagerlyLoadContent) && (
                        <>
                            <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>Content:</Typography>
                            <ContentDisplay
                                contentUri={sourceRecord.uri}
                                onContentLoaded={handleContentLoaded}
                                contentType={sourceRecord.contentType}
                            />
                            {(loadedContent || isContentViaSourceIdList()) && showRagGenerations && (
                                <>
                                    <RAGGeneration
                                        contentSupplier={ragContent}
                                        specialInstructionsSupplier={()=>{return ""}}
                                        contextSupplier={()=>{return ""}}
                                        buttonText="Quick Question"
                                        contentIsViaSourceIdList={isContentViaSourceIdList()}
                                    />
                                    <RAGGeneration
                                        contentSupplier={ragContent}
                                        specialInstructionsSupplier={()=>{return ""}}
                                        contextSupplier={()=>{return ""}}
                                        querySupplier={()=>{return goDeeper}}
                                        buttonText="Or Go Deeper"
                                        contentIsViaSourceIdList={isContentViaSourceIdList()}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                {showId &&
                    <Tooltip title={copyTooltip}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            onClick={handleCopyId}
                        >
                            ID: {sourceRecord.id}
                            <ContentCopyIcon fontSize="small" sx={{ ml: 1 }} />
                        </Typography>
                    </Tooltip>
                }
                {allowDelete &&
                    <Box>
                        {showDeleteConfirmation && (
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<CheckCircleIcon />}
                                onClick={handleDelete}
                                sx={{ mr: 2 }}
                            >
                                Confirm Delete
                            </Button>
                        )}
                        <IconButton
                            color="error"
                            onClick={() => setShowDeleteConfirmation(true)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
        </>
    );

    return (
        <>
            <ExpandableCard
                title={sourceRecord.name}
                initiallyExpanded={expanded}
                onExpand={(isExpanded) => {
                    if (autoLoadExpandedContent && isExpanded) {
                        setIsContentVisible(true);
                    }
                }}
            >
                {cardContent}
            </ExpandableCard>
            {/* Hidden ContentDisplay for eager loading */}
            {eagerlyLoadContent && !expanded && (
                <div style={{ display: 'none' }}>
                    <ContentDisplay
                        contentUri={sourceRecord.uri}
                        onContentLoaded={handleContentLoaded}
                        contentType={sourceRecord.contentType}
                    />
                </div>
            )}
        </>
    );
}

export default SourceItem;