import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const VoiceRecorder = ({ onRecordingComplete }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState('prompt'); // 'prompt', 'granted', 'denied'
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showPermissionDialog, setShowPermissionDialog] = useState(false);
    const [recordingStartTime, setRecordingStartTime] = useState(null);

    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const recordButtonRef = useRef(null);

    // Check initial permission status
    useEffect(() => {
        const checkPermission = async () => {
            try {
                const result = await navigator.permissions.query({ name: 'microphone' });
                setPermissionStatus(result.state);

                // Listen for permission changes
                result.onchange = () => {
                    setPermissionStatus(result.state);
                };
            } catch (err) {
                console.error('Error checking permission:', err);
            }
        };

        checkPermission();
    }, []);

    const requestPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            stream.getTracks().forEach(track => track.stop()); // Clean up the test stream
            setPermissionStatus('granted');
            setShowPermissionDialog(false);
            setSnackbarMessage('Microphone access granted');
            setSnackbarOpen(true);
        } catch (err) {
            console.error('Permission denied:', err);
            setPermissionStatus('denied');
            setSnackbarMessage('Microphone access denied');
            setSnackbarOpen(true);
        }
    };

    const stopRecording = useCallback(() => {
        if (mediaRecorder.current && isRecording) {
            mediaRecorder.current.stop();
            setIsRecording(false);
        }
    }, [isRecording]);

    useEffect(() => {
        const handleGlobalPointerUp = (e) => {
            if (isRecording && e.target !== recordButtonRef.current) {
                stopRecording();
            }
        };

        document.addEventListener('pointerup', handleGlobalPointerUp);
        return () => document.removeEventListener('pointerup', handleGlobalPointerUp);
    }, [isRecording, stopRecording]);

    useEffect(() => {
        if (mediaRecorder.current) {
            mediaRecorder.current.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                const recordingDuration = Date.now() - recordingStartTime;

                if (recordingDuration >= 500) {
                    onRecordingComplete(audioBlob);
                } else {
                    setSnackbarMessage('Recording too short (minimum 0.5 seconds)');
                    setSnackbarOpen(true);
                }

                audioChunks.current = [];
            };
        }
    }, [recordingStartTime, onRecordingComplete]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new MediaRecorder(stream);
            audioChunks.current = [];

            mediaRecorder.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorder.current.start();
            setIsRecording(true);
            setRecordingStartTime(Date.now());
        } catch (err) {
            console.error('Error starting recording:', err);
            setSnackbarMessage('Error accessing microphone');
            setSnackbarOpen(true);
        }
    };

    const handleRecordingToggle = (e) => {
        e.preventDefault();

        if (permissionStatus === 'prompt') {
            setShowPermissionDialog(true);
            return;
        }

        if (permissionStatus === 'denied') {
            setSnackbarMessage('Please enable microphone access in your browser settings');
            setSnackbarOpen(true);
            return;
        }

        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    return (
        <>
            <Button
                ref={recordButtonRef}
                variant="contained"
                color={isRecording ? "secondary" : "primary"}
                onPointerDown={handleRecordingToggle}
                onPointerUp={permissionStatus === 'granted' ? handleRecordingToggle : undefined}
                sx={{ marginLeft: 1, touchAction: 'none' }}
            >
                {isRecording ? <MicOffIcon /> :
                    permissionStatus === 'prompt' ? <HelpOutlineIcon /> :
                        <MicIcon />}
            </Button>

            <Dialog
                open={showPermissionDialog}
                onClose={() => setShowPermissionDialog(false)}
            >
                <DialogTitle>Microphone Access Required</DialogTitle>
                <DialogContent>
                    <Typography>
                        To use voice recording, we need permission to access your microphone.
                        Click "Allow" to continue when the browser prompt appears.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowPermissionDialog(false)} color="inherit">
                        Cancel
                    </Button>
                    <Button onClick={requestPermission} color="primary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </>
    );
};

export default VoiceRecorder;