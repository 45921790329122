import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Calendar from "./Calendar";
import { CircularProgress, Typography, Box } from '@mui/material';
import AssociationsDropdown from "../AssociationsDropdown";
import useApi from "../ApiService";

const ManagedCalendar = ({ rootAssociation, selected, onCollectionSelect, onCollectionsUpdate }) => {
    const { apiCall } = useApi();
    const [sources, setSources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedAssociation, setSelectedAssociation] = useState(null);

    const fetchSources = useCallback(async (associationsParam) => {
        if (associationsParam) {
            setLoading(true);

            try {
                const params = new URLSearchParams({
                    types: 'Date',
                    includeContent: 'true'
                });

                if (associationsParam) {
                    associationsParam.forEach(association => {
                        params.append('associations', association);
                    });
                } else {
                    params.append('rootAssociations', rootAssociation);
                }

                const response = await apiCall(`/source/search?${params.toString()}`);
                console.log('API Response:', response.data);
                if (response.data && response.data.sources) {
                    setSources(response.data.sources);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (err) {
                setError('Failed to fetch calendar data. Please try again later.');
                console.error('Error fetching sources:', err);
            } finally {
                setLoading(false);
            }
        }
    }, [rootAssociation, apiCall]);

    useEffect(() => {
        fetchSources();
    }, [fetchSources]);

    const handleOnCollectionsUpdate = useCallback((selectionInfo) => {
        if (onCollectionsUpdate) {
            onCollectionsUpdate(selectionInfo);
        }
    }, [onCollectionsUpdate]);

    const handleCollectionSelect = useCallback((selectionInfo) => {
        console.log("selectionInfo:", selectionInfo);
        const { allDescendants, id } = selectionInfo;
        fetchSources(allDescendants);
        setSelectedAssociation(id);

        // Update URL
        const searchParams = new URLSearchParams();
        allDescendants.forEach(descendant => {
            searchParams.append('associations', descendant);
        });
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);

        if (onCollectionSelect) {
            onCollectionSelect(selectionInfo)
        }
    }, [fetchSources, onCollectionSelect]);

    const memoizedAssociationsDropdown = useMemo(() => (
        <AssociationsDropdown
            rootAssociation={rootAssociation}
            onCollectionSelect={handleCollectionSelect}
            selected={selected || rootAssociation}
            onCollectionsUpdate={handleOnCollectionsUpdate}
        />
    ), [rootAssociation, handleCollectionSelect, selected, handleOnCollectionsUpdate]);

    let content;
    if (loading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    } else if (error) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    } else {
        content = <Calendar
            sources={sources}
            selectedAssociation={selectedAssociation}
        />;
    }

    return (
        <>
            {memoizedAssociationsDropdown}
            {content}
        </>
    );
};

export default ManagedCalendar;