import React, { useMemo } from 'react';
import { parseISO, compareDesc } from 'date-fns';
import RAGSimpleSpecificSourcesChat from "../../../../../components/rag/RAGSimpleSpecificSourcesChat";

const DietQueryRAGChat = ({ items, startDate, endDate }) => {
    const filteredItemIds = useMemo(() => {
        return items
            .filter(item => {
                const itemDate = parseISO(item.content.content.mealDate);
                return itemDate >= parseISO(startDate) && itemDate <= parseISO(endDate);
            })
            .sort((a, b) => compareDesc(parseISO(a.content.content.mealDate), parseISO(b.content.content.mealDate)))
            .map(item => item.id);
    }, [items, startDate, endDate]);

    const dietSpecialInstructions = "The user is chatting with you about their diet based on their recorded meals within the selected time period. Provide a detailed and helpful response. You are a nutritionist.";

    const dietTitle = "Chat with the Nutritionist about your diet";
    const dietSubtitle = startDate === endDate ? startDate : `${startDate} to ${endDate}`;

    return (
        <RAGSimpleSpecificSourcesChat
            ids={filteredItemIds}
            context=""
            specialInstructions={dietSpecialInstructions}
            title={dietTitle}
            subtitle={dietSubtitle}
        />
    );
};

export default DietQueryRAGChat;