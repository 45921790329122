import React from 'react';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GymLog from "./gymlog/GymLog";
import PersonalTrainer from "./personaltrainer/PersonalTrainer";
import ListAltIcon from '@mui/icons-material/ListAlt';

const subNav = {
    header: 'Personal Trainer (Gym)',
    links: [
        {
            name: 'Personal Trainer (Gym)',
            path: '/apps/personaltrainer/gym/personaltrainer',
            icon: FitnessCenterIcon,
            element: <PersonalTrainer />,
            title: 'Personal Trainer (Gym)',
            description: 'Report your sets and get advice.',
            buttonText: 'Start logging'
        },
        {
            name: 'Gym Log',
            path: '/apps/personaltrainer/gym/gymlog',
            icon: ListAltIcon,
            element: <GymLog />,
            title: 'Gym Log',
            description: 'See your tracked sets and reps and see your progress.',
            buttonText: 'Checkout your progress'
        }
    ],
    routes: [
        { path: '/apps/personaltrainer/gym/gymlog', element: <GymLog /> },
        { path: '/apps/personaltrainer/gym/personaltrainer', element: <PersonalTrainer /> },
    ]
};

export default subNav;