import React, {useState, useCallback, useMemo} from 'react';
import {
    TextField, Button, Card, CardContent, Container, Grid, CircularProgress, Alert,
    Typography, Slider, Box, useTheme, useMediaQuery, Accordion, AccordionSummary, AccordionDetails,
    Checkbox, FormControlLabel, Switch
} from '@mui/material';

import AssociationsDropdown from "../AssociationsDropdown";
import useApi from "../ApiService";
import {useSourceName} from "../../context/useSourceName";
import TooltipWrapper from "../StyledTooltip";
// import HighlightableTextBox from "../SelectableTextBox";
import ReactMarkdown from "react-markdown";
import ManagedList from "../list/ManagedList";

const RAGSourcesChat = ({rootCollection}) => {
    const {apiCall} = useApi();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {getSourceNameByUri} = useSourceName();

    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedAssociation, setSelectedAssociation] = useState(null);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [similarityScore, setSimilarityScore] = useState(0.65);
    const [maxSources, setMaxSources] = useState(5);
    const [temperature, setTemperature] = useState(0.1);
    const [chatHistory, setChatHistory] = useState([]);
    const [useSources, setUseSources] = useState(true);

    const handlePromptChange = useCallback((e) => {
        setPrompt(e.target.value);
    }, []);

    const handleCollectionChange = useCallback((association) => {
        setSelectedAssociation(association);
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (prompt.trim() === '') return;

        setLoading(true);
        setError('');

        try {
            let url, requestData;

            if (useSources) {
                const queryParams = new URLSearchParams();

                if (selectedAssociation && selectedAssociation.allDescendants) {
                    selectedAssociation.allDescendants.forEach((id) => {
                        queryParams.append('associations', id);
                    });
                }

                queryParams.append('similarityScore', similarityScore);
                queryParams.append('maxSources', maxSources);
                queryParams.append('temperature', temperature);

                url = `/ai/rag/source?${queryParams.toString()}`;
                requestData = {
                    query: prompt,
                    source_ids: [],
                    chatHistory: chatHistory
                        .filter(entry => entry.includeInHistory)
                        .map(entry => ({
                            prompt: entry.prompt,
                            response: entry.selectedText || entry.response,
                            uniqueReferences: entry.uniqueReferences
                        }))
                };
            } else {
                url = '/ai/rag/content';
                requestData = {
                    query: prompt,
                    content: "No content, see chat history for further content.",
                    temperature: temperature,
                    chatHistory: chatHistory
                        .filter(entry => entry.includeInHistory)
                        .map(entry => ({
                            prompt: entry.prompt,
                            response: entry.selectedText || entry.response,
                            uniqueReferences: entry.uniqueReferences
                        }))
                };
            }

            const response = await apiCall(url, {
                method: 'POST',
                data: requestData
            });

            const newResponse = response.data.generation;
            const newContext = response.data.content || [];

            const uniqueReferences = {};
            newContext.forEach(ctx => {
                if (!uniqueReferences[ctx.uri]) {
                    uniqueReferences[ctx.uri] = ctx;
                }
            });

            const newChatEntry = {
                prompt,
                response: newResponse,
                uniqueReferences: Object.values(uniqueReferences),
                selectedText: newResponse,
                includeInHistory: true,
                highlights: [],
                showOptions: false,
                mode: 'default'
            };

            setChatHistory(prevHistory => [newChatEntry, ...prevHistory]);
            setPrompt('');
            setUseSources(false); // Turn off useSources after receiving a response
        } catch (error) {
            console.error('Error fetching response:', error);
            setError('Error fetching response. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [prompt, selectedAssociation, similarityScore, maxSources, temperature, apiCall, chatHistory, useSources]);

    const handleClearConversation = useCallback(() => {
        setChatHistory([]);
        setError('');
        setUseSources(true); // Reset useSources to true when clearing conversation
    }, []);

    const toggleAdvanced = useCallback(() => {
        setShowAdvanced(prev => !prev);
    }, []);

    const handleHighlightChange = useCallback((index, newHighlights, newMode, newShowOptions) => {
        setChatHistory(prevHistory => {
            const newHistory = [...prevHistory];
            newHistory[index] = {
                ...newHistory[index],
                selectedText: newHighlights[0]?.text || newHistory[index].response,
                highlights: newHighlights,
                mode: newMode,
                showOptions: newShowOptions
            };
            return newHistory;
        });
    }, []);

    const handleIncludeInHistoryChange = useCallback((index, checked) => {
        setChatHistory(prevHistory => {
            const newHistory = [...prevHistory];
            newHistory[index].includeInHistory = checked;
            return newHistory;
        });
    }, []);

    const renderChatHistory = useMemo(() => {
        return chatHistory.map((entry, index) => (
            <Grid item key={index} xs={12}>
                <Card sx={{backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary}}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom><strong>Prompt</strong></Typography>
                        <Typography variant="body1" component="div" paragraph>{entry.prompt}</Typography>
                        <Typography variant="h6" gutterBottom><strong>Response</strong></Typography>
                        {/*<HighlightableTextBox*/}
                        {/*    text={entry.response}*/}
                        {/*    onHighlightChange={(highlights, mode, showOptions) => handleHighlightChange(index, highlights, mode, showOptions)}*/}
                        {/*    isScrollable={false}*/}
                        {/*    initialHighlights={entry.highlights}*/}
                        {/*    initialMode={entry.mode}*/}
                        {/*    initialShowOptions={entry.showOptions}*/}
                        {/*    allowSpecificSelection={false}*/}
                        {/*    key={`highlight-${index}`}*/}
                        {/*/>*/}
                        <ReactMarkdown>{entry.response}</ReactMarkdown>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={entry.includeInHistory}
                                    onChange={(e) => handleIncludeInHistoryChange(index, e.target.checked)}
                                />
                            }
                            label="Include in chat history"
                        />
                        {entry.uniqueReferences.length > 0 && (
                            <>
                                <Typography variant="h6" gutterBottom><strong>References</strong></Typography>
                                <ManagedList
                                    query={`/source/search?ids=${entry.uniqueReferences.map(ref => ref.id).join('&ids=')}`}
                                    rootAssociation="X"
                                    showAssociations={false}
                                    autoLoadExpandedContent={false}
                                    expanded={false}
                                    loadOnDefault={true}
                                    showAssociationsDropdown={false}
                                    showTypeSelection={false}
                                    allowDelete={false}
                                    showCheckAllClear={false}
                                    asChecklist={false}
                                    showFilter={false}
                                />
                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        ));
    }, [chatHistory, theme.palette.background.paper, theme.palette.text.primary, handleHighlightChange, handleIncludeInHistoryChange]);

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AssociationsDropdown
                            rootAssociation={rootCollection}
                            onCollectionSelect={handleCollectionChange}
                            selected={rootCollection}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h2" gutterBottom>
                            I will use your sources to help you learn. Ask away!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={prompt}
                            onChange={handlePromptChange}
                            placeholder="Type your prompt here..."
                            disabled={loading}
                            sx={{
                                backgroundColor: loading ? theme.palette.action.disabledBackground : 'inherit',
                                input: {color: loading ? theme.palette.text.disabled : 'inherit'}
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
                                <Box>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={loading}
                                        sx={{backgroundColor: theme.palette.primary.main, marginRight: 1}}
                                    >
                                        {loading ? <CircularProgress size={24}/> : 'Submit'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleClearConversation}
                                        disabled={loading}
                                        sx={{backgroundColor: theme.palette.primary.main}}
                                    >
                                        Clear Conversation
                                    </Button>
                                    &nbsp;&nbsp;<FormControlLabel
                                    control={
                                        <Switch
                                            checked={useSources}
                                            onChange={(e) => setUseSources(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Search and Use Sources for Current Query"
                                />
                                </Box>
                                {useSources &&
                                    <Button
                                        variant="text"
                                        onClick={toggleAdvanced}
                                        sx={{color: theme.palette.primary.main}}
                                    >
                                        {showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
                                    </Button>
                                }
                            </Box>
                            {useSources && showAdvanced && (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: isSmallScreen ? 'column' : 'row',
                                    gap: 2,
                                    alignItems: 'stretch'
                                }}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                            <Typography variant="body2" sx={{mr: 1}}>
                                                Similarity: {similarityScore.toFixed(2)}
                                            </Typography>
                                            <TooltipWrapper title={
                                                <React.Fragment>
                                                    Similarity score determines how closely related the sources should
                                                    be to your query. For best results, keep it within
                                                    the <strong>65-75</strong> range.
                                                </React.Fragment>
                                            }/>
                                        </Box>
                                        <Slider
                                            value={similarityScore}
                                            onChange={(_, newValue) => setSimilarityScore(newValue)}
                                            min={0}
                                            max={1}
                                            step={0.01}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                            <Typography variant="body2" sx={{mr: 1}}>
                                                Max Sources: {maxSources}
                                            </Typography>
                                            <TooltipWrapper
                                                title="This is the maximum number of related results that will be included in the response, if any are found."/>
                                        </Box>
                                        <Slider
                                            value={maxSources}
                                            onChange={(_, newValue) => setMaxSources(newValue)}
                                            min={1}
                                            max={50}
                                            step={1}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                            <Typography variant="body2" sx={{mr: 1}}>
                                                Temperature: {temperature.toFixed(2)}
                                            </Typography>
                                            <TooltipWrapper
                                                title="Temperature controls the randomness of the AI's output. Lower values (0.0-0.5) make the output more focused and deterministic, while higher values (0.5-1.0) make it more creative and diverse."/>
                                        </Box>
                                        <Slider
                                            value={temperature}
                                            onChange={(_, newValue) => setTemperature(newValue)}
                                            min={0}
                                            max={1}
                                            step={0.01}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
            {error && (
                <Alert severity="error" sx={{mt: 2}}>
                    {error}
                </Alert>
            )}
            <Grid container spacing={2} sx={{mt: 2}}>
                {renderChatHistory}
            </Grid>
        </Container>
    );
};

export default React.memo(RAGSourcesChat);