import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ManagedPage from "../../../../../components/apps/ManagedPage";
import MealDesigner from "../mealtracker/MealDesigner";
import MealStats from './MealStats';
import ExpandableCard from "../../../../../components/list/ExpandableCard";

function MealLog() {
    const navigate = useNavigate();

    const customFilter = (item, filterText) => {
        const content = item.content?.content;
        if (!content) return false;

        const lowerFilterText = filterText.toLowerCase();

        // Check item name first (quick check)
        if (item.name.toLowerCase().includes(lowerFilterText)) {
            return true;
        }

        // Check content fields
        const contentName = content.name || '';
        const contentCategory = content.category || '';
        const contentTags = content.tags || [];

        if (contentName.toLowerCase().includes(lowerFilterText) ||
            contentCategory.toLowerCase().includes(lowerFilterText) ||
            contentTags.some(tag => tag.toLowerCase().includes(lowerFilterText))) {
            return true;
        }

        // Check entree names
        return content.entrees?.some(entree =>
            (entree.name || '').toLowerCase().includes(lowerFilterText)
        ) || false;
    };

    const customSort = (a, b) => {
        const dateA = new Date(a.content?.content?.mealDate || '');
        const dateB = new Date(b.content?.content?.mealDate || '');

        // First, sort by date (most recent first)
        if (dateB.getTime() !== dateA.getTime()) {
            return dateB.getTime() - dateA.getTime();
        }

        // If dates are the same, sort by meal type
        const mealTypeOrder = { 'Dinner': 0, 'Lunch': 1, 'Breakfast': 2 };
        const mealTypeA = a.content?.content?.mealType || '';
        const mealTypeB = b.content?.content?.mealType || '';

        return (mealTypeOrder[mealTypeA] ?? 3) - (mealTypeOrder[mealTypeB] ?? 3);
    };

    const WrappedMealDisplay = ({ item, ...props }) => {
        const getFirstEntreePreview = (content) => {
            if (content.entrees && content.entrees.length > 0) {
                const firstEntreeName = content.entrees[0].name || '';
                return firstEntreeName.length > 40
                    ? firstEntreeName.substring(0, 40) + '...'
                    : firstEntreeName;
            }
            return '';
        };

        const title = `${item.content.content.mealDate} - ${item.content.content.mealType || 'Meal'}`;
        const entreePreview = getFirstEntreePreview(item.content.content);
        const fullTitle = entreePreview ? `${title} - ${entreePreview}` : title;

        return (
            <ExpandableCard title={fullTitle} initiallyExpanded={false}>
                <MealDesigner
                    allowCopy={true}
                    item={item}
                    {...props}
                    associationDropdownConfig={{ allowAddCollection: false }}
                />
            </ExpandableCard>
        );
    };

    const handleTrackMealClick = () => {
        navigate('/apps/nutritionist/mealtracker/mealtracker');
    };

    return (
        <ManagedPage
            title="Meal Log"
            addItemsButtonText="Add Meal"
            systemCollectionName="Meal Log"
            sourceType="Meal"
            StatsComponent={MealStats}
            ItemDisplay={WrappedMealDisplay}
            showExtractor={false}
            hideAssociationsDropdown={true}

            managedListProps={{
                customFilterFunction: customFilter,
                sortFunction: customSort,
                allowDelete: true,
            }}
            additionalButtons={
                <Button
                    variant="contained"
                    onClick={handleTrackMealClick}
                    sx={{
                        fontSize: '1.2rem',
                        padding: '12px 24px',
                        backgroundColor: 'secondary.main',
                        '&:hover': {
                            backgroundColor: 'secondary.dark',
                        },
                        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                        transition: 'all 0.3s ease',
                    }}
                >
                    + Track New Meal
                </Button>
            }
        />
    );
}

export default MealLog;