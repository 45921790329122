import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';

const SourceContext = createContext();

export function SourceProvider({ children }) {
    const [sourceMapById, setSourceMapById] = useState({});
    const [sourceMapByUri, setSourceMapByUri] = useState({});

    const updateSourceInfo = useCallback((id, name, uri) => {
        setSourceMapById(prevMap => ({...prevMap, [id]: name}));
        if (uri) {
            setSourceMapByUri(prevMap => ({...prevMap, [uri]: name}));
        }
    }, []);

    const value = useMemo(() => ({
        sourceMapById,
        sourceMapByUri,
        updateSourceInfo
    }), [sourceMapById, sourceMapByUri, updateSourceInfo]);

    return (
        <SourceContext.Provider value={value}>
            {children}
        </SourceContext.Provider>
    );
}

export function useSource() {
    const context = useContext(SourceContext);
    if (context === undefined) {
        throw new Error('useSource must be used within a SourceProvider');
    }
    return context;
}