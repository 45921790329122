import React from 'react';
import Recipes from "./recipes/Recipes";
import MealTracker from "./mealtracker/MealTracker";
import FoodStaples from "./staples/FoodStaples";
import FoodInventory from "./inventory/FoodInventory";
import GroceryList from "./grocerylist/GroceryList";

import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import KitchenIcon from '@mui/icons-material/Kitchen';
import Egg from '@mui/icons-material/Egg';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BrunchDining from '@mui/icons-material/BrunchDining';

const subNav = {
    header: 'Nutritionist',
    links: [
        {
            name: 'Meal Tracker',
            path: '/apps/nutritionist/mealtracker',
            icon: BrunchDining,
            element: <MealTracker />,
            title: 'Meal Tracker',
            description: 'Track your meals to see estimated nutrition trends.',
            buttonText: 'Plan Meals'
        },
        {
            name: 'Recipes',
            path: '/apps/nutritionist/recipes',
            icon: RestaurantMenuIcon,
            element: <Recipes />,
            title: 'Recipes',
            description: 'Browse, create, and manage your recipes',
            buttonText: 'Explore Recipes'
        },
        {
            name: 'Grocery List',
            path: '/apps/nutritionist/grocerylist',
            icon: ShoppingCartIcon,
            element: <GroceryList />,
            title: 'Grocery List',
            description: 'Generate a shopping list from your staples, recipes, or prompt',
            buttonText: 'Open Grocery List'
        },
        {
            name: 'Food Inventory',
            path: '/apps/nutritionist/foodinventory',
            icon: KitchenIcon,
            element: <FoodInventory />,
            title: 'Food Inventory',
            description: 'Track and manage your current food supplies',
            buttonText: 'Check Inventory'
        },
        {
            name: 'Food Staples',
            path: '/apps/nutritionist/foodstaples',
            icon: Egg,
            element: <FoodStaples />,
            title: 'Food Staples',
            description: 'Establish the food items you always want to have on hand',
            buttonText: 'View Food Staples'
        },
    ],
    routes: [
        { path: '/apps/nutritionist/recipes', element: <Recipes /> },
        { path: '/apps/nutritionist/mealtracker', element: <MealTracker /> },
        { path: '/apps/nutritionist/foodstaples', element: <FoodStaples /> },
        { path: '/apps/nutritionist/foodinventory', element: <FoodInventory /> },
        { path: '/apps/nutritionist/grocerylist', element: <GroceryList /> },
    ]
};

export default subNav;