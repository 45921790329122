import { useState, useCallback } from 'react';
import useApi from "../components/ApiService";

const useSourceAttributes = () => {
    const { apiCall } = useApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const upsertAttribute = useCallback(async (sourceId, attribute) => {
        setLoading(true);
        setError(null);
        try {
            const response = await apiCall(`/source/${sourceId}/attribute`, {
                method: 'POST',
                data: attribute,
            });
            setLoading(false);
            return response.data;
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred while upserting the attribute');
            setLoading(false);
            throw err;
        }
    }, [apiCall]);

    const getAllAttributes = useCallback(async (sourceId) => {
        setLoading(true);
        setError(null);
        try {
            const response = await apiCall(`/source/${sourceId}`);
            setLoading(false);
            console.log('getAllAttributes response:', response.data);
            const sources = response.data.sources || [];
            if (sources.length > 0 && sources[0].attributes) {
                return sources[0].attributes;
            } else {
                return [];
            }
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred while fetching all attributes');
            setLoading(false);
            throw err;
        }
    }, [apiCall]);

    return {
        upsertAttribute,
        getAllAttributes,
        loading,
        error,
    };
};

export default useSourceAttributes;
