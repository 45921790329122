import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Typography, Chip, TextField, IconButton, Button, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';

const StringList = React.memo(({
                                   label,
                                   values,
                                   onChange,
                                   editable,
                                   isNewItem,
                                   suggestedValues = [],
                                   displayPresenter = (value) => Promise.resolve(value),
                                   isRequired = false
                               }) => {
    const [newValue, setNewValue] = useState('');
    const [displayValues, setDisplayValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const displayPresenterRef = useRef(displayPresenter);
    const inputRef = useRef(null);

    useEffect(() => {
        displayPresenterRef.current = displayPresenter;
    }, [displayPresenter]);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        const loadDisplayValues = async () => {
            try {
                const newDisplayValues = await Promise.all(
                    values.map(async (value) => ({
                        original: value,
                        display: await displayPresenterRef.current(value)
                    }))
                );
                if (isMounted) {
                    setDisplayValues(newDisplayValues);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error loading display values:', error);
                if (isMounted) {
                    setDisplayValues(values.map(value => ({ original: value, display: value })));
                    setLoading(false);
                }
            }
        };

        loadDisplayValues();

        return () => {
            isMounted = false;
        };
    }, [values]);

    const handleAdd = useCallback((valueToAdd) => {
        if (valueToAdd.trim()) {
            const newValues = [...values, valueToAdd.trim()];
            onChange(newValues);
            setNewValue('');
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [values, onChange]);

    const handleDelete = useCallback((index) => {
        const newValues = values.filter((_, i) => i !== index);
        onChange(newValues);
    }, [values, onChange]);

    const handleSuggestedValueAdd = useCallback((suggestedValue) => {
        handleAdd(suggestedValue);
    }, [handleAdd]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            handleAdd(newValue);
        }
    }, [handleAdd, newValue]);

    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {label}:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
                {loading ? (
                    <CircularProgress size={24} />
                ) : displayValues.length > 0 ? (
                    displayValues.map(({ original, display }, index) => (
                        <Chip
                            key={index}
                            label={display}
                            onDelete={editable ? () => handleDelete(index) : undefined}
                        />
                    ))
                ) : isRequired ? (
                    <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        Please add at least one value
                    </Typography>
                ) : null}
            </Box>
            {editable && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <TextField
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                        variant="outlined"
                        size="small"
                        placeholder={`Add new ${label.toLowerCase()}`}
                        inputRef={inputRef}
                    />
                    <IconButton onClick={() => handleAdd(newValue)} color="primary">
                        <Add />
                    </IconButton>
                </Box>
            )}
            {editable && suggestedValues.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {suggestedValues.map((suggestedValue, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            size="small"
                            onClick={() => handleSuggestedValueAdd(suggestedValue)}
                        >
                            {suggestedValue}
                        </Button>
                    ))}
                </Box>
            )}
        </Box>
    );
});

export default StringList;