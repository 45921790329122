import axios from 'axios';
import { useAuth } from "../AuthContext";
import { useCallback, useMemo } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

const useApi = () => {
    const { accessToken, refreshAccessToken, logout } = useAuth();

    const axiosInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: API_URL,
        });

        instance.interceptors.request.use((config) => {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
            return config;
        });

        instance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const newToken = await refreshAccessToken();
                    if (newToken) {
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                        return instance(originalRequest);
                    } else {
                        logout();
                    }
                }
                return Promise.reject(error);
            }
        );

        return instance;
    }, [accessToken, refreshAccessToken, logout]);

    const apiCall = useCallback(async (endpoint, options = {}) => {
        let url = endpoint;

        // Handle URL parameters
        if (options.params) {
            const searchParams = new URLSearchParams();
            for (const [key, value] of Object.entries(options.params)) {
                searchParams.append(key, value);
            }
            url += `?${searchParams.toString()}`;
            delete options.params;
        }

        try {
            const response = await axiosInstance({
                url,
                ...options,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }, [axiosInstance])


    return { apiCall };
};

export default useApi;