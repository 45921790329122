// src/Chatbot.js
import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, List, ListItem, Typography, CircularProgress, useTheme } from '@mui/material';
import { Send } from 'lucide-react';
import useApi from "./components/ApiService";
import VoiceRecorder from "./components/VoiceRecorder";
import useSourceAttributes from "./context/useSourceAttributes";
import TextToSpeech from "./components/TextToSpeech"; // Add this import

function Chatbot({
                     initialConfig,
                     agentName = "Assistant",
                     sourceId,
                     attributeName = 'chatName',
                     onResponse,
                     onConversationComplete,
                     showResetButton = true,
                     resetButtonLabel = "Reset Chat",
                 }) {
    const [conversationId, setConversationId] = useState('');
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [latestAssistantMessage, setLatestAssistantMessage] = useState(''); // Add this state

    const { upsertAttribute, getAllAttributes } = useSourceAttributes();
    const [chatId, setChatId] = useState(undefined);
    const [loadingAttributes, setLoadingAttributes] = useState(true);
    const [attributesError, setAttributesError] = useState(null);

    const { apiCall } = useApi();
    const theme = useTheme();
    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        const fetchChatId = async () => {
            setLoadingAttributes(true);
            try {
                console.log('Fetching attributes for sourceId:', sourceId);
                const attributes = await getAllAttributes(sourceId);
                console.log('Fetched attributes:', attributes);
                const chatAttribute = attributes.find(attr => attr.name === attributeName);
                if (chatAttribute) {
                    console.log('Found chat attribute:', chatAttribute);
                    setChatId(chatAttribute.value);
                } else {
                    console.log('Chat attribute not found.');
                    setChatId(null);
                }
            } catch (error) {
                console.error('Error fetching attributes:', error);
                setAttributesError(error);
                setChatId(null);
            } finally {
                setLoadingAttributes(false);
            }
        };

        fetchChatId();
    }, [sourceId, attributeName, getAllAttributes]);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                console.log('Fetching chat history for conversationId:', chatId);
                const response = await apiCall(`/chat/${chatId}/history`, {
                    method: 'GET',
                });
                const data = response.data;
                console.log('Fetched chat history:', data);

                if (data.length === 0) {
                    console.log('Chat history is empty. Restarting chat.');
                    await clearChatId();
                    startConversation();
                    return;
                }

                let fetchedMessages = data.map(entry => ({
                    sender: entry.role === 'assistant' ? 'assistant' : 'user',
                    text: entry.content,
                }));

                // Remove the very first message if initialConfig has initialGreeting
                if (initialConfig && initialConfig.initialGreeting && fetchedMessages.length > 0) {
                    fetchedMessages = fetchedMessages.slice(1);
                }

                setMessages(fetchedMessages);
                setConversationId(chatId);
            } catch (error) {
                console.error('Error fetching chat history:', error);
                await clearChatId();
                startConversation();
            }
        };

        console.log('chatId changed:', chatId);
        if (chatId === undefined) return; // Still loading
        if (chatId) {
            console.log('Resuming conversation with ID:', chatId);
            setConversationId(chatId);
            fetchChatHistory();
        } else {
            console.log('No existing chat ID, starting new conversation.');
            startConversation();
        }
    }, [chatId]);

    const startConversation = async () => {
        setIsLoading(true);
        try {
            console.log('Starting new conversation with initialConfig:', initialConfig);
            const response = await apiCall('/chat/start', {
                method: 'POST',
                data: initialConfig,
            });
            const data = response.data;
            console.log('Started new conversation, response data:', data);
            setConversationId(data.conversationId);
            setMessages([{ sender: 'assistant', text: data.message }]);
            setLatestAssistantMessage(data.message); // Add this line
            if (onResponse) {
                onResponse(data);
            }
            // Save the chat ID to the source attribute
            const upsertResponse = await upsertAttribute(sourceId, { name: attributeName, value: data.conversationId });
            console.log('Upserted attribute response:', upsertResponse);
            // Do NOT update the chatId state here to prevent fetching history
            // setChatId(data.conversationId); // Removed this line
        } catch (error) {
            console.error('Error starting conversation:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChatId = async () => {
        try {
            console.log('Clearing chat ID for sourceId:', sourceId);
            await upsertAttribute(sourceId, { name: attributeName, value: null });
            setChatId(null);
        } catch (error) {
            console.error('Error clearing chat ID:', error);
        }
    };


    const resetChat = async () => {
        setIsLoading(true);
        try {
            if (conversationId) {
                try {
                    console.log('Attempting to delete current chat:', conversationId);
                    await apiCall(`/chat/${conversationId}`, {
                        method: 'DELETE',
                    });
                    console.log('Successfully deleted chat:', conversationId);
                } catch (deleteError) {
                    console.error('Error deleting chat:', deleteError);
                    // We're intentionally not rethrowing the error here
                    // so that the reset process can continue
                }
            }

            await clearChatId();
            setMessages([]);
            setIsCompleted(false);
            await startConversation();
        } catch (error) {
            console.error('Error resetting chat:', error);
            // If starting a new conversation fails, we should inform the user
            setMessages([{ sender: 'assistant', text: "An error occurred while resetting the chat. Please try again." }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendMessage = async (audio = null) => {
        if ((!inputMessage.trim() && !audio) || isCompleted) return;

        const userMessage = { sender: 'user', text: audio ? 'Audio message' : inputMessage };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInputMessage('');
        setIsLoading(true);

        try {
            let response;
            if (audio) {
                console.log('Sending audio message in conversation ID:', conversationId);
                const formData = new FormData();
                formData.append('file', audio, 'audio.wav');
                response = await apiCall(`/chat/${conversationId}/message/multipart`, {
                    method: 'POST',
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            } else {
                console.log('Sending text message in conversation ID:', conversationId, 'Message:', inputMessage);
                response = await apiCall(`/chat/${conversationId}/message`, {
                    method: 'POST',
                    data: { message: inputMessage },
                });
            }

            const data = response.data;
            console.log('Received response:', data);

            if (data.transcribedAudio) {
                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[updatedMessages.length - 1] = {
                        ...updatedMessages[updatedMessages.length - 1],
                        text: data.transcribedAudio
                    };
                    return updatedMessages;
                });
            }

            const assistantMessage = { sender: 'assistant', text: data.message };
            setMessages((prevMessages) => [...prevMessages, assistantMessage]);
            setLatestAssistantMessage(data.message); // Add this line

            if (data.isCompleted && !isCompleted) {
                setIsCompleted(true);
                await clearChatId(); // Clear the chat ID when conversation is completed
                if (onConversationComplete) {
                    onConversationComplete(data);
                }
            }

            if (onResponse) {
                onResponse(data);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatMessage = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index < text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    // Handle loading states and errors
    if (loadingAttributes) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (attributesError) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Typography color="error">Error loading chat attributes.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{
            maxWidth: 600,
            margin: 'auto',
            padding: 2,
            paddingTop: '4px',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
            marginTop: '0px'
        }} data-testid="chatbot-container">
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '24px',
                minHeight: 0,
                padding: 0,
                margin: 0,
                marginBottom: '4px'
            }}>
                <TextToSpeech text={latestAssistantMessage} />
            </Box>
            <Box sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                padding: 2,
                height: 400,
                overflowY: 'auto',
                bgcolor: 'background.default'
            }} data-testid="chatbot-messages">
                <List>
                    {messages.map((msg, index) => (
                        <ListItem key={index} sx={{
                            flexDirection: 'column',
                            alignItems: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                            padding: theme.spacing(1)
                        }} data-testid={`message-${index}`}>
                            <Typography variant="body2" fontWeight="bold" color={msg.sender === 'user' ? 'primary.main' : 'secondary.main'}>
                                {msg.sender === 'user' ? 'You' : agentName}:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    bgcolor: msg.sender === 'user' ? 'primary.light' : 'secondary.light',
                                    color: msg.sender === 'user' ? 'primary.contrastText' : 'secondary.contrastText',
                                    p: 1,
                                    borderRadius: 1,
                                    maxWidth: '80%',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {formatMessage(msg.text)}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
                {isLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress data-testid="loading-indicator" />
                    </Box>
                )}
                <div ref={messagesEndRef} />
            </Box>
            {!isCompleted && (
                <Box sx={{ display: 'flex', marginTop: 2 }}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        placeholder="Type your message..."
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                        sx={{ flexGrow: 1, marginRight: 1 }}
                        data-testid="chatbot-input"
                        autoComplete="off"
                        inputProps={{
                            autoComplete: 'off',
                            // autoCorrect: 'off',
                            // autoCapitalize: 'off'
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSendMessage()}
                        disabled={!inputMessage.trim() && !isLoading}
                        data-testid="send-button"
                    >
                        <Send size={20} />
                    </Button>
                    <VoiceRecorder onRecordingComplete={(audioBlob) => handleSendMessage(audioBlob)} data-testid="voice-recorder" />
                </Box>
            )}
            {isCompleted && (
                <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                    <Typography variant="h6" data-testid="completion-message">
                        The conversation is completed. Thank you!
                    </Typography>
                    {showResetButton && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={resetChat}
                            data-testid="reset-button"
                            sx={{ marginTop: 2 }}
                        >
                            {resetButtonLabel}
                        </Button>
                    )}
                </Box>
            )}
            {/* Always show Reset Chat button if configured */}
            {!isCompleted && showResetButton && (
                <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={resetChat}
                        data-testid="reset-button"
                    >
                        {resetButtonLabel}
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default Chatbot;