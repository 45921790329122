import React from 'react';
import Gym from "./gym/Gym";
import Exercise from "./exercise/Exercise";
import BodyMeasurements from "./bodymeasurements/BodyMeasurements";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import StraightenIcon from '@mui/icons-material/Straighten';

const subNav = {
    header: 'Personal Trainer',
    links: [
        // {
        //     name: 'Exercise',
        //     path: '/apps/personaltrainer/exercise',
        //     icon: DirectionsRunIcon,
        //     element: <Exercise />,
        //     title: 'Exercise',
        //     description: 'Plan and track your exercise routines.',
        //     buttonText: 'Start Exercising'
        // },
        {
            name: 'Gym',
            path: '/apps/personaltrainer/gym',
            icon: FitnessCenterIcon,
            element: <Gym />,
            title: 'Gym',
            description: 'Track your sets and reps and see your progress.',
            buttonText: 'Go to the Gym'
        },
        {
            name: 'Body Measurements',
            path: '/apps/personaltrainer/bodymeasurements',
            icon: StraightenIcon,
            element: <BodyMeasurements />,
            title: 'Body Measurements',
            description: 'Record and monitor your body measurements.',
            buttonText: 'Check Measurements'
        },
    ],
    routes: [
        { path: '/apps/personaltrainer/exercise', element: <Exercise /> },
        { path: '/apps/personaltrainer/gym', element: <Gym /> },
        { path: '/apps/personaltrainer/bodymeasurements', element: <BodyMeasurements /> }
    ]
};

export default subNav;