import dagre from 'dagre';

export const nodeWidth = 172;
export const nodeHeight = 36;

export const createClusterNode = (parentId, childIds, childTypes, clusterType) => ({
    id: `cluster-${clusterType}-${parentId}`,
    type: 'custom',
    data: {
        label: `${clusterType} Cluster (${childIds.length})`,
        type: ['Cluster', clusterType, ...new Set(childTypes.flat())],
        childIds: childIds,
        edges: []
    },
    position: { x: 0, y: 0 },
});

export const getLayoutedElements = (nodes, edges, direction = 'TB', horizontalSpacing = 50, verticalSpacing = 50) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: direction, nodesep: horizontalSpacing, ranksep: verticalSpacing });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    return {
        nodes: nodes.map((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            return {
                ...node,
                position: {
                    x: nodeWithPosition.x - nodeWidth / 2,
                    y: nodeWithPosition.y - nodeHeight / 2,
                },
            };
        }),
        edges,
    };
};

export const createClusters = (inputNodes, inputEdges, idTypeMap) => {
    const nodeParentMap = {};
    const nodeChildrenMap = {};
    inputEdges.forEach(edge => {
        if (!nodeParentMap[edge.target]) {
            nodeParentMap[edge.target] = [];
        }
        nodeParentMap[edge.target].push(edge.source);

        if (!nodeChildrenMap[edge.source]) {
            nodeChildrenMap[edge.source] = [];
        }
        nodeChildrenMap[edge.source].push(edge.target);
    });

    const parentChildrenMap = {};
    Object.entries(nodeParentMap).forEach(([child, parents]) => {
        const parentKey = parents.sort().join(',');
        if (!parentChildrenMap[parentKey]) {
            parentChildrenMap[parentKey] = [];
        }
        parentChildrenMap[parentKey].push(child);
    });

    let clusteredNodes = [...inputNodes];
    let clusteredEdges = [...inputEdges];

    // Configuration
    const minClusterSize = 2;
    const blacklistedTypes = ['System', 'Collection'];

    // Get all unique types
    const allTypes = new Set();
    Object.values(idTypeMap).forEach(node => {
        node.type.forEach(type => allTypes.add(type));
    });

    const createClusterForType = (type) => {
        Object.entries(parentChildrenMap).forEach(([parentKey, children]) => {
            const typeChildren = children.filter(child =>
                idTypeMap[child].type.includes(type) &&
                !idTypeMap[child].type.some(t => blacklistedTypes.includes(t)) &&
                (!nodeChildrenMap[child] || nodeChildrenMap[child].every(grandchild => children.includes(grandchild)))
            );
            if (typeChildren.length >= minClusterSize) {
                const childTypes = typeChildren.map(childId => idTypeMap[childId].type);
                const clusterNode = createClusterNode(parentKey, typeChildren, childTypes, type);

                clusteredNodes.push(clusterNode);
                clusteredNodes = clusteredNodes.filter(node => !typeChildren.includes(node.id));

                clusteredEdges = clusteredEdges.filter(edge => {
                    if (typeChildren.includes(edge.source)) {
                        if (!typeChildren.includes(edge.target)) {
                            clusteredEdges.push({
                                id: `${clusterNode.id}-${edge.target}`,
                                source: clusterNode.id,
                                target: edge.target
                            });
                        }
                        return false;
                    }
                    if (typeChildren.includes(edge.target)) {
                        if (!typeChildren.includes(edge.source)) {
                            clusteredEdges.push({
                                id: `${edge.source}-${clusterNode.id}`,
                                source: edge.source,
                                target: clusterNode.id
                            });
                        }
                        return false;
                    }
                    return true;
                });

                parentKey.split(',').forEach(parentId => {
                    clusteredEdges.push({
                        id: `${parentId}-${clusterNode.id}`,
                        source: parentId,
                        target: clusterNode.id
                    });
                });
            }
        });
    };

    // Create clusters for all types except blacklisted ones
    allTypes.forEach(type => {
        if (!blacklistedTypes.includes(type)) {
            createClusterForType(type);
        }
    });

    return { clusteredNodes, clusteredEdges };
};