// FilterBar.js
import React from 'react';
import { Box, TextField } from '@mui/material';

const FilterBar = ({ nameFilter, onNameFilterChange, includeOrphans, onIncludeOrphansChange }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
            <TextField
                sx={{ flexGrow: 1, mr: 2 }}
                variant="outlined"
                label="Filter sources by name"
                value={nameFilter}
                onChange={onNameFilterChange}
            />
            {/* Add Checkbox for includeOrphans if needed */}
        </Box>
    );
};

export default FilterBar;