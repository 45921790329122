import React, { useRef } from 'react';
import { Box, Typography, Container } from '@mui/material';
import ManagedList from "../../../components/list/ManagedList";
import ExpandableCard from "../../../components/list/ExpandableCard";
import SourceForm from "../../../components/form/SourceForm";

function Sources() {
    const query = '/source/search?a=a';
    const selectedAssociationId = useRef("AIssistant");

    const setSelectedAssociation = (associationSelection) => {
        selectedAssociationId.current = associationSelection.id;
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Sources
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <ManagedList
                        query={query}
                        rootAssociation={"AIssistant"}
                        ItemDisplay={({ sourceRecord, ...props }) => (
                            <ExpandableCard title={sourceRecord.name || 'New Source'}>
                                <SourceForm item={sourceRecord} {...props} />
                            </ExpandableCard>
                        )}
                        showOrphanOptions={true}
                        allowNew={true}
                        showOrphansByDefault={true}
                        asChecklist={true}
                        allowDelete={true}
                        showCheckAllClear={true}
                        onAssociationUpdate={setSelectedAssociation}
                        selectedAssociation={selectedAssociationId.current}
                        showTypeSelection={true}
                        defaultSelectedTypes={["Source"]}
                    />
                </Box>
            </Box>
        </Container>
    );
}

export default Sources;