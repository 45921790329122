import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Alert, ButtonGroup, CircularProgress,
    Typography, Box, Button, Card, CardContent, CardActions
} from '@mui/material';
import AssociationsDropdown from "../../../../components/AssociationsDropdown";
import QuizQuestion from "./QuizQuestion";
import RAGGeneration from "../../../../components/rag/RAGGeneration";
import useApi from "../../../../components/ApiService";
import Extractor from "../../../../components/extract/Extractor";

const StatusBar = ({ current, total, correct, wrong }) => (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
        <Typography variant="body1">
            {total > 0 && (
                <>Question {current} of {total}</>
            )}
        </Typography>
        <Typography variant="body1">
            Correct: {correct} | Wrong: {wrong}
        </Typography>
    </Box>
);

const QuizMe = () => {
    const { apiCall } = useApi();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [correctCount, setCorrectCount] = useState(0);
    const [wrongCount, setWrongCount] = useState(0);
    const [missedQuestions, setMissedQuestions] = useState([]);
    const [showMissedQuestions, setShowMissedQuestions] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAssociation, setSelectedAssociation] = useState(null);
    const [showExtractor, setShowExtractor] = useState(false);

    const currentQuestion = questions[currentQuestionIndex];
    const shuffleQuestions = true;

    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const handleCollectionSelect = useCallback((selected) => {
        console.log("Selected from QuizMe: ", selected);

        setSelectedAssociation(selected);
        // Clear current quiz data
        setQuestions([]);
        setSelectedAnswers([]);
        setMessages([]);
        setCurrentQuestionIndex(0);
        setCorrectCount(0);
        setWrongCount(0);
        setMissedQuestions([]);
        setShowMissedQuestions(false);
    }, []);

    useEffect(() => {
        if (selectedAssociation) {
            fetchQuiz();
        }
    }, [selectedAssociation]);

    const fetchQuiz = async () => {
        setIsLoading(true);
        try {
            const response = await apiCall(`/quiz/generate`, {
                method: "POST",
                data: {
                    associations: selectedAssociation.allDescendants,
                    numOfQuestions: 10000,
                    exclude_terminology_ids: [],
                    autoGenerate: true
                }
            });
            var quizData = response.data.quiz.questions;

            if (shuffleQuestions) {
                quizData = shuffleArray(quizData);
            }

            setQuestions(quizData);
            setSelectedAnswers(new Array(quizData.length).fill(null));
            setMessages(new Array(quizData.length).fill(null));
            setCurrentQuestionIndex(0);
        } catch (error) {
            console.error('Error fetching quiz:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteQuestion = async (id) => {
        try {
            await apiCall(`/source/${id}`, {
                method: 'DELETE',
            });

            // The rest of the deletion logic
            const updatedQuestions = questions.filter((_, index) => index !== currentQuestionIndex);

            setQuestions(updatedQuestions);
            setSelectedAnswers(selectedAnswers.filter((_, index) => index !== currentQuestionIndex));
            setMessages(messages.filter((_, index) => index !== currentQuestionIndex));

            if (selectedAnswers[currentQuestionIndex] === currentQuestion?.correct_answer?.toLowerCase()) {
                setCorrectCount(correctCount - 1);
            } else if (selectedAnswers[currentQuestionIndex] !== null) {
                setWrongCount(wrongCount - 1);
            }

            if (currentQuestionIndex > 0) {
                setCurrentQuestionIndex(currentQuestionIndex - 1);
            } else {
                setCurrentQuestionIndex(0);
            }
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };

    const handleAnswerClick = async (answer, terminologyId) => {
        const newSelectedAnswers = [...selectedAnswers];
        const newMessages = [...messages];
        let score = 0;

        if (newSelectedAnswers[currentQuestionIndex] === null) {
            if (answer === currentQuestion.correct_answer.toLowerCase()) {
                newMessages[currentQuestionIndex] = { type: 'success', text: `Correct: ${currentQuestion.explanation}` };
                setCorrectCount(prevCount => prevCount + 1);
                score = 100;
            } else {
                newMessages[currentQuestionIndex] = { type: 'danger', text: 'Incorrect, please try again.' };
                setWrongCount(prevCount => prevCount + 1);
                setMissedQuestions(prev => [...prev, { ...currentQuestion }]);
                score = 0;
            }

            try {
                // TODO: Save this, to add back in later
                // await apiCall(`/api/quiz/assessment/`, {method: 'POST', data: {
                //     terminology_id: terminologyId,
                //     score: score
                // }});
            } catch (error) {
                console.error('Error posting assessment:', error);
                alert(error);
            }
        } else {
            if (answer === currentQuestion.correct_answer.toLowerCase()) {
                newMessages[currentQuestionIndex] = { type: 'success', text: `Correct: ${currentQuestion.explanation}` };
                score = 100;
            } else {
                newMessages[currentQuestionIndex] = { type: 'danger', text: 'Incorrect, please try again.' };
                score = 0;
            }
        }

        newSelectedAnswers[currentQuestionIndex] = answer;
        setSelectedAnswers(newSelectedAnswers);
        setMessages(newMessages);
    }

    const handleNavigation = (direction) => {
        if (direction === 'back' && currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
            // We don't reset the answer when going back
        } else if (direction === 'forward' && currentQuestionIndex < questions.length - 1) {
            if (selectedAnswers[currentQuestionIndex] === questions[currentQuestionIndex]?.correct_answer.toLowerCase()) {
                setCurrentQuestionIndex(prevIndex => prevIndex + 1);
                // We don't reset the answer for the next question
            }
        }
    };

    const toggleMissedQuestions = () => {
        setShowMissedQuestions(!showMissedQuestions);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 5 }}>
            <Box mt={2} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">
                    Quiz Me
                    {isLoading && (
                        <CircularProgress size={20} sx={{ ml: 2 }} />
                    )}
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => setShowExtractor(!showExtractor)}
                    disabled={!selectedAssociation}
                >
                    {showExtractor ? 'Done Creating' : 'Create Questions'}
                </Button>
            </Box>

            <AssociationsDropdown
                rootAssociation={"Quizzes"}
                onCollectionSelect={handleCollectionSelect}
            />

            {showExtractor && selectedAssociation && (
                <Box sx={{ mb: 3 }}>
                    <Extractor
                        availableTypes={[{ id: "Question", label: "Quiz Questions" }]}
                        fromQuery={'/source/search?'}
                        toQuery={'/source/search?'}
                        fromPossibleTypes={["Source", "Terminology"]}
                        toPossibleTypes={["Collection"]}
                        toRootAssociation={"Quizzes"}
                        extractTo={[selectedAssociation.id]}
                        showTo={false}
                        fromRootAssociation={"Professor"}
                    />
                </Box>
            )}

            <StatusBar
                current={currentQuestionIndex + 1}
                total={questions.length}
                correct={correctCount}
                wrong={wrongCount}
            />

            {currentQuestion && (
                <>
                    <QuizQuestion
                        key={currentQuestionIndex}
                        question={currentQuestion}
                        selectedAnswer={selectedAnswers[currentQuestionIndex]}
                        onAnswerClick={handleAnswerClick}
                        randomizeAnswers={true}
                        onDelete={handleDeleteQuestion}
                    />
                </>
            )}

            {messages[currentQuestionIndex] && (
                <Alert severity={messages[currentQuestionIndex].type} sx={{ mt: 3 }}>
                    {messages[currentQuestionIndex].text}
                </Alert>
            )}

            <ButtonGroup sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant="contained"
                    onClick={() => handleNavigation('back')}
                    disabled={currentQuestionIndex === 0}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleNavigation('forward')}
                    disabled={
                        currentQuestionIndex === questions.length - 1 ||
                        selectedAnswers[currentQuestionIndex] !== questions[currentQuestionIndex]?.correct_answer.toLowerCase()
                    }
                >
                    Forward
                </Button>
            </ButtonGroup>

            {missedQuestions.length > 0 && (
                <Box mt={3}>
                    <Button
                        variant="contained"
                        onClick={toggleMissedQuestions}
                    >
                        {showMissedQuestions ? 'Hide Missed Questions' : 'Show Missed Questions'}
                    </Button>

                    {showMissedQuestions && (
                        <Box mt={4}>
                            <Typography variant="h5" color="white" mb={2}>Missed Questions</Typography>
                            {missedQuestions.map((question, index) => (
                                <Card key={index} sx={{ mb: 3 }}>
                                    <CardContent>
                                        <Typography variant="h6" mb={2}>{question.question}</Typography>
                                        <Typography><strong>Correct answer:</strong> {question[question.correct_answer.toLowerCase()]}</Typography>
                                        <Typography><strong>Explanation:</strong> {question.explanation}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <RAGGeneration
                                            querySupplier={()=>{return "You are a professor. Give a full lengthy lesson on the topic following quiz question. By the end of the detailed lesson, the student should be prepared to correctly answer the question. Have the lesson be centered on the topic, not specifically the specific question itself. This lesson is to have the student learn as they are taking the quiz, so provide the knowledge, but don't specifically highlight the answer."}}
                                            contentSupplier={() => {return `${question.question} : ${question[question.correct_answer.toLowerCase()]} - ${question.explanation}`}}
                                            specialInstructionsSupplier={() => {return "Start the response with the title name with no title label."}}
                                            contextSupplier={()=>{return ""}}
                                        />
                                    </CardActions>
                                </Card>
                            ))}
                        </Box>
                    )}
                </Box>
            )}
        </Container>
    );
};

export default QuizMe;