import React from 'react';
import { Container, Box, Typography, Paper, useTheme } from '@mui/material';
import AppCardGrid from "../../components/apps/AppCardGrid";
import subNav from "./subNavConfig";

function Advanced() {
    const theme = useTheme();
    return (
        <>
            <Container maxWidth={false} disableGutters>
                <Paper
                    elevation={3}
                    sx={{
                        backgroundColor: theme.palette.warning.light,
                        padding: 2,
                        marginBottom: 3,
                        textAlign: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.87)',
                            color: theme.palette.warning.light,
                            padding: 2,
                            marginBottom: 2,
                        }}
                    >
                        <Typography variant="h5" sx={{ color: theme.palette.warning.light }}>
                            !!! Proceed only if you know your bits from your bytes. !!!
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                        One wrong move and you might turn data harmony into digital chaos.
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                        Tread carefully, brave configurator!
                    </Typography>
                </Paper>
            </Container>
            <AppCardGrid title="Advanced Dashboard" subNav={subNav} />
        </>
    );
}

export default Advanced;