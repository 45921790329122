import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const OuterContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: 100,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
}));

const TimelineContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: 40,
    backgroundColor: '#34495e',
    borderRadius: theme.spacing(1),
    width: '80%',
    margin: '0 10%',
}));

const TimelineBar = styled(Box)({
    position: 'absolute',
    height: '100%',
    top: 0,
});

const TimelineMarker = styled(Box)({
    position: 'absolute',
    width: 2,
    height: '100%',
    backgroundColor: '#fff',
    top: 0,
});

const ItemMarker = styled(Box)(({ theme }) => ({
    position: 'absolute',
    borderRadius: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    border: '2px solid #fff',
    cursor: 'pointer',
}));

const OutlierNode = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1rem',
    cursor: 'pointer',
}));

const TimelineLabel = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    bottom: -25,
    transform: 'translateX(-50%)',
    fontSize: '0.75rem',
    color: '#bdc3c7',
    whiteSpace: 'nowrap',
}));

const ExpirationTimeline = ({ foodItems }) => {
    const today = new Date();
    const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const twoWeeksLater = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);

    const getPositionOnTimeline = (date) => {
        const totalDays = 21; // 1 week before + 2 weeks after
        const daysFromStart = (date - oneWeekAgo) / (24 * 60 * 60 * 1000);
        return (daysFromStart / totalDays) * 100;
    };

    const getColorForDate = (date) => {
        if (date < today) return '#e74c3c'; // Red for expired
        if (date < new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000)) return '#f39c12'; // Orange for next 3 days
        return '#2ecc71'; // Green for later dates
    };

    const formatDate = (date) => {
        const options = { month: 'short', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const todayPosition = getPositionOnTimeline(today);

    const groupedFoodItems = foodItems.reduce((acc, item) => {
        const date = new Date(item.expiration_date);
        if (isNaN(date.getTime())) return acc;
        const dateString = date.toISOString().split('T')[0];
        if (!acc[dateString]) acc[dateString] = [];
        acc[dateString].push(item);
        return acc;
    }, {});

    const beforeItems = [];
    const afterItems = [];
    const timelineItems = [];

    Object.entries(groupedFoodItems).forEach(([dateString, items]) => {
        const date = new Date(dateString);
        const position = getPositionOnTimeline(date);
        if (position < 0) {
            beforeItems.push(...items);
        } else if (position > 100) {
            afterItems.push(...items);
        } else {
            timelineItems.push({ date, items });
        }
    });

    return (
        <OuterContainer>
            <OutlierNode
                sx={{
                    left: '5%',
                    backgroundColor: '#e74c3c',
                }}
            >
                <Tooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Expired Items ({beforeItems.length})</Typography>
                            {beforeItems.map((item, i) => (
                                <Typography key={i} variant="body2">{item.name} ({formatDate(new Date(item.expiration_date))})</Typography>
                            ))}
                        </React.Fragment>
                    }
                    arrow
                >
                    <Box>{beforeItems.length}</Box>
                </Tooltip>
            </OutlierNode>

            <TimelineContainer>
                <TimelineBar sx={{ left: 0, width: `${todayPosition}%`, backgroundColor: 'rgba(231, 76, 60, 0.3)' }} />
                <TimelineBar sx={{ left: `${todayPosition}%`, width: `${9.52}%`, backgroundColor: 'rgba(243, 156, 18, 0.3)' }} />
                <TimelineBar sx={{ left: `${todayPosition + 9.52}%`, width: `${100 - todayPosition - 9.52}%`, backgroundColor: 'rgba(46, 204, 113, 0.3)' }} />
                <TimelineMarker sx={{ left: `${todayPosition}%` }} />

                {timelineItems.map(({ date, items }, index) => {
                    const position = getPositionOnTimeline(date);
                    const size = Math.max(24, Math.min(48, 24 + items.length * 2));

                    return (
                        <Tooltip
                            key={index}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{formatDate(date)}</Typography>
                                    {items.map((item, i) => (
                                        <Typography key={i} variant="body2">{item.name}</Typography>
                                    ))}
                                </React.Fragment>
                            }
                            arrow
                        >
                            <ItemMarker
                                sx={{
                                    left: `${position}%`,
                                    width: size,
                                    height: size,
                                    backgroundColor: getColorForDate(date),
                                    fontSize: `${Math.max(12, Math.min(16, 12 + items.length))}px`,
                                }}
                            >
                                {items.length}
                            </ItemMarker>
                        </Tooltip>
                    );
                })}

                <TimelineLabel sx={{ left: 0 }}>{formatDate(oneWeekAgo)}</TimelineLabel>
                <TimelineLabel sx={{ left: `${todayPosition}%` }}>Today</TimelineLabel>
                <TimelineLabel sx={{ right: 0 }}>{formatDate(twoWeeksLater)}</TimelineLabel>
            </TimelineContainer>

            <OutlierNode
                sx={{
                    right: '5%',
                    backgroundColor: '#2ecc71',
                }}
            >
                <Tooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Future Items ({afterItems.length})</Typography>
                            {afterItems.map((item, i) => (
                                <Typography key={i} variant="body2">{item.name} ({formatDate(new Date(item.expiration_date))})</Typography>
                            ))}
                        </React.Fragment>
                    }
                    arrow
                >
                    <Box>{afterItems.length}</Box>
                </Tooltip>
            </OutlierNode>
        </OuterContainer>
    );
};

export default ExpirationTimeline;