import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    Typography, Box, Button, Paper, Grid, useTheme, alpha,
    TextField, Select, MenuItem, IconButton, List, ListItem, ListItemText, FormControl, InputLabel
} from '@mui/material';
import {
    Print as PrintIcon,
    Restaurant as RecipeIcon,
    Kitchen as KitchenIcon,
    AccessTime as TimeIcon,
    Group as ServingIcon,
    FormatListNumbered as StepsIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import StringList from "../../../../components/form/StringList";
import useApi from "../../../../components/ApiService";
import debounce from 'lodash.debounce';
import NutritionEstimatesDisplay from "../nutrition/NutritionEstimatesDisplay";
import RecipeQueryChat from "./RecipeQueryChat";
import DeleteSource from "../../../../components/DeleteSource";

const categories = ['Appetizer', 'Main Course', 'Dessert', 'Snack', 'Drink', 'Other'];

const RecipeDisplay = ({ recipe: initialRecipe, sourceRecord, onSourceUpdate, onSourceDelete }) => {
    const { apiCall } = useApi();
    const [recipe, setRecipe] = useState(sourceRecord ? sourceRecord.content.content : initialRecipe);
    const [isEditing, setIsEditing] = useState(false);
    const theme = useTheme();
    const printRef = useRef();

    const handlePrint = useCallback(() => {
        const printContent = printRef.current;
        const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        winPrint.document.write(`
        <html>
            <head>
                <title>${recipe.name}</title>
                <style>
                    body { 
                        font-family: Arial, sans-serif;
                        line-height: 1.6;
                        color: #333;
                        max-width: 800px;
                        margin: 0 auto;
                        padding: 20px;
                    }
                    h1, h2 { color: #2c3e50; }
                    .recipe-info { 
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }
                    .ingredients-grid {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 10px;
                    }
                    .ingredient-item {
                        margin-bottom: 10px;
                    }
                    .ingredient-name {
                        font-weight: bold;
                    }
                    .ingredient-description {
                        font-size: 0.9em;
                        color: #666;
                    }
                    .step-item {
                        margin-bottom: 15px;
                    }
                    .step-name {
                        font-weight: bold;
                    }
                    .step-duration {
                        font-size: 0.9em;
                        color: #666;
                    }
                    .step-description {
                        margin-left: 20px;
                    }
                    .tags {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                    }
                    .tag {
                        background-color: #ecf0f1;
                        color: #2c3e50;
                        padding: 5px 10px;
                        border-radius: 4px;
                        font-size: 0.9em;
                    }
                </style>
            </head>
            <body>
                <h1>${recipe.name}</h1>
                <p>${recipe.description}</p>
                <div class="recipe-info">
                    <span>Serving Size: ${recipe.serving_size}</span>
                    <span>Time: ${recipe.time_estimate_minutes} minutes</span>
                </div>
                <h2>Ingredients</h2>
                <div class="ingredients-grid">
                    ${recipe.ingredients.map(ingredient => `
                        <div class="ingredient-item">
                            <div class="ingredient-name">${ingredient.quantity} ${ingredient.unit} ${ingredient.name}</div>
                            ${ingredient.description ? `<div class="ingredient-description">${ingredient.description}</div>` : ''}
                        </div>
                    `).join('')}
                </div>
                <h2>Steps</h2>
                ${recipe.steps.map((step, index) => `
                    <div class="step-item">
                        <div class="step-name">${index + 1}. ${step.name} 
                            ${step.duration ? `<span class="step-duration">(${step.duration})</span>` : ''}
                        </div>
                        <div class="step-description">${step.description}</div>
                    </div>
                `).join('')}
                <h3>Category: ${recipe.category}</h3>
                <div class="tags">
                    ${recipe.tags.map(tag => `<span class="tag">${tag}</span>`).join('')}
                </div>
            </body>
        </html>
    `);

        winPrint.document.close();
        winPrint.focus();
        winPrint.print();
        winPrint.close();
    }, [recipe]);

    const toggleEdit = () => {
        setIsEditing((prevIsEditing) => !prevIsEditing);
    };

    const debouncedSubmitEdit = useRef(
        debounce(async (updatedRecipe) => {
            try {
                const requestBody = { id: sourceRecord.content.id, content: updatedRecipe };
                const response = await apiCall(`/content/${sourceRecord.content.id}`, {method: 'PUT', data: requestBody});

                if (response.data && response.data.content[0].content) {
                    setRecipe(response.data.content[0].content);
                    if (onSourceUpdate) {
                        const updatedSource = {
                            ...sourceRecord,
                            content: {
                                ...sourceRecord.content,
                                content: response.data.content[0].content
                            }
                        };
                        onSourceUpdate(updatedSource);
                    }
                } else {
                    setRecipe(updatedRecipe);
                }
            } catch (error) {
                console.error('Error updating recipe:', error);
            }
        }, 500)  // 500ms debounce time
    ).current;

    useEffect(() => {
        return () => {
            debouncedSubmitEdit.cancel();
        };
    }, [debouncedSubmitEdit]);

    const handleChange = (field, value) => {
        const updatedRecipe = { ...recipe, [field]: value };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const handleIngredientChange = (index, field, value) => {
        const updatedIngredients = [...recipe.ingredients];
        updatedIngredients[index] = { ...updatedIngredients[index], [field]: value };
        const updatedRecipe = { ...recipe, ingredients: updatedIngredients };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const handleStepChange = (index, field, value) => {
        const updatedSteps = [...recipe.steps];
        updatedSteps[index] = { ...updatedSteps[index], [field]: value };
        const updatedRecipe = { ...recipe, steps: updatedSteps };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const addIngredient = () => {
        const updatedRecipe = {
            ...recipe,
            ingredients: [...recipe.ingredients, { name: '', description: '', quantity: '', unit: '' }]
        };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const removeIngredient = (index) => {
        const updatedRecipe = {
            ...recipe,
            ingredients: recipe.ingredients.filter((_, i) => i !== index)
        };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const moveIngredient = (index, direction) => {
        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= recipe.ingredients.length) return;
        const updatedIngredients = [...recipe.ingredients];
        [updatedIngredients[index], updatedIngredients[newIndex]] = [updatedIngredients[newIndex], updatedIngredients[index]];
        const updatedRecipe = { ...recipe, ingredients: updatedIngredients };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const addStep = () => {
        const updatedRecipe = {
            ...recipe,
            steps: [...recipe.steps, { name: '', description: '', duration: '' }]
        };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const removeStep = (index) => {
        const updatedRecipe = {
            ...recipe,
            steps: recipe.steps.filter((_, i) => i !== index)
        };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const moveStep = (index, direction) => {
        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= recipe.steps.length) return;
        const updatedSteps = [...recipe.steps];
        [updatedSteps[index], updatedSteps[newIndex]] = [updatedSteps[newIndex], updatedSteps[index]];
        const updatedRecipe = { ...recipe, steps: updatedSteps };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    const handleNutritionUpdate = (updatedNutrition) => {
        const updatedRecipe = { ...recipe, nutrition_estimate: updatedNutrition };
        setRecipe(updatedRecipe);
        debouncedSubmitEdit(updatedRecipe);
    };

    return (
        <Paper
            elevation={3}
            sx={{
                p: theme.spacing(3),
                mb: theme.spacing(3),
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={theme.spacing(2)}>
                {isEditing ? (
                    <TextField
                        fullWidth
                        label="Recipe Name"
                        value={recipe.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        sx={{ mr: 2 }}
                    />
                ) : (
                    <Typography variant="h4" color="primary">
                        <RecipeIcon sx={{ mr: theme.spacing(1), verticalAlign: 'middle' }} />
                        {recipe.name}
                    </Typography>
                )}
                <Box>
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}
                        sx={{ mr: 1 }}
                    >
                        Print
                    </Button>
                </Box>
            </Box>
            <Box ref={printRef}>
                {isEditing ? (
                    <TextField
                        fullWidth
                        label="Description"
                        value={recipe.description}
                        onChange={(e) => handleChange('description', e.target.value)}
                        multiline
                        rows={2}
                        sx={{ mb: 2 }}
                    />
                ) : (
                    <Typography variant="body1" paragraph>{recipe.description}</Typography>
                )}
                <Box className="recipe-info" sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    {isEditing ? (
                        <>
                            <TextField
                                label="Serving Size"
                                value={recipe.serving_size}
                                onChange={(e) => handleChange('serving_size', e.target.value)}
                                sx={{ width: '45%' }}
                            />
                            <TextField
                                label="Time (minutes)"
                                type="number"
                                value={recipe.time_estimate_minutes}
                                onChange={(e) => handleChange('time_estimate_minutes', e.target.value)}
                                sx={{ width: '45%' }}
                            />
                        </>
                    ) : (
                        <>
                            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                                <ServingIcon sx={{ mr: 1 }} />
                                Serving Size: {recipe.serving_size}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                                <TimeIcon sx={{ mr: 1 }} />
                                Time: {recipe.time_estimate_minutes} minutes
                            </Typography>
                        </>
                    )}
                </Box>
                <Box>
                    <RecipeQueryChat recipeSource={sourceRecord}/>
                </Box>
                <Box className="section" sx={{ mb: 3 }}>
                    <Typography variant="h5" sx={{
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                        pb: 1,
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <KitchenIcon sx={{ mr: 1 }} />
                        Ingredients
                    </Typography>
                    <Grid container spacing={2}>
                        {recipe.ingredients.map((ingredient, index) => (
                            <Grid item xs={12} key={index}>
                                {isEditing ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <TextField
                                            label="Name"
                                            value={ingredient.name}
                                            onChange={(e) => handleIngredientChange(index, 'name', e.target.value)}
                                            sx={{ mr: 1, flexGrow: 1 }}
                                        />
                                        <TextField
                                            label="Quantity"
                                            value={ingredient.quantity}
                                            onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
                                            sx={{ mr: 1, width: '20%' }}
                                        />
                                        <TextField
                                            label="Unit"
                                            value={ingredient.unit}
                                            onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)}
                                            sx={{ mr: 1, width: '20%' }}
                                        />
                                        <TextField
                                            label="Description"
                                            value={ingredient.description}
                                            onChange={(e) => handleIngredientChange(index, 'description', e.target.value)}
                                            sx={{ mr: 1, flexGrow: 1 }}
                                        />
                                        <IconButton onClick={() => removeIngredient(index)}>
                                            <RemoveIcon />
                                        </IconButton>
                                        <IconButton onClick={() => moveIngredient(index, -1)} disabled={index === 0}>
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton onClick={() => moveIngredient(index, 1)} disabled={index === recipe.ingredients.length - 1}>
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Box sx={{ mb: 1 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            {ingredient.quantity} {ingredient.unit} {ingredient.name}
                                        </Typography>
                                        {ingredient.description && (
                                            <Typography variant="body2" color="text.secondary">
                                                {ingredient.description}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                    {isEditing && (
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={addIngredient}
                            sx={{ mt: 2 }}
                        >
                            Add Ingredient
                        </Button>
                    )}
                </Box>
                <Box className="section">
                    <Typography variant="h5" sx={{
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                        pb: 1,
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <StepsIcon sx={{ mr: 1 }} />
                        Steps
                    </Typography>
                    {recipe.steps.map((step, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            {isEditing ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <TextField
                                        label="Name"
                                        value={step.name}
                                        onChange={(e) => handleStepChange(index, 'name', e.target.value)}
                                        sx={{ mr: 1, flexGrow: 1 }}
                                    />
                                    <TextField
                                        label="Duration"
                                        value={step.duration}
                                        onChange={(e) => handleStepChange(index, 'duration', e.target.value)}
                                        sx={{ mr: 1, width: '20%' }}
                                    />
                                    <TextField
                                        label="Description"
                                        value={step.description}
                                        onChange={(e) => handleStepChange(index, 'description', e.target.value)}
                                        sx={{ mr: 1, flexGrow: 1 }}
                                    />
                                    <IconButton onClick={() => removeStep(index)}>
                                        <RemoveIcon />
                                    </IconButton>
                                    <IconButton onClick={() => moveStep(index, -1)} disabled={index === 0}>
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton onClick={() => moveStep(index, 1)} disabled={index === recipe.steps.length - 1}>
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </Box>
                            ) : (
                                <>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {index + 1}. {step.name}
                                        {step.duration && (
                                            <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                                                ({step.duration})
                                            </Typography>
                                        )}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 0.5, ml: 3 }}>{step.description}</Typography>
                                </>
                            )}
                        </Box>
                    ))}
                    {isEditing && (
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={addStep}
                            sx={{ mt: 2 }}
                        >
                            Add Step
                        </Button>
                    )}
                </Box>
                <Box className="section" sx={{ mt: 3 }}>
                    {isEditing ? (
                        <>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select
                                    labelId="category-label"
                                    value={recipe.category}
                                    onChange={(e) => handleChange('category', e.target.value)}
                                    label="Category"
                                >
                                    {categories.map((category) => (
                                        <MenuItem key={category} value={category}>{category}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <StringList
                                label="Tags"
                                values={recipe.tags || []}
                                onChange={(newTags) => handleChange('tags', newTags)}
                                editable={true}
                                suggestedValues={['Vegetarian', 'Gluten-Free', 'No-Bake', 'Unique', 'Refreshing']}
                            />
                        </>
                    ) : (
                        <>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Category: {recipe.category}</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                {recipe.tags && recipe.tags.map((tag, index) => (
                                    <Typography
                                        key={index}
                                        variant="body2"
                                        sx={{
                                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                            color: theme.palette.primary.main,
                                            px: 1,
                                            py: 0.5,
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {tag}
                                    </Typography>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
                <NutritionEstimatesDisplay
                    nutritionEstimate={recipe.nutrition_estimate}
                    onUpdate={handleNutritionUpdate}
                    isEditing={isEditing}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
                        onClick={toggleEdit}
                    >
                        {isEditing ? 'Done Editing' : 'Edit Recipe'}
                    </Button>
                    <DeleteSource
                        sourceId={sourceRecord.id}
                        onDeleteSuccess={onSourceDelete}
                    />
                </Box>
            </Box>
        </Paper>
    );
};

export default RecipeDisplay;
