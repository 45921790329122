import { useState, useCallback, useEffect } from 'react';
import useApi from "../components/ApiService";

export function useSystemCollection() {
    const [sourceMap, setSourceMap] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { apiCall } = useApi();

    const fetchSystemCollectionSources = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await apiCall('/source/system');
            const sources = response.data.sources;
            console.log("Got data: ", response.data);
            const newSourceMap = {};
            sources.forEach(source => {
                newSourceMap[source.name] = source.id;
            });
            setSourceMap(newSourceMap);
        } catch (err) {
            console.error('Error fetching system collection sources:', err);
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }, [apiCall]);

    useEffect(() => {
        fetchSystemCollectionSources();
    }, [fetchSystemCollectionSources]);

    const getSystemCollectionIdByName = useCallback(async (name) => {
        if (typeof name !== 'string') {
            throw new Error('Source name must be a string.');
        }

        if (isLoading) {
            await new Promise(resolve => {
                const checkLoading = () => {
                    if (!isLoading) {
                        resolve();
                    } else {
                        setTimeout(checkLoading, 100);
                    }
                };
                checkLoading();
            });
        }

        if (sourceMap[name]) {
            return sourceMap[name];
        }

        throw new Error(`Source with name "${name}" not found.`);
    }, [sourceMap, isLoading]);

    return {
        getSystemCollectionIdByName,
        isLoading,
        error,
        refetch: fetchSystemCollectionSources
    };
}
