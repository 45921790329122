import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, CircularProgress, IconButton, Button, Grid, Paper, Breadcrumbs, Link } from '@mui/material';
import { ArrowLeft, Plus, Folder, FileText, Copy, Move, Trash2, CornerUpLeft } from 'lucide-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useSourceName } from "../../../context/useSourceName";

const FolderNavigation = ({
                              initialNodes,
                              initialEdges,
                              selectedCollectionId,
                              componentId,
                              onNavigate,
                              navigateTo,
                              onAddFolder,
                              refreshData,
                              otherNavigationPath
                          }) => {
    const [currentPath, setCurrentPath] = useState([]);
    const [structure, setStructure] = useState({ root: { name: 'Root', type: 'folder', children: {} } });
    const [isLoading, setIsLoading] = useState(true);
    const [showOrphans, setShowOrphans] = useState(false);
    const { getSourceNameById } = useSourceName();
    const structureRef = useRef(structure);

    const loadChildren = useCallback(async (nodeId, orphansOnly = false) => {
        if (orphansOnly) {
            const orphanNodes = initialNodes.filter(node =>
                !initialEdges.some(edge => edge.target === node.id)
            );
            const children = {};
            for (const node of orphanNodes) {
                const name = await getSourceNameById(node.id);
                const isFolder = node.type.includes('Collection') ||
                    (Array.isArray(node.type) &&
                        node.type.includes('System') &&
                        node.type.includes('Application'));
                children[node.id] = {
                    id: node.id,
                    name: name,
                    type: isFolder ? 'folder' : 'file',
                    children: isFolder ? {} : undefined
                };
            }
            return children;
        } else {
            const childEdges = initialEdges.filter(edge => edge.source === nodeId);
            const children = {};
            for (const edge of childEdges) {
                const childNode = initialNodes.find(n => n.id === edge.target);
                if (childNode) {
                    const name = await getSourceNameById(edge.target);
                    const isFolder = childNode.type.includes('Collection') ||
                        (Array.isArray(childNode.type) &&
                            childNode.type.includes('System') &&
                            childNode.type.includes('Application'));
                    children[edge.target] = {
                        id: edge.target,
                        name: name,
                        type: isFolder ? 'folder' : 'file',
                        children: isFolder ? {} : undefined
                    };
                }
            }
            return children;
        }
    }, [initialNodes, initialEdges, getSourceNameById]);

    const initializeStructure = useCallback(async () => {
        const newStructure = { root: { name: 'Root', type: 'folder', children: {} } };

        if (selectedCollectionId) {
            const rootNode = initialNodes.find(n => n.id === selectedCollectionId);
            if (rootNode) {
                const name = await getSourceNameById(selectedCollectionId);
                newStructure.root.children[selectedCollectionId] = {
                    id: selectedCollectionId,
                    name: name,
                    type: 'folder',
                    children: await loadChildren(selectedCollectionId)
                };
            }
        } else {
            const rootNodes = initialNodes.filter(node =>
                !initialEdges.some(edge => edge.target === node.id)
            );
            for (const node of rootNodes) {
                const name = await getSourceNameById(node.id);
                const isFolder = node.type.includes('Collection') || node.type.includes('Application');
                newStructure.root.children[node.id] = {
                    id: node.id,
                    name: name,
                    type: isFolder ? 'folder' : 'file',
                    children: isFolder ? {} : undefined
                };
            }
        }

        newStructure.root.children['orphans'] = {
            id: 'orphans',
            name: 'Orphans',
            type: 'folder',
            children: {}
        };

        return newStructure;
    }, [initialNodes, initialEdges, selectedCollectionId, getSourceNameById, loadChildren]);

    const navigateToPath = useCallback(async (path) => {
        setIsLoading(true);
        let current = structureRef.current.root;
        const newPath = [];
        for (const id of path) {
            if (current.children[id]) {
                newPath.push(id);
                if (current.children[id].type === 'folder' && Object.keys(current.children[id].children).length === 0) {
                    if (id === 'orphans') {
                        current.children[id].children = await loadChildren(null, true);
                        setShowOrphans(true);
                    } else {
                        current.children[id].children = await loadChildren(id);
                        setShowOrphans(false);
                    }
                }
                current = current.children[id];
            } else {
                console.warn(`Path segment ${id} not found in collection structure`);
                break;
            }
        }
        setStructure({...structureRef.current});
        setCurrentPath(newPath);
        onNavigate(newPath);
        setIsLoading(false);
    }, [loadChildren, onNavigate]);

    useEffect(() => {
        const initialize = async () => {
            setIsLoading(true);
            try {
                const newStructure = await initializeStructure();
                setStructure(newStructure);
                structureRef.current = newStructure;
                if (navigateTo && navigateTo.length > 0) {
                    await navigateToPath(navigateTo);
                }
            } catch (error) {
                console.error('Error during initialization:', error);
            } finally {
                setIsLoading(false);
            }
        };

        initialize();
    }, [initializeStructure, navigateToPath, navigateTo]);

    const handleBackClick = useCallback(() => {
        if (currentPath.length > 0) {
            const newPath = currentPath.slice(0, -1);
            navigateToPath(newPath);
        }
    }, [currentPath, navigateToPath]);

    const handleAddItem = useCallback(() => {
        onAddFolder(currentPath, componentId);
    }, [onAddFolder, currentPath, componentId]);

    const handleItemClick = useCallback(async (id, type) => {
        if (type === 'folder') {
            const newPath = [...currentPath, id];
            await navigateToPath(newPath);
        } else {
            console.log('File clicked:', id);
        }
    }, [currentPath, navigateToPath]);

    const getCurrentFolder = useCallback(() => {
        let current = structureRef.current.root;
        for (let id of currentPath) {
            if (current && current.children && current.children[id]) {
                current = current.children[id];
            } else {
                console.warn(`Path segment ${id} not found in current colection structure`);
                return null;
            }
        }
        return current;
    }, [currentPath]);

    const renderFolderContent = useCallback(() => {
        const currentFolder = getCurrentFolder();
        if (!currentFolder || !currentFolder.children) {
            return <Typography>This folder cannot be accessed.</Typography>;
        }

        const childrenCount = Object.keys(currentFolder.children).length;

        if (childrenCount === 0) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        border: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Typography variant="body1" color="text.secondary">
                        This collection is empty. Drag items here or use the "Add Collection" button to add a collection.
                    </Typography>
                </Box>
            );
        }

        return (
            <Droppable droppableId={`${componentId}-${currentPath.length > 0 ? currentPath[currentPath.length - 1] : 'root'}`}>
                {(provided, snapshot) => (
                    <Grid
                        container
                        spacing={2}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{
                            minHeight: '300px',
                            bgcolor: snapshot.isDraggingOver ? 'action.hover' : 'background.paper',
                            border: '2px dashed',
                            borderColor: snapshot.isDraggingOver ? 'primary.main' : 'divider',
                            borderRadius: 2,
                            p: 2,
                        }}
                    >
                        {Object.entries(currentFolder.children).map(([id, item], index) => (
                            <Draggable key={id} draggableId={`${componentId}-${id}`} index={index}>
                                {(provided, snapshot) => (
                                    <Grid item xs={3} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Paper
                                            elevation={3}
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                '&:hover': { bgcolor: 'action.hover' },
                                                opacity: snapshot.isDragging ? 0.5 : 1,
                                            }}
                                            onClick={() => handleItemClick(id, item.type)}
                                        >
                                            {item.type === 'folder' ? (
                                                <Folder size={40} color="#FFA000" />
                                            ) : (
                                                <FileText size={40} color="#2196F3" />
                                            )}
                                            <Typography variant="body2" align="center" mt={1}>
                                                {item.name}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        );
    }, [getCurrentFolder, handleItemClick, componentId, currentPath]);

    const renderActionAreas = useCallback(() => {
        const isOrphansFolder = currentPath.length > 0 && currentPath[currentPath.length - 1] === 'orphans';
        const actions = ['Copy To', 'Move To', isOrphansFolder ? 'Delete' : 'Remove'];

        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    mb: 2,
                    gap: 2,
                    '& > *': { flexGrow: 1, flexBasis: 0 }
                }}
            >
                {actions.map((action) => (
                    <Droppable
                        key={action}
                        droppableId={`${componentId}-${action.toLowerCase().replace(' ', '')}`}
                    >
                        {(provided, snapshot) => (
                            <Paper
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                elevation={3}
                                sx={{
                                    p: 2,
                                    height: '60px',
                                    bgcolor: snapshot.isDraggingOver ? 'action.hover' : 'background.default',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '2px dashed',
                                    borderColor: snapshot.isDraggingOver ? 'primary.main' : 'divider',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        bgcolor: 'action.hover',
                                    },
                                }}
                            >
                                {action === 'Copy To' && <Copy size={20} />}
                                {action === 'Move To' && <Move size={20} />}
                                {action === 'Remove' && <CornerUpLeft size={20} />}
                                {action === 'Delete' && <Trash2 size={20} color="#f44336" />}
                                <Typography
                                    ml={1}
                                    color={action === 'Delete' ? 'error' : 'inherit'}
                                    sx={{ fontWeight: snapshot.isDraggingOver ? 'bold' : 'normal' }}
                                >
                                    {action}
                                </Typography>
                                {provided.placeholder}
                            </Paper>
                        )}
                    </Droppable>
                ))}
            </Box>
        );
    }, [componentId, currentPath]);

    const renderBreadcrumbs = useCallback(() => {
        const pathItems = [{ id: 'root', name: 'Root' }];
        let current = structureRef.current.root;

        for (let i = 0; i < currentPath.length; i++) {
            const id = currentPath[i];
            if (current && current.children && current.children[id]) {
                current = current.children[id];
                pathItems.push({ id, name: current.name });
            } else {
                console.warn(`Path segment ${id} not found in current collection structure`);
                break;
            }
        }

        return (
            <Breadcrumbs aria-label="breadcrumb">
                {pathItems.map((item, index) => (
                    <Link
                        key={item.id}
                        color="inherit"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            navigateToPath(currentPath.slice(0, index));
                        }}
                    >
                        <Typography variant="body2">{item.name}</Typography>
                    </Link>
                ))}
            </Breadcrumbs>
        );
    }, [currentPath, navigateToPath, structureRef]);

    return (
        <Box sx={{ p: 2, border: 1, borderColor: 'divider', borderRadius: 1, bgcolor: 'background.paper' }}>
            <Box display="flex" alignItems="center" mb={2}>
                <IconButton
                    onClick={handleBackClick}
                    disabled={currentPath.length === 0 || isLoading}
                    size="small"
                    sx={{ mr: 1 }}
                >
                    <ArrowLeft />
                </IconButton>
                <Box flexGrow={1}>{renderBreadcrumbs()}</Box>
            </Box>
            {isLoading ? (
                <CircularProgress size={24} />
            ) : (
                <>
                    {renderActionAreas()}
                    {renderFolderContent()}
                </>
            )}
            <Box display="flex" gap={1} mt={2}>
                <Button
                    variant="outlined"
                    startIcon={<Plus />}
                    onClick={handleAddItem}
                    disabled={isLoading || showOrphans}
                >
                    Add Collection
                </Button>
                {showOrphans && (
                    <Button
                        variant="outlined"
                        onClick={refreshData}
                        disabled={isLoading}
                    >
                        Refresh
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default FolderNavigation;