import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MicIcon from '@mui/icons-material/Mic';

const OptionButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
        transform: 'translateY(-4px)',
    },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    fontSize: '2rem',
    marginBottom: theme.spacing(1),
}));

const InputMethodSelector = ({ inputMethod, handleInputMethodChange }) => {
    const options = [
        { value: 'text', label: 'Enter Details', icon: <TextFieldsIcon fontSize="inherit" /> },
        { value: 'file', label: 'From File', icon: <FileUploadIcon fontSize="inherit" /> },
        { value: 'speech', label: 'From Speech', icon: <MicIcon fontSize="inherit" /> },
        { value: 'select', label: 'From Sources', icon: <FormatListBulletedIcon fontSize="inherit" /> },
    ];

    return (
        <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
                Add From:
            </Typography>
            <Grid container spacing={2}>
                {options.map((option) => (
                    <Grid item xs={12} sm={6} md={3} key={option.value}>
                        <OptionButton
                            variant={inputMethod === option.value ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => handleInputMethodChange({ target: { value: option.value } })}
                        >
                            <IconWrapper>
                                {option.icon}
                            </IconWrapper>
                            <Typography variant="subtitle1">{option.label}</Typography>
                        </OptionButton>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default InputMethodSelector;