import React from 'react';
import {
    Container,
    Typography,
    Box,
    Divider,
    Paper
} from '@mui/material';

const Terms = () => {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: 4, my: 4 }}>
                <Typography variant="h3" component="h1" gutterBottom align="center">
                    Terms and Conditions
                </Typography>

                <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 4 }}>
                    Last updated: {new Date().toLocaleDateString()}
                </Typography>

                <Divider sx={{ mb: 4 }} />

                <Box sx={{ '& > *': { mb: 4 } }}>
                    <section>
                        <Typography variant="h5" gutterBottom>
                            1. Agreement to Terms
                        </Typography>
                        <Typography paragraph>
                            By accessing or using our service, you agree to be bound by these Terms and Conditions.
                            If you disagree with any part of these terms, you do not have permission to access the service.
                        </Typography>
                    </section>

                    <section>
                        <Typography variant="h5" gutterBottom>
                            2. AI-Generated Content and User Responsibility
                        </Typography>
                        <Typography paragraph>
                            Our service utilizes artificial intelligence to generate content related to nutrition and fitness. By using our service, you acknowledge and agree to the following:
                        </Typography>
                        <ul>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                All AI-generated content is provided for informational and entertainment purposes only
                            </Typography>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                The accuracy, completeness, and reliability of AI-generated content cannot be guaranteed
                            </Typography>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                You must independently verify any advice, recommendations, or information provided through our service with qualified healthcare professionals, certified fitness trainers, or other relevant experts
                            </Typography>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                You assume full responsibility for how you choose to use or implement any AI-generated content
                            </Typography>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                You should never disregard professional medical advice or delay seeking it because of content generated by our service
                            </Typography>
                        </ul>
                    </section>

                    <section>
                        <Typography variant="h5" gutterBottom>
                            3. User Accounts
                        </Typography>
                        <Typography paragraph>
                            When you create an account with us, you must provide accurate, complete, and current information.
                            You are responsible for safeguarding the password and for all activities that occur under your account.
                        </Typography>
                        <Typography paragraph>
                            You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
                            We cannot and will not be liable for any loss or damage arising from your failure to comply with this section.
                        </Typography>
                    </section>

                    <section>
                        <Typography variant="h5" gutterBottom>
                            4. Acceptable Use
                        </Typography>
                        <Typography paragraph>
                            You agree not to use the service:
                        </Typography>
                        <ul>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                In any way that violates any applicable laws or regulations
                            </Typography>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                To transmit any material that is unlawful, harmful, threatening, abusive, or harassing
                            </Typography>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                To impersonate or attempt to impersonate any person or entity
                            </Typography>
                            <Typography component="li" sx={{ ml: 4, mb: 1 }}>
                                To engage in any conduct that restricts or inhibits anyone's use of the service
                            </Typography>
                        </ul>
                    </section>

                    <section>
                        <Typography variant="h5" gutterBottom>
                            5. Intellectual Property
                        </Typography>
                        <Typography paragraph>
                            The service and its original content, features, and functionality are and will remain the exclusive
                            property of our company and its licensors. The service is protected by copyright, trademark, and
                            other laws.
                        </Typography>
                    </section>

                    <section>
                        <Typography variant="h5" gutterBottom>
                            6. Termination
                        </Typography>
                        <Typography paragraph>
                            We may terminate or suspend your account immediately, without prior notice or liability, for any
                            reason whatsoever, including without limitation if you breach the Terms.
                        </Typography>
                        <Typography paragraph>
                            Upon termination, your right to use the service will immediately cease. If you wish to terminate
                            your account, you may simply discontinue using the service.
                        </Typography>
                    </section>

                    <section>
                        <Typography variant="h5" gutterBottom>
                            7. Limitation of Liability
                        </Typography>
                        <Typography paragraph>
                            In no event shall this site's owner, nor its directors, employees, partners, agents, suppliers, or
                            affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
                            including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
                            resulting from your access to or use of or inability to access or use the service.
                        </Typography>
                        <Typography paragraph>
                            Specifically regarding AI-generated content, we expressly disclaim all liability for any harm, injury,
                            loss, or damage of any kind arising from or relating to your use of or reliance on the AI-generated
                            content, including but not limited to any nutrition advice, fitness recommendations, or health-related
                            information. You expressly agree that your use of any AI-generated content is at your own risk and
                            that you bear sole responsibility for verifying such content with qualified professionals before
                            implementation or use.
                        </Typography>
                    </section>

                    <section>
                        <Typography variant="h5" gutterBottom>
                            8. Changes to Terms
                        </Typography>
                        <Typography paragraph>
                            We reserve the right to modify or replace these Terms at any time.
                        </Typography>
                        <Typography paragraph>
                            By continuing to access or use our service after those revisions become effective, you agree
                            to be bound by the revised terms.
                        </Typography>
                    </section>
                </Box>

                <Divider sx={{ mt: 4, mb: 2 }} />

                <Typography variant="body2" color="text.secondary" align="center">
                    These terms and conditions constitute a legally binding agreement between you and our entity.
                </Typography>
            </Paper>
        </Container>
    );
};

export default Terms;