import React, { useMemo, useState } from 'react';
import { Paper, Typography, Box, useTheme, Select, MenuItem, FormControl, InputLabel, useMediaQuery } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { parseISO, format, addDays, isBefore, isSameDay } from 'date-fns';

const diets = {
    ketogenic: { name: "Ketogenic", protein: 20, carbs: 5, fat: 75 },
    lowCarb: { name: "Low-Carb", protein: 30, carbs: 20, fat: 50 },
    lowFat: { name: "Low-Fat", protein: 25, carbs: 60, fat: 15 },
    atkins: { name: "Atkins", protein: 30, carbs: 10, fat: 60 },
    zone: { name: "Zone", protein: 30, carbs: 40, fat: 30 }
};

const CustomTooltip = ({ active, payload, label }) => {
    const theme = useTheme();

    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(1.5),
                    boxShadow: theme.shadows[4],
                }}
            >
                <Typography variant="subtitle2" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
                    {format(parseISO(label), 'MMMM d, yyyy')}
                </Typography>
                {payload.map((entry, index) => (
                    <Box key={`item-${index}`} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                        <Typography variant="body2" sx={{ color: entry.color, mr: 2, fontWeight: 'bold' }}>
                            {entry.name}:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {entry.value.toFixed(2)}%
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    }
    return null;
};

const MacronutrientTrendsWithDietComparison = ({ items, startDate, endDate, getChartTimeframe }) => {
    const theme = useTheme();
    const [selectedDiet, setSelectedDiet] = useState('');
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const chartData = useMemo(() => {
        const dateArray = [];
        for (let d = parseISO(startDate); isBefore(d, parseISO(endDate)) || isSameDay(d, parseISO(endDate)); d = addDays(d, 1)) {
            dateArray.push(format(d, 'yyyy-MM-dd'));
        }

        return dateArray.map(date => {
            const dayItems = items.filter(item => item.content.content.mealDate === date);
            const dayTotals = dayItems.reduce((acc, item) => {
                const nutrition = item.content.content.totalNutrition;
                acc.protein += nutrition.protein || 0;
                acc.carbs += nutrition.carbs || 0;
                acc.fat += nutrition.fat || 0;
                return acc;
            }, { protein: 0, carbs: 0, fat: 0 });

            const totalCalories = (dayTotals.protein * 4) + (dayTotals.carbs * 4) + (dayTotals.fat * 9);
            return {
                date,
                protein: totalCalories > 0 ? (dayTotals.protein * 4 / totalCalories) * 100 : 0,
                carbs: totalCalories > 0 ? (dayTotals.carbs * 4 / totalCalories) * 100 : 0,
                fat: totalCalories > 0 ? (dayTotals.fat * 9 / totalCalories) * 100 : 0
            };
        });
    }, [items, startDate, endDate]);

    const averages = useMemo(() => {
        const validData = chartData.filter(day => {
            const totalPercentage = day.protein + day.carbs + day.fat;
            return totalPercentage > 0 && totalPercentage <= 100;
        });

        if (validData.length === 0) {
            return { protein: 0, carbs: 0, fat: 0 };
        }

        const totals = validData.reduce((acc, day) => {
            acc.protein += day.protein;
            acc.carbs += day.carbs;
            acc.fat += day.fat;
            return acc;
        }, { protein: 0, carbs: 0, fat: 0 });

        const days = validData.length;
        return {
            protein: (totals.protein / days) || 0,
            carbs: (totals.carbs / days) || 0,
            fat: (totals.fat / days) || 0
        };
    }, [chartData]);

    const lineColors = {
        protein: '#ff6b6b',  // Bright red
        carbs: '#4ecdc4',    // Bright teal
        fat: '#feca57'       // Bright yellow
    };

    const getDietFeedback = () => {
        if (!selectedDiet) return [];
        const diet = diets[selectedDiet];
        const feedback = [];
        for (const macro of ['protein', 'carbs', 'fat']) {
            const diff = averages[macro] - diet[macro];
            if (Math.abs(diff) < 5) {
                feedback.push(`Your ${macro} intake is well-aligned with the ${diet.name} diet.`);
            } else if (diff > 0) {
                feedback.push(`Your ${macro} intake is higher than recommended for the ${diet.name} diet. Consider reducing it.`);
            } else {
                feedback.push(`Your ${macro} intake is lower than recommended for the ${diet.name} diet. Consider increasing it.`);
            }
        }
        return feedback;
    };

    return (
        <Paper sx={{ p: 2, height: 'auto', minHeight: 600 }}>
            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ color: theme.palette.text.primary, mb: isSmallScreen ? 1 : 0 }}>
                    Macronutrient Trends - {getChartTimeframe()}
                </Typography>
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel id="diet-select-label">Diet</InputLabel>
                    <Select
                        labelId="diet-select-label"
                        value={selectedDiet}
                        label="Diet"
                        onChange={(e) => setSelectedDiet(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>Select a diet to compare</em>
                        </MenuItem>
                        {Object.entries(diets).map(([key, diet]) => (
                            <MenuItem key={key} value={key}>{diet.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chartData} margin={{ right: 60, left: -10, bottom: 0, top: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(value) => format(parseISO(value), 'MM/dd')}
                        stroke={theme.palette.text.secondary}
                        tick={{ fill: theme.palette.text.secondary }}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        tick={{ fill: theme.palette.text.secondary }}
                        domain={[0, 100]}
                        unit="%"
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        wrapperStyle={{ color: theme.palette.text.primary }}
                        formatter={(value, entry) => <span style={{ color: entry.color }}>{value}</span>}
                    />
                    {Object.entries(lineColors).map(([key, color]) => (
                        <React.Fragment key={key}>
                            <Line
                                type="monotone"
                                dataKey={key}
                                stroke={color}
                                strokeWidth={2}
                                name={key.charAt(0).toUpperCase() + key.slice(1)}
                                dot={{ fill: color, r: 4 }}
                                activeDot={{ r: 6 }}
                            />
                            {selectedDiet && (
                                <ReferenceLine
                                    y={diets[selectedDiet][key]}
                                    stroke={color}
                                    strokeDasharray="3 3"
                                    label={{
                                        value: `${key.charAt(0).toUpperCase() + key.slice(1)} Target: ${diets[selectedDiet][key]}%`,
                                        position: 'right',
                                        fill: color,
                                        fontSize: isSmallScreen ? 10 : 12,
                                        offset: 5,
                                    }}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </LineChart>
            </ResponsiveContainer>
            <Box sx={{ mt: 4 }}>
                {selectedDiet ? (
                    <>
                        <Typography variant="h6" sx={{ mb: 2 }}>Comparison with {diets[selectedDiet].name} Diet</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', mb: 2 }}>
                            {Object.entries(diets[selectedDiet]).map(([macro, percentage]) => (
                                macro !== 'name' && (
                                    <Box key={macro} sx={{ textAlign: 'center', flexBasis: isSmallScreen ? '100%' : '30%', mb: isSmallScreen ? 2 : 0 }}>
                                        <Typography variant="subtitle1">{macro.charAt(0).toUpperCase() + macro.slice(1)}</Typography>
                                        <Typography variant="body2">Target: {percentage}%</Typography>
                                        <Typography variant="body2">Actual: {averages[macro].toFixed(1)}%</Typography>
                                    </Box>
                                )
                            ))}
                        </Box>
                        <Typography variant="h6" sx={{ mb: 1 }}>Feedback:</Typography>
                        {getDietFeedback().map((feedback, index) => (
                            <Typography key={index} variant="body2" sx={{ mb: 0.5 }}>• {feedback}</Typography>
                        ))}
                    </>
                ) : (
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>Select a diet to see comparison and feedback</Typography>
                )}
            </Box>
        </Paper>
    );
};

export default MacronutrientTrendsWithDietComparison;