import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from './components/Navigation';
import NotFound from "./context/NotFound";
import themeDefinitions from './themeDefinitions';
import { AuthProvider } from "./AuthContext";
import Terms from "./Terms";
import Login from "./Login";
import Register from "./Register"
import PrivateRoute from "./PrivateRoute";
import Account from "./root/Account";
import {SourceProvider} from "./context/SourceContext"

const drawerWidth = 10;
const CHECK_INTERVAL = 5 * 60 * 1000;

function CacheRefreshCheck() {
    const location = useLocation();

    useEffect(() => {
        const checkAndRefresh = async () => {
            const lastCheckTime = localStorage.getItem('lastCheckTime');
            const now = Date.now();

            // Only check if it's been more than CHECK_INTERVAL since the last check
            if (!lastCheckTime || now - parseInt(lastCheckTime) > CHECK_INTERVAL) {
                try {
                    const response = await fetch('http://localhost:8080/api/actuator/system-info');
                    const data = await response.json();
                    const serverUiVersion = data.uiVersion;

                    const lastUiVersion = localStorage.getItem('lastUiVersion');

                    if (!lastUiVersion || lastUiVersion !== serverUiVersion) {
                        localStorage.setItem('lastUiVersion', serverUiVersion);
                        setTimeout(() => window.location.reload(true), 100);
                    }

                    // Update the last check time
                    localStorage.setItem('lastCheckTime', now.toString());
                } catch (error) {
                    console.error('Failed to fetch system info:', error);
                }
            }
        };

        checkAndRefresh();
    }, [location]);

    return null;
}

function App() {
    const [currentTheme, setCurrentTheme] = useState(() => {
        const savedThemeIndex = localStorage.getItem('selectedThemeIndex');
        return themeDefinitions[savedThemeIndex ? parseInt(savedThemeIndex) : 0].theme;
    });
    const [routes, setRoutes] = useState([]);
    const [mobileOpen, setMobileOpen] = useState(false);
    const isMobile = useMediaQuery(currentTheme.breakpoints.down('sm'));

    useEffect(() => {
        const loadAllRoutes = async () => {
            const context = require.context('./root', true, /subNavConfig\.js$/);
            const allRoutes = await Promise.all(
                context.keys().map(async (key) => {
                    const config = await import(`./root${key.slice(1)}`);
                    return config.default.routes || [];
                })
            );
            setRoutes(allRoutes.flat());
        };

        loadAllRoutes();
    }, []);

    const handleThemeChange = (newTheme) => {
        setCurrentTheme(newTheme);
        const newThemeIndex = themeDefinitions.findIndex(def => def.theme === newTheme);
        localStorage.setItem('selectedThemeIndex', newThemeIndex.toString());
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <AuthProvider>
            <ThemeProvider theme={currentTheme}>
                <SourceProvider>
                    <CssBaseline />
                    <Router>
                        <CacheRefreshCheck />
                        <ToastContainer
                            position="top-right"
                            autoClose={4000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                            limit={6}
                        />
                        <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
                            <Navigation
                                onThemeChange={handleThemeChange}
                                mobileOpen={mobileOpen}
                                handleDrawerToggle={handleDrawerToggle}
                            />
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                                    ml: { sm: `${drawerWidth}px` },
                                    overflowY: 'auto',
                                    p: isMobile ? 0 : 3, // Remove padding for mobile screens
                                }}
                            >
                                <Routes>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/register" element={<Register />} />
                                    <Route path="/terms" element={<Terms />} />
                                    <Route path="/account" element={<Account />} />
                                    <Route path="/" element={<Navigate to="/apps" replace />} />
                                    <Route element={<PrivateRoute />}>
                                        {routes.map((route, index) => (
                                            <Route key={index} path={route.path} element={route.element} />
                                        ))}
                                    </Route>
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </Box>
                        </Box>
                    </Router>
                </SourceProvider>
            </ThemeProvider>
        </AuthProvider>
    );
}

export default App;