import React, { useState } from 'react';
import AppCardGrid from "../../components/apps/AppCardGrid";
import subNav from './subNavConfig';
import { Box, Typography, Paper, useTheme, Modal, Button } from '@mui/material';

function AppsLanding() {
    const theme = useTheme();
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    background: 'linear-gradient(135deg, #6e8efb, #a777e3)',
                    color: 'white',
                    padding: 4,
                    borderRadius: 2,
                    marginBottom: 4,
                }}
            >
                <Typography variant="h3" component="h2" gutterBottom>
                    Welcome to early Alpha version of AIssistant!
                </Typography>
                <Button
                    onClick={handleOpen}
                    variant="contained"
                    color="warning"
                    sx={{ mt: 2 }}
                >
                    Important: Alpha Version Notice
                </Button>
            </Paper>
            <Box>
                <AppCardGrid title={`${subNav.header} Dashboard`} subNav={subNav} />
            </Box>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    maxWidth: '90%',
                    maxHeight: '90%',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
                        Important Alpha Version Notice
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <strong>About Collections and Sources:</strong>
                        <ul>
                            <li>Collections are your virtual bookshelves (like folders), helping you categorize and manage your sources.</li>
                            <li>Sources are the building blocks of your knowledge (like files), the source of all your information.</li>
                            <li>You link them together with "Associations". A Source associated to a Collection will be available when that Collection is selected and anything below it.</li>
                            <li>You can also associate a Collection with a Collection to create a hierarchy and better organize your Sources.</li>
                        </ul>
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        This is the Alpha version and only has the basics. More advanced features and applications will come. You may need to refresh the page after making changes, such as deleting a source, or showing IDs where there should be readable names. Such things will be worked on, but this is just an Alpha version and a work in progress.
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        <strong>Warning:</strong> Do not submit any sensitive, private, or questionable materials as some data will be persisted and sent out to LLM models.
                    </Typography>
                    <Box sx={{ backgroundColor: theme.palette.warning.light, padding: 2, mt: 2 }}>
                        <Typography variant="body2" color="error">
                            <strong>IMPORTANT: Data Loss Risk</strong>
                            <br />
                            Do not keep anything important here as it is likely to have to be re-loaded on future versions of the app. Best attempts will be made to upgrade all existing data, but no promises can be made.
                        </Typography>
                    </Box>
                    <Button onClick={handleClose} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </>
    );
}

export default AppsLanding;