import React, { useMemo } from 'react';
import RAGSimpleSpecificSources from "../../../../../components/rag/RAGSimpleSpecificSources";

const DietAnalysisRAG = ({ items }) => {
    const sourceIds = useMemo(() => items.map(item => item.id), [items]);

    const query = "How is my diet based on my past meals?";
    const context = "Analyze the nutritional content and balance of the meals provided. Consider factors such as macronutrient distribution, calorie intake, and overall dietary patterns.";
    const specialInstructions = "Provide a comprehensive analysis of the diet based on the meal data. Include insights on nutritional balance, potential areas for improvement, and general dietary recommendations. Summarize if too many entries. You are a nutritionist.";

    return (
        <RAGSimpleSpecificSources
            sourceIds={sourceIds}
            query={query}
            context={context}
            specialInstructions={specialInstructions}
            buttonText="Analyze My Diet"
            analysisTitle="Diet Analysis"
        />
    );
};

export default DietAnalysisRAG;