import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, CircularProgress, Alert } from '@mui/material';
import { useAuth } from "../AuthContext";
import PasswordUpdateForm from "../components/PasswordUpdateForm";
import CreditManagement from "../components/CreditManagement";
import useApi from "../components/ApiService";

const Account = () => {
    const { user } = useAuth();
    const { apiCall } = useApi();
    const [creditIds, setCreditIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCreditIds = async () => {
            if (!user?.username) return;

            try {
                const response = await apiCall(`/credits/user/${user.username}/ids`, {
                    method: 'GET'
                });

                if (response.status === 200) {
                    setCreditIds(response.data);
                }
            } catch (error) {
                console.error('Error fetching credit IDs:', error);
                setError('Failed to fetch credit accounts');
            } finally {
                setLoading(false);
            }
        };

        fetchCreditIds();
    }, [user?.username, apiCall]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography component="h1" variant="h4" gutterBottom>
                    Account Settings
                </Typography>

                {user ? (
                    <>
                        <Paper sx={{ p: 3, mb: 4 }}>
                            <Typography component="h2" variant="h5" gutterBottom>
                                Change Password
                            </Typography>
                            <PasswordUpdateForm />
                        </Paper>

                        {error && (
                            <Alert severity="error" sx={{ mb: 3 }}>
                                {error}
                            </Alert>
                        )}

                        {creditIds.map((creditId) => (
                            <Paper sx={{ p: 3, mb: 3 }} key={creditId}>
                                <Typography variant="h6" gutterBottom>
                                    Credit Account: {creditId}
                                </Typography>
                                <CreditManagement creditId={creditId} />
                            </Paper>
                        ))}

                        {creditIds.length === 0 && !error && (
                            <Paper sx={{ p: 3 }}>
                                <Typography>No credit accounts found</Typography>
                            </Paper>
                        )}
                    </>
                ) : (
                    <Paper sx={{ p: 3 }}>
                        <Typography>Please log in to view account settings</Typography>
                    </Paper>
                )}
            </Box>
        </Container>
    );
};

export default Account;