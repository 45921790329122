import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Box, Chip, Paper, Tooltip, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { CheckCircle, Warning, EventAvailable, HelpOutline } from '@mui/icons-material';
import ExpandableCard from "../../../../components/list/ExpandableCard";
import EditField from "../../../../components/form/EditField";
import useApi from "../../../../components/ApiService";
import NutritionEstimatesDisplay from "../nutrition/NutritionEstimatesDisplay";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const ContentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const foodGroups = ['Grain', 'Fruit', 'Vegetable', 'Protein', 'Dairy', 'Other'];

function FoodItem({ sourceRecord, onSourceUpdate }) {
    const { apiCall } = useApi();
    const [food, setFood] = useState(sourceRecord?.content?.content ?? {});
    const [editingField, setEditingField] = useState(null);
    const [tempEditValue, setTempEditValue] = useState('');

    useEffect(() => {
        if (food?.purchase_date && typeof food.purchase_date === 'string' && food.purchase_date.includes("Expiration Date:")) {
            const [purchaseDate, expirationDate] = food.purchase_date.split("Expiration Date:");
            setFood(prevFood => ({
                ...prevFood,
                purchase_date: purchaseDate.trim(),
                expiration_date: expirationDate?.trim() || prevFood.expiration_date
            }));
        }
    }, [food]);

    const handleDoubleClick = (field) => {
        setEditingField(field);
        setTempEditValue(food[field] ?? '');
    };

    const handleCancelEdit = () => {
        setEditingField(null);
        setTempEditValue('');
    };

    const handleSubmitEdit = useCallback(async (field, value) => {
        try {
            let updatedFood = { ...food, [field]: value };

            if (field === 'purchase_date_estimated' || field === 'expiration_date_estimated') {
                updatedFood[field] = value === 'true';
            }

            if (field === 'purchase_date' && value) {
                updatedFood.purchase_date_estimated = false;
            }
            if (field === 'expiration_date' && value) {
                updatedFood.expiration_date_estimated = false;
            }

            await updateFoodItem(updatedFood);
        } catch (error) {
            console.error('Error updating food item:', error);
        }
    }, [food]);

    const handleNutritionUpdate = useCallback(async (updatedNutrition) => {
        try {
            const updatedFood = { ...food, nutrition_estimate: updatedNutrition };
            await updateFoodItem(updatedFood);
        } catch (error) {
            console.error('Error updating nutrition estimate:', error);
        }
    }, [food]);

    const updateFoodItem = async (updatedFood) => {
        const requestBody = { id: sourceRecord?.content?.id, content: updatedFood };
        const response = await apiCall(`/content/${sourceRecord?.content?.id}`, {method: 'PUT', data: requestBody});

        if (response?.data?.content?.[0]?.content) {
            setFood(response.data.content[0].content);
            if (onSourceUpdate) {
                const updatedSource = {
                    ...sourceRecord,
                    content: {
                        ...sourceRecord.content,
                        content: response.data.content[0].content
                    }
                };
                onSourceUpdate(updatedSource);
            }
        } else {
            setFood(updatedFood);
        }

        setEditingField(null);
        setTempEditValue('');
    };

    const renderEditableField = (field, value, label) => {
        if (editingField === field) {
            if (field === 'food_group') {
                return (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id={`${field}-label`}>{label}</InputLabel>
                        <Select
                            labelId={`${field}-label`}
                            value={tempEditValue ?? ''}
                            onChange={(e) => setTempEditValue(e.target.value)}
                            onBlur={() => handleSubmitEdit(field, tempEditValue)}
                            label={label}
                        >
                            {foodGroups.map((group) => (
                                <MenuItem key={group} value={group}>{group}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
            return (
                <EditField
                    field={label}
                    value={tempEditValue?.toString() ?? ''}
                    onSubmit={(newValue) => handleSubmitEdit(field, newValue)}
                    onCancel={handleCancelEdit}
                />
            );
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'action.hover',
                    },
                }}
                onDoubleClick={() => handleDoubleClick(field)}
            >
                <Typography variant="body1">
                    <strong>{label}:</strong> {value?.toString() ?? 'N/A'}
                </Typography>
            </Box>
        );
    };

    const isExpired = food?.expiration_date && new Date(food.expiration_date) < new Date();

    const foodTitle = (
        <span>
            <strong>{food.name}</strong> ({food.quantity} {food.unit})
        </span>
    );

    return (
        <ExpandableCard title={foodTitle ?? 'Unnamed Food'}>
            <StyledPaper>
                <ContentBox>
                    {renderEditableField('name', food?.name, 'Name')}
                    {renderEditableField('description', food?.description, 'Description')}
                    {renderEditableField('quantity', food?.quantity, 'Quantity')}
                    {renderEditableField('unit', food?.unit, 'Unit')}
                    {renderEditableField('purchase_date', food?.purchase_date, 'Purchase Date')}
                    {renderEditableField('purchase_date_estimated', food?.purchase_date_estimated, 'Purchase Date Estimated')}
                    {renderEditableField('expiration_date', food?.expiration_date, 'Expiration Date')}
                    {renderEditableField('expiration_date_estimated', food?.expiration_date_estimated, 'Expiration Date Estimated')}
                    {renderEditableField('food_group', food?.food_group, 'Food Group')}
                    {renderEditableField('type', food?.type, 'Type')}
                </ContentBox>

                <NutritionEstimatesDisplay
                    nutritionEstimate={food.nutrition_estimate}
                    onUpdate={handleNutritionUpdate}
                />

                <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap' }}>
                    <Chip
                        icon={isExpired ? <Warning /> : <CheckCircle />}
                        label={isExpired ? "Expired" : "Not Expired"}
                        color={isExpired ? "error" : "success"}
                        size="small"
                    />
                    <Chip
                        label={food?.food_group ?? 'No Group'}
                        color="primary"
                        size="small"
                    />
                    <Chip
                        label={food?.type ?? 'No Type'}
                        size="small"
                    />
                    <Tooltip title="Purchase Date Estimated">
                        <Chip
                            icon={food?.purchase_date_estimated ? <HelpOutline /> : <EventAvailable />}
                            label={food?.purchase_date_estimated ? "Est. Purchase" : "Exact Purchase"}
                            color={food?.purchase_date_estimated ? "warning" : "info"}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Expiration Date Estimated">
                        <Chip
                            icon={food?.expiration_date_estimated ? <HelpOutline /> : <EventAvailable />}
                            label={food?.expiration_date_estimated ? "Est. Expiration" : "Exact Expiration"}
                            color={food?.expiration_date_estimated ? "warning" : "info"}
                            size="small"
                        />
                    </Tooltip>
                </Box>
            </StyledPaper>
        </ExpandableCard>
    );
}

export default FoodItem;