import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { EmojiEvents as TrophyIcon, Info as InfoIcon } from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts';
import { parseISO, format, differenceInDays, subDays } from 'date-fns';

const WorkoutStatsForExercise = ({ workouts }) => {
  const theme = useTheme();
  const [selectedExercise, setSelectedExercise] = useState('');

  // Normalize exercise name to lowercase for comparison
  const normalizeExerciseName = (name) => name.trim().toLowerCase();

  // Format exercise name to title case (capitalize first letter of each word)
  const formatExerciseName = (name) => {
    return name
        .trim()
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
  };

  const exercises = useMemo(() => {
    const uniqueExercises = new Map();
    workouts.forEach(workout => {
      workout.content?.content?.activities.forEach(activity => {
        if (activity.activity) {
          const normalizedName = normalizeExerciseName(activity.activity);
          // Store the formatted version of the name
          if (!uniqueExercises.has(normalizedName)) {
            uniqueExercises.set(normalizedName, formatExerciseName(activity.activity));
          }
        }
      });
    });
    // Get the formatted names and sort them
    return Array.from(uniqueExercises.values()).sort();
  }, [workouts]);

  const exerciseData = useMemo(() => {
    if (!selectedExercise) return [];
    const normalizedSelected = normalizeExerciseName(selectedExercise);
    return workouts
        .flatMap(workout =>
            workout.content?.content?.activities
                .filter(activity => normalizeExerciseName(activity.activity) === normalizedSelected)
                .map(activity => ({
                  date: workout.content.content.date,
                  sets: activity.sets,
                  notes: activity.notes
                }))
        )
        .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [workouts, selectedExercise]);

  const chartData = useMemo(() => {
    return exerciseData.map(data => ({
      date: data.date,
      maxWeight: Math.max(...data.sets.map(set => parseFloat(set.weight) || 0)),
      totalVolume: data.sets.reduce((total, set) =>
          total + (parseFloat(set.weight) || 0) * (parseInt(set.reps) || 0), 0
      )
    }));
  }, [exerciseData]);

  const personalRecords = useMemo(() => {
    if (exerciseData.length === 0) return null;

    const today = new Date();
    const records = {
      maxWeight: { value: 0, date: null, reps: 0 },
      maxReps: { value: 0, date: null, weight: 0 },
      maxVolume: {
        value: 0,
        date: null,
        sets: []
      }
    };

    exerciseData.forEach(workout => {
      // Calculate total volume for the entire workout
      const workoutVolume = workout.sets.reduce((total, set) =>
          total + (parseFloat(set.weight) || 0) * (parseInt(set.reps) || 0), 0
      );

      // Update max volume if this workout's total volume is higher
      if (workoutVolume > records.maxVolume.value) {
        records.maxVolume = {
          value: workoutVolume,
          date: workout.date,
          sets: workout.sets // Store all sets from this workout
        };
      }

      // Check each set for max weight and reps
      workout.sets.forEach(set => {
        const weight = parseFloat(set.weight) || 0;
        const reps = parseInt(set.reps) || 0;

        // Update max weight record
        if (weight > records.maxWeight.value) {
          records.maxWeight = {
            value: weight,
            date: workout.date,
            reps: reps // Store the reps done at max weight
          };
        }

        // Update max reps record
        if (reps > records.maxReps.value) {
          records.maxReps = {
            value: reps,
            date: workout.date,
            weight: weight // Store the weight used for max reps
          };
        }
      });
    });

    records.daysSinceMaxWeight = records.maxWeight.date ?
        differenceInDays(today, parseISO(records.maxWeight.date)) : null;
    records.daysSinceMaxReps = records.maxReps.date ?
        differenceInDays(today, parseISO(records.maxReps.date)) : null;
    records.daysSinceMaxVolume = records.maxVolume.date ?
        differenceInDays(today, parseISO(records.maxVolume.date)) : null;

    return records;
  }, [exerciseData]);

  const averageStats = useMemo(() => {
    if (exerciseData.length === 0) return null;

    const today = new Date();
    const thirtyDaysAgo = subDays(today, 30);
    const ninetyDaysAgo = subDays(today, 90);

    const allSets = exerciseData.flatMap(data => data.sets);
    const weights = allSets.map(set => parseFloat(set.weight) || 0).filter(w => w > 0);
    const reps = allSets.map(set => parseInt(set.reps) || 0).filter(r => r > 0);

    // Calculate total volume for each workout
    const workoutVolumes = exerciseData.map(workout => ({
      date: parseISO(workout.date),
      volume: workout.sets.reduce((total, set) =>
          total + (parseFloat(set.weight) || 0) * (parseInt(set.reps) || 0), 0
      )
    }));

    // Calculate recent workout statistics
    const last30DaysWorkouts = workoutVolumes.filter(w => w.date >= thirtyDaysAgo);
    const last90DaysWorkouts = workoutVolumes.filter(w => w.date >= ninetyDaysAgo);

    return {
      // Workout frequency
      totalWorkouts: exerciseData.length,
      workoutsLast30Days: last30DaysWorkouts.length,
      workoutsLast90Days: last90DaysWorkouts.length,
      averageWorkoutsPerMonth: (() => {
        if (exerciseData.length <= 1) {
          return exerciseData.length.toFixed(1);
        }

        const daysDiff = differenceInDays(
            parseISO(exerciseData[exerciseData.length - 1].date),
            parseISO(exerciseData[0].date)
        );
        if (daysDiff < 1) {
          return exerciseData.length.toFixed(1);
        }

        return (exerciseData.length / (daysDiff / 30)).toFixed(1);
      })(),

      // Weight statistics
      averageWeight: weights.length ? (weights.reduce((a, b) => a + b) / weights.length).toFixed(2) : 0,

      // Rep statistics
      averageReps: reps.length ? (reps.reduce((a, b) => a + b) / reps.length).toFixed(2) : 0,
      totalReps: reps.reduce((a, b) => a + b, 0),
      totalSets: allSets.length,

      // Volume statistics
      totalVolume: workoutVolumes.reduce((total, workout) => total + workout.volume, 0).toFixed(0),
      averageVolumePerWorkout: (workoutVolumes.reduce((total, workout) =>
          total + workout.volume, 0) / workoutVolumes.length).toFixed(0),
      volumeLast30Days: last30DaysWorkouts.reduce((total, workout) =>
          total + workout.volume, 0).toFixed(0),
      volumeLast90Days: last90DaysWorkouts.reduce((total, workout) =>
          total + workout.volume, 0).toFixed(0)
    };
  }, [exerciseData]);

  const mostRecentWorkout = useMemo(() => {
    return exerciseData.length > 0 ? exerciseData[exerciseData.length - 1] : null;
  }, [exerciseData]);

  const renderRecordValue = (value, unit = '', subtext = null, details = null) => (
      <Box sx={{ textAlign: 'center' }}>
        <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: theme.palette.primary.main,
              mb: 1
            }}
        >
          {value}{unit}
        </Typography>
        {subtext && (
            <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.secondary,
                  display: 'block',
                  mb: 1
                }}
            >
              {subtext}
            </Typography>
        )}
        {details && (
            <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.secondary,
                  display: 'block',
                  mb: 1,
                  fontSize: '0.75rem'
                }}
            >
              {details}
            </Typography>
        )}
      </Box>
  );

  const renderDateChip = (date, daysSince) => {
    if (!date || daysSince === null) return null;

    try {
      const formattedDate = format(parseISO(date), 'MMM d, yyyy');
      return (
          <Tooltip title={`Achieved on ${formattedDate}`}>
            <Chip
                label={`${daysSince} days ago`}
                size="small"
                color={daysSince <= 30 ? "success" : daysSince <= 90 ? "warning" : "default"}
                sx={{ ml: 1 }}
            />
          </Tooltip>
      );
    } catch (error) {
      console.error('Error rendering date chip:', error);
      return null;
    }
  };

  if (exercises.length === 0) {
    return (
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography>No exercises found in the workout data.</Typography>
        </Box>
    );
  }

  return (
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom color="primary">
          Workout Statistics
        </Typography>

        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel id="exercise-select-label">Select Exercise</InputLabel>
          <Select
              labelId="exercise-select-label"
              value={selectedExercise}
              label="Select Exercise"
              onChange={(e) => setSelectedExercise(e.target.value)}
          >
            {exercises.map(exercise => (
                <MenuItem key={exercise} value={exercise}>
                  {exercise}
                </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedExercise && exerciseData.length > 0 ? (
            <Grid container spacing={3}>
              {personalRecords && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Card raised sx={{
                          bgcolor: theme.palette.background.paper,
                          border: `2px solid ${theme.palette.primary.main}`,
                          height: '100%'
                        }}>
                          <CardContent>
                            <Typography variant="subtitle2" color="textSecondary" align="center">
                              Max Weight
                            </Typography>
                            {renderRecordValue(
                                personalRecords.maxWeight.value,
                                ' lbs',
                                `@ ${personalRecords.maxWeight.reps} reps`
                            )}
                            <Box sx={{ textAlign: 'center' }}>
                              {renderDateChip(personalRecords.maxWeight.date, personalRecords.daysSinceMaxWeight)}
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Card raised sx={{
                          bgcolor: theme.palette.background.paper,
                          border: `2px solid ${theme.palette.info.main}`,
                          height: '100%'
                        }}>
                          <CardContent>
                            <Typography variant="subtitle2" color="textSecondary" align="center">
                              Max Reps
                            </Typography>
                            {renderRecordValue(
                                personalRecords.maxReps.value,
                                ' reps',
                                `@ ${personalRecords.maxReps.weight} lbs`
                            )}
                            <Box sx={{ textAlign: 'center' }}>
                              {renderDateChip(personalRecords.maxReps.date, personalRecords.daysSinceMaxReps)}
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Card raised sx={{
                          bgcolor: theme.palette.background.paper,
                          border: `2px solid ${theme.palette.secondary.main}`,
                          height: '100%'
                        }}>
                          <CardContent>
                            <Typography variant="subtitle2" color="textSecondary" align="center">
                              Max Volume
                            </Typography>
                            {renderRecordValue(
                                personalRecords.maxVolume.value,
                                '',
                                'Total Volume',
                                personalRecords.maxVolume.sets.map((set, index) =>
                                    `${set.weight}×${set.reps}`
                                ).join(' + ')
                            )}
                            <Box sx={{ textAlign: 'center' }}>
                              {renderDateChip(personalRecords.maxVolume.date, personalRecords.daysSinceMaxVolume)}
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
              )}

              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>Progress Over Time</Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                          dataKey="date"
                          tickFormatter={(date) => format(parseISO(date), 'MMM d')}
                      />
                      <YAxis yAxisId="left" />
                      <YAxis yAxisId="right" orientation="right" />
                      <ChartTooltip
                          labelFormatter={(date) => format(parseISO(date), 'MMM d, yyyy')}
                      />
                      <Legend />
                      <Line
                          yAxisId="left"
                          type="monotone"
                          dataKey="maxWeight"
                          stroke={theme.palette.primary.main}
                          name="Max Weight (lbs)"
                      />
                      <Line
                          yAxisId="right"
                          type="monotone"
                          dataKey="totalVolume"
                          stroke={theme.palette.secondary.main}
                          name="Total Volume"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>Most Recent Workout</Typography>
                  {mostRecentWorkout && (
                      <>
                        <Typography sx={{ mb: 2 }}>
                          Date: {format(parseISO(mostRecentWorkout.date), 'MMM d, yyyy')}
                        </Typography>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Set</TableCell>
                                <TableCell>Weight</TableCell>
                                <TableCell>Reps</TableCell>
                                <TableCell>Volume</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {mostRecentWorkout.sets.map((set, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{set.weight}</TableCell>
                                    <TableCell>{set.reps}</TableCell>
                                    <TableCell>
                                      {(parseFloat(set.weight) || 0) * (parseInt(set.reps) || 0)}
                                    </TableCell>
                                  </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Typography sx={{ mt: 2 }}>
                          Notes: {mostRecentWorkout.notes || 'No notes'}
                        </Typography>
                      </>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>Overall Statistics</Typography>
                  {averageStats && (
                      <>
                        <Typography variant="subtitle1" color="primary" sx={{ mt: 2, mb: 1 }}>
                          Workout Frequency
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Total Workouts</Typography>
                            <Typography variant="h6">{averageStats.totalWorkouts}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Avg Workouts/Month</Typography>
                            <Typography variant="h6">{averageStats.averageWorkoutsPerMonth}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Last 30 Days</Typography>
                            <Typography variant="h6">{averageStats.workoutsLast30Days}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Last 90 Days</Typography>
                            <Typography variant="h6">{averageStats.workoutsLast90Days}</Typography>
                          </Grid>
                        </Grid>

                        <Divider sx={{ my: 2 }} />

                        <Typography variant="subtitle1" color="primary" sx={{ mt: 2, mb: 1 }}>
                          Weight & Reps
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Average Weight</Typography>
                            <Typography variant="h6">{averageStats.averageWeight} lbs</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Average Reps</Typography>
                            <Typography variant="h6">{averageStats.averageReps}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Total Reps</Typography>
                            <Typography variant="h6">{averageStats.totalReps.toLocaleString()}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Total Sets</Typography>
                            <Typography variant="h6">{averageStats.totalSets.toLocaleString()}</Typography>
                          </Grid>
                        </Grid>

                        <Divider sx={{ my: 2 }} />

                        <Typography variant="subtitle1" color="primary" sx={{ mt: 2, mb: 1 }}>
                          Volume Metrics
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Total Volume</Typography>
                            <Typography variant="h6">{Number(averageStats.totalVolume).toLocaleString()}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Avg Volume/Workout</Typography>
                            <Typography variant="h6">{Number(averageStats.averageVolumePerWorkout).toLocaleString()}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Volume (30 Days)</Typography>
                            <Typography variant="h6">{Number(averageStats.volumeLast30Days).toLocaleString()}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Volume (90 Days)</Typography>
                            <Typography variant="h6">{Number(averageStats.volumeLast90Days).toLocaleString()}</Typography>
                          </Grid>
                        </Grid>
                      </>
                  )}
                </Paper>
              </Grid>
            </Grid>
        ) : (
            <Typography>Select an exercise to view statistics.</Typography>
        )}
      </Box>
  );
};

export default WorkoutStatsForExercise;