import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from "jwt-decode";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
    const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');

    useEffect(() => {
        if (accessToken) {
            setUser(extractUserFromToken(accessToken));
        }
    }, [accessToken]);

    const extractUserFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            return {
                username: decodedToken.sub,
            };
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    const login = async (username, password, remember) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                console.log("Logged in response OK.")
                const data = await response.json();
                setAccessToken(data.accessToken);
                setRefreshToken(data.refreshToken);
                setRememberMe(remember);

                if (remember) {
                    localStorage.setItem('accessToken', data.accessToken);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    localStorage.setItem('rememberMe', 'true');
                } else {
                    sessionStorage.setItem('accessToken', data.accessToken);
                    sessionStorage.setItem('refreshToken', data.refreshToken);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('rememberMe');
                }

                setUser(extractUserFromToken(data.accessToken));

                // Hit the /api/process endpoint with the new JWT using POST
                try {
                    const processResponse = await fetch(`${apiUrl}/application-data/process`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${data.accessToken}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!processResponse.ok) {
                        console.error('Error hitting /application-data/process:', processResponse.statusText);
                    }
                } catch (processError) {
                    console.error('Error hitting /application-data/process:', processError);
                }

                return true;
            }
            return false;
        } catch (error) {
            console.error('Login error:', error);
            return false;
        }
    };

    const refreshAccessToken = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/auth/refresh`, { refreshToken });

            if (response.data.accessToken && response.data.refreshToken) {
                setAccessToken(response.data.accessToken);
                setRefreshToken(response.data.refreshToken);
                if (rememberMe) {
                    localStorage.setItem('accessToken', response.data.accessToken);
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                } else {
                    sessionStorage.setItem('accessToken', response.data.accessToken);
                    sessionStorage.setItem('refreshToken', response.data.refreshToken);
                }
                const extractedUser = extractUserFromToken(response.data.accessToken);
                setUser(extractedUser);
                return response.data.accessToken;
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            logout();
        }
        return null;
    };

    const logout = () => {
        setUser(null);
        setAccessToken(null);
        setRefreshToken(null);
        setRememberMe(false);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
    };

    const isAuthenticated = () => {
        const token = rememberMe ? localStorage.getItem('accessToken') : sessionStorage.getItem('accessToken');
        if (!token) return false;
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp > currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return false;
        }
    };

    const value = {
        user,
        accessToken,
        login,
        logout,
        isAuthenticated,
        refreshAccessToken,
        rememberMe,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
