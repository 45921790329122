import React from 'react';
import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/material/styles';

const TooltipWrapper = ({ title }) => {
    const theme = useTheme();

    return (
        <Tooltip
            title={title}
            placement="top"
            sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                boxShadow: theme.shadows[1],
                fontSize: '0.875rem'
            }}
        >
            <HelpOutlineIcon
                fontSize="small"
                sx={{ mr: 1, color: theme.palette.text.secondary }}
            />
        </Tooltip>
    );
};

export default TooltipWrapper;