import React, { useMemo } from 'react';
import { Typography, Box, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const CustomProgressBar = styled(Box)(({ theme }) => ({
    height: 20,
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
}));

function AssignmentStats({ items }) {
    const stats = useMemo(() => {
        return items.reduce((acc, item) => {
            if (!item.content || !item.content.content) {
                console.warn("Invalid item structure:", item);
                return acc;
            }

            const assignment = item.content.content;
            acc.totalAssignments++;

            const pointsPossible = parseFloat(assignment.points_possible);
            if (!isNaN(pointsPossible)) {
                acc.pointsPossible += pointsPossible;
            }

            if (assignment.submitted) {
                acc.submittedAssignments++;
                if (!assignment.points_earned || assignment.points_earned === '') {
                    acc.pointsNotGraded += pointsPossible;
                } else {
                    const pointsEarned = parseFloat(assignment.points_earned);
                    if (!isNaN(pointsEarned)) {
                        acc.pointsEarned += pointsEarned;
                        acc.gradedAssignments++;
                        acc.pointsMissed += (pointsPossible - pointsEarned);
                    }
                }
            } else {
                acc.pointsNotGraded += pointsPossible;
            }

            return acc;
        }, {
            totalAssignments: 0,
            gradedAssignments: 0,
            submittedAssignments: 0,
            pointsPossible: 0,
            pointsEarned: 0,
            pointsMissed: 0,
            pointsNotGraded: 0,
        });
    }, [items]);

    const pointsEarned = stats.pointsEarned || 0;
    const pointsPossible = stats.pointsPossible || 0;
    const pointsMissed = stats.pointsMissed || 0;
    const pointsNotGraded = Math.max(0, pointsPossible - pointsEarned - pointsMissed);

    const earnedPercentage = pointsPossible > 0 ? (pointsEarned / pointsPossible) * 100 : 0;
    const missedPercentage = pointsPossible > 0 ? (pointsMissed / pointsPossible) * 100 : 0;
    const notGradedPercentage = pointsPossible > 0 ? (pointsNotGraded / pointsPossible) * 100 : 0;

    const assignmentCompletionPercentage = stats.totalAssignments > 0 ? (stats.submittedAssignments / stats.totalAssignments) * 100 : 0;

    const currentGradePercentage = (pointsEarned + pointsMissed) > 0 ? (pointsEarned / (pointsEarned + pointsMissed)) * 100 : 0;
    const highestPossiblePercentage = pointsPossible > 0 ? ((pointsEarned + pointsNotGraded) / pointsPossible) * 100 : 0;

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Assignments</Typography>
                    <Box sx={{ position: 'relative', height: 24, mb: 2 }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                right: 0,
                                height: '2px',
                                bgcolor: 'grey.300',
                                transform: 'translateY(-50%)',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                width: `${assignmentCompletionPercentage}%`,
                                height: '2px',
                                bgcolor: 'primary.main',
                                transform: 'translateY(-50%)',
                                transition: 'width 0.5s ease-in-out',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {[...Array(stats.totalAssignments || 0)].map((_, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 1,
                                    }}
                                >
                                    {index < (stats.submittedAssignments || 0) ? (
                                        <InsertDriveFileIcon
                                            sx={{
                                                color: 'primary.main',
                                                fontSize: 24
                                            }}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                bgcolor: 'grey.300',
                                            }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body2">
                            {stats.submittedAssignments || 0} / {stats.totalAssignments || 0} assignments submitted
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Points</Typography>
                    <Box sx={{ mb: 1, position: 'relative' }}>
                        <CustomProgressBar>
                            <Box
                                sx={{
                                    width: `${earnedPercentage}%`,
                                    height: '100%',
                                    backgroundColor: '#2ecc71', // Green for points earned
                                }}
                            />
                            <Box
                                sx={{
                                    width: `${notGradedPercentage}%`,
                                    height: '100%',
                                    backgroundColor: '#95a5a6', // Gray for points not graded
                                }}
                            />
                            <Box
                                sx={{
                                    width: `${missedPercentage}%`,
                                    height: '100%',
                                    backgroundColor: '#e74c3c', // Red for points missed
                                }}
                            />
                        </CustomProgressBar>
                        <Typography
                            variant="body2"
                            sx={{
                                position: 'absolute',
                                left: 8,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            {earnedPercentage.toFixed(2)}%
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            {highestPossiblePercentage.toFixed(2)}%
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">
                            {pointsEarned.toFixed(2)} earned
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {pointsNotGraded.toFixed(2)} not graded
                        </Typography>
                        <Typography variant="body2" color="error">
                            {pointsMissed.toFixed(2)} missed
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">Total Points Possible: {pointsPossible.toFixed(2)}</Typography>
                        <Typography variant="body2"><strong>Current Grade: {currentGradePercentage.toFixed(2)}%</strong></Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default AssignmentStats;