import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Container,
    List,
    ListItem,
    ListItemText,
    Divider,
    Snackbar,
} from '@mui/material';
import { styled } from '@mui/system';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SendIcon from '@mui/icons-material/Send';
import HistoryIcon from '@mui/icons-material/History';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const ResizableTextField = styled(TextField)({
    '& .MuiInputBase-inputMultiline': {
        resize: 'vertical',
    },
});

const Database = () => {
    const [query, setQuery] = useState('');
    const [result, setResult] = useState('');
    const [history, setHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const savedHistory = localStorage.getItem('queryHistory');
        if (savedHistory) {
            setHistory(JSON.parse(savedHistory));
        }
    }, []);

    const executeQuery = async (queryBody) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/executeQuery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: queryBody }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            return await response.json();
        } catch (error) {
            console.error('Error executing query:', error);
            throw error;
        }
    };

    const handleQueryChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            let parsedQuery;
            try {
                // Try to parse the query as JSON
                parsedQuery = JSON.parse(query);
            } catch (parseError) {
                // If parsing fails, use the query as a string
                parsedQuery = query;
            }

            const data = await executeQuery(parsedQuery);
            setResult(JSON.stringify(data, null, 2));

            const newHistory = [query, ...history.slice(0, 9)];
            setHistory(newHistory);
            localStorage.setItem('queryHistory', JSON.stringify(newHistory));

            setSnackbarMessage('Query executed successfully!');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error:', error);
            setResult(`Error: ${error.message}`);
            setSnackbarMessage('Error executing query. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const handleHistoryItemClick = (item) => {
        setQuery(item);
        setShowHistory(false);
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                MongoDB Database Explorer
            </Typography>

            <StyledPaper elevation={3}>
                <ResizableTextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={20}
                    variant="outlined"
                    label="Enter your MongoDB query"
                    value={query}
                    onChange={handleQueryChange}
                    sx={{ mb: 2 }}
                    placeholder="Enter your MongoDB query as a JSON object or a raw query string"
                    helperText="Enter a valid MongoDB query as a JSON object or a raw query string."
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        onClick={handleSubmit}
                    >
                        Run Query
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<HistoryIcon />}
                        onClick={() => setShowHistory(!showHistory)}
                    >
                        {showHistory ? 'Hide History' : 'Show History'}
                    </Button>
                </Box>
                {showHistory && (
                    <Paper elevation={2} sx={{ mb: 2, maxHeight: 200, overflow: 'auto' }}>
                        <List dense>
                            {history.map((item, index) => (
                                <React.Fragment key={index}>
                                    <ListItem button onClick={() => handleHistoryItemClick(item)}>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                    {index < history.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    </Paper>
                )}
            </StyledPaper>
            {result && (
                <StyledPaper elevation={3}>
                    <Typography variant="h6" gutterBottom>
                        Query Result:
                    </Typography>
                    <SyntaxHighlighter language="json" style={docco} wrapLongLines>
                        {result}
                    </SyntaxHighlighter>
                </StyledPaper>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Container>
    );
};

export default Database;
