import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Button, Card, CardContent, CircularProgress, Typography, Box, TextField } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import useApi from "../../../../components/ApiService";

const useRecipeQueryChat = () => {
    const { apiCall } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const queryRecipe = useCallback(async (recipeId, query, chatHistory) => {
        setIsLoading(true);
        setError(null);
        try {
            const endpoint = `/ai/rag/source?ids=${recipeId}`;
            const response = await apiCall(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    query,
                    context: "",
                    specialInstructions: "The user is chatting with you about a specific recipe. Provide detailed and helpful responses based on the recipe's ingredients, steps, and nutritional information. You are a knowledgeable chef and nutritionist.",
                    content: "",
                    chatHistory: chatHistory.map(entry => ({
                        prompt: entry.prompt,
                        response: entry.response,
                        uniqueReferences: entry.uniqueReferences || []
                    }))
                })
            });
            setIsLoading(false);
            return response.data.generation;
        } catch (error) {
            console.error('Error querying recipe:', error);
            setError('Failed to process your query. Please try again.');
            setIsLoading(false);
            return null;
        }
    }, [apiCall]);

    return { queryRecipe, isLoading, error };
};

const RecipeQueryChat = ({ recipeSource }) => {
    const [query, setQuery] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const { queryRecipe, isLoading, error } = useRecipeQueryChat();
    const chatBoxRef = useRef(null);

    const handleQuerySubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!query.trim()) return;

        const response = await queryRecipe(recipeSource.id, query, chatHistory);
        if (response) {
            const newEntry = {
                prompt: query,
                response: response,
                uniqueReferences: []
            };
            setChatHistory(prev => [newEntry, ...prev]);
            setQuery('');
        }
    }, [query, recipeSource.id, queryRecipe, chatHistory]);

    const handleClearChat = useCallback(() => {
        setChatHistory([]);
        setQuery('');
    }, []);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = 0;
        }
    }, [chatHistory]);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Talk to the Chef about this recipe:
                </Typography>
                <Box ref={chatBoxRef} sx={{ maxHeight: 300, overflowY: 'auto', mb: 2 }}>
                    {chatHistory.map((entry, index) => (
                        <Box key={index} sx={{ mb: 2, animation: index === 0 ? 'fadeIn 0.5s ease-in' : 'none' }}>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                }}
                            >
                                You:
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                {entry.prompt}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                }}
                            >
                                Chef:
                            </Typography>
                            <Typography component="div">
                                <ReactMarkdown>{entry.response}</ReactMarkdown>
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <form onSubmit={handleQuerySubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Ask about this recipe"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        disabled={isLoading}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isLoading || !query.trim()}
                            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                        >
                            {isLoading ? 'Processing...' : 'Ask'}
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleClearChat}
                            disabled={isLoading || chatHistory.length === 0}
                        >
                            Clear Chat
                        </Button>
                    </Box>
                </form>
                {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                        Error: {error}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default RecipeQueryChat;