// src/components/apps/AppCardGrid.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Paper, Button, Container } from '@mui/material';

function AppCardGrid({ title, subNav }) {
    const navigate = useNavigate();

    const apps = subNav.links.map(link => ({
        title: link.title,
        description: link.description,
        Icon: link.icon, // Capitalize 'Icon' as it's a component
        path: link.path,
        buttonText: link.buttonText
    }));

    return (
        <Container maxWidth="lg">
            <Typography variant="h2" component="h1" align="center" gutterBottom sx={{ my: 4 }}>
                {title}
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {apps.map((app) => (
                    <Grid item xs={12} sm={6} md={4} key={app.title}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 3,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                border: 1,
                                borderColor: 'primary.main',
                                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            {app.Icon && <app.Icon fontSize="large" />} {/* Use the Icon component here */}
                            <Typography variant="h4" component="h2" sx={{ mt: 2, mb: 1 }}>
                                {app.title}
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2, flexGrow: 1 }}>
                                {app.description}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate(app.path)}
                                fullWidth
                                size="large"
                            >
                                {app.buttonText || `Open ${app.title}`}
                            </Button>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default AppCardGrid;