import React, { useState } from 'react';
import { Button, Box, useTheme } from '@mui/material';
import { Lock, LockOpen } from 'lucide-react';

const TypeFilterButton = ({ type, isActive, onClick, isLocked }) => {
    const theme = useTheme();
    const Icon = isLocked ? Lock : LockOpen;

    return (
        <Button
            variant={isActive ? "contained" : "outlined"}
            onClick={() => onClick(type)}
            sx={{
                margin: '3px',
                padding: '3px 10px',
                minWidth: 'unset',
                height: '28px',
                fontSize: '0.8125rem',
                borderRadius: '14px',
                textTransform: 'none',
                position: 'relative',
                border: isLocked ? `1px solid ${theme.palette.action.disabledBackground}` : undefined,
                opacity: isLocked ? 0.7 : 1,
                '& .MuiButton-startIcon': {
                    marginRight: '5px',
                    marginLeft: '-3px',
                },
            }}
            startIcon={isLocked && (
                <Icon
                    size={14}
                    style={{
                        color: theme.palette.text.primary,
                    }}
                />
            )}
        >
            {type}
        </Button>
    );
};

const TypeSelector = ({ possibleTypes, defaultSelectedTypes, onChange, lockedTypes = [] }) => {
    const theme = useTheme();
    const types = possibleTypes || [
        "Source",
        "Collection",
        "Date",
        "Question",
        "Terminology",
        "Assignment",
        "Recipe",
        "Food Item",
        "Food",
        "Meal",
        "Chat"
    ];

    const [selectedTypes, setSelectedTypes] = useState(defaultSelectedTypes || ["Source"]);

    const handleTypeToggle = (type) => {
        if (!lockedTypes.includes(type)) {
            setSelectedTypes(prev => {
                const newSelected = prev.includes(type)
                    ? prev.filter(t => t !== type)
                    : [...prev, type];
                onChange(newSelected);
                return newSelected;
            });
        }
    };

    const handleSelectAll = () => {
        const allTypes = types.filter(type => !lockedTypes.includes(type) || selectedTypes.includes(type));
        setSelectedTypes(allTypes);
        onChange(allTypes);
    };

    const handleClear = () => {
        const newSelected = selectedTypes.filter(type => lockedTypes.includes(type));
        setSelectedTypes(newSelected);
        onChange(newSelected);
    };

    const specialButtonStyle = {
        margin: '3px',
        padding: '3px 10px',
        minWidth: 'unset',
        height: '28px',
        fontSize: '0.8125rem',
        borderRadius: '14px',
        textTransform: 'none',
        color: theme.palette.specialButton.text,
        border: `1px solid ${theme.palette.specialButton.text}`,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.specialButton.text,
            color: theme.palette.background.default,
        },
    };

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '10px 0' }}>
            {types.map(type => (
                <TypeFilterButton
                    key={type}
                    type={type}
                    isActive={selectedTypes.includes(type)}
                    onClick={handleTypeToggle}
                    isLocked={lockedTypes.includes(type)}
                />
            ))}
            <Button
                variant="contained"
                onClick={handleSelectAll}
                sx={specialButtonStyle}
            >
                All
            </Button>
            <Button
                variant="contained"
                onClick={handleClear}
                sx={specialButtonStyle}
            >
                Clear
            </Button>
        </Box>
    );
};

export default TypeSelector;