import React, { useMemo } from 'react';
import { Typography, Box, Paper, Grid, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import ExpirationTimeline from './ExpirationTimeline';
import NutritionStats from "../nutrition/NutritionStats";
import ExpandableCard from "../../../../components/list/ExpandableCard";
import FoodInventoryRAGChat from "./FoodInventoryRAGChat";
import RAGSimpleSpecificSources from "../../../../components/rag/RAGSimpleSpecificSources";
import FoodInventoryAnalysisRAG from "./FoodInventoryAnalysisRAG";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));

const StatBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
}));

const FoodStats = React.memo(({ items }) => {
    const theme = useTheme();
    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.error.main,
        theme.palette.warning.main,
        theme.palette.info.main,
        theme.palette.success.main,
    ];

    const stats = useMemo(() => {
        const newStats = {
            totalItems: 0,
            expiredItems: 0,
            totalQuantity: 0,
            uniqueUnits: new Set(),
            foodGroups: {},
            types: {},
            estimatedPurchaseDates: 0,
            estimatedExpirationDates: 0
        };

        items.forEach(item => {
            const food = item.content.content;
            newStats.totalItems++;

            const quantity = parseFloat(food.quantity);
            if (!isNaN(quantity)) {
                newStats.totalQuantity += quantity;
            }

            if (food.unit) {
                newStats.uniqueUnits.add(food.unit);
            }

            if (food.expiration_date && new Date(food.expiration_date) < new Date()) {
                newStats.expiredItems++;
            }

            if (food.food_group) {
                newStats.foodGroups[food.food_group] = (newStats.foodGroups[food.food_group] || 0) + 1;
            }

            if (food.type) {
                newStats.types[food.type] = (newStats.types[food.type] || 0) + 1;
            }

            if (food.purchase_date_estimated) {
                newStats.estimatedPurchaseDates++;
            }
            if (food.expiration_date_estimated) {
                newStats.estimatedExpirationDates++;
            }
        });

        return newStats;
    }, [items]);

    const preparePieChartData = (data) => {
        const lowercaseData = {};
        Object.entries(data).forEach(([key, value]) => {
            const lowercaseKey = key.toLowerCase();
            lowercaseData[lowercaseKey] = (lowercaseData[lowercaseKey] || 0) + value;
        });

        // Convert to array and sort by value
        const sortedData = Object.entries(lowercaseData)
            .map(([name, value]) => ({ name, value }))
            .sort((a, b) => b.value - a.value);

        // Take the top 10 categories
        const topCategories = sortedData.slice(0, 10);
        const otherCategories = sortedData.slice(10);

        // Sum the rest into a "Misc" category
        const miscValue = otherCategories.reduce((sum, category) => sum + category.value, 0);
        if (miscValue > 0) {
            topCategories.push({ name: 'Misc', value: miscValue });
        }

        // Capitalize the first letter of each category name
        return topCategories.map(({ name, value }) => ({
            name: name.charAt(0).toUpperCase() + name.slice(1),
            value
        }));
    };

    const foodGroupData = preparePieChartData(stats.foodGroups);
    const foodTypeData = preparePieChartData(stats.types);

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>Food Inventory Stats</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <StatBox>
                        <Typography variant="h4">{stats.totalItems}</Typography>
                        <Typography variant="body2">Total Items</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatBox>
                        <Typography variant="h4">{stats.expiredItems}</Typography>
                        <Typography variant="body2">Expired Items</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatBox>
                        <Typography variant="h4">{stats.estimatedPurchaseDates}</Typography>
                        <Typography variant="body2">Estimated Purchase Dates</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatBox>
                        <Typography variant="h4">{stats.estimatedExpirationDates}</Typography>
                        <Typography variant="body2">Estimated Expiration Dates</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Expiration Timeline</Typography>
                    <ExpirationTimeline foodItems={items.map(item => item.content.content)} />
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        The timeline above shows your food items' expiration dates. Red indicates expired items, orange shows items expiring in the next 3 days, and green represents items expiring later. Hover over a circle to see all items expiring on a date.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FoodInventoryRAGChat items={items}/>
                </Grid>
                <Grid item xs={12}>
                    <FoodInventoryAnalysisRAG items={items}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>Food Groups Distribution</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={foodGroupData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                outerRadius={80}
                                fill={theme.palette.primary.main}
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {foodGroupData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>Food Types Distribution</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={foodTypeData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                outerRadius={80}
                                fill={theme.palette.primary.main}
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {foodTypeData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        Your food inventory contains a total of {stats.totalItems} items, with {stats.expiredItems} items that have expired.
                        There are {stats.estimatedPurchaseDates} items with estimated purchase dates and {stats.estimatedExpirationDates} items with estimated expiration dates.
                    </Typography>
                    <Typography variant="body2">
                        {stats.expiredItems > 0
                            ? `Consider removing the ${stats.expiredItems} expired items to maintain a fresh inventory.`
                            : "Great job! All your food items are fresh and within their expiration dates."}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        The pie charts display the distribution of your food items by food groups and types, helping you visualize the variety in your inventory.
                    </Typography>
                </Grid>
            </Grid>
            &nbsp;
            <ExpandableCard
                title={"Nutrition Estimates"}
                initiallyExpanded={false}
            >
                <NutritionStats items={items} nutritionPath="content.content.nutrition_estimate" />
            </ExpandableCard>
        </StyledPaper>
    );
});

export default FoodStats;