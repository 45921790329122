import React, { useCallback } from 'react';
import ManagedPage from "../../../../../components/apps/ManagedPage";
import RecipeDisplay from "../RecipeDisplay";
import RecipeStats from './RecipeStats';
import ExpandableCard from "../../../../../components/list/ExpandableCard";

function CookBook() {

    const customFilter = (item, filterText) => {
        const content = item.content?.content;
        if (!content) return false;

        const searchableText = `${content.name} ${content.category} ${content.tags?.join(' ')}`.toLowerCase();
        return searchableText.includes(filterText.toLowerCase());
    };

    const customSort = (a, b) => {
        const nameA = a.content?.content?.name?.toLowerCase() || '';
        const nameB = b.content?.content?.name?.toLowerCase() || '';
        return nameA.localeCompare(nameB);
    };

    const WrappedRecipeDisplay = useCallback(({ item, ...props }) => {
        return (
            <ExpandableCard title={item.content.content.name} initiallyExpanded={false}>
                <RecipeDisplay item={item} sourceRecord={item} {...props} />
            </ExpandableCard>
        );
    }, []);

    return (
        <ManagedPage
            title="Recipes"
            addItemsButtonText={"Add Recipes"}
            systemCollectionName="Recipes"
            sourceType="Recipe"
            StatsComponent={RecipeStats}
            ItemDisplay={WrappedRecipeDisplay}
            showExtractor={true}
            extractorProps={{
                availableTypes: [{ id: "Recipe", label: "Recipe" }],
                fromQuery: '/source/search?',
                toQuery: '/source/search?',
                fromRootAssociation: "Nutritionist",
                fromPossibleTypes: ["Source", "Recipe", "Meal"],
                showTo: false,
                showContext: false,
                showType: false,
            }}
            managedListProps={{
                filterFunction: customFilter,
                sortFunction: customSort,
                allowDelete: true,
            }}
        />
    );
}

export default CookBook;