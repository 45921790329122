import AppCardGrid from "../../../components/apps/AppCardGrid";
import subNav from "../nutritionist/subNavConfig";
import React from "react";

const NutritionistLanding = () => {
    return (
        <AppCardGrid title={`${subNav.header} Dashboard`} subNav={subNav} />
    );
};

export default NutritionistLanding;
