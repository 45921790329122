import { useRef, useCallback } from 'react';
import useApi from "../components/ApiService";

export function useSourceName() {
    const { apiCall } = useApi();
    const sourceMapRef = useRef({ byId: {}, byUri: {} });
    const fetchPromisesRef = useRef({});

    const getSourceNameById = useCallback(async (id) => {
        if (typeof id !== 'string' && typeof id !== 'number') {
            throw new Error('Source ID must be a string or number.');
        }

        if (sourceMapRef.current.byId[id]) {
            return sourceMapRef.current.byId[id];
        }

        if (fetchPromisesRef.current[id]) {
            return fetchPromisesRef.current[id];
        }

        fetchPromisesRef.current[id] = fetchSourceInfoByIdFromAPI(id, apiCall)
            .then(({ name, uri }) => {
                sourceMapRef.current.byId[id] = name;
                sourceMapRef.current.byUri[uri] = name;
                delete fetchPromisesRef.current[id];
                return name;
            })
            .catch(error => {
                console.error('Error fetching source name by ID:', error);
                delete fetchPromisesRef.current[id];
                return 'Unknown Source Name';
            });

        return fetchPromisesRef.current[id];
    }, [apiCall]);

    const getSourceNameByUri = useCallback(async (uri) => {
        if (typeof uri !== 'string') {
            throw new Error('Source URI must be a string.');
        }

        if (sourceMapRef.current.byUri[uri]) {
            return sourceMapRef.current.byUri[uri];
        }

        if (fetchPromisesRef.current[uri]) {
            return fetchPromisesRef.current[uri];
        }

        fetchPromisesRef.current[uri] = fetchSourceInfoByUriFromAPI(uri, apiCall)
            .then(({ id, name }) => {
                sourceMapRef.current.byId[id] = name;
                sourceMapRef.current.byUri[uri] = name;
                delete fetchPromisesRef.current[uri];
                return name;
            })
            .catch(error => {
                console.error('Error fetching source name by URI:', error);
                delete fetchPromisesRef.current[uri];
                return 'Unknown Source Name';
            });

        return fetchPromisesRef.current[uri];
    }, [apiCall]);

    return { getSourceNameById, getSourceNameByUri };
}

async function fetchSourceInfoByIdFromAPI(id, apiCall) {
    const response = await apiCall(`/source/by-ids?ids=${id}`);
    const data = response.data.sources[0];
    return { name: data.name, uri: data.uri };
}

async function fetchSourceInfoByUriFromAPI(uri, apiCall) {
    const response = await apiCall(`/source/by-uris?uris=${uri}`);
    const data = response.data.sources[0];
    return { id: data.id, name: data.name };
}