import React from 'react';
import ManagedPage from "../../../../components/apps/ManagedPage";
import FoodStapleItem from './FoodStapleItem';
import FoodStapleStats from './FoodStapleStats';

function FoodStaples() {
    const customFilter = (item, filterText) => {
        const content = item.content?.content;
        if (!content) return false;

        const searchableText = `${content.name} ${content.food_group} ${content.type}`.toLowerCase();
        return searchableText.includes(filterText.toLowerCase());
    };

    const customSort = (a, b) => {
        const nameA = a.content?.content?.name?.toLowerCase() || '';
        const nameB = b.content?.content?.name?.toLowerCase() || '';
        return nameA.localeCompare(nameB);
    };

    return (
        <ManagedPage
            title="Food Staples"
            systemCollectionName="Food Staples"
            sourceType="Food"
            StatsComponent={FoodStapleStats}
            ItemDisplay={FoodStapleItem}
            extractorProps={{
                availableTypes: [{ id: "Food", label: "Food" }],
                fromQuery: '/source/search?',
                toQuery: '/source/search?',
                fromRootAssociation: "Food Staples",
                fromPossibleTypes: ["Source", "Food", "Food Item"],
                showTo: false,
                showContext: false,
                showType: false,
            }}
            managedListProps={{
                allowNew: false
            }}
        />
    );
}

export default FoodStaples;