import React from 'react';
import ManagedPage from "../../../../components/apps/ManagedPage";
import FoodStapleItem from "../staples/FoodStapleItem";
import PrintableGroceryList from "./PrintableGroceryList";

function GroceryList() {
    const customFilter = (item, filterText) => {
        const content = item.content?.content;
        if (!content) return false;

        const searchableText = `${content.name} ${content.food_group} ${content.type} ${content.quantity} ${content.unit}`.toLowerCase();
        return searchableText.includes(filterText.toLowerCase());
    };

    const customSort = (a, b) => {
        const groupA = a.content?.content?.food_group || 'Other';
        const groupB = b.content?.content?.food_group || 'Other';
        return groupA.localeCompare(groupB);
    };

    return (
        <ManagedPage
            title="Grocery List"
            addItemsButtonText={"Add Items"}
            systemCollectionName="Grocery List"
            sourceType="Food Shopping Item"
            StatsComponent={PrintableGroceryList}
            ItemDisplay={FoodStapleItem}
            extractorProps={{
                fromQuery: '/source/search?',
                toQuery: '/source/search?',
                availableTypes: [{ id: "Food Shopping Item", label: "Food Shopping Item" }],
                fromRootAssociation: "Nutritionist",
                fromPossibleTypes: ["Source", "Recipe", "Food Item", "Food", "Report"],
                showTo: false,
                showContext: false,
                showType: false,
            }}
            managedListProps={{
                allowNew: false,
                filterFunction: customFilter,
                sortFunction: customSort,
                showAssociationsDropdown: false,
                showFilter: true,
                eagerLoad: true,
                expanded: false,
                allowDelete: true,
                asChecklist: false,
            }}
        />
    );
}

export default GroceryList;