import React from 'react';
import AppCardGrid from "../../../components/apps/AppCardGrid";
import subNav from './subNavConfig';

function ProfessorLanding() {
    return (
        <AppCardGrid title={`${subNav.header} Dashboard`} subNav={subNav} />
    );
}

export default ProfessorLanding;