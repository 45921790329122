import React, { useMemo } from 'react';
import { Typography, Box, Paper, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));

const StatBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
}));

function TerminologyStats({ items }) {
    const theme = useTheme();

    const totalUniqueTerms = useMemo(() => {
        const uniqueTerms = new Set();
        items.forEach(item => {
            if (item.id) {
                uniqueTerms.add(item.id);
            }
        });
        return uniqueTerms.size;
    }, [items]);

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>Terminology Stats</Typography>
            <StatBox>
                <Typography variant="h4">{totalUniqueTerms}</Typography>
                <Typography variant="body2">Total Unique Terms</Typography>
            </StatBox>
        </StyledPaper>
    );
}

export default TerminologyStats;