import React, { useState, useEffect, useCallback } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Button, Grid, Modal, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import useApi from "./ApiService";
import TooltipWrapper from "./StyledTooltip";
import CreateCollectionForm from "../root/apps/library/createcollection/CreateCollectionForm";

const StyledMenuItem = styled(MenuItem, {
    shouldForwardProp: (prop) => !['depth', 'isHighlighted', 'isHovered'].includes(prop),
})(({ theme, depth, isHighlighted, isHovered }) => ({
    paddingLeft: theme.spacing(2 + depth * 2),
    backgroundColor: isHighlighted ? theme.palette.action.hover : 'inherit',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&::before': {
        content: '""',
        display: 'inline-block',
        width: isHovered ? '12px' : '8px',
        height: isHovered ? '12px' : '8px',
        borderRadius: '50%',
        backgroundColor: isHovered ? theme.palette.primary.main : theme.palette.text.disabled,
        marginRight: theme.spacing(1),
        opacity: isHovered ? 1 : 0.5,
        transition: 'all 0.3s',
    },
}));

const AssociationsDropdown = ({ rootAssociation, onCollectionsUpdate, onCollectionSelect, hide = false, selected = null, selectionLabel, allowAddCollection = true }) => {
    const { apiCall } = useApi();
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [hasUserSelected, setHasUserSelected] = useState(false);
    const [treeStructure, setTreeStructure] = useState([]);
    const [hoveredNodeId, setHoveredNodeId] = useState(null);
    const [hoveredNodePath, setHoveredNodePath] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false);
    const [associationsRefreshTrigger, setAssociationsRefreshTrigger] = useState(0);


    const getAllDescendants = (nodeId, nodeMap) => {
        const visited = new Set();
        const descendants = [];
        const stack = [nodeId];

        while (stack.length) {
            const currentId = stack.pop();
            if (!visited.has(currentId)) {
                visited.add(currentId);
                descendants.push(currentId);
                const node = nodeMap.get(currentId);
                if (node && node.children) {
                    stack.push(...node.children);
                }
            }
        }

        return descendants.filter(id => id !== nodeId);
    };

    const fetchCollections = useCallback(() => {
        apiCall(`/graph/subgraph?types=Application&types=Collection&associations=${encodeURIComponent(rootAssociation)}`)
            .then(response => {
                const graphData = response.data.data;
                const nodeMap = new Map();

                graphData.nodes.forEach(node => {
                    nodeMap.set(node.id, { ...node, children: [], parents: [] });
                });

                graphData.edges.forEach(edge => {
                    const parentNode = nodeMap.get(edge.source);
                    const childNode = nodeMap.get(edge.target);
                    if (parentNode && childNode) {
                        parentNode.children.push(edge.target);
                        childNode.parents.push(edge.source);
                    }
                });

                const createTreeStructure = (nodeId, depth = 0, parentPath = [], visitedNodes = new Set()) => {
                    const node = nodeMap.get(nodeId);
                    if (!node || visitedNodes.has(nodeId)) return null;

                    visitedNodes.add(nodeId);
                    const currentPath = [...parentPath, nodeId];

                    return {
                        id: node.id,
                        label: node.label,
                        depth,
                        path: currentPath,
                        children: node.children
                            .map(childId => createTreeStructure(childId, depth + 1, currentPath, new Set(visitedNodes)))
                            .filter(Boolean)
                    };
                };

                const rootNodes = graphData.nodes.filter(node => !nodeMap.get(node.id).parents.length);
                const tree = rootNodes.map(node => createTreeStructure(node.id)).filter(Boolean);

                setTreeStructure(tree);

                const flattenedCollections = graphData.nodes
                    .filter(node => node.type.includes('Collection') || node.type.includes('Application'))
                    .map(node => ({
                        id: node.id,
                        label: node.label,
                        allDescendants: getAllDescendants(node.id, nodeMap),
                        parents: nodeMap.get(node.id).parents,
                        children: nodeMap.get(node.id).children
                    }));

                setCollections(flattenedCollections);
                if (onCollectionsUpdate) {
                    onCollectionsUpdate(flattenedCollections);
                }
            })
            .catch(error => {
                console.error('Error fetching collections:', error);
            });
    }, [apiCall, rootAssociation, onCollectionsUpdate]);

    useEffect(() => {
        fetchCollections();
    }, [fetchCollections, associationsRefreshTrigger]);

    const findCollection = useCallback((identifier) => {
        let foundCollection = collections.find(c => c.id === identifier);
        if (!foundCollection) {
            foundCollection = collections.find(c => c.label === identifier);
        }
        return foundCollection;
    }, [collections]);

    useEffect(() => {
        if (selected && collections.length > 0 && !hasUserSelected) {
            const selectedCollection = findCollection(selected);
            if (selectedCollection) {
                setSelectedCollection(selectedCollection.id);
                if (onCollectionSelect) {
                    onCollectionSelect({
                        ...selectedCollection,
                        allDescendants: [selectedCollection.id, ...selectedCollection.allDescendants]
                    });
                }
            } else {
                console.warn(`Selected collection with identifier ${selected} not found in the results.`);
            }
        }
    }, [selected, collections, onCollectionSelect, findCollection, hasUserSelected]);

    const isHighlighted = useCallback((nodeId, nodePath) => {
        if (!hoveredNodeId) return false;
        return hoveredNodePath.every((id, index) => nodePath[index] === id) &&
            nodePath.length > hoveredNodePath.length;
    }, [hoveredNodeId, hoveredNodePath]);

    const handleMouseEnter = useCallback((nodeId, nodePath) => {
        setHoveredNodeId(nodeId);
        setHoveredNodePath(nodePath);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setHoveredNodeId(null);
        setHoveredNodePath([]);
    }, []);

    const handleCollectionSelect = useCallback((event) => {
        const selectedId = event.target.value;
        setSelectedCollection(selectedId);
        setHasUserSelected(true);
        setIsOpen(false);
        const selected = findCollection(selectedId);
        if (selected && onCollectionSelect) {
            onCollectionSelect({
                ...selected,
                allDescendants: [selected.id, ...selected.allDescendants]
            });
        }
    }, [findCollection, onCollectionSelect]);

    const renderTreeOptions = useCallback((nodes) => {
        const render = (nodes, parentKey = '') => {
            return nodes.flatMap((node, index) => {
                const highlighted = isHighlighted(node.id, node.path);
                const hovered = node.id === hoveredNodeId;
                const nodeKey = `${parentKey}${node.id}-${index}`;

                const menuItem = (
                    <StyledMenuItem
                        key={nodeKey}
                        value={node.id}
                        depth={node.depth}
                        isHighlighted={highlighted}
                        isHovered={hovered}
                        onMouseEnter={() => handleMouseEnter(node.id, node.path)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleCollectionSelect({ target: { value: node.id } })}
                    >
                        {node.label}
                    </StyledMenuItem>
                );

                if (node.children && node.children.length > 0) {
                    return [
                        menuItem,
                        ...render(node.children, `${nodeKey}-child-`)
                    ];
                }

                return menuItem;
            });
        };

        return render(nodes);
    }, [isHighlighted, hoveredNodeId, handleMouseEnter, handleMouseLeave, handleCollectionSelect]);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleAddCollectionClick = () => {
        setShowCreateCollectionModal(true);
    };

    const handleCloseCreateCollectionModal = () => {
        setShowCreateCollectionModal(false);
    };

    const handleCollectionAdded = (response) => {
        if (response && response.message === "Successfully created source" && response.sources && response.sources.length > 0) {
            const newCollection = response.sources[0];
            setSelectedCollection(newCollection.id);
            setAssociationsRefreshTrigger(prev => prev + 1);
            if (onCollectionSelect) {
                onCollectionSelect(newCollection);
            }
        }
        setShowCreateCollectionModal(false);
    };

    if (hide) {
        return null;
    }

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <FormControl fullWidth>
                        <InputLabel id="collection-select-label">{selectionLabel || "Select root collection to begin"}</InputLabel>
                        <Select
                            labelId="collection-select-label"
                            id="collection-select"
                            value={selectedCollection}
                            label="Select Collection"
                            onChange={handleCollectionSelect}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            open={isOpen}
                            renderValue={(value) => {
                                const selected = findCollection(value);
                                return selected ? selected.label : '';
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '50vh',
                                    },
                                },
                            }}
                        >
                            {renderTreeOptions(treeStructure)}
                        </Select>
                    </FormControl>
                </Grid>
                {allowAddCollection && (
                    <Grid item>
                        <Button
                            onClick={handleAddCollectionClick}
                            disabled={!selectedCollection}
                            sx={{
                                height: '56px',
                                whiteSpace: 'nowrap',
                                padding: '0 16px',
                            }}
                        >
                            Add Collection
                            <TooltipWrapper title={"This will create a new collection under the current selected collection to help you stay organized."}>
                                <span style={{ marginLeft: '4px' }}>ⓘ</span>
                            </TooltipWrapper>
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Modal
                open={showCreateCollectionModal}
                onClose={handleCloseCreateCollectionModal}
                aria-labelledby="create-collection-modal"
                aria-describedby="modal-to-create-new-collection"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 800,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}>
                    <CreateCollectionForm
                        initialAssociations={selectedCollection ? [selectedCollection] : []}
                        onUpdate={handleCollectionAdded}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default AssociationsDropdown;