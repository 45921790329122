import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import useSourceAttributes from "../context/useSourceAttributes";

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

const AttributeEditor = ({
                             sourceId,
                             attributeName,
                             title = "Edit Attribute",
                             placeholder = "Enter value...",
                             onValueChange,
                             minRows = 4,
                             maxRows = 8,
                             disabled = false,
                             multiline = true,
                             helperText,
                             className = "",
                             debounceMs = 500,
                             elevation = 1,
                             variant = "outlined",
                         }) => {
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const debouncedValue = useDebounce(value, debounceMs);
    const theme = useTheme();
    const { upsertAttribute, getAllAttributes } = useSourceAttributes();

    // Load initial value
    useEffect(() => {
        let mounted = true;

        const loadInitialValue = async () => {
            if (!sourceId || !attributeName) return;

            try {
                setIsLoading(true);
                const attributes = await getAllAttributes(sourceId);
                if (!mounted) return;

                const existingAttribute = attributes.find(attr => attr.name === attributeName);
                if (existingAttribute && existingAttribute.value !== value) {
                    setValue(existingAttribute.value || "");
                }
            } catch (error) {
                console.error('Error loading attribute:', error);
            } finally {
                if (mounted) {
                    setIsLoading(false);
                }
            }
        };

        loadInitialValue();

        return () => {
            mounted = false;
        };
    }, [sourceId, attributeName]);

    // Handle updates
    useEffect(() => {
        let mounted = true;

        const updateAttribute = async () => {
            if (!sourceId || !attributeName || isLoading) return;

            try {
                await upsertAttribute(sourceId, {
                    name: attributeName,
                    value: debouncedValue
                });

                if (mounted && onValueChange) {
                    onValueChange(debouncedValue);
                }
            } catch (error) {
                console.error('Error updating attribute:', error);
            }
        };

        updateAttribute();

        return () => {
            mounted = false;
        };
    }, [debouncedValue, sourceId, attributeName, isLoading]);

    return (
        <Card className={className} variant={variant} elevation={elevation}>
            <CardHeader title={<Typography variant="h6">{title}</Typography>} />
            <CardContent>
                <TextField
                    fullWidth
                    multiline={multiline}
                    minRows={minRows}
                    maxRows={maxRows}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={disabled || isLoading}
                    helperText={helperText}
                />
            </CardContent>
        </Card>
    );
};

export default AttributeEditor;