import React from 'react';
import { Box, Typography } from '@mui/material';

const DisplayField = React.memo(({ field, value, onEdit, editable }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            cursor: editable ? 'pointer' : 'default',
        }}
        onDoubleClick={editable ? onEdit : undefined}
    >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 1 }}>
            {field}:
        </Typography>
        <Typography variant="body1">
            {value || <span style={{ color: 'gray', fontStyle: 'italic' }}>&lt;empty&gt;</span>}
        </Typography>
    </Box>
));

export default DisplayField;