import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    TextField,
    Button,
    Typography,
    Alert,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Link,
    Modal,
} from '@mui/material';
import Terms from './Terms';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        token: '',
        acceptTerms: false
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'acceptTerms' ? checked : value
        }));
    };

    const validateForm = () => {
        if (!formData.username) {
            setError('Username is required');
            return false;
        }
        if (formData.password.length < 6) {
            setError('Password must be at least 6 characters long');
            return false;
        }
        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            return false;
        }
        if (!formData.token) {
            setError('Registration token is required');
            return false;
        }
        if (!formData.acceptTerms) {
            setError('You must accept the Terms and Conditions');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setError('');
        setIsLoading(true);

        const registrationData = {
            username: formData.username,
            password: formData.password
        };

        try {
            const response = await fetch(
                `${API_URL}/auth/register?token=${encodeURIComponent(formData.token)}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(registrationData)
                }
            );

            if (response.ok) {
                console.log('Registration successful');
                navigate('/login#registerSuccess', {
                    state: { message: 'Registration successful! Please login.' }
                });
            } else {
                const errorData = await response.json().catch(() => null);
                let errorMessage;

                if (response.status === 409) {
                    errorMessage = `The username "${formData.username}" is already taken. Please try a different username.`;
                } else if (response.status === 403) {
                    errorMessage = 'Invalid registration token. Please check and try again.';
                } else {
                    errorMessage = errorData?.message || 'Something went wrong with registration. Please try again.';
                }

                console.error('Registration failed:', errorMessage);
                setError(errorMessage);
            }
        } catch (err) {
            console.error('Registration network error:', err);
            setError('Unable to connect to our servers. Please check your internet connection and try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 1,
                    maxWidth: 400,
                    margin: 'auto',
                    padding: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'background.paper'
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Create Account
                </Typography>

                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="off"
                    autoFocus
                    value={formData.username}
                    onChange={handleChange}
                    disabled={isLoading}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleChange}
                    disabled={isLoading}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    disabled={isLoading}
                    error={formData.password !== formData.confirmPassword && formData.confirmPassword !== ''}
                    helperText={
                        formData.password !== formData.confirmPassword && formData.confirmPassword !== ''
                            ? 'Passwords do not match'
                            : ''
                    }
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="token"
                    label="Registration Token"
                    id="token"
                    autoComplete="off"
                    value={formData.token}
                    onChange={handleChange}
                    disabled={isLoading}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            name="acceptTerms"
                            checked={formData.acceptTerms}
                            onChange={handleChange}
                            disabled={isLoading}
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            I agree to the{' '}
                            <Link
                                component="button"
                                variant="body2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setTermsOpen(true);
                                }}
                            >
                                Terms and Conditions
                            </Link>
                        </Typography>
                    }
                    sx={{ mt: 2 }}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Register'}
                </Button>

                <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate('/login')}
                    disabled={isLoading}
                >
                    Already have an account? Sign In
                </Button>
            </Box>

            <Modal
                open={termsOpen}
                onClose={() => setTermsOpen(false)}
                aria-labelledby="terms-modal"
                sx={{
                    overflow: 'auto',
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 800,
                    maxHeight: '90vh',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Terms />
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setTermsOpen(false)}
                        sx={{ mt: 2 }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default Register;