import React, { useState, useLayoutEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Box,
    CircularProgress,
    Divider,
    IconButton,
    Button,
    useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled, useTheme } from '@mui/material/styles';
import themeDefinitions from '../themeDefinitions';
import { useAuth } from "../AuthContext";

    const drawerWidth = 220;

    const StyledDrawer = styled(Drawer)(({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
    }));

    const SubNavigation = styled(Box)(({ theme }) => ({
        marginTop: theme.spacing(2),
        padding: theme.spacing(1, 2),
    }));

    const StyledNavLink = styled(NavLink)(({ theme }) => ({
        color: theme.palette.text.primary,
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        margin: theme.spacing(0.5, 0),
        borderRadius: theme.shape.borderRadius,
        transition: 'all 0.3s',
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const SectionHeader = styled(Typography)(({ theme }) => ({
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        letterSpacing: '0.1em',
        marginBottom: theme.spacing(1),
    }));

    const SmallIcon = styled(ListItemIcon)(({ theme }) => ({
        minWidth: 'auto',
        marginRight: theme.spacing(1),
        '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
        },
    }));

    const ColorSquare = styled('div')({
        width: '100%',
        height: '100%',
    });

    const ThemeIconsContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: theme.spacing(1),
    }));

    const ExpandButton = styled(Button)(({ theme }) => ({
        width: '80%',
        height: '24px',
        minWidth: 'unset',
        padding: 0,
        borderRadius: '12px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.divider,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    }));


    function Navigation({ onThemeChange, mobileOpen, handleDrawerToggle }) {
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        const location = useLocation();
        const navigate = useNavigate();
        const { isAuthenticated: isAuthenticatedFn, logout } = useAuth();
        const [subNavs, setSubNavs] = useState([]);
        const [loading, setLoading] = useState(true);
        const [expandedThemes, setExpandedThemes] = useState(false);
        const [hoveredTheme, setHoveredTheme] = useState(null);
        const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);

        const ThemeButton = styled(IconButton)(({ theme }) => ({
            width: 30,
            height: 30,
            padding: 0,
            margin: theme.spacing(0, 0.5),
            border: `1px solid ${theme.palette.divider}`,
        }));

        const handleThemeHover = (themeName) => {
            setHoveredTheme(themeName);
        };

        const handleThemeLeave = () => {
            setHoveredTheme(null);
        };

        const ThemeNameDisplay = styled(Typography)(({ theme }) => ({
            textAlign: 'center',
            minHeight: '1.5em',
            marginBottom: theme.spacing(1),
            color: theme.palette.text.secondary,
            fontSize: '0.875rem',
        }));

        const toggleExpandThemes = () => {
            setExpandedThemes(!expandedThemes);
        };

        const visibleThemes = expandedThemes ? themeDefinitions : themeDefinitions.slice(0, 4);

        useLayoutEffect(() => {
            let isMounted = true;

            const loadSubNav = async (path) => {
                try {
                    const normalizedPath = path === '/' ? '' : path;
                    const subNavConfig = await import(`../root${normalizedPath}/subNavConfig`);
                    if (isMounted) {
                        setSubNavs((prevSubNavs) => [...prevSubNavs, subNavConfig.default]);
                    }
                } catch (error) {
                    // Handle error
                }
            };

            const initializeSubNavs = async () => {
                setLoading(true);
                setSubNavs([]);

                await loadSubNav('/');

                const paths = location.pathname.split('/').filter(Boolean);
                let accumulatedPath = '';
                for (const path of paths) {
                    accumulatedPath += `/${path}`;
                    await loadSubNav(accumulatedPath);
                }

                if (isMounted) {
                    setLoading(false);
                }
            };

            initializeSubNavs();

            return () => {
                isMounted = false;
            };
        }, [location.pathname]);

        const handleLogout = () => {
            logout();
            navigate('/login');
        };

        const drawer = (
            <>
                {isMobile && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                        <IconButton onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )}
                <ThemeNameDisplay>
                    {hoveredTheme}
                </ThemeNameDisplay>
                <ThemeIconsContainer>
                    {visibleThemes.map((themeDef, index) => (
                        <ThemeButton
                            key={`theme-${index}`}
                            onClick={() => onThemeChange(themeDef.theme)}
                            onMouseEnter={() => handleThemeHover(themeDef.name)}
                            onMouseLeave={handleThemeLeave}
                        >
                            <ColorSquare sx={themeDef.icon} />
                        </ThemeButton>
                    ))}
                </ThemeIconsContainer>
                {themeDefinitions.length > 4 && (
                    <Box display="flex" justifyContent="center">
                        <ExpandButton onClick={toggleExpandThemes}>
                            {expandedThemes ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                        </ExpandButton>
                    </Box>
                )}
                <Divider />
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    isAuthenticatedFn() && (
                        <List>
                            {subNavs.map((subNav, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && <Divider sx={{ my: 2 }} />}
                                    <SubNavigation>
                                        <SectionHeader variant="h6" gutterBottom>
                                            {subNav.header}
                                        </SectionHeader>
                                        <List disablePadding>
                                            {subNav.links.map(link => (
                                                <ListItem key={link.path} disablePadding>
                                                    <StyledNavLink to={link.path}>
                                                        {link.icon && (
                                                            <SmallIcon>
                                                                <link.icon />
                                                            </SmallIcon>
                                                        )}
                                                        <ListItemText
                                                            primary={link.name}
                                                            primaryTypographyProps={{
                                                                fontSize: '0.9rem',
                                                                fontWeight: 'medium'
                                                            }}
                                                        />
                                                    </StyledNavLink>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </SubNavigation>
                                </React.Fragment>
                            ))}
                        </List>
                    )
                )}
                <Box sx={{ mt: 'auto', p: 2 }}>
                    <Button
                        fullWidth
                        href="https://discord.gg/7GFhCVqA"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mb: 1 }}
                    >
                        Join Discord
                    </Button>
                    {isAuthenticatedFn() && (
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => navigate('/account')}
                            sx={{ mb: 1 }}
                        >
                            Account
                        </Button>
                    )}
                    {isAuthenticatedFn() ? (
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => navigate('/login')}
                        >
                            Login
                        </Button>
                    )}
                </Box>
            </>
        );

        return (
            <>
                {isMobile && !mobileOpen && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{
                            position: 'fixed',
                            top: 16,
                            left: 16,
                            zIndex: theme.zIndex.drawer + 1,
                            bgcolor: 'background.paper',
                            '&:hover': {
                                bgcolor: 'action.hover',
                            },
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <StyledDrawer
                    variant={isMobile ? 'temporary' : 'permanent'}
                    open={isMobile ? mobileOpen : true}
                    onClose={isMobile ? handleDrawerToggle : undefined}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </StyledDrawer>
            </>
        );
    }

export default Navigation;