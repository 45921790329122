import React, { useMemo, useState, useCallback } from 'react';
import { Card, Button, Typography, CardContent, useTheme, Box, IconButton } from '@mui/material';
import RAGGeneration from "../../../../components/rag/RAGGeneration";
import DeleteIcon from "@mui/icons-material/Delete";

const QuizQuestion = ({ question, selectedAnswer, onAnswerClick, randomizeAnswers = true, onDelete }) => {
    const theme = useTheme();
    const [ragKey, setRagKey] = useState(0);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const shuffleAnswers = (question) => {
        const answerKeys = ['answer_1', 'answer_2', 'answer_3', 'answer_4'];
        const shuffled = [...answerKeys].sort(() => Math.random() - 0.5);

        return shuffled.map(key => ({
            key: key,
            text: question[key]
        }));
    };

    const answers = useMemo(() => {
        if (randomizeAnswers) {
            return shuffleAnswers(question);
        } else {
            return ['answer_1', 'answer_2', 'answer_3', 'answer_4'].map(key => ({
                key: key,
                text: question[key]
            }));
        }
    }, [question, randomizeAnswers]);

    const contentSupplier = useCallback(() => {
        return `${question.question} : ${question[question.correct_answer.toLowerCase()]} - ${question.explanation}`;
    }, [question]);

    // Force RAGGeneration to re-render when the question changes
    React.useEffect(() => {
        setRagKey(prevKey => prevKey + 1);
    }, [question]);

    const handleDeleteClick = () => {
        setShowDeleteConfirm(true);
    };

    const handleConfirmDelete = () => {
        onDelete(question.source);
        setShowDeleteConfirm(false);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirm(false);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" mb={2}>{question.question}</Typography>
                {answers.map(({ key, text }) => (
                    <Button
                        key={key}
                        variant="outlined"
                        fullWidth
                        sx={{
                            mb: 1,
                            justifyContent: 'flex-start',
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: selectedAnswer === key
                                    ? key === question.correct_answer.toLowerCase()
                                        ? theme.palette.success.dark
                                        : theme.palette.error.dark
                                    : theme.palette.action.hover,
                                color: selectedAnswer === key
                                    ? theme.palette.common.white
                                    : theme.palette.primary.main,
                            },
                            backgroundColor: selectedAnswer === key
                                ? key === question.correct_answer.toLowerCase()
                                    ? theme.palette.success.light
                                    : theme.palette.error.light
                                : 'inherit',
                            '&:disabled': {
                                color: theme.palette.text.disabled,
                            },
                        }}
                        onClick={() => onAnswerClick(key, question.source)}
                    >
                        {text}
                    </Button>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mt: 2 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <RAGGeneration
                            key={ragKey}
                            querySupplier={() => "You are a professor. Give a full lengthy lesson on the topic following quiz question. By the end of the detailed lesson, the student should be prepared to correctly answer the question. Have the lesson be centered on the topic, not specifically the specific question itself. This lesson is to have the student learn as they are taking the quiz, so provide the knowledge, but don't specifically highlight the answer."}
                            contentSupplier={contentSupplier}
                            specialInstructionsSupplier={() => "Start the response with the title name with no title label."}
                            contextSupplier={() => ""}
                        />
                    </Box>
                    <Box sx={{ ml: 2, display: 'flex', alignItems: 'flex-start' }}>
                        {showDeleteConfirm ? (
                            <>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleConfirmDelete}
                                    sx={{ mr: 1 }}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleCancelDelete}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <IconButton
                                aria-label="delete"
                                onClick={handleDeleteClick}
                                color="error"
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
                {question.references && question.references.length > 0 && (
                    <Button
                        href={`/apps/library/catalog#${question.references[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            mt: 2,
                            color: theme.palette.primary.main,
                            '&:hover': {
                                color: theme.palette.primary.dark,
                            },
                        }}
                    >
                        Reference
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default QuizQuestion;