import React, { useMemo } from 'react';
import { Paper, Typography, Grid, Box } from '@mui/material';

const NutritionSummary = ({ items }) => {
    const stats = useMemo(() => {
        const totalNutrition = items.reduce((acc, item) => {
            const nutrition = item.content.content.totalNutrition;
            Object.keys(nutrition).forEach(key => {
                if (typeof nutrition[key] === 'number') {
                    acc[key] = (acc[key] || 0) + nutrition[key];
                }
            });
            return acc;
        }, {});

        // Calculate the number of unique days
        const uniqueDays = new Set(items.map(item => new Date(item.content.content.mealDate).toDateString())).size;

        const avgNutrition = {};
        Object.keys(totalNutrition).forEach(key => {
            avgNutrition[key] = totalNutrition[key] / uniqueDays;
        });

        return { total: totalNutrition, average: avgNutrition };
    }, [items]);

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <Typography variant="h6" gutterBottom>Nutrition Summary</Typography>
            <Typography variant="subtitle1">Total (Daily Average)</Typography>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                {['Calories', 'Protein', 'Carbs', 'Fat'].map((nutrient) => (
                    <Grid item xs={6} key={nutrient} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box>
                            <Typography variant="body2">{nutrient}</Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'baseline',
                                flexWrap: 'wrap',
                                gap: '4px',
                                '@media (max-width: 600px)': {
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }
                            }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', lineHeight: 1.2 }}>
                                    {stats.total[nutrient.toLowerCase()].toFixed(2)}
                                </Typography>
                                <Typography
                                    component="span"
                                    sx={{
                                        color: '#66D9E8',
                                        whiteSpace: 'nowrap',
                                        fontSize: '0.875rem',
                                        lineHeight: 1.2
                                    }}
                                >
                                    ({stats.average[nutrient.toLowerCase()].toFixed(2)}{nutrient !== 'Calories' ? 'g' : ''}/day)
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default NutritionSummary;