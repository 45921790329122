import React, { useState, useCallback, useMemo } from 'react';
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNode from './CustomNode';
import { getLayoutedElements, createClusters } from './graphUtils';
import './Graph.css';
import { Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SourceForm from "../../../components/form/SourceForm";
import ManagedList from "../../../components/list/ManagedList";

const MemoizedCustomNode = React.memo(CustomNode);

const nodeTypes = {
    custom: MemoizedCustomNode,
};

const MODAL_STYLE = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

const CLOSE_BUTTON_STYLE = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
};

const useGraphState = (initialNodes, initialEdges) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [showGraph, setShowGraph] = useState(false);

    const memoizedIdTypeMap = useMemo(() => {
        return initialNodes.reduce((acc, node) => {
            acc[node.id] = {
                type: node.data.type
            };
            return acc;
        }, {});
    }, [initialNodes]);

    const {clusteredNodes, clusteredEdges} = useMemo(() => {
        return createClusters(initialNodes, initialEdges, memoizedIdTypeMap);
    }, [initialNodes, initialEdges, memoizedIdTypeMap]);

    const {nodes: layoutedNodes, edges: layoutedEdges} = useMemo(() => {
        return getLayoutedElements(clusteredNodes, clusteredEdges, 'TB', 5, 150);
    }, [clusteredNodes, clusteredEdges]);

    React.useEffect(() => {
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
        setShowGraph(true);
    }, [layoutedNodes, layoutedEdges, setNodes, setEdges]);

    return {
        nodes,
        edges,
        showGraph,
        setNodes,
        setEdges,
        onNodesChange,
        onEdgesChange
    };
};

const NodeModal = ({ open, onClose, selectedNodeId, onDelete }) => {
    const handleDelete = (deletedId) => {
        onDelete(deletedId);
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="node-modal-title"
            aria-describedby="node-modal-description"
        >
            <Box sx={MODAL_STYLE}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={CLOSE_BUTTON_STYLE}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ mt: 2 }}>
                    <ManagedList
                        query={`/source/${selectedNodeId}`}
                        ItemDisplay={(props) => (
                            <SourceForm {...props} onDelete={handleDelete} />
                        )}
                        showAssociationsDropdown={false}
                        showFilter={false}
                        expanded={true}
                        loadOnDefault={true}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

const DataGraph = ({ initialNodes, initialEdges, onConnectApi, onEdgeDeleteApi, onNodeDelete, onClusterEdgeDeleteApi, onClusterConnectApi }) => {
    const {
        nodes,
        edges,
        showGraph,
        setNodes,
        setEdges,
        onNodesChange,
        onEdgesChange
    } = useGraphState(initialNodes, initialEdges);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedNodeId, setSelectedNodeId] = useState(null);

    const nodeMap = useMemo(() => {
        return nodes.reduce((acc, node) => {
            acc[node.id] = node;
            return acc;
        }, {});
    }, [nodes]);

    const onConnect = useCallback(async (params) => {
        const sourceNode = nodeMap[params.source];
        const targetNode = nodeMap[params.target];

        if (!sourceNode || !targetNode) {
            console.error('Source or target node not found');
            return;
        }

        try {
            let success;
            if (sourceNode.data.type.includes('Cluster') || targetNode.data.type.includes('Cluster')) {
                const clusterNode = sourceNode.data.type.includes('Cluster') ? sourceNode : targetNode;
                const otherNode = sourceNode.data.type.includes('Cluster') ? targetNode : sourceNode;
                const reverse = targetNode.data.type.includes('Cluster');
                success = await onClusterConnectApi(clusterNode.data.childIds, otherNode.id, reverse);
            } else {
                success = await onConnectApi(sourceNode, targetNode);
            }
            if (success) {
                setEdges((eds) => addEdge(params, eds));
            }
        } catch (error) {
            console.error('Error connecting nodes:', error);
        }
    }, [nodeMap, onConnectApi, onClusterConnectApi, setEdges]);

    const onEdgeDoubleClick = useCallback(async (event, edge) => {
        event.preventDefault();

        const sourceNode = nodeMap[edge.source];
        const targetNode = nodeMap[edge.target];

        if (!sourceNode || !targetNode) {
            console.error('Source or target node not found');
            return;
        }

        try {
            let success;
            if (sourceNode.data.type.includes('Cluster') || targetNode.data.type.includes('Cluster')) {
                const clusterNode = sourceNode.data.type.includes('Cluster') ? sourceNode : targetNode;
                const otherNode = sourceNode.data.type.includes('Cluster') ? targetNode : sourceNode;
                const reverse = targetNode.data.type.includes('Cluster');
                success = await onClusterEdgeDeleteApi(clusterNode.data.childIds, otherNode.id, reverse);
            } else {
                success = await onEdgeDeleteApi(sourceNode, targetNode);
            }
            if (success) {
                setEdges((eds) => eds.filter((e) => e.id !== edge.id));
            }
        } catch (error) {
            console.error('Error deleting edge:', error);
        }
    }, [nodeMap, onEdgeDeleteApi, onClusterEdgeDeleteApi, setEdges]);

    const onNodeDoubleClick = useCallback((event, node) => {
        if (node.type === 'custom' && node.data.type.includes('Cluster')) {
            const childIds = node.data.childIds;
            const childNodes = initialNodes.filter(n => childIds.includes(n.id));
            const childEdges = initialEdges.filter(e =>
                childIds.includes(e.source) || childIds.includes(e.target)
            );

            const radius = Math.max(childNodes.length * 10, 50);
            const angleStep = (2 * Math.PI) / childNodes.length;
            const positionedChildNodes = childNodes.map((childNode, index) => {
                const angle = index * angleStep;
                return {
                    ...childNode,
                    position: {
                        x: node.position.x + radius * Math.cos(angle),
                        y: node.position.y + radius * Math.sin(angle)
                    }
                };
            });

            setNodes(prevNodes => prevNodes.filter(n => n.id !== node.id).concat(positionedChildNodes));
            setEdges(prevEdges => {
                const newEdges = prevEdges.filter(e => e.source !== node.id && e.target !== node.id);
                childEdges.forEach(childEdge => {
                    if (!newEdges.some(e => e.source === childEdge.source && e.target === childEdge.target)) {
                        newEdges.push(childEdge);
                    }
                });
                return newEdges;
            });
        } else {
            setSelectedNodeId(node.id);
            setModalOpen(true);
        }
    }, [initialNodes, initialEdges, setNodes, setEdges]);

    const handleNodeDelete = useCallback(async (deletedId) => {
        const success = await onNodeDelete(deletedId);
        if (success) {
            setNodes((prevNodes) => prevNodes.filter((node) => node.id !== deletedId));
            setEdges((prevEdges) => prevEdges.filter((edge) =>
                edge.source !== deletedId && edge.target !== deletedId
            ));
        }
    }, [onNodeDelete, setNodes, setEdges]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedNodeId(null);
    };

    return (
        <Box sx={{width: '100%', height: '800px'}}>
            {showGraph && (
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onEdgeDoubleClick={onEdgeDoubleClick}
                    onNodeDoubleClick={onNodeDoubleClick}
                    nodeTypes={nodeTypes}
                    fitView
                >
                    <MiniMap/>
                    <Controls/>
                    <Background/>
                </ReactFlow>
            )}
            <NodeModal
                open={modalOpen}
                onClose={handleCloseModal}
                selectedNodeId={selectedNodeId}
                onDelete={handleNodeDelete}
            />
        </Box>
    );
}

export default DataGraph;