import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Box, Typography, Paper, Button, IconButton, TextField, Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import DisplayField from './DisplayField';
import EditField from './EditField';
import AttributeList from './AttributeList';
import StringList from './StringList';
import ContentField from './ContentField';
import AssociationManager from "./AssociationManager";
import useApi from "../ApiService";
import { useSourceName } from "../../context/useSourceName";

const MemoizedStringList = React.memo(StringList);
const MemoizedAssociationManager = React.memo(AssociationManager);

const SourceForm = ({ item, onSourceDelete, onSubmit, onCancel, mode = 'default', allowContent = true }) => {
    const { apiCall } = useApi();
    const [data, setData] = useState(item || {});
    const [editingField, setEditingField] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [associations, setAssociations] = useState(item?.associations || []);

    const [content, setContent] = useState(item?.content || '');
    const [file, setFile] = useState(null);
    const [contentMode, setContentMode] = useState(item?.contentType ? (item.contentType === 'text/plain' ? 'Text' : (item.contentType === 'application/json' ? 'JSON Object' : 'File')) : '');
    const loadingAssociationsRef = useRef(false);
    const { getSourceNameById } = useSourceName();
    const isNewItem = !data.id;
    const sourceUrl = `/source${!isNewItem ? '/' + item.id : ''}`;

    const suggestedTypes = useMemo(() => ["Collection", "Source"], []);

    const submitData = useCallback(async (updatedData, updatedAssociations = null) => {
        try {
            let endpoint = sourceUrl;
            let body;
            let headers = {};

            const dataToSubmit = {
                ...updatedData,
                associations: updatedAssociations !== null ? updatedAssociations : associations
            };

            if (allowContent && file) {
                endpoint = `/aissistant/ingest/file`;
                body = new FormData();
                body.append('file', file);
                const metadata = {
                    ...dataToSubmit,
                    contentType: file.type
                };
                body.append('metadata', new Blob([JSON.stringify(metadata)], {
                    type: 'application/json'
                }));
            } else if ((dataToSubmit.contentType === 'text/plain' || dataToSubmit.contentType === 'application/json') && content.trim()) {
                endpoint = `/aissistant/ingest/content`;
                body = JSON.stringify({
                    ...dataToSubmit,
                    content: dataToSubmit.contentType === 'application/json' ? JSON.parse(content) : content,
                    runDataPreperation: false
                });
                headers['Content-Type'] = 'application/json';
            } else {
                body = JSON.stringify(dataToSubmit);
                headers['Content-Type'] = 'application/json';
            }

            const response = await apiCall(endpoint, {
                method: isNewItem ? 'POST' : 'PUT',
                headers: headers,
                data: body,
            });

            const result = await response.data;
            setData(result);
            setEditingField(null);
            if (allowContent) {
                setContent(result.content || '');
            }
            if (onSubmit) {
                onSubmit(result);
            }
            return true;
        } catch (error) {
            console.error('Error submitting data:', error);
            return false;
        }
    }, [isNewItem, sourceUrl, content, file, onSubmit, allowContent, apiCall, associations]);

    const handleEdit = useCallback(async (field, value) => {
        const updatedData = {
            ...data,
            [field]: value,
        };

        if (field === 'associations') {
            setAssociations(value);
            if (!isNewItem) {
                await submitData(updatedData, value);
            }
        } else {
            if (!isNewItem) {
                const success = await submitData(updatedData);
                if (success) {
                    setData(updatedData);
                }
            } else {
                setData(updatedData);
            }
        }
        setEditingField(null);
    }, [data, isNewItem, submitData]);

    const handleCancel = useCallback(() => {
        setEditingField(null);
        if (onCancel) {
            onCancel();
        }
    }, [onCancel]);

    const handleSubmitNewItem = useCallback(async () => {
        const success = await submitData(data);
        if (success) {
            if (onSubmit) {
                onSubmit(data);
            }
        }
    }, [data, submitData, onSubmit]);

    const handleAssociationsUpdate = useCallback((newAssociations) => {
        setAssociations(newAssociations);
        if (!isNewItem) {
            submitData(data, newAssociations);
        }
    }, [data, isNewItem, submitData]);

    const handleDelete = useCallback(async () => {
        try {
            await apiCall(sourceUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (onSourceDelete) {
                onSourceDelete(item);
            }

        } catch (error) {
            console.error('Error deleting source:', error);
        }
    }, [sourceUrl, onSourceDelete, item, apiCall]);

    const handleContentModeChange = useCallback((event) => {
        const newMode = event.target.value;
        setContentMode(newMode);
        if (newMode === 'Text') {
            handleEdit('contentType', 'text/plain');
            setFile(null);
        } else if (newMode === 'JSON Object') {
            handleEdit('contentType', 'application/json');
            setFile(null);
        } else if (newMode === 'File') {
            setContent('');
        } else {
            handleEdit('contentType', '');
            setContent('');
            setFile(null);
        }
    }, [handleEdit]);

    const onDrop = useCallback((acceptedFiles) => {
        console.log('onDrop called', { acceptedFiles });
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0]);
            handleEdit('contentType', acceptedFiles[0].type);
        }
    }, [handleEdit]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false
    });

    const sourceDisplayPresenter = useCallback((sourceId) => {
        return getSourceNameById(sourceId);
    }, [getSourceNameById]);

    const renderField = useCallback((field, value) => {
        if (isNewItem && field === 'uri') {
            return null;
        }

        if (mode === 'display' || field === 'uri') {
            return <DisplayField field={field} value={value} />;
        }

        if (isNewItem) {
            return (
                <TextField
                    label={field}
                    value={value || ''}
                    onChange={(e) => handleEdit(field, e.target.value)}
                    fullWidth
                    margin="normal"
                />
            );
        }

        if (editingField === field) {
            return (
                <EditField
                    field={field}
                    value={value}
                    onSubmit={(newValue) => handleEdit(field, newValue)}
                    onCancel={handleCancel}
                />
            );
        }

        return (
            <DisplayField
                field={field}
                value={value}
                onEdit={() => setEditingField(field)}
                editable={mode !== 'display'}
            />
        );
    }, [mode, editingField, isNewItem, handleEdit, handleCancel]);

    const renderContentField = useCallback(() => {
        if (!allowContent || !isNewItem) return null;

        return (
            <Box>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="content-mode-label">Source Type</InputLabel>
                    <Select
                        labelId="content-mode-label"
                        value={contentMode}
                        onChange={handleContentModeChange}
                        label="Source Type"
                    >
                        <MenuItem value="">Select Source Type</MenuItem>
                        <MenuItem value="Text">Text</MenuItem>
                        <MenuItem value="JSON Object">JSON Object</MenuItem>
                        <MenuItem value="File">File</MenuItem>
                    </Select>
                </FormControl>
                {(contentMode === 'Text' || contentMode === 'JSON Object') && (
                    <ContentField
                        content={content}
                        setContent={setContent}
                        contentType={contentMode === 'JSON Object' ? 'application/json' : 'text/plain'}
                        sourceTypes={data.types || []}
                    />
                )}
                {contentMode === 'File' && isNewItem && (
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #ccc',
                            borderRadius: '4px',
                            padding: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            marginTop: '16px',
                            '&:hover': {
                                borderColor: '#999',
                            },
                        }}
                    >
                        <input {...getInputProps()} />
                        <CloudUploadIcon sx={{ fontSize: 48, color: '#999' }} />
                        <Typography>
                            {isDragActive
                                ? "Drop the file here"
                                : file
                                    ? `File selected: ${file.name}`
                                    : "Drag and drop a file here, or click to select a file"}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    }, [allowContent, isNewItem, contentMode, handleContentModeChange, content, data.types, getRootProps, getInputProps, isDragActive, file]);

    return (
        <Paper elevation={3} sx={{ p: 3, position: 'relative' }}>
            {isNewItem ? (
                <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                    <IconButton
                        color="default"
                        onClick={handleCancel}
                        aria-label="cancel"
                    >
                        <CancelIcon />
                    </IconButton>
                </Box>
            ) : mode !== 'display' && (
                <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', alignItems: 'center' }}>
                    {showDeleteConfirmation && (
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<CheckCircleIcon />}
                            onClick={handleDelete}
                            sx={{ mr: 2 }}
                        >
                            Confirm Delete
                        </Button>
                    )}
                    <IconButton
                        color="error"
                        onClick={() => setShowDeleteConfirmation(true)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )}
            <Typography variant="h5" gutterBottom>
                {isNewItem ? 'New Source' : `Source: ${data.id}`}
            </Typography>
            <Box>
                {renderField('name', data.name)}
                {renderField('description', data.description)}
                {renderField('uri', data.uri)}
                {renderContentField()}
                <Box>
                    <Typography variant="subtitle1">Associations</Typography>
                    {loadingAssociationsRef.current ? (
                        <CircularProgress />
                    ) : (
                        <MemoizedStringList
                            label="Associations"
                            values={associations}
                            onChange={handleAssociationsUpdate}
                            editable={mode !== 'display'}
                            isNewItem={isNewItem}
                            displayPresenter={sourceDisplayPresenter}
                        />
                    )}
                    <MemoizedAssociationManager
                        associations={associations}
                        onUpdate={handleAssociationsUpdate}
                    />
                </Box>
                <MemoizedStringList
                    label="Types"
                    values={data.types || []}
                    onChange={(newValues) => handleEdit('types', newValues)}
                    editable={mode !== 'display'}
                    isNewItem={isNewItem}
                    suggestedValues={suggestedTypes}
                />
                <AttributeList
                    attributes={data.attributes || []}
                    onChange={(newAttributes) => handleEdit('attributes', newAttributes)}
                    editable={mode !== 'display'}
                    isNewItem={isNewItem}
                />
            </Box>
            {isNewItem && (
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitNewItem}
                    >
                        Submit New Source
                    </Button>
                </Box>
            )}
        </Paper>
    );
};

export default React.memo(SourceForm);