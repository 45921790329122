import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme, Snackbar, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManagedList from "../../../components/list/ManagedList";
import useApi from "../../../components/ApiService";

const MemoizedManagedList = React.memo(ManagedList);

function Associations() {
    const { apiCall } = useApi();
    const theme = useTheme();
    const [availableTypes, setAvailableTypes] = useState([]);
    const [sourcesToSet, setSourcesToSet] = useState([]);
    const [sourcesToAdd, setSourcesToAdd] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        const fetchAvailableTypes = async () => {
            try {
                const response = await apiCall(`/ai/extractor/types`);
                const types = response.data;
                const formattedTypes = types.map(type => ({
                    label: type.replace(/([A-Z])/g, ' $1').trim(),
                    id: type
                }));
                setAvailableTypes(formattedTypes);
            } catch (error) {
                console.error('Error fetching available types:', error);
            }
        };
        fetchAvailableTypes();
    }, [apiCall]);

    const handleSourcesToSetUpdate = useCallback((selectedItems) => {
        setSourcesToSet(selectedItems);
    }, []);

    const handleSourcesToAddUpdate = useCallback((selectedItems) => {
        setSourcesToAdd(selectedItems);
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            const response = await apiCall(`/source/associations`, {
                method: 'POST',
                data: {
                    sourcesToSet,
                    sourcesToAdd
                }
            });
            console.log('Associations updated successfully:', response.data);
            setSuccessMessage("Associations updated successfully!");
        } catch (error) {
            console.error('Error updating associations:', error);
            setSuccessMessage("Error updating associations. Please try again.");
        }
    }, [apiCall, sourcesToSet, sourcesToAdd]);

    const handleCloseSnackbar = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessMessage("");
    }, []);

    const headerStyle = useMemo(() => ({
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '2rem',
        width: '100%',
        textAlign: 'center',
    }), [theme.palette.primary.main]);

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px', margin: 'auto', padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Assign Associations
            </Typography>

            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem' }} />}
                    sx={{ minHeight: 70 }}
                >
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h3" sx={headerStyle}>
                            Items to set associations
                        </Typography>
                        <Typography variant="caption">
                            ({sourcesToSet.length} selected)
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <MemoizedManagedList
                        query={'/source/search'}
                        asChecklist={true}
                        rootAssociation={"AIssistant"}
                        defaultSelectedTypes={["Source"]}
                        showTypeSelection={true}
                        showCheckAllClear={true}
                        showOrphanOptions={true}
                        showOrphansByDefault={true}
                        onChecklistUpdate={handleSourcesToSetUpdate}
                        showAssociations={false}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem' }} />}
                    sx={{ minHeight: 70 }}
                >
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h3" sx={headerStyle}>
                            Assign To
                        </Typography>
                        <Typography variant="caption">
                            ({sourcesToAdd.length} selected)
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <MemoizedManagedList
                        query={'/source/search'}
                        rootAssociation={"AIssistant"}
                        asChecklist={true}
                        showTypeSelection={true}
                        showCheckAllClear={true}
                        showOrphanOptions={true}
                        showOrphansByDefault={true}
                        defaultSelectedTypes={["Collection"]}
                        possibleTypes={["Application", "Collection"]}
                        onChecklistUpdate={handleSourcesToAddUpdate}
                        showAssociations={false}
                    />
                </AccordionDetails>
            </Accordion>

            <Box sx={{ mt: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                    size="large"
                    sx={{ fontSize: '1.1rem', py: 1.5 }}
                    disabled={sourcesToSet.length === 0 || sourcesToAdd.length === 0}
                >
                    Update Associations
                </Button>
            </Box>

            <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={successMessage.includes("Error") ? "error" : "success"} sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default React.memo(Associations);