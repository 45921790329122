import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "./AuthContext";
import { Box, TextField, Button, Typography, Alert, CircularProgress } from '@mui/material';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#registerSuccess') {
            setShowRegisterSuccess(true);
            // Clear the hash from the URL without navigating
            window.history.replaceState(null, '', location.pathname);
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
        console.log('Login attempt with:', username, password, 'Remember Me: Always');

        try {
            const success = await login(username, password, true); // Always pass true for rememberMe
            console.log('Login success:', success);
            if (success) {
                console.log('Login successful, redirecting...');
                const from = location.state?.from?.pathname || '/apps';
                navigate(from, { replace: true });
            } else {
                setError('Invalid username or password');
            }
        } catch (err) {
            console.error('Login error:', err);
            setError('An error occurred during login. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                mt: 1,
                maxWidth: 400,
                margin: 'auto',
                padding: 3,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: 'background.paper'
            }}
        >
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            {showRegisterSuccess && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    Registration successful! Please log in with your new account.
                </Alert>
            )}
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="off"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={isLoading}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
            </Button>
            <Button
                fullWidth
                variant="outlined"
                onClick={() => navigate('/register')}
                sx={{ mb: 2 }}
            >
                Create an Account
            </Button>
        </Box>
    );
};

export default Login;