import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    textAlign: 'center',
}));

const CatalogStats = React.memo(({ items }) => {
    const itemCount = items.length;

    return (
        <StyledPaper elevation={3}>
            <Box>
                <Typography variant="h6">Total Items in Current Selection</Typography>
                <Typography variant="h4">{itemCount}</Typography>
            </Box>
        </StyledPaper>
    );
});

export default CatalogStats;