import React, { useState, useCallback, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box, Divider, Chip, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { EventNote, Description, Delete, Check, Close } from '@mui/icons-material';
import EditField from "../form/EditField";
import useApi from "../ApiService";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        overflow: 'hidden',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
}));

const DateHeader = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.text.disabled,
    '& .MuiChip-icon': {
        color: theme.palette.text.disabled,
    },
}));

const EventDetailsModal = ({ isOpen, onClose, selectedEvent, onEventUpdate }) => {
    const { apiCall } = useApi();
    const [eventContent, setEventContent] = useState({});
    const [editMode, setEditMode] = useState({
        date: false,
        title: false,
        description: false,
    });
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    useEffect(() => {
        console.log("Selected Event:", selectedEvent);

        if (selectedEvent && selectedEvent.content && selectedEvent.content.content) {
            setEventContent(selectedEvent.content.content);
            setShowDeleteConfirm(false);
        }
    }, [selectedEvent]);

    const handleUpdate = useCallback(async (field, value) => {
        const updatedContent = { ...eventContent, [field]: value };
        setEventContent(updatedContent);
        setEditMode({ ...editMode, [field]: false });

        try {
            const response = await apiCall(`/content/${selectedEvent.content.id}`, {
                method: 'PUT',
                data: {
                    ...selectedEvent.content,
                    content: updatedContent,
                }
            });

            if (response.status === 200) {
                onEventUpdate(selectedEvent.id, updatedContent);
            }
        } catch (error) {
            console.error('Error updating event:', error);
            // Handle error (e.g., show an error message to the user)
        }
    }, [eventContent, selectedEvent, onEventUpdate, editMode]);

    const handleDelete = useCallback(async () => {
        try {
            const response = await apiCall(`/source/${selectedEvent.id}`, {method: 'DELETE'});

            if (response.status === 200) {
                onEventUpdate(selectedEvent.id, null);
                onClose();
            }
        } catch (error) {
            console.error('Error deleting event:', error);
            // Handle error (e.g., show an error message to the user)
        }
    }, [selectedEvent, onClose, onEventUpdate]);

    const handleEdit = (field) => {
        setEditMode({ ...editMode, [field]: true });
    };

    const handleCancel = (field) => {
        setEditMode({ ...editMode, [field]: false });
    };

    if (!selectedEvent) return null;

    return (
        <StyledDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DateHeader>
                <Box display="flex" alignItems="center" gap={1}>
                    <EventNote fontSize="small" />
                    {editMode.date ? (
                        <EditField
                            field="Date"
                            value={eventContent.date}
                            onSubmit={(value) => handleUpdate('date', value)}
                            onCancel={() => handleCancel('date')}
                            invertColors={true}
                            showDelete={false}
                        />
                    ) : (
                        <Typography variant="subtitle1" fontWeight="bold" onDoubleClick={() => handleEdit('date')}>
                            {eventContent.date}
                        </Typography>
                    )}
                </Box>
                <IconButton onClick={onClose} size="small" sx={{ color: 'inherit' }}>
                    <Close fontSize="small" />
                </IconButton>
            </DateHeader>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    {editMode.title ? (
                        <EditField
                            field="Title"
                            value={eventContent.title}
                            onSubmit={(value) => handleUpdate('title', value)}
                            onCancel={() => handleCancel('title')}
                            showDelete={false}
                        />
                    ) : (
                        <Typography variant="h4" fontWeight="bold" textAlign="center" mt={2} onDoubleClick={() => handleEdit('title')}>
                            {eventContent.title}
                        </Typography>
                    )}

                    <Divider flexItem />

                    <Box display="flex" alignItems="flex-start" gap={1} width="100%">
                        <Description color="primary" style={{ marginTop: '3px' }} />
                        {editMode.description ? (
                            <EditField
                                field="Description"
                                value={eventContent.description}
                                onSubmit={(value) => handleUpdate('description', value)}
                                onCancel={() => handleCancel('description')}
                                showDelete={false}
                            />
                        ) : (
                            <Typography align="left" onDoubleClick={() => handleEdit('description')}>
                                {eventContent.description}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <StyledDialogActions>
                <StyledChip
                    label={`Source ID: ${selectedEvent.id}`}
                    size="small"
                />
                <Box>
                    {showDeleteConfirm ? (
                        <Button
                            startIcon={<Check />}
                            color="error"
                            onClick={handleDelete}
                        >
                            Confirm Delete
                        </Button>
                    ) : (
                        <Button
                            startIcon={<Delete />}
                            color="error"
                            onClick={() => setShowDeleteConfirm(true)}
                        >
                        </Button>
                    )}
                </Box>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default EventDetailsModal;