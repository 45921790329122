import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Typography, Box, Button, CircularProgress, Modal } from '@mui/material';
import ManagedList from "../list/ManagedList";
import Extractor from "../extract/Extractor";
import { useSystemCollection } from "../../context/systemColections";
import AssociationsDropdown from "../AssociationsDropdown";
import { useNavigate, useLocation } from 'react-router-dom';

function ManagedPage({
                         title,
                         systemCollectionName,
                         sourceType,
                         StatsComponent,
                         ItemDisplay,
                         extractorProps = {},
                         showExtractor = true,
                         additionalButtons,
                         managedListProps = {},
                         addItemsButtonText = "Add Items",
                         eagerLoad = true,
                         hideAssociationsDropdown = false
                     }) {
    const [items, setItems] = useState([]);
    const [showExtractorUI, setShowExtractorUI] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [associationsRefreshTrigger, setAssociationsRefreshTrigger] = useState(0);
    const selectedAssociation = useRef(null);
    const query = useRef('');
    const { getSystemCollectionIdByName, isLoading, error } = useSystemCollection();
    const rootAssociation = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const initializeAssociation = async () => {
            try {
                const collectionId = await getSystemCollectionIdByName(systemCollectionName);
                rootAssociation.current = collectionId;

                // Check if there's an association in the URL
                const searchParams = new URLSearchParams(location.search);
                const urlAssociation = searchParams.get('association');

                if (urlAssociation) {
                    selectedAssociation.current = urlAssociation;
                } else {
                    selectedAssociation.current = collectionId;
                    updateURL(collectionId);
                }

                updateQuery();
                setIsInitialized(true);
            } catch (err) {
                console.error(`Error initializing ${systemCollectionName} association:`, err);
            }
        };

        initializeAssociation();
    }, [getSystemCollectionIdByName, systemCollectionName, sourceType, location.search]);

    const updateQuery = () => {
        const params = new URLSearchParams({
            types: sourceType,
            includeContent: "true",
            associations: selectedAssociation.current
        });
        query.current = `/source/search?${params.toString()}`;
    };

    const updateURL = (associationId) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('association', associationId);
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };

    const handleSourceLoad = useCallback((loadedSource) => {
        if (loadedSource && typeof loadedSource === 'object' && loadedSource.id) {
            setItems(prevItems => {
                const exists = prevItems.some(item => item.id === loadedSource.id);
                if (!exists) {
                    return [...prevItems, loadedSource];
                }
                return prevItems;
            });
        } else {
            console.warn("Invalid source received:", loadedSource);
        }
    }, []);

    const handleAssociationUpdate = useCallback((selected) => {
        if (selectedAssociation.current === selected.id) {
            return;
        }

        setItems([]);
        selectedAssociation.current = selected.id;
        updateQuery();
        updateURL(selected.id);
        setRefreshTrigger(prev => prev + 1);
    }, []);

    const handleSourceUpdate = useCallback((updatedSource) => {
        if (updatedSource && typeof updatedSource === 'object' && updatedSource.id) {
            setItems(prevItems => prevItems.map(item =>
                item.id === updatedSource.id ? updatedSource : item
            ));
        } else {
            console.warn("Invalid updated source received:", updatedSource);
        }
    }, []);

    const handleNewExtractedItems = useCallback(() => {
        setItems([]);
        setRefreshTrigger(prev => prev + 1);
    }, []);

    const handleSourceDelete = useCallback((deletionInfo) => {
        if (deletionInfo && deletionInfo.id) {
            setItems(prevItems => prevItems.filter(item => item.id !== deletionInfo.id));
        }
    }, []);

    if (isLoading || !isInitialized) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
    if (error) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">Error: {error.message}</Box>;

    return (
        <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>
                <Box>
                    {showExtractor && (
                        <Button
                            variant="contained"
                            onClick={() => setShowExtractorUI(!showExtractorUI)}
                            sx={{
                                mr: 2,
                                fontSize: '1.2rem',
                                padding: '12px 24px',
                                backgroundColor: 'primary',
                                '&:hover': {
                                    backgroundColor: showExtractorUI ? '#d32f2f' : '#45a049',
                                },
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                transition: 'all 0.3s ease',
                            }}
                        >
                            {showExtractorUI ? `Done ${addItemsButtonText}` : addItemsButtonText}
                        </Button>
                    )}
                    {additionalButtons}
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <AssociationsDropdown
                        rootAssociation={rootAssociation.current}
                        onCollectionSelect={handleAssociationUpdate}
                        selected={selectedAssociation.current}
                        key={associationsRefreshTrigger}
                        hide={hideAssociationsDropdown}
                    />
                </Box>
            </Box>

            {showExtractorUI && (
                <Box sx={{ mb: 3 }}>
                    <Extractor
                        extractTo={[selectedAssociation.current]}
                        onSubmit={handleNewExtractedItems}
                        showTo={false}
                        showType={false}
                        {...extractorProps}
                    />
                    <Button
                        variant="contained"
                        onClick={() => setShowExtractorUI(false)}
                        sx={{
                            mt: 2,
                            backgroundColor: '#f44336',
                            '&:hover': {
                                backgroundColor: '#d32f2f',
                            },
                        }}
                    >
                        Done {addItemsButtonText}
                    </Button>
                </Box>
            )}

            {StatsComponent && (
                <StatsComponent
                    items={items}
                    collectionId={selectedAssociation.current}
                />
            )}

            {isInitialized && (
                <ManagedList
                    query={query.current}
                    ItemDisplay={ItemDisplay}
                    rootAssociation={rootAssociation.current}
                    showAssociationsDropdown={false}
                    showFilter={true}
                    eagerLoad={eagerLoad}
                    expanded={false}
                    onSourceLoad={handleSourceLoad}
                    onSourceUpdate={handleSourceUpdate}
                    onSourceDelete={handleSourceDelete}
                    onAssociationUpdate={handleAssociationUpdate}
                    loadOnDefault={true}
                    selectedAssociation={selectedAssociation.current}
                    allowDelete={true}
                    key={refreshTrigger}
                    {...managedListProps}
                />
            )}
        </Box>
    );
}

export default ManagedPage;