import React, { useMemo } from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { parseISO, format, addDays, isBefore, isSameDay } from 'date-fns';

const CustomTooltip = ({ active, payload, label }) => {
    const theme = useTheme();

    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(1.5),
                    boxShadow: theme.shadows[4],
                }}
            >
                <Typography variant="subtitle2" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
                    {format(parseISO(label), 'MMMM d, yyyy')}
                </Typography>
                {payload.map((entry, index) => (
                    <Box key={`item-${index}`} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                        <Typography variant="body2" sx={{ color: entry.color, mr: 2, fontWeight: 'bold' }}>
                            {entry.name}:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {entry.value.toFixed(2)}g
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    }
    return null;
};

const MacronutrientTrends = ({ items, startDate, endDate, getChartTimeframe }) => {
    const theme = useTheme();

    const chartData = useMemo(() => {
        const dateArray = [];
        for (let d = parseISO(startDate); isBefore(d, parseISO(endDate)) || isSameDay(d, parseISO(endDate)); d = addDays(d, 1)) {
            dateArray.push(format(d, 'yyyy-MM-dd'));
        }

        return dateArray.map(date => {
            const dayItems = items.filter(item => item.content.content.mealDate === date);
            const dayTotals = dayItems.reduce((acc, item) => {
                const nutrition = item.content.content.totalNutrition;
                acc.protein += nutrition.protein || 0;
                acc.carbs += nutrition.carbs || 0;
                acc.fat += nutrition.fat || 0;
                return acc;
            }, { protein: 0, carbs: 0, fat: 0 });

            return {
                date,
                ...dayTotals
            };
        });
    }, [items, startDate, endDate]);

    const lineColors = {
        protein: '#ff6b6b',  // Bright red
        carbs: '#4ecdc4',    // Bright teal
        fat: '#feca57'       // Bright yellow
    };

    return (
        <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary, mb: 2 }}>
                Macronutrient Trends - {getChartTimeframe()}
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
                <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(value) => format(parseISO(value), 'MM/dd')}
                        stroke={theme.palette.text.secondary}
                        tick={{ fill: theme.palette.text.secondary }}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        tick={{ fill: theme.palette.text.secondary }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        wrapperStyle={{ color: theme.palette.text.primary }}
                        formatter={(value, entry) => <span style={{ color: entry.color }}>{value}</span>}
                    />
                    {Object.entries(lineColors).map(([key, color]) => (
                        <Line
                            key={key}
                            type="monotone"
                            dataKey={key}
                            stroke={color}
                            strokeWidth={2}
                            name={key.charAt(0).toUpperCase() + key.slice(1)}
                            dot={{ fill: color, r: 4 }}
                            activeDot={{ r: 6 }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default MacronutrientTrends;