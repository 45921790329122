import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    Typography, Box, Button, Paper, Grid, useTheme, alpha,
    TextField, IconButton, List, ListItem, ListItemText, Chip, Divider
} from '@mui/material';
import {
    FitnessCenter as WorkoutIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import StringList from "../../../../components/form/StringList";
import useApi from "../../../../components/ApiService";
import debounce from 'lodash.debounce';
import DeleteSource from "../../../../components/DeleteSource";

const GymWorkoutDisplay = ({ workout: initialWorkout, sourceRecord, onSourceUpdate, onSourceDelete }) => {
    const { apiCall } = useApi();
    const [workout, setWorkout] = useState(sourceRecord ? sourceRecord.content.content : initialWorkout);
    const [isEditing, setIsEditing] = useState(false);
    const theme = useTheme();

    const debouncedSubmitEdit = useRef(
        debounce(async (updatedWorkout) => {
            try {
                const requestBody = { id: sourceRecord.content.id, content: updatedWorkout };
                const response = await apiCall(`/content/${sourceRecord.content.id}`, {method: 'PUT', data: requestBody});

                if (response.data && response.data.content[0].content) {
                    setWorkout(response.data.content[0].content);
                    if (onSourceUpdate) {
                        const updatedSource = {
                            ...sourceRecord,
                            content: {
                                ...sourceRecord.content,
                                content: response.data.content[0].content
                            }
                        };
                        onSourceUpdate(updatedSource);
                    }
                } else {
                    setWorkout(updatedWorkout);
                }
            } catch (error) {
                console.error('Error updating workout:', error);
            }
        }, 500)
    ).current;

    useEffect(() => {
        return () => {
            debouncedSubmitEdit.cancel();
        };
    }, [debouncedSubmitEdit]);

    const handleChange = (field, value) => {
        const updatedWorkout = { ...workout, [field]: value };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const handleActivityChange = (index, field, value) => {
        const updatedActivities = [...workout.activities];
        if (field === 'primary' || field === 'muscles') {
            value = Array.isArray(value) ? value : parseMuscles(value);
        }
        updatedActivities[index] = { ...updatedActivities[index], [field]: value };
        const updatedWorkout = { ...workout, activities: updatedActivities };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const handleSetChange = (activityIndex, setIndex, field, value) => {
        const updatedActivities = [...workout.activities];
        const updatedSets = [...updatedActivities[activityIndex].sets];
        updatedSets[setIndex] = { ...updatedSets[setIndex], [field]: value };
        updatedActivities[activityIndex] = { ...updatedActivities[activityIndex], sets: updatedSets };
        const updatedWorkout = { ...workout, activities: updatedActivities };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const addActivity = () => {
        const newActivity = {
            activity: "",
            primary: "",
            muscles: "",
            duration: "",
            sets: [],
            assessment: "",
            notes: ""
        };
        const updatedWorkout = {
            ...workout,
            activities: [...workout.activities, newActivity]
        };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const removeActivity = (index) => {
        const updatedWorkout = {
            ...workout,
            activities: workout.activities.filter((_, i) => i !== index)
        };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const parseMuscles = (musclesString) => {
        if (Array.isArray(musclesString)) return musclesString;
        if (typeof musclesString === 'string') {
            return musclesString.replace(/^\[|\]$/g, '').split(/,\s*/).map(m => m.trim());
        }
        return [];
    };

    const moveActivity = (index, direction) => {
        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= workout.activities.length) return;
        const updatedActivities = [...workout.activities];
        [updatedActivities[index], updatedActivities[newIndex]] = [updatedActivities[newIndex], updatedActivities[index]];
        const updatedWorkout = { ...workout, activities: updatedActivities };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const addSet = (activityIndex) => {
        const updatedActivities = [...workout.activities];
        updatedActivities[activityIndex].sets.push({ weight: "", reps: "" });
        const updatedWorkout = { ...workout, activities: updatedActivities };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const removeSet = (activityIndex, setIndex) => {
        const updatedActivities = [...workout.activities];
        updatedActivities[activityIndex].sets = updatedActivities[activityIndex].sets.filter((_, i) => i !== setIndex);
        const updatedWorkout = { ...workout, activities: updatedActivities };
        setWorkout(updatedWorkout);
        debouncedSubmitEdit(updatedWorkout);
    };

    const toggleEdit = () => {
        setIsEditing((prevIsEditing) => !prevIsEditing);
    };

    const renderActivityCard = (activity, activityIndex) => {
        const hasValidContent = (content) => {
            if (Array.isArray(content)) {
                return content.length > 0;
            }
            return content && content !== "N/A" && content.trim() !== "";
        };

        return (
            <Paper
                elevation={3}
                sx={{
                    mb: 3,
                    p: 3,
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                    borderRadius: theme.shape.borderRadius,
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}
            >
                <Typography variant="h5" color="primary" gutterBottom>
                    {activity.activity || "Unnamed Exercise"}
                </Typography>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    {hasValidContent(activity.primary) && (
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" color="textSecondary">Primary Muscle Group:</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {parseMuscles(activity.primary).map((muscle, index) => (
                                    <Chip key={index} label={muscle} size="small" />
                                ))}
                            </Box>
                        </Grid>
                    )}
                    {hasValidContent(activity.muscles) && (
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" color="textSecondary">Muscles Worked:</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {parseMuscles(activity.muscles).map((muscle, index) => (
                                    <Chip key={index} label={muscle} size="small" />
                                ))}
                            </Box>
                        </Grid>
                    )}
                    {hasValidContent(activity.duration) && (
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" color="textSecondary">Duration:</Typography>
                            <Typography variant="body1">{activity.duration}</Typography>
                        </Grid>
                    )}
                </Grid>

                {activity.sets && activity.sets.length > 0 && activity.sets.some(set => hasValidContent(set.weight) || hasValidContent(set.reps)) && (
                    <>
                        <Typography variant="subtitle1" color="primary" gutterBottom>Sets:</Typography>
                        <List dense>
                            {activity.sets.map((set, setIndex) => {
                                const setInfo = [];
                                if (hasValidContent(set.weight)) setInfo.push(set.weight);
                                if (hasValidContent(set.reps)) setInfo.push(set.reps);

                                if (setInfo.length > 0) {
                                    return (
                                        <ListItem key={setIndex}>
                                            <ListItemText primary={`Set ${setIndex + 1}: ${setInfo.join(' x ')}`} />
                                        </ListItem>
                                    );
                                }
                                return null;
                            }).filter(Boolean)}
                        </List>
                    </>
                )}

                {(hasValidContent(activity.assessment) || hasValidContent(activity.notes)) && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        {hasValidContent(activity.assessment) && (
                            <Box sx={{ mb: 1 }}>
                                <Typography variant="subtitle2" color="textSecondary">Assessment:</Typography>
                                <Typography variant="body2">{activity.assessment}</Typography>
                            </Box>
                        )}
                        {hasValidContent(activity.notes) && (
                            <Box>
                                <Typography variant="subtitle2" color="textSecondary">Notes:</Typography>
                                <Typography variant="body2">{activity.notes}</Typography>
                            </Box>
                        )}
                    </>
                )}
            </Paper>
        );
    };

    return (
        <Paper
            elevation={3}
            sx={{
                p: theme.spacing(3),
                mb: theme.spacing(3),
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={theme.spacing(3)}>
                <Typography variant="h3" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                    <WorkoutIcon sx={{ mr: theme.spacing(1) }} />
                    {workout.goal || "Unnamed Workout"}
                </Typography>
                <Button
                    variant="contained"
                    size="small"
                    startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
                    onClick={toggleEdit}
                >
                    {isEditing ? 'Done Editing' : 'Edit Workout'}
                </Button>
            </Box>

            {workout.activities.map((activity, index) => (
                <React.Fragment key={`activity-${index}`}>
                    {isEditing ? (
                        <Box key={index} sx={{ mb: 3, p: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: theme.shape.borderRadius }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Exercise Name"
                                        value={activity.activity}
                                        onChange={(e) => handleActivityChange(index, 'activity', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <StringList
                                        label="Primary Muscle Group"
                                        values={parseMuscles(activity.primary)}
                                        onChange={(newPrimary) => handleActivityChange(index, 'primary', newPrimary)}
                                        editable={true}
                                        suggestedValues={['Chest', 'Back', 'Legs', 'Shoulders', 'Arms', 'Core']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <StringList
                                        label="Muscles Worked"
                                        values={parseMuscles(activity.muscles)}
                                        onChange={(newMuscles) => handleActivityChange(index, 'muscles', newMuscles)}
                                        editable={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Duration"
                                        value={activity.duration}
                                        onChange={(e) => handleActivityChange(index, 'duration', e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Sets:</Typography>
                            {activity.sets.map((set, setIndex) => (
                                <Box key={setIndex} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <TextField
                                        label="Weight"
                                        value={set.weight}
                                        onChange={(e) => handleSetChange(index, setIndex, 'weight', e.target.value)}
                                        sx={{ mr: 1, width: '40%' }}
                                    />
                                    <TextField
                                        label="Reps"
                                        value={set.reps}
                                        onChange={(e) => handleSetChange(index, setIndex, 'reps', e.target.value)}
                                        sx={{ mr: 1, width: '40%' }}
                                    />
                                    <IconButton onClick={() => removeSet(index, setIndex)}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon />}
                                onClick={() => addSet(index)}
                                sx={{ mt: 1 }}
                            >
                                Add Set
                            </Button>

                            <TextField
                                fullWidth
                                label="Assessment"
                                value={activity.assessment}
                                onChange={(e) => handleActivityChange(index, 'assessment', e.target.value)}
                                sx={{ mt: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Notes"
                                value={activity.notes}
                                onChange={(e) => handleActivityChange(index, 'notes', e.target.value)}
                                multiline
                                rows={2}
                                sx={{ mt: 2 }}
                            />
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton onClick={() => removeActivity(index)} color="error">
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton onClick={() => moveActivity(index, -1)} disabled={index === 0}>
                                    <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton onClick={() => moveActivity(index, 1)} disabled={index === workout.activities.length - 1}>
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ) : renderActivityCard(activity, index)}
                </React.Fragment>
            ))}

            {isEditing && (
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={addActivity}
                    sx={{ mt: 2, mb: 3 }}
                >
                    Add Activity
                </Button>
            )}

            {isEditing ? (
                <TextField
                    fullWidth
                    label="Workout Notes"
                    value={workout.workoutNotes}
                    onChange={(e) => handleChange('workoutNotes', e.target.value)}
                    multiline
                    rows={3}
                    sx={{ mb: 2 }}
                />
            ) : workout.workoutNotes && workout.workoutNotes !== "N/A" && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" color="primary" gutterBottom>Workout Notes:</Typography>
                    <Typography variant="body1">{workout.workoutNotes}</Typography>
                </Box>
            )}

            <Box display="flex" justifyContent="flex-end" mt={3}>
                <DeleteSource
                    sourceId={sourceRecord.id}
                    onDeleteSuccess={onSourceDelete}
                />
            </Box>
        </Paper>
    );
};

export default GymWorkoutDisplay;