import React from 'react';
import ManagedPage from "../../../../components/apps/ManagedPage";
import AssignmentItem from './AssignmentItem';
import AssignmentStats from './AssignmentStats';

function Assignments() {
    return (
        <ManagedPage
            title="Assignments"
            systemCollectionName="Assignments"
            sourceType="Assignment"
            StatsComponent={AssignmentStats}
            ItemDisplay={AssignmentItem}
            extractorProps={{
                availableTypes: [{ id: "Assignment", label: "Assignment" }],
                fromQuery: '/source/search?',
                toQuery: '/source/search?',
                fromPossibleTypes: ["Source"],
                toPossibleTypes: ["Collection"],
                toRootAssociation: "Assignments",
            }}
            managedListProps={{
                showAssociationsDropdown: false,
                showFilter: true,
                eagerLoad: true,
                expanded: false,
                loadOnDefault: true,
            }}
        />
    );
}

export default Assignments;