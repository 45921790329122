import React, { useMemo } from 'react';
import { Typography, Box, Paper, Grid, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import ExpandableCard from "../../../../components/list/ExpandableCard";
import NutritionStats from "../nutrition/NutritionStats";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));

const StatBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
}));

function FoodStapleStats({ items }) {
    const theme = useTheme();
    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.error.main,
        theme.palette.warning.main,
        theme.palette.info.main,
        theme.palette.success.main,
    ];

    const stats = useMemo(() => {
        const foodGroups = {};
        const foodTypes = {};
        items.forEach(item => {
            if (item.content && item.content.content) {
                const { food_group, type } = item.content.content;
                if (food_group) {
                    foodGroups[food_group] = (foodGroups[food_group] || 0) + 1;
                }
                if (type) {
                    foodTypes[type] = (foodTypes[type] || 0) + 1;
                }
            }
        });
        return {
            totalItems: items.length,
            foodGroups,
            foodTypes
        };
    }, [items]);

    const preparePieChartData = (data) => {
        return Object.entries(data).map(([name, value]) => ({
            name: name.charAt(0).toUpperCase() + name.slice(1),
            value
        }));
    };

    const foodGroupData = preparePieChartData(stats.foodGroups);
    const foodTypeData = preparePieChartData(stats.foodTypes);

    const renderPieChart = (data, title) => {
        if (data.length === 0) {
            return (
                <Typography variant="body2">
                    No data available for {title.toLowerCase()}.
                </Typography>
            );
        }

        return (
            <>
                <Typography variant="h6" gutterBottom>{title}</Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill={theme.palette.primary.main}
                            dataKey="value"
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </>
        );
    };

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>Food Staples Checklist Stats</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <Typography variant="h4">{stats.totalItems}</Typography>
                        <Typography variant="body2">Total Staple Items</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <Typography variant="h4">{Object.keys(stats.foodGroups).length}</Typography>
                        <Typography variant="body2">Food Groups</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <Typography variant="h4">{Object.keys(stats.foodTypes).length}</Typography>
                        <Typography variant="body2">Food Types</Typography>
                    </StatBox>
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderPieChart(foodGroupData, "Food Groups Distribution")}
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderPieChart(foodTypeData, "Food Types Distribution")}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" paragraph>
                        Your food staples checklist contains a total of {stats.totalItems} items, covering {Object.keys(stats.foodGroups).length} different food groups and {Object.keys(stats.foodTypes).length} different food types.
                    </Typography>
                    <Typography variant="body2">
                        {(foodGroupData.length > 0 || foodTypeData.length > 0) ?
                            "The pie charts display the distribution of food groups and types in your staples list, helping you visualize the variety and balance of your food staples." :
                            "Add items with food groups and types to see their distribution in pie charts."}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        This overview can help you ensure you're maintaining a well-balanced list of essential food staples across various food groups and types. Consider reviewing any underrepresented categories to optimize your checklist.
                    </Typography>
                </Grid>
            </Grid>
            &nbsp;
            <ExpandableCard
                title={"Nutrition Estimates"}
                initiallyExpanded={false}
            >
                <NutritionStats items={items} nutritionPath="content.content.nutrition_estimate" />
            </ExpandableCard>
        </StyledPaper>
    );
}

export default FoodStapleStats;