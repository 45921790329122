import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataGraph from './DataGraph';
import AssociationsDropdown from "../../../components/AssociationsDropdown";
import TypeSelector from "../../../components/TypeSelector";
import {TextField, Box, Checkbox, FormControlLabel, Typography} from '@mui/material';
import useApi from "../../../components/ApiService";
import TooltipWrapper from "../../../components/StyledTooltip";

const Graph = () => {
    const { apiCall } = useApi();
    const [allNodes, setAllNodes] = useState([]);
    const [allEdges, setAllEdges] = useState([]);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [includeOrphans, setIncludeOrphans] = useState(true);
    const [selectedTypes, setSelectedTypes] = useState([
        "Application",
        "Collection",
        "Source"
    ]);

    const handleOnCollectionSelect = useCallback((selected) => {
        const associationsQueryFilter = selected.allDescendants.map(id => `&associations=${id}`).join('');
        setFilter(associationsQueryFilter);
    }, []);

    const handleOnColectionsUpdate = useCallback((selected) => {
    }, []);

    const handleNameFilterChange = (event) => {
        setNameFilter(event.target.value);
    };

    const handleTypeChange = useCallback((newSelectedTypes) => {
        setSelectedTypes(newSelectedTypes);
    }, []);

    const handleIncludeOrphansChange = (event) => {
        setIncludeOrphans(event.target.checked);
    };

    const onConnectClusterApi = useCallback(async (childIds, otherNodeId, reverse) => {
        try {
            const response = await apiCall('/source/add-associations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    childIds,
                    otherNodeId,
                    reverse
                }),
            });

            return true;
        } catch (error) {
            console.error('Error connecting cluster:', error);
            return false;
        }
    }, [apiCall]);

    const onEdgeDeleteClusterApi = useCallback(async (childIds, otherNodeId, reverse) => {
        try {
            const response = await apiCall('/source/remove-associations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    childIds,
                    otherNodeId,
                    reverse
                }),
            });

            return true;
        } catch (error) {
            console.error('Error deleting cluster edge:', error);
            return false;
        }
    }, [apiCall]);

    useEffect(() => {
        const fetchGraphData = async () => {
            try {
                const orphansParam = includeOrphans ? '&includeOrphans=true' : '';
                const filterParam = filter ? `&${filter.slice(1)}` : '';
                const url = `/graph/subgraph?a=a${filterParam}${orphansParam}`;
                // console.log('Fetching graph data with URL:', url);
                // console.log('Include orphans:', includeOrphans);
                // console.log(url);
                const response = await apiCall(url);
                // console.log("THE GRAPH RESPONSE:", response);

                const result = await response.data;
                // console.log("THE GRAPH DATA:", response.data)

                if (!result.data || !Array.isArray(result.data.nodes) || !Array.isArray(result.data.edges)) {
                    throw new Error('Invalid data structure received from API');
                }

                const fetchedNodes = result.data.nodes.map((node) => ({
                    id: node.id,
                    data: {
                        label: node.label,
                        type: node.type
                    },
                    position: { x: 0, y: 0 },
                    type: 'custom',
                    draggable: true
                }));

                const fetchedEdges = result.data.edges.map((edge, index) => ({
                    id: `e${index}`,
                    source: edge.source,
                    target: edge.target,
                }));

                setAllNodes(fetchedNodes);
                setAllEdges(fetchedEdges);
            } catch (error) {
                console.error('Error fetching graph data:', error);
                setError(error.message);
            }
        };

        fetchGraphData();
    }, [filter, includeOrphans]);

    const filteredNodes = useMemo(() => {
        return allNodes.filter(node => {
            const nodeTypes = Array.isArray(node.data.type) ? node.data.type : [node.data.type];

            const nameMatch = nodeTypes.some(type =>
                type.includes("System") ||
                type.includes("Collection") ||
                type.includes("Application")
            ) || node.data.label.toLowerCase().includes(nameFilter.toLowerCase());

            const typeMatch = nodeTypes.some(type =>
                selectedTypes.includes(type) ||
                (type.includes("System") && selectedTypes.includes("Application")) ||
                (type.includes("Collection") && selectedTypes.includes("Collection")) ||
                (type.includes("Application") && selectedTypes.includes("Application"))
            );

            return nameMatch && typeMatch;
        });
    }, [allNodes, nameFilter, selectedTypes]);

    const filteredEdges = useMemo(() => {
        return allEdges.filter(edge =>
            filteredNodes.some(node => node.id === edge.source) &&
            filteredNodes.some(node => node.id === edge.target)
        );
    }, [allEdges, filteredNodes]);

    const handleConnect = useCallback(async (sourceNode, targetNode) => {
        try {
            if (sourceNode.data.type.includes("Cluster") || targetNode.data.type.includes("Cluster")) {
                return;
            }

            const response = await apiCall(`/source/${targetNode.id}/associations/${sourceNode.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sourceId: sourceNode.id,
                    targetId: targetNode.id,
                    sourceType: sourceNode.data.type,
                    targetType: targetNode.data.type,
                }),
            });

            const result = await response.data;
            console.log('Backend connection created:', result);
            return true;
        } catch (error) {
            console.error('Error creating connection:', error);
            return false;
        }
    }, []);

    const handleEdgeDelete = useCallback(async (sourceNode, targetNode) => {
        try {
            const response = await apiCall(`/source/${targetNode.id}/associations/${sourceNode.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Backend connection deleted');
            return true;
        } catch (error) {
            console.error('Error deleting connection:', error);
            return false;
        }
    }, []);

    const handleNodeDelete = useCallback(async (deletedId) => {
        try {
            const response = await fetch(`/source/${deletedId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Backend node deleted');

            setAllNodes(prevNodes => prevNodes.filter(node => node.id !== deletedId));
            setAllEdges(prevEdges => prevEdges.filter(edge =>
                edge.source !== deletedId && edge.target !== deletedId
            ));

            return true;
        } catch (error) {
            console.error('Error deleting node:', error);
            return false;
        }
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>

            <Typography variant="h4" component="h1" gutterBottom>
                Graph-Based View of Data
            </Typography>
            <AssociationsDropdown
                rootAssociation={"AIssistant"}
                selected={"AIssistant"}
                onCollectionsUpdate={handleOnColectionsUpdate}
                onCollectionSelect={handleOnCollectionSelect}
            />
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '20px 0'
            }}>
                <TextField
                    sx={{ flexGrow: 1, marginRight: 2 }}
                    variant="outlined"
                    label="Filter sources by name"
                    value={nameFilter}
                    onChange={handleNameFilterChange}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeOrphans}
                            onChange={handleIncludeOrphansChange}
                            name="includeOrphans"
                        />
                    }
                    label="Include Orphans"
                />
                <TooltipWrapper title={"An orphan is a Source that is not associated with any collection."}/>
            </Box>
            <TypeSelector
                defaultSelectedTypes={[
                    "System",
                    "Application",
                    "Collection",
                    "Source"
                ]}
                possibleTypes={[
                    "System",
                    "Source",
                    "Collection",
                    "Date",
                    "Question",
                    "Terminology",
                    "Assignment",
                    "Recipe",
                    "Food Item",
                    "Food",
                    "Meal",
                    "Chat"
                ]}
                onChange={handleTypeChange}
                lockedTypes={["System"]}
            />
            <DataGraph
                initialNodes={filteredNodes}
                initialEdges={filteredEdges}
                onConnectApi={handleConnect}
                onEdgeDeleteApi={handleEdgeDelete}
                onNodeDelete={handleNodeDelete}
                onClusterEdgeDeleteApi={onEdgeDeleteClusterApi}
                onClusterConnectApi={onConnectClusterApi}
            />
        </>
    );
};

export default Graph;