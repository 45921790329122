import React, { useState, useCallback, useEffect } from 'react';
import ManagedCalendar from "../../../../components/calendar/ManagedCalendar";
import { Box, Container, Typography, Button } from '@mui/material';
import Extractor from "../../../../components/extract/Extractor";

function ProfessorCalendar() {
    const [showExtractor, setShowExtractor] = useState(false);
    const [selectedAssociation, setSelectedAssociation] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const handleCollectionSelect = useCallback((selectionInfo) => {
        setSelectedAssociation(selectionInfo);
    }, []);

    const handleUpdate = useCallback(() => {
        if (selectedAssociation) {
            console.log("Refreshing calendar with selected association:", selectedAssociation);
            setRefreshKey(prevKey => prevKey + 1);
        }
    }, [selectedAssociation]);

    useEffect(() => {
        if (selectedAssociation) {
            console.log("Selected association updated:", selectedAssociation);
        }
    }, [selectedAssociation]);

    return (
        <Container maxWidth="lg">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Important Dates</Typography>
                <Button
                    variant="contained"
                    onClick={() => setShowExtractor(!showExtractor)}
                >
                    {showExtractor ? 'Done Adding Dates' : 'Add Dates'}
                </Button>
            </Box>

            {showExtractor && (
                <Box sx={{ mb: 3 }}>
                    <Extractor
                        availableTypes={[{ id: "Date", label: "Date" }]}
                        fromQuery={'/source/search?'}
                        toQuery={'/source/search?'}
                        onSubmit={handleUpdate}
                        extractTo={selectedAssociation ? [selectedAssociation.id] : []}
                        showTo={false}
                        showType={false}
                    />
                </Box>
            )}

            <Box>
                <ManagedCalendar
                    key={refreshKey}
                    rootAssociation={"Professor"}
                    selected={selectedAssociation ? selectedAssociation.id : null}
                    onCollectionSelect={handleCollectionSelect}
                />
            </Box>
        </Container>
    );
}

export default ProfessorCalendar;