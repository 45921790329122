import React, { useState, useEffect } from 'react';
import {
    Typography, Box, TextField, Select, MenuItem, InputLabel, FormControl,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    useTheme, IconButton, Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const categories = ['low', 'medium', 'high'];
const healthCategories = ['unhealthy', 'moderately unhealthy', 'generally healthy', 'very healthy'];

const NutritionEstimatesDisplay = ({ nutritionEstimate, onUpdate, isEditing, showEditIcon = true }) => {
    const [localEstimate, setLocalEstimate] = useState(nutritionEstimate);
    const [editing, setEditing] = useState(isEditing);
    const [iconTriggeredEdit, setIconTriggeredEdit] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        setLocalEstimate(nutritionEstimate);
    }, [nutritionEstimate]);

    useEffect(() => {
        setEditing(isEditing);
        if (isEditing) {
            setIconTriggeredEdit(false);
        }
    }, [isEditing]);

    const handleChange = (field, value) => {
        const updatedEstimate = { ...localEstimate, [field]: value };
        setLocalEstimate(updatedEstimate);
        onUpdate(updatedEstimate);
    };

    const toggleEditing = () => {
        setEditing(!editing);
        setIconTriggeredEdit(!editing);
    };

    const handleDoneEditing = () => {
        setEditing(false);
        setIconTriggeredEdit(false);
    };

    const renderEditableField = (label, field, type = 'text') => (
        <TextField
            label={label}
            type={type}
            value={localEstimate[field]}
            onChange={(e) => handleChange(field, e.target.value)}
            fullWidth
            margin="normal"
            size="small"
        />
    );

    const renderCategorySelect = (label, field, options) => (
        <FormControl fullWidth margin="normal" size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                value={localEstimate[field]}
                onChange={(e) => handleChange(field, e.target.value)}
                label={label}
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const renderNutrientRow = (nutrient, value, unit) => (
        <TableRow key={nutrient}>
            <TableCell>{nutrient}</TableCell>
            <TableCell align="right">
                {editing ? (
                    <Box display="flex" alignItems="center">
                        <TextField
                            type="number"
                            value={value}
                            onChange={(e) => handleChange(nutrient.toLowerCase(), e.target.value)}
                            size="small"
                            sx={{ width: '80px', mr: 1 }}
                        />
                        <TextField
                            value={unit}
                            onChange={(e) => handleChange(`${nutrient.toLowerCase()}_unit`, e.target.value)}
                            size="small"
                            sx={{ width: '80px' }}
                        />
                    </Box>
                ) : (
                    `${value} ${unit}`
                )}
            </TableCell>
        </TableRow>
    );

    return (
        <Box sx={{ mt: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="h6" component="h3">Nutrition Estimates</Typography>
                <Typography variant="body2" color="text.secondary">
                    Serving: {localEstimate.quantity} {localEstimate.unit}
                </Typography>
            </Box>
            {editing ? (
                <Box sx={{ mt: 2 }}>
                    {renderEditableField('Name', 'name')}
                    {renderCategorySelect('Carb Category', 'carb_category', categories)}
                    {renderCategorySelect('Fat Category', 'fat_category', categories)}
                    {renderCategorySelect('Protein Category', 'protein_category', categories)}
                    {renderCategorySelect('Calorie Category', 'calorie_category', categories)}
                    {renderCategorySelect('Fiber Category', 'fiber_category', categories)}
                    {renderCategorySelect('Health Category', 'health_category', healthCategories)}
                    {renderEditableField('Description', 'description')}
                    <Box display="flex" justifyContent="space-between">
                        {renderEditableField('Quantity', 'quantity', 'number')}
                        {renderEditableField('Unit', 'unit')}
                    </Box>
                </Box>
            ) : null}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nutrient</TableCell>
                            <TableCell align="right">
                                Value
                                {showEditIcon && !isEditing && (
                                    <IconButton onClick={toggleEditing} size="small" sx={{ ml: 1 }}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderNutrientRow('Calories', localEstimate.calories, localEstimate.calories_unit || 'kcal')}
                        {renderNutrientRow('Fat', localEstimate.fat, localEstimate.fat_unit || 'g')}
                        {renderNutrientRow('Protein', localEstimate.protein, localEstimate.protein_unit || 'g')}
                        {renderNutrientRow('Carbs', localEstimate.carbs, localEstimate.carbs_unit || 'g')}
                        {renderNutrientRow('Fiber', localEstimate.fiber, localEstimate.fiber_unit || 'g')}
                        {renderNutrientRow('Net Carbs', localEstimate.net_carbs, localEstimate.net_carbs_unit || 'g')}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="body2" sx={{ mt: 2 }}>
                {localEstimate.description}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Categories: {localEstimate.carb_category} carb, {localEstimate.fat_category} fat,
                {localEstimate.protein_category} protein, {localEstimate.calorie_category} calorie,
                {localEstimate.fiber_category} fiber, {localEstimate.health_category}
            </Typography>
            {iconTriggeredEdit && !isEditing && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" onClick={handleDoneEditing}>
                        Done Editing Nutrition
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default NutritionEstimatesDisplay;