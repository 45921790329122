import React, { useState, useEffect } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const ContentField = ({ content, setContent, contentType, sourceTypes }) => {
    const [formData, setFormData] = useState({});
    const [jsonContent, setJsonContent] = useState('');

    useEffect(() => {
        if (contentType === 'application/json' && content) {
            try {
                const parsedContent = JSON.parse(content);
                setFormData(parsedContent);
                setJsonContent(JSON.stringify(parsedContent, null, 2));
            } catch (error) {
                console.error('Error parsing JSON content:', error);
            }
        } else {
            setJsonContent(content);
        }
    }, [content, contentType]);

    const handleInputChange = (field, value) => {
        setFormData(prevData => {
            const newData = { ...prevData, [field]: value };
            const newJsonContent = JSON.stringify(newData, null, 2);
            setJsonContent(newJsonContent);
            setContent(newJsonContent);
            return newData;
        });
    };

    const renderDateForm = () => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <DatePicker
                    label="Date"
                    value={formData.date ? new Date(formData.date) : null}
                    onChange={(newDate) => handleInputChange('date', newDate.toISOString().split('T')[0])}
                    renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                    label="Title"
                    value={formData.title || ''}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                />
                <TextField
                    label="Description"
                    value={formData.description || ''}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    multiline
                    rows={4}
                />
            </Box>
        </LocalizationProvider>
    );

    const renderQuestionForm = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
                label="Question"
                value={formData.question || ''}
                onChange={(e) => handleInputChange('question', e.target.value)}
                multiline
                rows={2}
            />
            {['Answer_1', 'Answer_2', 'Answer_3', 'Answer_4'].map((answerKey) => (
                <TextField
                    key={answerKey}
                    label={`Option ${answerKey.split('_')[1]}`}
                    value={formData.options?.[answerKey] || ''}
                    onChange={(e) => handleInputChange('options', { ...formData.options, [answerKey]: e.target.value })}
                />
            ))}
            <FormControl fullWidth>
                <InputLabel>Correct Answer</InputLabel>
                <Select
                    value={formData.correct_answer || ''}
                    onChange={(e) => handleInputChange('correct_answer', e.target.value)}
                >
                    {['Answer_1', 'Answer_2', 'Answer_3', 'Answer_4'].map((answerKey) => (
                        <MenuItem key={answerKey} value={answerKey}>{answerKey}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Explanation"
                value={formData.explanation || ''}
                onChange={(e) => handleInputChange('explanation', e.target.value)}
                multiline
                rows={4}
            />
        </Box>
    );

    const renderTerminologyForm = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
                label="Terminology"
                value={formData.terminology || ''}
                onChange={(e) => handleInputChange('terminology', e.target.value)}
            />
            <TextField
                label="Description"
                value={formData.description || ''}
                onChange={(e) => handleInputChange('description', e.target.value)}
                multiline
                rows={4}
            />
        </Box>
    );

    const renderContentForm = () => {
        const primaryType = sourceTypes[0];
        switch (primaryType) {
            case 'Date':
                return renderDateForm();
            case 'Question':
                return renderQuestionForm();
            case 'Terminology':
                return renderTerminologyForm();
            default:
                return null;
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
                Content
            </Typography>
            {contentType === 'JSON' && sourceTypes.length > 0 ? (
                renderContentForm()
            ) : (
                <TextField
                    fullWidth
                    multiline
                    rows={6}
                    value={jsonContent}
                    onChange={(e) => {
                        setJsonContent(e.target.value);
                        setContent(e.target.value);
                    }}
                />
            )}
        </Box>
    );
};

export default React.memo(ContentField);