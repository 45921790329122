import React from 'react';
import RAGSimpleSpecificSourcesChat from "../../../../../components/rag/RAGSimpleSpecificSourcesChat";

const GymWorkoutRAGChat = ({ workouts, currentGoal }) => {
    const workoutIds = workouts.map(workout => workout.id);

    const workoutSpecialInstructions = "The user is chatting with you about their gym workouts based on their recorded exercises. Provide a detailed and helpful response. You are a professional fitness trainer.";

    const workoutTitle = "Chat with the Fitness Trainer about your workouts";
    const workoutSubtitle = "All recorded workouts";

    return (
        <RAGSimpleSpecificSourcesChat
            ids={workoutIds}
            context={`The user's current goal is: ${currentGoal}`}
            specialInstructions={workoutSpecialInstructions}
            title={workoutTitle}
            subtitle={workoutSubtitle}
        />
    );
};

export default GymWorkoutRAGChat;